<template>
  <v-row class="pl-3 pr-3 pt-3">
    <v-combobox
      outlined
      ref="locations"
      :append-icon="isOnline ? 'map' : ''"
      :items="items"
      :id="id"
      :label="label"
      counter="255"
      maxlength="255"
      :placeholder="placeholder"
      :rules="rules"
      :disabled="disabled"
      :value="value"
      @click:append="getAddressByLocation"
      @change="$emit('change', $event)"
    />
    <v-progress-linear
      :active="loading"
      class="mt-n8 mr-1"
      :indeterminate="loading"
      reverse
      color="primary"
    ></v-progress-linear>
  </v-row>
</template>
<script>
import { REVERSE_GEOCODE_QUERY } from "@/constants/geocoding-queries";
import getEnv from "@/utilities/env.js";

export default {
  name: "GpsAddress",
  props: {
    value: String,
    label: String,
    placeholder: String,
    disabled: Boolean,
    id: String,
    rules: Array,
    isOnline: Boolean
  },
  data() {
    return {
      loading: false,
      select: this.value,
      items: []
    };
  },
  methods: {
    async getAddressByLocation() {
      this.loading = true;

      // 900_000 is 15 minutes.
      let options = {
        enableHighAccuracy: true,
        maximumAge: getEnv("GPS_CACHE_MAXIMUM_AGE") || 900000,
        timeout: 60000
      };

      if (navigator.geolocation) {
        await navigator.geolocation.getCurrentPosition(
          async position => {
            const availableAddresses = await this.$apollo.query({
              query: REVERSE_GEOCODE_QUERY,
              variables: {
                lat: position.coords.latitude,
                long: position.coords.longitude
              }
            });
            const currentAddresses = availableAddresses.data.reverseGeocode.map(
              i => {
                return i.formattedAddress;
              }
            );
            this.items = currentAddresses;
            if (this.items) {
              this.select = this.items[0];
              this.$emit("change", this.select);
            }
            this.loading = false;
          },
          error => {
            this.loading = false;
            console.warn(`ERROR(${error.code}): ${error.message}`);
          },
          options
        );
      } else {
        console.error(`unable to read geolocation`);
      }
    }
  }
};
</script>
