<template>
  <v-combobox
    class="mt-2"
    outlined
    :clearable="selected ? true : false"
    v-model="selected"
    :items="items"
    :disabled="disabled"
    :rules="rules"
    @change="onChangeEvent($event)"
  />
</template>
<script>
import dataAccess from "@/js/data-access.js";

export default {
  name: "Combobox",
  props: {
    source: String,
    id: String,
    disabled: Boolean,
    initialValue: String,
    rules: Array
  },
  data() {
    return {
      selected: null,
      items: []
    };
  },
  watch: {
    initialValue: {
      immediate: true,
      handler: function(value) {
        if (value) this.selected = { value: value, text: value };
      }
    },
    source: {
      immediate: true,
      handler: async function(value) {
        const sourceList = await dataAccess.getEntitiesAsync(value);
        if (sourceList) {
          this.items = sourceList.map(s => {
            return {
              text: s.externalRef,
              value: s.externalRef
            };
          });
        }
      }
    }
  },
  methods: {
    onChangeEvent(e) {
      if (e && e.text) this.$emit("change", e.text);
      else {
        if (!e) {
          this.selected = null;
          this.$emit("change", null);
        } else this.$emit("change", e);
      }
    }
  }
};
</script>
