// All masking functions MUST return the masked AND raw values back.
// Basically, any dev should be able to add AND remove the same mask from a value.

/**
 * phoneMask
 * @param {string} val - The value to be masked.
 * @returns {string} Masked string with the pattern (000) 000-0000
 */
export function phoneMask(val) {
  if (!val) return;

  // first we remove everything but numbers
  const raw = val.replace(/\D/g, "");

  // we then get the groups
  /* eslint-disable */
  const [_, region, group1, group2] = raw.match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

  let masked;

  if (!group1) masked = region;
  else {
    masked = `(${region}) ${group1}`;
    if (group2) masked += `-${group2}`;
  }

  return {
    masked,
    raw,
  };
}
