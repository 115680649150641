<template>
  <div class="pb-3">
    <v-row class="component-header">
      <v-spacer />
      <v-btn
        class="primary--text mb-1"
        text
        :disabled="disabled"
        @click="searchCrewDialog = true"
      >
        <v-icon class="mr-3"> add_circle_outline </v-icon>
        Add Employees
      </v-btn>
      <employees-search
        :searchCrewDialogShow="searchCrewDialog"
        :membersList="selected"
        title="Add Names for Signature"
        :multiple="true"
        :cancelAction="cancelSearchAction"
        @done="doneAction"
        @delete="removeCrewMember"
      />
    </v-row>
    <v-row>
      <v-simple-table class="table-theme" fixed-header>
        <template>
          <thead>
            <tr>
              <th>Employee name <span class="red--text ml-2">*</span></th>
              <th>Title</th>
              <th>Vehicle #</th>
              <th>Trailer</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(crew, index) in selected"
              :key="index + 1"
              style="height: 52px"
            >
              <td>
                {{
                  `${index + 1}. ${crew.employee.firstName} ${
                    crew.employee.lastName
                  }`
                }}
              </td>
              <td>{{ crew.employee.title }}</td>
              <td>
                <v-combobox
                  class="mt-2"
                  outlined
                  dense
                  :clearable="crew.vehicle && crew.vehicle.value ? true : false"
                  v-model="crew.vehicle"
                  :items="vehicles"
                  :disabled="disabled"
                  @blur="verifyData"
                />
              </td>
              <td>
                <v-combobox
                  class="mt-2"
                  outlined
                  dense
                  :clearable="crew.trailer && crew.trailer.value ? true : false"
                  v-model="crew.trailer"
                  :items="trailers"
                  :disabled="disabled"
                  @blur="verifyData"
                />
              </td>
              <td>
                <v-btn
                  :disabled="disabled"
                  icon
                  v-if="user && crew.employee.id !== user.employee.id"
                >
                  <v-icon @click="deleteEmployee(crew)">cancel</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <dialog-message
        :showDialogMessage="showDialogMessage"
        displayCaption="Delete Employee"
        :displayText="getSelectedEmployeeName"
        :okAction="deleteAction"
        :cancelAction="cancelAction"
      />
      <dialog-message
        :showDialogMessage="showIneligibleDialogMessage"
        displayCaption="Ineligible Employee"
        :displayText="ineligibleDialogMessageDisplayText"
        :okAction="okIneligibleEmployeeAction"
        okButtonText="Ok"
      />
    </v-row>
  </div>
</template>
<script>
import employeesSearch from "@/components/submission/employees-search.vue";
import dialogMessage from "@/components/submission/dialog-message.vue";

export default {
  name: "EmployeeManagment",
  components: {
    dialogMessage,
    employeesSearch
  },
  props: {
    crewLeaderId: String,
    membersList: Array,
    employeeVisitorList: Array,
    disabled: Boolean,
    id: String,
    refresh: { type: Function }
  },
  data() {
    return {
      searchCrewDialog: false,
      tempSelected: -1,
      showDialogMessage: false,
      selectedEmployee: null,
      inEligibleEmployees: [],
      showIneligibleDialogMessage: false
    };
  },
  updated: function() {
    this.$nextTick(function() {
      if (this.tempSelected !== 1) {
        this.refresh(this.id, [0, 0]);
        this.tempSelected = 1;
      }
    });
  },
  watch: {
    membersList: {
      handler(newVal, oldVal) {
        // Check if membersList actually changed to prevent unnecessary calls
        if (newVal !== oldVal) {
          this.validateEmployees();

          if (this.inEligibleEmployees && this.inEligibleEmployees.length > 0) {
            this.removeIneligibleEmployees();
            this.showIneligibleDialogMessage = true;
          }
        }
      },
      deep: true
    }
  },
  computed: {
    selected() {
      return this.membersList.map(e => {
        return {
          id: e.id,
          employee: e.employee,
          vehicle: {
            value: e.vehicle ? e.vehicle.id : null,
            text: e.vehicle ? e.vehicle.vehicleRef : null
          },
          trailer: {
            value: e.trailer ? e.trailer.id : null,
            text: e.trailer ? e.trailer.vehicleRef : null
          }
        };
      });
    },
    getSelectedEmployeeName() {
      return this.selectedEmployee && this.selectedEmployee.employee
        ? `Please confirm that you want to delete employee name: ${this.selectedEmployee.employee.firstName} ${this.selectedEmployee.employee.lastName}<br><br>
        
        This will remove the employee from all related dropdowns. If you have already selected this employee on another page, your selection will be deleted.`
        : "";
    },
    ineligibleDialogMessageDisplayText() {
      if (!this.inEligibleEmployees || this.inEligibleEmployees.length === 0) {
        return "";
      }

      const names = this.inEligibleEmployees
        .map(ie => `${ie.employee.firstName} ${ie.employee.lastName}`)
        .join(", ");

      return `The following employee(s) could not be added because they are listed on the Visitors/Contractors page: ${names}`;
    },
    user() {
      return this.$store.getters.getUser;
    },
    vehicles() {
      return this.$store.getters.getVehicles
        .filter(v => !v.isTrailer)
        .map(this.toItem);
    },
    trailers() {
      return this.$store.getters.getVehicles
        .filter(v => v.isTrailer)
        .map(this.toItem);
    }
  },
  methods: {
    verifyData() {
      this.$emit("change", this.mapSelectedToEmployees());
    },
    toItem({ id, vehicleRef }) {
      return {
        value: id,
        text: vehicleRef
      };
    },
    deleteAction() {
      if (this.selectedEmployee) {
        this.removeCrewMember(this.selectedEmployee);
        this.$emit("delete", [this.selectedEmployee.id]);
        this.selectedEmployee = null;
        this.showDialogMessage = false;
      }
    },
    getValidationResult() {
      return [0, 0];
    },
    verifyProperty(property) {
      return property
        ? {
            id: property.value,
            vehicleRef: property.text
          }
        : {
            id: null,
            vehicleRef: null
          };
    },
    mapSelectedToEmployees() {
      let result = this.selected.map(e => {
        return {
          id: e.id,
          employee: e.employee,
          trailer: this.verifyProperty(e.trailer),
          vehicle: this.verifyProperty(e.vehicle)
        };
      });
      return result;
    },
    validateEmployees() {
      this.inEligibleEmployees = [];

      this.employeeVisitorList.forEach(employeeVisitor => {
        const found = this.membersList.some(
          e => e.employee.id === employeeVisitor.employee.id
        );
        if (found) {
          this.inEligibleEmployees.push(employeeVisitor);
        }
      });
    },
    removeIneligibleEmployees() {
      let employeeRefs = this.inEligibleEmployees.map(
        employeeObj => employeeObj.employee.employeeRef
      );

      this.$emit("delete", employeeRefs);
    },
    doneAction() {
      this.$emit("change", this.mapSelectedToEmployees());

      this.searchCrewDialog = false;
    },
    cancelAction() {
      this.showDialogMessage = false;
    },
    cancelSearchAction() {
      this.searchCrewDialog = false;
    },
    deleteEmployee(employee) {
      this.selectedEmployee = employee;
      this.showDialogMessage = true;
    },
    removeCrewMember(crew) {
      if (crew.employee.employeeRef != this.user.employee.employeeRef) {
        this.$emit("delete", [crew.employee.employeeRef]);
      }
    },
    okIneligibleEmployeeAction() {
      this.showIneligibleDialogMessage = false;
    }
  }
};
</script>
<style scoped>
.table-theme {
  width: 100% !important;
}
.table-theme table th {
  color: #005d55 !important;
}

.table-theme table tr td:last-child {
  width: 1%;
  white-space: nowrap;
}
</style>
