<template>
  <component :class="[{ 'is-na': isNA }, className]" v-bind:is="tag">
    <slot></slot>
  </component>
</template>

<script>
export default {
  name: "PreviewFormTitle",
  props: {
    level: Number,
    isNA: Boolean
  },
  computed: {
    tag() {
      return `h${this.level}`;
    },
    className() {
      return `preview-form-title preview-form-title-h${this.level}`;
    }
  }
};
</script>

<style scoped>
.preview-form-title {
  font-family: "Soho Gothic W01", "Roboto", sans-serif;
  text-transform: uppercase;
  color: var(--v-primary-base);
  column-span: all;
  font-weight: 900;
}

.preview-form-title.is-na {
  grid-column: span 2;
}

.preview-form-title-h1 {
  font-weight: 600;
  font-size: 2.4em;
  margin: 10px 0 16px;
}

.preview-form-title-h2 {
  border-bottom: 2px solid var(--v-primary-base);
  font-size: 1.6em;
  padding: 4px 8px;
  margin: 4px 0 6px;
}

.preview-form-title-h3 {
  padding: 2px 8px;
  margin: 4px 0;
  color: white;
  background-color: var(--v-primary-base);
  font-size: 1.2em;
}

.preview-form-title-h4 {
  padding: 1em 8px;
  margin: 8px;
  background-color: var(--v-whiteBackground-base);
  font-size: 1.1em;
  border-radius: 0.2em;
}

.preview-form-title-h4.is-na {
  padding-left: 0.6em;
  font-size: 1.4em;
}

.preview-form-title-h5 {
  padding: 1em;
  margin: 4px;
  background-color: var(--v-whiteBackground-base);
  font-size: 1em;
  border-radius: 0.2em;
}
</style>
