<template>
  <v-dialog
    v-model="showChildDialog"
    persistent
    max-width="412px"
    max-height="438px"
    style="z-index:9999;"
  >
    <v-card>
      <v-card-title>
        <span>{{ caption }}</span>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-container>
          <v-row>
            <v-text-field
              outlined
              class="ma-2"
              dense
              label="Type the form name or insert from list below."
              prepend-inner-icon="search"
              v-model="search"
              clearable
              @input="findItem($event)"
              @click:clear="clearSearch"
            />
          </v-row>
          <v-list two-line>
            <template v-for="(simple, index) in childForms">
              <v-list-item
                :key="`simpleForm-${index + 1}`"
                ripple
                :disabled="simple.disabled"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ `${simple.text}` }}</v-list-item-title>
                  <v-list-item-subtitle class="gray--text">{{
                    simple.instructions
                  }}</v-list-item-subtitle>
                  <v-progress-linear
                    v-if="simple.inProgress === true"
                    background-color="secondary darken-2"
                    height="3"
                    :indeterminate="simple.inProgress"
                    rounded
                    class="ma-0 pa-0"
                    color="accent darken-1"
                  ></v-progress-linear>
                </v-list-item-content>
                <v-list-item-action
                  @click="selectItem(simple)"
                  class="cursor-pointer"
                >
                  <v-icon v-if="simple.selected" color="red">
                    remove_circle_outline
                  </v-icon>
                  <v-icon
                    v-else
                    :color="simple.disabled ? 'secondary' : 'primary'"
                  >
                    add_circle_outline
                  </v-icon>
                </v-list-item-action>
              </v-list-item>
              <v-divider
                v-if="index + 1 < childForms.length"
                :key="`divider-${index}`"
              ></v-divider>
            </template>
          </v-list>
        </v-container>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="cancelAction"> Cancel </v-btn>
        <v-btn
          class="primary"
          :disabled="!multiple && (!isSelected || loading)"
          :loading="loading"
          @click="goToChildForm"
        >
          Done
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "SelectChildForm",
  props: {
    multiple: Boolean,
    caption: String,
    childFormsData: Array,
    showChildDialog: Boolean,
    loading: Boolean,
    selectItemAction: { type: Function },
    selectAction: { type: Function },
    cancelAction: { type: Function }
  },
  watch: {
    childFormsData: {
      immediate: true,
      deep: true,
      handler: function(val) {
        return (this.childForms = val);
      }
    }
  },
  data() {
    return {
      childForms: [],
      selected: [],
      search: ""
    };
  },
  computed: {
    isSelected() {
      return this.childForms.some(v => v.selected === true);
    }
  },
  methods: {
    goToChildForm() {
      const result = this.childForms.filter(c => c.selected === true);
      this.selectAction(
        this.multiple ? result : result.length > 0 ? result[0] : null
      );
    },
    selectItem(item) {
      if (!this.multiple) {
        this.childForms.forEach(i => {
          i.selected = i.id === item.id;
        });
      } else item.selected = !item.selected;
      if (this.selectItemAction) this.selectItemAction(item);
    },
    findItem(item) {
      this.childForms = this.childFormsData.filter(i => i.text.includes(item));
    },
    clearSearch() {
      this.search = "";
    }
  }
};
</script>
