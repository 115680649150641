<template>
  <div class="component-job-details">
    <preview-form-component>
      <preview-form-title :level="3">Details</preview-form-title>

      <preview-form-group>
        <preview-input-string
          :name="'Date initiated'"
          :value="submission.tailboardFormHeader.date"
        />
        <preview-input-string
          :name="'ESRR#'"
          :value="submission.tailboardFormHeader.esrr"
        />
        <preview-input-string
          :name="'Work Order#'"
          :value="submission.tailboardFormHeader.workOrderNumber"
        />
        <preview-input-string
          :name="'WBS'"
          :value="submission.tailboardFormHeader.wbs"
        />
        <preview-input-string
          :name="'Field Activity ID'"
          :value="submission.tailboardFormHeader.fieldActivityId"
        />
        <preview-input-string
          :name="'Work Description'"
          :value="submission.tailboardFormHeader.workDescription"
        />
        <preview-input-string
          :name="'Department'"
          :value="submission.tailboardFormHeader.dept"
        />
        <preview-input-string
          :name="'Cost Center#'"
          :value="submission.tailboardFormHeader.rcNumber"
        />
        <preview-input-string
          :name="'Work Site Location'"
          :value="submission.tailboardFormHeader.location"
        />
        <preview-input-string
          :name="'Project Name'"
          :value="submission.tailboardFormHeader.projectName"
        />
      </preview-form-group>
    </preview-form-component>

    <preview-form-component>
      <preview-form-title :level="3">Crew Contact Details</preview-form-title>

      <preview-form-group>
        <preview-input-employee
          :name="'Supervisor Name'"
          :value="submission.supervisorId"
        />
        <preview-input-string
          :name="'Supervisor Phone'"
          :value="submission.supervisorPhone"
        />
        <preview-input-employee
          :name="'Crew Leader Name'"
          :value="submission.crewLeaderId"
        />
        <preview-input-string
          :name="'Crew Leader Phone'"
          :value="submission.crewLeaderPhone"
        />
      </preview-form-group>
    </preview-form-component>
  </div>
</template>

<script>
import PreviewFormComponent from "./preview-form-component.vue";
import PreviewFormGroup from "./preview-form-group.vue";
import PreviewFormTitle from "./preview-form-title.vue";
import PreviewInputString from "./preview-input-string.vue";
import PreviewInputEmployee from "./preview-input-employee.vue";

export default {
  name: "PreviewComponentJobDetails",
  props: {
    submission: Object
  },
  components: {
    PreviewFormComponent,
    PreviewFormGroup,
    PreviewFormTitle,
    PreviewInputString,
    PreviewInputEmployee
  }
};
</script>
