<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="50"
    viewBox="0 0 50 50"
  >
    <g
      fill="none"
      fill-rule="evenodd"
      stroke="#005d55"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    >
      <path d="M33 35H18V15h7.5l7.5 6.667z" />
      <path d="M32 21h-8v-6" />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Pdf"
};
</script>
