<template>
  <v-dialog v-model="dialog" persistent max-width="500px">
    <v-card>
      <v-card-title>
        <span class="headline">Edit User</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col>
              <v-text-field
                outlined
                required
                v-model="formData.name"
                label="Name"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                outlined
                required
                v-model="formData.email"
                label="Email"
                :rules="[rules.required, rules.email]"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                outlined
                required
                v-model="formData.role"
                label="Role"
                :items="roleOptions"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="$emit('close')">Cancel</v-btn>
        <v-btn
          class="primary"
          :loading="loading"
          :disabled="!valid"
          @click="submit"
          >Submit</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {
  UPDATE_USER_MUTATION,
  UPDATE_USER_ROLE_MUTATION
} from "@/graphql/accounts/mutations.js";

export default {
  name: "UserEdit",

  props: {
    value: Object,
    dialog: Boolean
  },

  data() {
    return {
      formData: {
        id: this.value.id,
        name: this.value.name,
        email: this.value.email,
        role: this.value.role
      },
      roleOptions: [
        { value: "ADMIN", text: "Admin" },
        { value: "SAFETY", text: "Safety" },
        { value: "MANAGER", text: "Manager" },
        { value: "SUPERVISOR", text: "Supervisor" },
        { value: "USER", text: "User" }
      ],
      rules: {
        required: value => !!value || "Required.",
        email: value => /.+@.+\..+/.test(value) || "Invalid email."
      },
      loading: false,
      valid: true,
      success: true
    };
  },

  methods: {
    submit() {
      try {
        this.loading = true;

        Promise.all([this.updateUser(), this.updateUserRole()]).then(
          responses => {
            this.loading = false;
            this.success = true;

            this.onUserUpdateFulfilled(responses[0]);
            this.onUserRoleUpdateFulfilled(responses[1]);

            if (this.success) {
              this.$emit("close");
            }
          }
        );
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    },

    async updateUser() {
      return this.$apollo.mutate({
        mutation: UPDATE_USER_MUTATION,
        variables: {
          id: this.formData.id,
          name: this.formData.name,
          email: this.formData.email
        }
      });
    },

    async updateUserRole() {
      return this.$apollo.mutate({
        mutation: UPDATE_USER_ROLE_MUTATION,
        variables: {
          id: this.formData.id,
          role: this.formData.role
        }
      });
    },

    onUserUpdateFulfilled({ data }) {
      const { successful } = data.updateUser;

      if (!successful) {
        this.success = false;
      }
    },

    onUserRoleUpdateFulfilled({ data }) {
      const { successful } = data.updateUserRole;

      if (!successful) {
        this.success = false;
      }
    }
  }
};
</script>
