import Appsignal from "@appsignal/javascript";
import getEnv from "@/utilities/env.js";
import * as windowEvents from "@appsignal/plugin-window-events";
import * as pathDecorator from "@appsignal/plugin-path-decorator";

const appsignal = new Appsignal({
  key: getEnv("VUE_APP_APPSIGNAL_KEY"),
  revision: getEnv("VUE_APP_VERSION")
});

appsignal.use(windowEvents.plugin());
appsignal.use(pathDecorator.plugin());

export default appsignal;
