<template>
  <v-dialog
    v-model="showDialogMessage"
    max-width="512px"
    max-height="438px"
    persistent
  >
    <v-card>
      <v-card-title>
        <span class="headline" v-html="displayCaption" />
      </v-card-title>
      <v-card-text>
        <p v-html="displayText" />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="cancelAction" v-if="cancelAction"> No </v-btn>
        <v-btn class="primary ml-2" @click="okAction">
          {{ okButtonText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DialogMessage",
  props: {
    showDialogMessage: Boolean,
    displayText: String,
    displayCaption: String,
    okAction: { type: Function },
    cancelAction: { type: Function },
    okButtonText: {
      type: String,
      default: "Yes"
    }
  }
};
</script>
