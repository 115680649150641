<template>
  <v-combobox
    outlined
    :id="id"
    class="mb-0"
    :placeholder="placeholder"
    :multiple="multiple"
    v-model="value"
    :items="items"
    :search-input.sync="search"
    :disabled="disabled"
    :error-messages="validateRules"
    @change="$emit('change', $event)"
  >
    <template v-slot:no-data>
      <v-list-item>
        <span class="subheading pr-1">Create other: </span>
        {{ search }}
      </v-list-item>
    </template>
  </v-combobox>
</template>
<script>
export default {
  name: "AllowOtherCombobox",

  props: {
    id: String,
    placeholder: String,
    disabled: Boolean,
    multiple: Boolean,
    initialValue: String,
    initialItems: Array,
    rules: Array
  },
  computed: {
    validateRules() {
      if (this.rules && this.rules.length > 0) {
        let required = this.rules[0];
        const result = required(this.initialValue);
        return result === true ? "" : result;
      } else return "";
    }
  },
  created() {
    this.value = this.multiple
      ? this.initialValue
        ? this.initialValue.split(",")
        : []
      : this.initialValue;
  },
  data() {
    return {
      value: null,
      items: [
        {
          header: "Select an option or create one",
          divider: true
        },
        ...this.initialItems.map(x => x.text)
      ],
      search: null
    };
  }
};
</script>
