<template>
  <preview-form-component class="preview-component-critical-tasks-assessment">
    <preview-form-title :level="3">Critical Tasks</preview-form-title>

    <preview-form-group v-if="criticalTasks && criticalTasks.length > 0">
      <preview-input-select-many
        :name="'Critical Tasks'"
        :value="criticalTasks"
      />
    </preview-form-group>
  </preview-form-component>
</template>

<script>
import PreviewFormComponent from "./preview-form-component.vue";
import PreviewFormGroup from "./preview-form-group.vue";
import PreviewFormTitle from "./preview-form-title.vue";
import PreviewInputSelectMany from "./preview-input-select-many.vue";

export default {
  name: "PreviewComponentCriticalTasksAssessment",
  props: {
    submission: Object
  },
  components: {
    PreviewFormComponent,
    PreviewFormGroup,
    PreviewFormTitle,
    PreviewInputSelectMany
  },
  computed: {
    criticalTasks() {
      const { criticalTasksAssessment } = this.submission;

      if (!criticalTasksAssessment || criticalTasksAssessment.length <= 0)
        return [];

      const { criticalTasksIds } = criticalTasksAssessment;

      if (!criticalTasksIds || criticalTasksIds.length <= 0) return [];

      return this.$store.getters.getCriticalTasks
        .filter(({ id }) => criticalTasksIds.includes(id))
        .map(({ name }) => name);
    }
  }
};
</script>
