<template>
  <v-menu
    v-model="menu"
    min-width="290px"
    :close-on-content-click="false"
    :disabled="disabled"
    offset-y
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        outlined
        v-on="on"
        :id="id"
        :label="label"
        :placeholder="placeholder"
        append-icon="today"
        @click="setToday()"
        @click:append="setToday()"
        @click:clear="$emit('change', null)"
        :value="value"
        :rules="rules"
        :disabled="disabled"
        clearable
        readonly
      />
    </template>
    <v-date-picker :value="value" @input="onChange($event)" />
  </v-menu>
</template>
<script>
import dayjs from "@/plugins/dayjs";
export default {
  name: "Datepicker",

  props: {
    id: String,
    label: String,
    placeholder: String,
    value: String,
    disabled: Boolean,
    rules: Array
  },

  data() {
    return {
      menu: false,
      date: this.value
    };
  },

  methods: {
    setToday() {
      this.date = dayjs().format("YYYY-MM-DD");
      this.$emit("change", this.date);
    },
    onChange(val) {
      this.date = val;
      this.$emit("change", val);
      this.menu = false;
    }
  }
};
</script>
