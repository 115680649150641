<template>
  <div class="preview-form-group">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "PreviewFormGroup"
};
</script>

<style scoped>
.preview-form-group {
  padding: 4px 8px;
}
</style>
