<template>
  <component :is="as" class="preview-input-value"><slot></slot></component>
</template>

<script>
export default {
  name: "PreviewInputValue",
  props: {
    as: {
      type: String,
      default: "p"
    }
  }
};
</script>

<style scoped>
.preview-input-value {
  font-size: 1em;
  font-weight: 700;
  background-color: white;
  border-radius: 4px;
  margin: 0;
  padding: 4px;
  page-break-inside: avoid;
}
</style>
