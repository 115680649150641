<template>
  <v-row>
    <v-col
      align="center"
      class="d-md-flex ma-0 pa-0"
      :id="id"
      :value="forecast"
    >
      <v-text-field
        outlined
        v-model="forecast.description"
        label="Description"
        :disabled="disabled"
        :rules="rules"
        @input="updateWeather"
      />
      <v-text-field
        outlined
        v-model="forecast.temperature"
        label="Temperature"
        :disabled="disabled"
        suffix="°C"
        :rules="rules"
        @input="updateWeather"
      />
      <v-text-field
        outlined
        v-model="forecast.heatIndex"
        label="Humidex"
        suffix="°C"
        :rules="rules"
        :disabled="disabled"
        @input="updateWeather"
      />
      <v-text-field
        outlined
        v-model="forecast.windChill"
        label="Wind Chill"
        suffix="°C"
        :rules="rules"
        :disabled="disabled"
        @input="updateWeather"
      />
      <v-btn
        :disabled="disabled || !isOnline"
        class="weather-btn"
        color="primary"
        @click="getWeatherByLocation"
      >
        <v-icon> search </v-icon>
      </v-btn>
    </v-col>
    <v-progress-linear
      :active="loading"
      class="mt-n8 mr-1"
      :indeterminate="loading"
      reverse
      color="primary"
    ></v-progress-linear>
  </v-row>
</template>
<script>
import { WEATHER_GEOCODE_QUERY } from "../../constants/geocoding-queries";
import getEnv from "@/utilities/env.js";
import dayjs from "@/plugins/dayjs";
export default {
  name: "GpsWeather",
  props: {
    label: String,
    value: String,
    placeholder: String,
    disabled: Boolean,
    id: String,
    rules: Array,
    isOnline: Boolean
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    forecast() {
      return this.value
        ? JSON.parse(this.value)
        : {
            description: null,
            temperature: null,
            heatIndex: null,
            windChill: null,
            dateTime: null
          };
    }
  },
  methods: {
    updateWeather() {
      if (Object.keys(this.forecast).some(e => this.forecast[e] !== null)) {
        this.forecast.dateTime = dayjs.utc().format();
        this.$emit("change", JSON.stringify(this.forecast));
      }
    },
    async getWeatherByLocation() {
      this.loading = true;
      if (navigator.geolocation) {
        // 900_000 is 15 minutes.
        let options = {
          enableHighAccuracy: true,
          maximumAge: getEnv("GPS_CACHE_MAXIMUM_AGE") || 900000,
          timeout: 60000
        };
        await navigator.geolocation.getCurrentPosition(
          async position => {
            const availableWeather = await this.$apollo.query({
              query: WEATHER_GEOCODE_QUERY,
              variables: {
                lat: position.coords.latitude,
                long: position.coords.longitude
              }
            });
            if (availableWeather) {
              const currentWeather = availableWeather.data.weatherForecast;
              this.forecast.description = currentWeather.description;
              this.forecast.temperature = currentWeather.temperature;
              this.forecast.heatIndex = currentWeather.heatIndex;
              this.forecast.windChill = currentWeather.windChill;
              this.updateWeather();
            }
            this.loading = false;
          },
          error => {
            this.loading = false;
            console.warn(`ERROR(${error.code}): ${error.message}`);
          },
          options
        );
      } else {
        console.error(`unable to read geolocation for weather`);
      }
    }
  }
};
</script>
<style scoped>
.weather-btn {
  height: 56px !important;
}
</style>
