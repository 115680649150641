<template>
  <preview-input-any-file :name="name" :value="value" />
</template>

<script>
import PreviewInputAnyFile from "./preview-input-any-file.vue";

export default {
  name: "PreviewInputFile",
  props: {
    name: String,
    value: Array
  },
  components: {
    PreviewInputAnyFile
  }
};
</script>
