<template id="submissionId">
  <div class="submission-main">
    <loading-screen
      :show="isLoading"
      :locked="isLocked"
      lockedMessage="THIS FORM SUBMISSION IS LOCKED"
    />
    <dialog-message
      :showDialogMessage="showAlertMessage"
      displayCaption="Alert"
      displayText="This tailboard has been updated by another user. You will be navigated to the dashboard"
      :okAction="okAction"
    />
    <div
      class="submission-side-bar"
      :class="{ 'side-bar': collaps }"
      v-if="isIPad"
    >
      <v-btn fab outlined x-small color="primary" @click="collaps = false">
        <v-icon color="primary"> keyboard_arrow_right </v-icon>
      </v-btn>
      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            outlined
            x-small
            color="white"
            class="mt-5"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon color="primary">list</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="(section, index) in sortedSections"
            :key="`list-item-${index}`"
            @click="goToSection(index + 1)"
            :class="{
              'section-selected': currentStep == index + 1,
              'section-disabled':
                index > 0 && jobDetailsComplete !== jobDetailsRequiredFields
            }"
          >
            <sections-list
              :currentStep="currentStep"
              :section="section"
              :disabled="inapplicableSectionIds.includes(section.id)"
              :index="index"
            />
          </v-list-item>
        </v-list>
      </v-menu>
      <div class="state-holder">
        <p>
          {{
            tailboardFormSubmission
              ? pascalCase(tailboardFormSubmission.state)
              : "In Progress"
          }}
        </p>
      </div>
    </div>
    <div
      class="submission-nav mb-1"
      :class="{ collaps: collaps }"
      :key="tailboardFormSubmission.formId"
      v-show="!isFullscreen"
    >
      <div class="pa-2 white">
        <div class="d-flex">
          <p
            class="text-decoration-underline mt-4 cursor-pointer"
            @click="gotoURL()"
          >
            Dashboard
          </p>
          <v-icon color="primary" v-if="isChildForm">navigate_next</v-icon>
          <p
            class="text-decoration-underline mt-4 cursor-pointer"
            v-if="isChildForm"
            @click="displayParentForm()"
          >
            Parent
          </p>
          <v-icon color="primary">navigate_next</v-icon>
          <p class="p-child">{{ isChildForm ? "Child" : "Parent" }}</p>
          <div class="div-ipad">
            <v-btn
              v-if="isIPad"
              class="float-right"
              fab
              outlined
              x-small
              color="primary"
              @click="collaps = true"
            >
              <v-icon color="primary">keyboard_arrow_left</v-icon>
            </v-btn>
          </div>
        </div>
        <div>
          <p class="ma-0 primary--text">
            {{ isChildForm ? "Form title" : "Select Form" }}
          </p>
          <v-select
            v-if="!isChildForm"
            class="mb-2"
            outlined
            hide-details
            :label="tailboardFormTitle"
            v-model="selectedChild"
            :items="relationFormsItems"
            @change="displayChildForm"
          >
            <template v-slot:item="{ item }">
              <v-col class="pa-0 select-existing-child-form__item">
                <p class="ma-0 mb-1" style="flex-basis: 100%">
                  {{ item.title || "" }}
                </p>
                <v-row align="end" justify="space-between" class="ma-0">
                  <span class="caption ma-0 ml-4">
                    Owner: {{ item.owner || "" }}
                  </span>
                  <span class="caption flex-end">[ {{ item.state }} ]</span>
                </v-row>
              </v-col>
            </template>
            <template v-slot:selection="{ item }">
              <span class="caption nowrap">{{ item.title || "" }}</span>
            </template>
          </v-select>
          <p v-else>{{ tailboardFormTitle }}</p>
          <p class="caption ma-0">
            Owner:
            <span
              v-if="tailboardFormSubmission && tailboardFormSubmission.user"
              >{{ tailboardFormSubmission.user.name }}</span
            >
          </p>
          <p class="caption">
            Created:
            <span v-if="tailboardFormSubmission">{{
              tailboardFormSubmission.insertedAt | parseDate
            }}</span>
          </p>
        </div>
        <v-divider class="mt-n4 m-1"></v-divider>
        <p class="pa-0 ma-0">
          {{
            tailboardFormSubmission
              ? pascalCase(tailboardFormSubmission.state)
              : "In Progress"
          }}
        </p>
        <div class="d-flex mt-2">
          <v-progress-circular color="primary" :value="totalRequiredProgress"
            ><v-icon v-if="formSubmissionStateInt > 0">done</v-icon>
            <span v-else>1</span>
          </v-progress-circular>
          <v-divider class="mt-4 ml-1 mr-1"></v-divider>
          <v-progress-circular
            color="primary"
            :value="formSubmissionStateInt >= 1 ? 100 : 0"
          >
            <v-icon v-if="formSubmissionStateInt > 1">done</v-icon>
            <span v-else>2</span></v-progress-circular
          >
          <v-divider v-if="!isChildForm" class="mt-4 ml-1 mr-1"></v-divider>
          <v-progress-circular
            v-if="!isChildForm"
            color="primary"
            :value="signOffState"
            ><v-icon v-if="formSubmissionStateInt > 2">done</v-icon>
            <span v-else>3</span></v-progress-circular
          >
          <v-divider v-if="!isChildForm" class="mt-4 ml-1 mr-1"></v-divider>
          <v-progress-circular
            v-if="!isChildForm"
            color="primary"
            :value="formSubmissionStateInt === 3 ? 100 : 0"
            ><v-icon v-if="formSubmissionStateInt == 3">done</v-icon>
            <span v-else>4</span></v-progress-circular
          >
        </div>
      </div>
      <v-divider class="ml-1 mr-1"></v-divider>
      <ul class="nav-menu" :class="isChildForm ? 'nav-menu-child' : ''">
        <li
          v-for="(section, index) in sortedSections"
          :key="'section-id' + index"
          @click="goToSection(index + 1)"
          :class="{
            'section-selected': currentStep == index + 1,
            'section-disabled':
              index > 0 && jobDetailsComplete !== jobDetailsRequiredFields
          }"
        >
          <sections-list
            :currentStep="currentStep"
            :section="section"
            :disabled="inapplicableSectionIds.includes(section.id)"
            :index="index"
          />
          <ul v-if="currentStep == index + 1">
            <li
              v-for="(component, idx) in sorted(section.components)"
              :key="'component-id' + idx"
            >
              <div class="d-flex">
                <p
                  class="caption pa-0 ma-0 w-100"
                  :class="{ 'component-selected': currentStep == index + 1 }"
                >
                  {{ component.title }}
                </p>
                <div
                  v-if="
                    section.showErrors &&
                      (component.totalRequired() > 0 || component.notified > 0)
                  "
                  class="div-indicator"
                >
                  <span>{{
                    component.totalRequired() + component.notified
                  }}</span>
                </div>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div
      ref="sectionContainerId"
      class="submission-section overflow-y-auto overflow-x-hidden"
      :class="{ relative: isFullscreen, absolute: !isFullscreen }"
    >
      <v-row>
        <form-toolbar
          ref="tailboardFormToolbar"
          v-show="!isFullscreen"
          :totalRequiredProgress="totalRequiredProgress"
          :relationFormsItems="relationFormsItems"
          :childForms="tailboardFormChildren"
          :tailboardFormSubmission="tailboardFormSubmission"
          :tailboardForm="tailboardForm"
          :tailboardFormTitle="tailboardFormTitle"
          :disabled="!(jobDetailsComplete === jobDetailsRequiredFields)"
          :done="doneAsync"
          :doneLoading="doneLoading"
          :validSubmit="validSubmit"
          :closeFormAsync="closeFormAsync"
          :saveFormAsync="saveFormAsync"
          :userName="user ? user.name : ''"
          :isChildForm="isChildForm"
          :isOnline="isOnline"
          @signature="addSubmissionSignature"
          @alertError="$emit('alertError', $event)"
          @redirect="onRedirect($event)"
          @change="editFormConfirmation"
      /></v-row>
      <div>
        <v-row justify="space-between">
          <v-carousel
            v-model="sliderStep"
            height="auto"
            light
            :show-arrows="false"
            hide-delimiters
            touchless
          >
            <!-- <v-form v-model="valid" ref="projectForm"> -->
            <v-carousel-item
              :eager="instanceRender(section)"
              v-for="(section, index) in sortedSections"
              :key="'slide-' + index"
            >
              <v-sheet class="pa-6" style="width: 100%">
                <v-form v-model="valid" :ref="section.id + '-form'">
                  <v-row>
                    <v-col
                      cols="8"
                      class="pa-0 ma-0"
                      :class="{
                        'text--disabled': inapplicableSectionIds.includes(
                          section.id
                        )
                      }"
                    >
                      <h2
                        v-html="textToHTML(section.title)"
                        v-show="!isFullscreen"
                      />
                      <p
                        class="pa-0"
                        v-html="textToHTML(section.description)"
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-checkbox
                        class="float-right pa-0 ma-0"
                        v-if="section.applicabilityPrompt"
                        :label="section.applicabilityPrompt"
                        :input-value="
                          inapplicableSectionIds.includes(section.id)
                        "
                        @change="updateSectionApplicability(section.id, $event)"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <template
                      v-for="(component, index) in sorted(section.components)"
                    >
                      <v-col :key="component.id">
                        <v-row
                          class="component-header"
                          :style="!isFullscreen ? {} : { borderBottom: '0px' }"
                        >
                          <h3
                            class="pt-4"
                            :class="{
                              'text--disabled': activeInapplicable(
                                section,
                                component
                              )
                            }"
                            v-html="textToHTML(component.title)"
                            v-show="!isFullscreen"
                          ></h3>
                          <v-spacer />
                          <v-checkbox
                            class="pa-0 pr-5 mb-n2"
                            v-if="component.applicabilityPrompt"
                            label="N/A"
                            :disabled="
                              inapplicableSectionIds.includes(section.id)
                            "
                            :input-value="
                              inapplicableComponentIds.includes(component.id)
                            "
                            @change="
                              updateComponentApplicability(
                                component.id,
                                section.id,
                                $event
                              )
                            "
                          />
                        </v-row>
                        <v-row v-if="component.customType">
                          <v-col>
                            <job-details
                              v-if="component.customType === 'JOB_DETAILS'"
                              :jobDetailsData="tailboardFormSubmission"
                              :isOnline="isOnline"
                              @change="setModified"
                            />
                            <employee-entry-log
                              v-else-if="
                                component.customType === 'EMPLOYEE_ENTRY_LOG'
                              "
                              ref="employeeEntryLog"
                              :disabled="activeInapplicable(section, component)"
                              :membersList="initEmployeeList"
                              :entries="
                                tailboardFormSubmission.employeeEntryLogs
                              "
                              :id="component.id"
                              :sectionId="section.id"
                              :crewMembers="initEmployeeList"
                              @change="
                                setSubmissionData('employeeEntryLogs', $event)
                              "
                            />
                            <employee-management
                              v-else-if="
                                component.customType === 'EMPLOYEE_MANAGEMENT'
                              "
                              ref="employeeManagement"
                              :disabled="activeInapplicable(section, component)"
                              :membersList="initEmployeeList"
                              :employeeVisitorList="initEmployeeVisitors"
                              :crewLeaderId="
                                tailboardFormSubmission &&
                                  tailboardFormSubmission.crewLeaderId
                              "
                              :id="component.id"
                              :refresh="updateSectionValidation"
                              @change="updateEmployeeList($event)"
                              @delete="deleteEmployees($event)"
                            />
                            <visitor-managment
                              v-if="
                                component.customType === 'VISITOR_MANAGEMENT'
                              "
                              ref="visitorManagement"
                              :disabled="activeInapplicable(section, component)"
                              :membersList="tailboardFormSubmission.visitors"
                              :signatures="
                                tailboardFormSubmission
                                  ? tailboardFormSubmission.signatures
                                  : null
                              "
                              :id="component.id"
                              :refresh="updateSectionValidation"
                              @change="updateVisitorList($event)"
                              @delete="deleteVisitor($event)"
                            />
                            <visitor-employee-managment
                              v-if="
                                component.customType ===
                                  'EMPLOYEE_VISITOR_MANAGEMENT'
                              "
                              :id="component.id"
                              :crewMembers="initEmployeeList"
                              :employeeVisitors="initEmployeeVisitors"
                              :disabled="activeInapplicable(section, component)"
                              :refresh="updateSectionValidation"
                              @change="updateEmployeeVisitors($event)"
                              @delete="deleteEmployeeVisitors($event)"
                            />
                            <critical-tasks-assessment
                              v-else-if="
                                component.customType ===
                                  'CRITICAL_TASKS_ASSESSMENT'
                              "
                              :criticalTasksData="
                                tailboardFormSubmission.criticalTasksAssessment
                                  .criticalTasksIds
                              "
                              :disabled="activeInapplicable(section, component)"
                              :id="component.id"
                              @change="updateCriticalTasks($event)"
                            />
                            <confined-space-substance
                              v-else-if="
                                component.customType ===
                                  'CONFINED_SPACE_SUBSTANCE_TEST'
                              "
                              ref="confinedSpaceSubstanceTest"
                              :confinedSpaceTestsData="
                                initConfinedSpaceTests(component.id, section.id)
                              "
                              :disabled="activeInapplicable(section, component)"
                              :id="component.id"
                              :sectionId="section.id"
                              @change="setModified"
                            />
                            <cable-chamber-feeder
                              v-else-if="
                                component.customType === 'CABLE_CHAMBER_FEEDER'
                              "
                              ref="cableChamberFeeder"
                              :cableChamberFeederData="
                                initCableChamberFeeder(component.id, section.id)
                              "
                              :disabled="activeInapplicable(section, component)"
                              :id="component.id"
                              :sectionId="section.id"
                              @change="setModified"
                            />
                            <employee-sign-off
                              v-else-if="
                                component.customType === 'EMPLOYEE_SIGN_OFF'
                              "
                              ref="employeeSignOff"
                              :disabled="activeInapplicable(section, component)"
                              :tailboardFormSubmission="tailboardFormSubmission"
                              :tailboardFormTitle="tailboardFormTitle"
                              :employeeVisitorComponentId="
                                getComponentId('EMPLOYEE_VISITOR_MANAGEMENT')
                              "
                              :shouldHideEmployeeVisitors="
                                getComponentInApplicability(
                                  'EMPLOYEE_VISITOR_MANAGEMENT'
                                )
                              "
                              @signature="addSignature"
                              @delete="deleteSignature($event, true)"
                              @refresh="refresh()"
                            />
                            <visitor-sign-off
                              v-else-if="
                                component.customType === 'VISITOR_SIGN_OFF'
                              "
                              ref="visitorSignOff"
                              :disabled="activeInapplicable(section, component)"
                              :tailboardFormSubmission="tailboardFormSubmission"
                              :tailboardFormTitle="tailboardFormTitle"
                              :visitorManagementComponentId="
                                getComponentId('VISITOR_MANAGEMENT')
                              "
                              :shouldHideVisitors="
                                getComponentInApplicability(
                                  'VISITOR_MANAGEMENT'
                                )
                              "
                              @signature="addSignature"
                              @delete="deleteSignature($event, false)"
                            />
                            <risk-assessment
                              v-else-if="
                                component.customType === 'RISK_ASSESSMENT'
                              "
                              ref="riskAssessment"
                              :disabled="activeInapplicable(section, component)"
                              :tailboardFormSubmission="tailboardFormSubmission"
                              @change="
                                setSubmissionData('riskAssessment', $event)
                              "
                              :id="component.id"
                              @refresh="refresh()"
                            />
                          </v-col>
                          <v-responsive
                            v-if="
                              component.layout === 'GRID'
                                ? (index + 1) % 3 == 0
                                : true
                            "
                            :key="index"
                            width="100%"
                          />
                        </v-row>
                        <v-row v-else>
                          <template
                            v-for="(field, idx) in sorted(
                              component.inputFields
                            )"
                          >
                            <v-col :key="field.id">
                              <div
                                :class="{
                                  'secondary--text': activeInapplicable(
                                    section,
                                    component
                                  )
                                }"
                                v-if="field.inputType == 'STATIC_TEXT'"
                                :id="field.id"
                                v-html="textToHTML(field.description)"
                              />
                              <preview
                                v-else-if="field.inputType == 'PREVIEW'"
                                :ref="`preview-${field.id}`"
                              >
                                <preview-form
                                  :tailboardForm="tailboardForm"
                                  :tailboardSubmission="tailboardFormSubmission"
                                />
                              </preview>

                              <submission-input
                                v-else
                                :field="field"
                                :mode="formSubmissionMode"
                                :value="getFormDataValue(field.id)"
                                :disabled="
                                  activeInapplicable(section, component)
                                "
                                :isOnline="isOnline"
                                :getOtherFieldValue="getFormDataValue"
                                :getOtherFieldName="getOtherFieldName"
                                :getFieldNotApplicable="getFieldNotApplicable"
                                :crewMembers="initEmployeeList"
                                @change="
                                  updateFormDataValue(
                                    field.id,
                                    $event,
                                    field.inputType == 'AUDIO'
                                  )
                                "
                                @alertError="$emit('alertError', $event)"
                              />
                            </v-col>
                            <v-responsive
                              v-if="
                                component.layout === 'GRID'
                                  ? (idx + 1) % 3 == 0
                                  : true
                              "
                              :key="idx"
                              width="100%"
                            />
                          </template>
                        </v-row>
                      </v-col>
                      <v-responsive
                        v-if="
                          section.layout === 'GRID'
                            ? (index + 1) % 3 == 0
                            : true
                        "
                        :key="index"
                        width="100%"
                      />
                    </template>
                  </v-row>
                  <v-row class="ma-2" v-show="!isFullscreen">
                    <v-btn
                      class="primary"
                      @click="goToSection(currentStep - 1)"
                      v-if="currentStep > 1"
                      >Previous</v-btn
                    >
                    <v-spacer />
                    <v-btn
                      class="primary"
                      @click="goToSection(currentStep + 1)"
                      v-if="
                        tailboardForm.sections.length > currentStep &&
                          jobDetailsComplete === jobDetailsRequiredFields
                      "
                      >Next</v-btn
                    >
                  </v-row>
                </v-form>
              </v-sheet>
            </v-carousel-item>
            <!-- </v-form> -->
          </v-carousel>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "@/plugins/dayjs";
import loadingScreen from "@/components/loading-screen.vue";
import sectionsList from "@/components/sections-list.vue";
import preview from "@/components/submission/preview.vue";
import previewForm from "@/components/submission/preview-form/preview-form.vue";
import submissionInput from "@/components/submission/submission-input.vue";
import jobDetails from "@/components/submission/custom/job-details.vue";
import employeeEntryLog from "@/components/submission/custom/employee-entry-log.vue";
import employeeManagement from "@/components/submission/custom/employee-managment.vue";
import employeeSignOff from "@/components/submission/custom/employee-sign-off.vue";
import visitorManagment from "@/components/submission/custom/visitor-managment.vue";
import visitorEmployeeManagment from "@/components/submission/custom/visitor-employee-managment.vue";
import visitorSignOff from "@/components/submission/custom/visitor-sign-off.vue";
import formToolbar from "@/components/submission/form-toolbar.vue";
import riskAssessment from "@/components/submission/custom/risk-assessment.vue";
import confinedSpaceSubstance from "@/components/submission/custom/confined-space-substance.vue";
import cableChamberFeeder from "@/components/submission/custom/cable-chamber-feeder.vue";
import criticalTasksAssessment from "@/components/submission/custom/critical-tasks-assessment.vue";
import tailboardController from "@/js/tailboards-controller.js";
import dialogMessage from "@/components/submission/dialog-message.vue";
import utils from "@/shared/Utils";
import serviceController from "@/js/service-controller.js";
import appsignal from "@/plugins/appsignal";

function addBreadcrumb(action, metadata = {}) {
  appsignal.addBreadcrumb({
    category: "Submission Component",
    action,
    metadata
  });
}

export default {
  name: "Submission",

  props: {
    formId: String,
    submissionId: {
      type: String,
      default: null
    }
  },
  filters: {
    parseDate(val) {
      return dayjs
        .utc(val)
        .local()
        .format("YYYY-MM-DD, HH:mm");
    }
  },
  components: {
    submissionInput,
    jobDetails,
    employeeEntryLog,
    employeeManagement,
    employeeSignOff,
    visitorManagment,
    visitorEmployeeManagment,
    visitorSignOff,
    formToolbar,
    riskAssessment,
    confinedSpaceSubstance,
    cableChamberFeeder,
    criticalTasksAssessment,
    loadingScreen,
    dialogMessage,
    preview,
    previewForm,
    sectionsList
  },

  data() {
    return this.initSubmissionData();
  },

  beforeRouteLeave(to, from, next) {
    if (this.isModified) {
      const isLeave = window.confirm(
        "Do you really want to leave? You have unsaved changes!"
      );
      addBreadcrumb("BeforeRouteLeave", {
        isLeave
      });
      if (!isLeave) {
        return next(false);
      }
    }
    return next();
  },
  watch: {
    isIPad: {
      immediate: true,
      handler: function(value) {
        this.collaps = value;
      }
    },

    isLocked: {
      handler(oldValue, newValue) {
        addBreadcrumb("isLocked", {
          oldValue,
          newValue,
          user: this.user && this.user.id,
          role: this.user && this.user.role,
          submissionState: this.tailboardFormSubmission.state,
          submissionMode: this.tailboardFormSubmission.mode,
          submissionUser: this.tailboardFormSubmission.user.id
        });
      }
    },
    // notifiedId: {
    //   immediate: true,
    //   handler: async function(value) {
    //     if (value && this.tailboardFormSubmission.id === value) {
    //       const updatedSubmission = await tailboardController.getTailboardFormSubmissionAsync(
    //         value
    //       );
    //       if (!updatedSubmission) this.showAlertMessage = true;
    //       else {
    //         const hasVisitorManagment = this.tailboardForm.sections.find(s =>
    //           s.components.find(c => c.customType === "VISITOR_MANAGEMENT")
    //         );
    //         this.$set(
    //           updatedSubmission,
    //           "visitorsSectionId",
    //           hasVisitorManagment ? hasVisitorManagment.id : null
    //         );
    //         this.cleanUpObject(updatedSubmission);
    //         this.tailboardFormSubmission = updatedSubmission;
    //       }
    //       this.$store.commit("setNotifiedId", null);
    //     }
    //   }
    // },
    isModified: {
      handler: function(value) {
        if (
          this.tailboardFormSubmission &&
          this.tailboardFormSubmission.state === "SUBMITTED"
        ) {
          if (value && !this.lockWatch) {
            this.$refs.tailboardFormToolbar.showEditConfirmation();
          }
        }
      }
    },
    currentStep: {
      deep: true,
      handler: async function(value) {
        addBreadcrumb("Step Changed", {
          currentStep: value
        });

        if (!this.isLoading) {
          this.$refs.sectionContainerId.scrollTop = 0;
          // Reset Preview iframe src
          //     when we go there it refreshes to get current version
          await this.saveFormAsync();
        }
      }
    },
    async $route(to) {
      // react to route changes...
      const isReady = await this.initTailboardFormSubmissionAsync(
        to.params.formId,
        to.params.submissionId
      );

      if (isReady) this.isLoading = false;
    },
    user: {
      handler: function(value) {
        if (value) this.tailboardFormSubmission.user = value;
      }
    }
  },
  beforeMount() {
    this.isLoading =
      this.tailboardFormSubmission && this.tailboardFormSubmission.id !== null;

    //window.addEventListener("beforeunload", this.onRefresh);
  },
  beforeDestroy() {
    addBreadcrumb("Destroy");
    // window.removeEventListener("beforeunload", this.onRefresh);
  },
  async mounted() {
    const isExist = await this.initTailboardFormSubmissionAsync(
      this.formId,
      this.submissionId
    );
    if (isExist || !this.submissionId) this.isLoading = false;
    else {
      this.isModified = false;
      this.$router.push("/").catch(e => {
        console.error(e);
      });
    }

    await this.cacheChildFormEmployeeSignatures();
  },
  computed: {
    isFullscreen() {
      return this.$store.getters.getIsFullscreen;
    },
    isIPad() {
      return this.$store.getters.getIsIPad;
    },
    isOnline() {
      return (
        this.$store.getters.getConnected &&
        this.$store.getters.getServerActivity
      );
    },
    sortedSections() {
      return this.sorted(this.tailboardForm.sections);
    },
    notifiedId() {
      return this.$store.getters.getNotifiedId;
    },
    signOffState() {
      let signatures = this.tailboardFormSubmission.signatures;
      let visitorsCount = this.tailboardFormSubmission.visitors.length;
      let employeeVisitorsCount = this.tailboardFormSubmission.employeeVisitors
        .length;

      // 1. FILTER by excluding visitors
      if (this.getComponentInApplicability("VISITOR_MANAGEMENT")) {
        signatures = signatures.filter(
          signature => signature.employee !== null
        );
        // Value set to zero here because if the Visitors section is N/A then the signOfState
        // should not count visitors as part of it's final calculation
        visitorsCount = 0;
      }

      // // 2. FILTER by excluding employee visitors
      if (this.getComponentInApplicability("EMPLOYEE_VISITOR_MANAGEMENT")) {
        const visitorEmployeeIds = new Set(
          this.tailboardFormSubmission.employeeVisitors.map(
            visitor => visitor.employee.id
          )
        );
        signatures = signatures.filter(
          signature =>
            !signature.employee ||
            !visitorEmployeeIds.has(signature.employee.id)
        );
        // Value set to zero here because if the TH Employee Visitors section is N/A then the signOfState
        // should not count visitors as part of it's final calculation
        employeeVisitorsCount = 0;
      }

      return (
        (signatures.filter(s => s.phase === "SIGN_OFF").length * 100) /
        (this.tailboardFormSubmission.crewMembers.length +
          visitorsCount +
          employeeVisitorsCount)
      );
    },

    isLocked() {
      const span = appsignal.createSpan();
      span.setNamespace("Submission");
      span.setAction("isLocked");

      // Validate either logged in user or form submission user exist
      if (!this.user || !this.tailboardFormSubmission.user) {
        span.setError(new Error("Missing user data."));
        appsignal.send(span);

        return true;
      }

      const isNotOwner = this.user.id !== this.tailboardFormSubmission.user.id;
      const isSameDepartment =
        this.user.employee.departmentRef ===
        this.tailboardFormSubmission.user.employee.departmentRef;
      const isSupervisorEditMode =
        isNotOwner && this.user.role === "SUPERVISOR" && isSameDepartment;
      const userBlockMode = isNotOwner && this.user.role === "USER";

      // Validate if form is submitted and closed
      if (
        this.tailboardFormSubmission.state === "SUBMITTED" &&
        this.tailboardFormSubmission.mode === "CLOSED"
      ) {
        span.setError(new Error("Form is submitted and closed."));
        appsignal.send(span);

        return true;
      }

      // Validate if form is archived
      if (this.tailboardFormSubmission.state === "ARCHIVED") {
        span.setError(new Error("Form is archived."));
        appsignal.send(span);

        return true;
      }

      // Validate if user is blocked and not in supervistor edit mode
      if (userBlockMode && !isSupervisorEditMode) {
        span.setError(
          new Error("User is blocked and not in supervisor edit mode.")
        );
        appsignal.send(span);

        return true;
      }

      return false;
    },

    totalRequiredProgress() {
      if (
        this.tailboardForm.sections &&
        this.tailboardForm.sections.length > 0
      ) {
        const processed = this.tailboardForm
          ? this.tailboardForm.sections.reduce(function(result, s) {
              return result + s.processed();
            }, 0)
          : 0;
        const required = this.tailboardForm
          ? this.tailboardForm.sections.reduce(function(result, s) {
              return result + s.required();
            }, 0)
          : 0;
        return required ? Math.round((processed / required) * 100) : 0;
      } else return 0;
    },
    getFormDataValue() {
      return id => (this.formData[id] ? this.formData[id].value : null);
    },
    initEmployeeList() {
      if (
        this.isChildForm &&
        this.tailboardFormSubmission.parentTailboardFormSubmission
      ) {
        return this.parentCrewMembers;
      }
      return this.tailboardFormSubmission.crewMembers;
    },
    initEmployeeVisitors() {
      return this.tailboardFormSubmission.employeeVisitors;
    },
    user() {
      return this.$store.getters.getUser;
    },
    formSubmissionMode() {
      return this.tailboardFormSubmission
        ? this.tailboardFormSubmission.mode
        : "OPEN";
    },
    formSubmissionStateInt() {
      if (this.tailboardFormSubmission) {
        switch (this.tailboardFormSubmission.state) {
          case "IN_PROGRESS":
            return 0;
          case "COMPLETED":
            return 1;
          case "SIGNED_OFF":
            return 2;
          case "SUBMITTED":
            return 3;
        }
      }
      return -1;
    },
    sliderStep: {
      get: function() {
        return this.currentStep - 1;
      },
      set: function() {}
    },
    jobDetailsComplete() {
      const header = this.tailboardFormSubmission.tailboardFormHeader;
      let result = 0;
      if (
        this.tailboardFormSubmission.supervisorId &&
        this.tailboardFormSubmission.supervisorId !== ""
      )
        result += 1;
      if (
        this.tailboardFormSubmission.supervisorPhone &&
        this.tailboardFormSubmission.supervisorPhone !== ""
      )
        result += 1;
      if (header.projectName && header.projectName !== "") result += 1;
      if (header.date && header.date !== "") result += 1;
      if (header.rcNumber && header.rcNumber !== "") result += 1;
      if (header.dept && header.dept !== "") result += 1;
      if (header.location && header.location !== "") result += 1;
      if (header.workDescription && header.workDescription !== "") result += 1;
      return result;
    },
    applicableSections() {
      return this.tailboardForm.sections.filter(
        ({ id }) => !this.inapplicableSectionIds.includes(id)
      );
    },
    inapplicableSectionIds() {
      return this.tailboardFormSubmission
        ? this.tailboardFormSubmission.inapplicableSections
        : [];
    },
    inapplicableComponentIds() {
      return this.tailboardFormSubmission
        ? this.tailboardFormSubmission.inapplicableComponents
        : [];
    },
    tailboardFormTitle() {
      return this.tailboardForm ? this.tailboardForm.title : null;
    },
    tailboardFormChildren() {
      return this.tailboardForm && this.tailboardForm.childForms
        ? this.tailboardForm.childForms.map(c => {
            return {
              id: c.id,
              header: c.header,
              logoFile: c.logoFile,
              title: c.title,
              type: c.type,
              value: c.id,
              text: c.title,
              instructions: c.instructions,
              disabled: false,
              selected: false
            };
          })
        : [];
    },
    relationFormsItems() {
      if (this.isChildForm)
        return this.tailboardFormSubmission.parentTailboardFormSubmission
          ? [
              {
                value: {
                  id: this.tailboardFormSubmission.parentTailboardFormSubmission
                    .id,
                  formId: this.tailboardFormSubmission
                    .parentTailboardFormSubmission.formId
                },

                title: `${this.tailboardFormSubmission.parentTailboardFormSubmission.tailboardForm.title}`,
                owner: ""
              }
            ]
          : [];
      else
        return this.tailboardFormSubmission.childTailboardFormSubmissions
          ? this.tailboardFormSubmission.childTailboardFormSubmissions
              .map(c => {
                return {
                  value: { formId: c.formId, id: c.id },
                  disabled:
                    this.user &&
                    this.user.id !== c.user.id &&
                    this.user.role !== "ADMIN" &&
                    this.user.role !== "SAFETY",
                  title: `${c.tailboardForm.title} -${dayjs
                    .utc(c.insertedAt)
                    .local()
                    .format("HH:mm")}`,
                  owner: `${c.user.name}`,
                  state: this.pascalCase(c.state),
                  created: c.insertedAt,
                  isLocked: c.isLocked,
                  isLockedBy: c.isLockedBy
                };
              })
              .sort((a, b) => {
                return new Date(b.created) - new Date(a.created);
              })
          : [];
    },
    isChildForm() {
      return this.tailboardForm ? this.tailboardForm.type === "SIMPLE" : false;
    }
  },
  methods: {
    async cacheChildFormEmployeeSignatures() {
      if (this.isChildForm) {
        return;
      }

      this.childFormEmployeeSignatures = {};
      let employeeSignatures = {};

      const childForms = this.tailboardFormSubmission
        .childTailboardFormSubmissions;

      // Get child forms
      for (let childForm of childForms) {
        const submission = await tailboardController.getTailboardFormSubmissionAsync(
          childForm.id
        );
        // get components that contain a signature
        const form = await tailboardController.getTailboardFormByIdAsync(
          submission.formId
        );

        const componentsWithSignatures = form.sections
          .flatMap(section => section.components)
          .filter(component =>
            component.inputFields.some(
              inputField => inputField.inputType === "SIGNATURE"
            )
          );

        employeeSignatures[childForm.id] = componentsWithSignatures;
      }

      this.childFormEmployeeSignatures = employeeSignatures;
    },
    getComponentId(name) {
      const visitorManagementSection = this.tailboardForm.sections
        .find(s => s.components.find(c => c.customType === name))
        ?.components.find(c => c.customType === name)?.id;

      return visitorManagementSection;
    },
    getComponentInApplicability(name) {
      const id = this.getComponentId(name);
      return this.tailboardFormSubmission.inapplicableComponents.some(
        component => component === id
      );
    },
    async fetchParentCrewMembers() {
      if (
        this.isChildForm &&
        this.tailboardFormSubmission.parentTailboardFormSubmission &&
        this.parentCrewMembers == null
      ) {
        const submission = await this.getTailboardFormSubmissionAsync(
          this.tailboardFormSubmission.parentTailboardFormSubmission.id
        );
        if (submission) {
          this.parentCrewMembers = submission.crewMembers;
        }
      }
    },
    okAction() {
      this.isModified = false;
      this.$router.push("/").catch(e => {
        console.error(e);
      });
    },
    async initTailboardFormSubmissionAsync(formId, submissionId) {
      const templateLoaded = await this.loadFormSubmissionTemplate(formId);

      if (templateLoaded) {
        if (submissionId) {
          if (await this.loadTailboardFormSubmissionDataAsync(submissionId)) {
            if (this.tailboardFormSubmission.state === "SUBMITTED")
              this.$refs.tailboardFormToolbar.showEditConfirmation();

            this.tailboardFormSubmission.state = this.getState();
            this.fetchParentCrewMembers();
            return true;
          }
        } else {
          const { esrr, wo, names, vehicle, trailer, feeder, gridsupervisor } =
            this.$route.query || {};

          // 1. if we have esrr it means we need to create a new tailboard with the data from the url
          if (esrr) {
            this.tailboardFormSubmission.tailboardFormHeader.esrr = esrr;

            // Work order
            if (wo)
              this.tailboardFormSubmission.tailboardFormHeader.workOrderNumber = wo;

            // Feeder
            if (feeder) {
              // the feeders input is nested within the inputFieldData
              // let feedersInput;
              for (let section of this.tailboardForm.sections) {
                for (let component of section.components) {
                  // NOTE: this is really fragile.
                  // if at some point these titles change, this will break
                  // if (
                  //   section.title.toLowerCase() === "job information" &&
                  //   component.title.toLowerCase() === "technical details"
                  // ) {
                  for (let input of component.inputFields) {
                    if (
                      input.name &&
                      input.name.toLowerCase() === "feeder(s)"
                    ) {
                      this.$set(this.formData, input.id, {
                        inputFieldId: input.id,
                        value: feeder
                      });
                      break;
                    }
                  }
                }
              }
            }

            // Crew members
            const user = await serviceController.getUser();
            if (user) {
              this.tailboardFormSubmission.tailboardFormHeader.rcNumber = user
                ? user.employee.rcNumber
                : null;
              this.tailboardFormSubmission.tailboardFormHeader.dept = user
                ? user.employee.departmentName
                : null;
              const isCrewLeader = !!user?.employee?.title
                ?.toLowerCase()
                ?.includes("crew leader");
              this.tailboardFormSubmission.updatedBy = user ? user.id : null;
              this.tailboardFormSubmission.crewLeaderId = isCrewLeader
                ? "CURRENT_USER"
                : null;
              this.tailboardFormSubmission.crewLeaderPhone = isCrewLeader
                ? user.employee.phoneNumber
                : null;
              let crewMembers = [];
              crewMembers.push({
                employee: user.employee,
                trailer: null,
                vehicle: null
              });
              const allEmployees = await serviceController.getObjectStoreAsync(
                "employees"
              );
              if (allEmployees.length > 0) {
                if (gridsupervisor) {
                  let supervisorName = gridsupervisor
                    .replace(/\s/g, "")
                    .toUpperCase();
                  let supervisorIndex = allEmployees.findIndex(e => {
                    const fullName = `${e.firstName}${e.lastName}`
                      .replace(/\s/g, "")
                      .toUpperCase();
                    return fullName === supervisorName;
                  });
                  if (supervisorIndex != -1) {
                    this.tailboardFormSubmission.supervisorId =
                      allEmployees[supervisorIndex].id;
                    this.tailboardFormSubmission.supervisorPhone =
                      allEmployees[supervisorIndex].phoneNumber;
                  }
                }
                if (names && names.length) {
                  let nameList = names
                    .replace(/\s/g, "")
                    .toUpperCase()
                    .split(",");
                  let employeeIndex = -1;
                  for (let name of nameList) {
                    employeeIndex = allEmployees.findIndex(e => {
                      const fullName = `${e.firstName}${e.lastName}`
                        .replace(/\s/g, "")
                        .toUpperCase();
                      return fullName === name;
                    });
                    if (
                      employeeIndex != -1 &&
                      crewMembers.findIndex(
                        e => e.employee.id === allEmployees[employeeIndex].id
                      ) === -1
                    ) {
                      crewMembers.push({
                        employee: allEmployees[employeeIndex]
                      });
                    }
                  }
                  if (crewMembers && crewMembers.length) {
                    // add vehicle and trailer to all crew members if they exist
                    if (vehicle || trailer) {
                      const allVehicles = await serviceController.getObjectStoreAsync(
                        "vehicles"
                      );
                      if (allVehicles) {
                        let vehicleOnDB = null;
                        if (vehicle) {
                          const underLine = vehicle.indexOf("-");
                          let newVehicleId = "";
                          if (underLine !== -1) {
                            newVehicleId = `0${vehicle.substring(
                              0,
                              underLine
                            )}V${vehicle.substring(underLine)}`;
                          } else newVehicleId = `0${vehicle}V`;
                          vehicleOnDB = allVehicles.find(
                            v => v.vehicleRef === newVehicleId
                          );
                        }
                        const trailerOnDB = trailer
                          ? allVehicles.find(v => v.vehicleRef === trailer)
                          : null;
                        crewMembers = crewMembers.map(c => {
                          c.vehicle = vehicleOnDB;
                          c.trailer = trailerOnDB;
                          return c;
                        });
                      }
                    }

                    this.tailboardFormSubmission.crewMembers = crewMembers;
                  }
                }
              }
            }

            // at this point nothing is saved so this is just a failsafe
            this.setModified();
            return true;
          }
        }
      }

      return false;
    },
    deleteSignature(id, isEmployee) {
      const index = this.tailboardFormSubmission.signatures.findIndex(s =>
        isEmployee ? (s.employee ? s.employee.id === id : false) : s.name === id
      );

      if (index !== -1) {
        this.tailboardFormSubmission.signatures.splice(index, 1);
        this.saveFormAsync();
      }
    },

    generateSignature({ employee = null, file, name = "", phase }) {
      if (!file)
        throw Error(
          `submission.generateSignature: missing file to generate a signature`
        );

      return {
        employee,
        file,
        name,
        phase,
        id: utils.getUUID(),
        mode: this.tailboardFormSubmission.mode,
        signedAt: dayjs.utc().format(),
        submissionId: this.tailboardFormSubmission.id
      };
    },

    async addSignature(event, callback) {
      const signature = this.generateSignature({
        ...event,
        phase: "SIGN_OFF"
      });

      const { employee, name } = signature;
      const employeeId = employee ? employee.id : null;

      const index = this.tailboardFormSubmission.signatures.findIndex(s =>
        employeeId
          ? s.employee
            ? s.employee.id === employeeId
            : false
          : s.name === name
      );

      if (index === -1) this.tailboardFormSubmission.signatures.push(signature);
      else this.tailboardFormSubmission.signatures[index] = signature;

      await this.saveFormAsync();

      if (callback) callback();
    },

    async addSubmissionSignature(event, callback) {
      const phase = "SUBMISSION";
      const signature = this.generateSignature({
        ...event,
        phase
      });

      const index = this.tailboardFormSubmission.signatures.findIndex(
        s => s.phase === phase
      );

      if (index === -1) this.tailboardFormSubmission.signatures.push(signature);
      else this.tailboardFormSubmission.signatures[index] = signature;

      await this.saveFormAsync();
      if (callback) callback();

      if (this.tailboardFormSubmission.mode === "CLOSED") {
        this.isModified = false;
        this.$router.push("/").catch(e => {
          console.error(e);
        });
      }
    },

    async getTailboardFormSubmissionAsync(submissionId) {
      let submission = await tailboardController.getTailboardFormSubmissionAsync(
        submissionId
      );
      if (submission) {
        // VISITOR_MANAGEMENT
        const hasVisitorManagment = this.tailboardForm.sections.find(s =>
          s.components.find(c => c.customType === "VISITOR_MANAGEMENT")
        );
        this.$set(
          submission,
          "visitorsSectionId",
          hasVisitorManagment ? hasVisitorManagment.id : null
        );

        // EMPLOYEE_VISITOR_MANAGEMENT
        const hasEmpVisitorManagment = this.tailboardForm.sections.find(s =>
          s.components.find(c => c.customType === "EMPLOYEE_VISITOR_MANAGEMENT")
        );
        this.$set(
          submission,
          "employeeVisitorsSectionId",
          hasEmpVisitorManagment ? hasEmpVisitorManagment.id : null
        );

        return submission;
      }
      return null;
    },

    async loadTailboardFormSubmissionDataAsync(submissionId) {
      let submission = await this.getTailboardFormSubmissionAsync(submissionId);
      if (submission) {
        this.tailboardFormSubmission = submission;
        this.loadFormData(submission);
        const progress = await this.getCurrentProgress();
        if (progress) {
          this.isModified = false;
          this.lockWatch = false;
          this.isLoading = false;
          return this.tailboardFormSubmission;
        }
      }
      return null;
    },
    getFormShadowCopy(inputForm) {
      let clearList = [];
      this.tailboardForm.sections.forEach(s => {
        s.components.forEach(c => {
          c.inputFields.forEach(f => {
            if (f.inputType === "AUDIO" || f.inputType === "FILE") {
              clearList.push(f.id);
            }
          });
        });
      });
      inputForm.inputFieldData = inputForm.inputFieldData.map(field => {
        return {
          inputFieldId: field.inputFieldId,
          value:
            clearList.length > 0 && clearList.includes(field.inputFieldId)
              ? null
              : field.value
        };
      });
      this.tailboardFormSubmission = inputForm;
      this.loadFormData(this.tailboardFormSubmission);
      this.$store.commit("setTailboardFormSubmission", {});
      this.currentStep = 1;
      this.$emit("alertSuccess", "Form copied successfully");
    },
    editFormConfirmation() {
      this.lockWatch = true;
      //this.setModified();
    },
    updateTailboardSubmissionState() {
      if (this.tailboardFormSubmission.state === "SUBMITTED") {
        const index = this.tailboardFormSubmission.signatures.findIndex(
          s => s.phase === "SUBMISSION"
        );
        if (index !== -1)
          this.tailboardFormSubmission.signatures.splice(index, 1);
      }
      this.tailboardFormSubmission.state = this.getState();
    },
    async setModified() {
      this.isModified = true;
      if (this.currentStep === this.tailboardForm.sections.length) {
        const progress = await this.getCurrentProgress();
        if (progress) {
          this.updateTailboardSubmissionState();
        }
      }
    },
    setSubmissionData(property, value) {
      this.tailboardFormSubmission[property] = value;
      this.setModified();
    },
    async loadFormSubmissionTemplate(id) {
      let template = await tailboardController.getTailboardFormByIdAsync(id);
      if (template) {
        this.setTailbaordForm(template);
        return true;
      }
      return false;
    },

    setTailbaordForm(template) {
      this.tailboardForm = template;
      this.initFormData();
      this.tailboardFormSubmission.tailboardForm.header = template.header;
      this.tailboardFormSubmission.tailboardForm.id = template.id;
      this.tailboardFormSubmission.tailboardForm.instructions =
        template.instructions;
      this.tailboardFormSubmission.tailboardForm.logoFile = template.logoFile;
      this.tailboardFormSubmission.tailboardForm.title = template.title;
      this.tailboardFormSubmission.tailboardForm.type = template.type;
    },

    initSubmissionData() {
      const user = this.$store.getters.getUser;
      const data = {
        dateTime: dayjs.utc().format("YYYY-MM-DD"),
        tailboardForm: {},
        formId: this.formId,
        submissionId: this.submissionId,
        user: user,
        hasRiskAssessment: true,
        insertedAt: dayjs.utc().format(),
        parent: null,
        childFormEmployeeSignatures: {}
      };

      return {
        jobDetailsRequiredFields: 8,
        showAlertMessage: false,
        selectedChild: null,
        loaded: false,
        isModified: false,
        valid: false,
        collaps: this.isIPad || true,
        formData: {},
        doneLoading: false,
        isLoading: true,
        currentStep: 1,
        lockWatch: false,
        mutation: null,
        query: null,
        form: { sections: [] },
        browsedSections: [],
        currentSection: "",
        tailboardForm: { sections: [] },
        sectionToResetValidationMapping: null,
        parentCrewMembers: null,
        tailboardFormSubmission: tailboardController.generateFormSubmission(
          data
        )
      };
    },

    async closeFormAsync() {
      this.tailboardFormSubmission.mode = "CLOSED";
      if (this.tailboardFormSubmission.isLocked) {
        const isSaved = await tailboardController.unLockTailboardFormSubmission(
          this.tailboardFormSubmission
        );
        if (isSaved) {
          this.tailboardFormSubmission.isLocked = false;
          this.tailboardFormSubmission.isLockedBy = null;
          await this.saveFormAsync();
        }
      } else await this.saveFormAsync();
    },

    updateCriticalTasks(criticalTasks) {
      this.tailboardFormSubmission.criticalTasksAssessment.criticalTasksIds = criticalTasks.slice();
      this.setModified();
    },
    async onRedirect(url) {
      this.isLoading = true;
      this.currentStep = 1;
      await this.gotoURL(url);
    },
    async gotoURL(url = null) {
      this.isModified = false;
      if (url) {
        this.$emit("navigate", url);
      } else
        this.$router.push("/").catch(e => {
          console.error(e);
        });
    },
    onRefresh(e) {
      e.preventDefault();
      e.returnValue = "";
    },
    updateSectionValidation(id, values) {
      for (let section of this.applicableSections) {
        for (let component of section.components || []) {
          if (!this.inapplicableComponentIds.includes(component.id)) {
            if (component.id === id) {
              this.$set(component, "required", values[1]);
              this.$set(component, "processed", values[0]);
            }
          }
        }
      }
    },
    async displayParentForm() {
      let url = null;
      if (this.tailboardFormSubmission.parentTailboardFormSubmission)
        url = {
          formId: this.tailboardFormSubmission.parentTailboardFormSubmission
            .formId,
          id: this.tailboardFormSubmission.parentTailboardFormSubmission.id
        };
      await this.saveSubmissionFormAsync(url);
    },
    async displayChildForm(child) {
      await this.saveSubmissionFormAsync(child);
      this.selectedChild = null;
    },
    deleteEmployees(ids) {
      if (ids && ids.length) {
        ids.forEach(id => {
          let index = this.tailboardFormSubmission.crewMembers.findIndex(
            n => n.employee.employeeRef === id
          );
          if (index !== -1) {
            const employeeId = this.tailboardFormSubmission.crewMembers[index]
              .employee.id;
            this.tailboardFormSubmission.crewMembers.splice(index, 1);
            this.tailboardFormSubmission.riskAssessment.jobSteps.forEach(js => {
              js.employeeIds = js.employeeIds.filter(i => i !== employeeId);
            });

            if (this.employeeExistsInEmployeeVisitors(employeeId)) {
              return;
            }

            // delete employee signature
            index = this.tailboardFormSubmission.signatures.findIndex(s =>
              s.employee ? s.employee.employeeRef === id : false
            );
            if (index !== -1) {
              this.tailboardFormSubmission.signatures.splice(index, 1);
            }

            // Update parent form input fields
            this.deleteEmployeeFromParentInputFields(employeeId);

            // Update child form input fields
            this.deleteEmployeeFromChildFormInputFields(employeeId);

            this.setModified();
          }
        });
      }
    },
    employeeExistsInEmployeeVisitors(employeeId) {
      for (let employee of this.tailboardFormSubmission.employeeVisitors) {
        if (employeeId === employee.employeeId) {
          return true;
        }
      }
      return false;
    },
    employeeExistsInCrewMembers(employee) {
      for (let crewMember of this.tailboardFormSubmission.crewMembers) {
        if (employee === crewMember.employee.employeeRef) {
          return true;
        }
      }
      return false;
    },
    deleteEmployeeFromParentInputFields(employeeId) {
      this.tailboardFormSubmission.inputFieldData.forEach(item => {
        if (item.value === employeeId) {
          this.$set(this.formData, item.inputFieldId, {
            fieldId: item.inputFieldId,
            value: null
          });
        }
      });
    },
    async deleteEmployeeFromChildFormInputFields(employeeId) {
      const childForms = this.tailboardFormSubmission
        .childTailboardFormSubmissions;

      let signatureFieldIds = [];

      // delete from submission
      for (let childForm of childForms) {
        const submission = await tailboardController.getTailboardFormSubmissionAsync(
          childForm.id
        );

        let valuesChanged = false;

        // Delete employee
        for (let j = 0; j < submission.inputFieldData.length; j++) {
          if (submission.inputFieldData[j].value === employeeId) {
            this.$set(submission.inputFieldData, j, {
              ...submission.inputFieldData[j],
              value: null
            });

            const inputData =
              this.childFormEmployeeSignatures[childForm.id].find(component =>
                component.inputFields.some(
                  field =>
                    field.id === submission.inputFieldData[j].inputFieldId
                )
              ) || null;

            if (inputData) {
              const signature = inputData.inputFields.find(
                field => field.inputType === "SIGNATURE"
              );
              if (signature) {
                signatureFieldIds.push(signature.id);
              }
            }

            valuesChanged = true;
          }
        }

        // Delete signatures
        const signatureFieldIdSet = new Set(signatureFieldIds);
        submission.inputFieldData.forEach((field, index) => {
          if (signatureFieldIdSet.has(field.inputFieldId)) {
            this.$set(submission.inputFieldData, index, {
              ...field,
              value: null
            });

            valuesChanged = true;
          }
        });

        if (valuesChanged) {
          this.$set(childForm, "state", "IN_PROGRESS");
          await tailboardController.saveSubmissionAsync(submission);
        }
      }
    },
    deleteVisitor(id) {
      const index = this.tailboardFormSubmission.signatures.findIndex(
        s => s.name === id
      );
      if (index !== -1)
        this.tailboardFormSubmission.signatures.splice(index, 1);
      this.setModified();
    },
    deleteEmployeeVisitors(ids) {
      if (ids && ids.length) {
        ids.forEach(id => {
          if (id != null && typeof id === "object") {
            id = id.employee.employeeRef;
          }

          let index = this.tailboardFormSubmission.employeeVisitors.findIndex(
            n => n.employee.employeeRef === id
          );

          // delete employee visitor
          if (index !== -1) {
            this.tailboardFormSubmission.employeeVisitors.splice(index, 1);
            this.setModified();
          }

          if (this.employeeExistsInCrewMembers(id)) {
            return;
          }

          // delete employee signature
          let sigIndex = this.tailboardFormSubmission.signatures.findIndex(s =>
            s.employee ? s.employee.employeeRef === id : false
          );
          if (sigIndex !== -1) {
            this.tailboardFormSubmission.signatures.splice(sigIndex, 1);
            this.setModified();
          }
        });
      }
    },
    instanceRender(section) {
      return section && section.components
        ? section.components.findIndex(
            c =>
              c.customType === "RISK_ASSESSMENT" ||
              c.customType === "EMPLOYEE_MANAGEMENT" ||
              c.customType === "VISITOR_MANAGEMENT" ||
              c.customType === "EMPLOYEE_SIGN_OFF" ||
              c.customType === "EMPLOYEE_VISITOR_MANAGEMENT"
          ) != -1
        : false;
    },
    goToSection(index) {
      const section = this.sorted(this.tailboardForm.sections)[index - 1];
      this.currentSection = section.id;
      if (this.tailboardForm.sections.length >= index) this.currentStep = index;
      if (this.browsedSections.indexOf(section.id) === -1)
        this.browsedSections.push(section.id);
    },
    getNow() {
      return dayjs().format("ddd, MMM D, YYYY h:mm A");
    },
    pascalCase(str) {
      return utils.toPascalCase(str);
    },

    getFieldNotApplicable(id) {
      const field = this.formData[id];
      return field && field.allowNotApplicable ? field.value === "N/A" : false;
    },
    async refresh() {
      // we can use this function in the future if we need to do more
      // things during a refresh
      //this.saveFormAsync();
      await this.getCurrentProgress();
    },
    async getCurrentProgress() {
      let setSectionProgress = (section, component, value, isJson = false) => {
        component.required += 1;
        if (value) {
          if (isJson) {
            const jsonObject = JSON.parse(value);
            for (let [key, property] of Object.entries(jsonObject)) {
              if (!property || !key) return;
            }
          }
          component.processed += 1;
        }
      };
      let setCustomComponetNotifications = (component, value) => {
        this.$set(component, "notified", value);
      };
      let setCustomComponentProgress = (component, value) => {
        this.$set(component, "required", value[1]);
        this.$set(component, "processed", value[0]);
      };
      const toCamel = s => {
        return s.toLowerCase().replace(/([-_][a-z])/gi, $1 => {
          return $1
            .toUpperCase()
            .replace("-", "")
            .replace("_", "");
        });
      };
      const mode = this.formSubmissionMode;
      for (let section of this.tailboardForm.sections) {
        this.$set(
          section,
          "showErrors",
          this.browsedSections.indexOf(section.id) !== -1
        );
        if (!this.inapplicableSectionIds.includes(section.id)) {
          for (const component of section.components || []) {
            this.$set(component, "required", 0);
            this.$set(component, "processed", 0);
            if (!this.inapplicableComponentIds.includes(component.id)) {
              if (component.customType) {
                switch (component.customType) {
                  case "JOB_DETAILS": {
                    setCustomComponentProgress(component, [
                      this.jobDetailsComplete,
                      this.jobDetailsRequiredFields
                    ]);
                    break;
                  }
                  case "EMPLOYEE_VISITOR_MANAGEMENT": {
                    const hasEmployeeVisitors = !!this.tailboardFormSubmission
                      .employeeVisitors.length;

                    hasEmployeeVisitors
                      ? this.$set(component, "required", 0)
                      : this.$set(component, "required", 1);

                    break;
                  }
                }
                const customType = toCamel(component.customType);
                if (this.$refs[customType] && this.$refs[customType][0]) {
                  if (this.$refs[customType][0].getValidationResult) {
                    setCustomComponentProgress(
                      component,
                      this.$refs[customType][0].getValidationResult()
                    );
                  } else {
                    if (this.$refs[customType][0].getNotifications) {
                      setCustomComponetNotifications(
                        component,
                        this.$refs[customType][0].getNotifications()
                      );
                    }
                  }
                }
              } else {
                for (let field of component.inputFields || []) {
                  const isJsonValue = ["WEATHER"].includes(field.inputType);
                  const value = this.getFormDataValue(field.id);
                  const disabled = field.allowNotApplicable
                    ? value === "N/A"
                    : false;

                  if (!disabled && field.validations) {
                    if (value) {
                      const min = field.validations.filter(function(v) {
                        return v.mode
                          ? v.type === "MIN" && v.mode === mode
                          : v.type === "MIN";
                      });
                      if (min && min.length > 0) {
                        const minValidation =
                          field.inputType == "STRING" ||
                          field.inputType == "TEXT"
                            ? value.length < min[0].limit
                            : value < min[0].limit;
                        if (minValidation)
                          setSectionProgress(section, component, null);
                      }
                      const max = field.validations.filter(function(v) {
                        return v.mode
                          ? v.type === "MAX" && v.mode === mode
                          : v.type === "MAX";
                      });
                      if (max && max.length > 0) {
                        const maxValidation =
                          field.inputType == "STRING" ||
                          field.inputType == "TEXT"
                            ? value.length > max[0].limit
                            : value > max[0].limit;
                        if (maxValidation)
                          setSectionProgress(section, component, null);
                      }
                    }

                    if (
                      field.validations.filter(function(v) {
                        return v.mode
                          ? v.type === "REQUIRED" && v.mode === mode
                          : v.type === "REQUIRED";
                      }).length > 0
                    ) {
                      setSectionProgress(
                        section,
                        component,
                        value,
                        isJsonValue
                      );
                    } else {
                      const otherEmpty = field.validations.filter(function(v) {
                        return v.mode
                          ? v.type === "REQUIRED_IF_OTHER_FIELD_NOT_EMPTY" &&
                              v.mode === mode
                          : v.type === "REQUIRED_IF_OTHER_FIELD_NOT_EMPTY";
                      });
                      if (otherEmpty.length > 0) {
                        if (
                          !this.getFieldNotApplicable(
                            otherEmpty[0].otherFieldId
                          ) &&
                          this.getFormDataValue(otherEmpty[0].otherFieldId)
                        ) {
                          setSectionProgress(
                            section,
                            component,
                            value,
                            isJsonValue
                          );
                        }
                      }
                      const otherEquals = field.validations.filter(function(v) {
                        return v.mode
                          ? v.type === "REQUIRED_IF_OTHER_FIELD_EQUALS" &&
                              v.mode === mode
                          : v.type === "REQUIRED_IF_OTHER_FIELD_EQUALS";
                      });
                      if (otherEquals.length > 0) {
                        if (
                          this.getFormDataValue(otherEquals[0].otherFieldId) ===
                          otherEquals[0].otherFieldValue
                        ) {
                          setSectionProgress(
                            section,
                            component,
                            value,
                            isJsonValue
                          );
                        }
                      }
                    }
                    const containsAnyIfOtherEquals = field.validations.filter(
                      function(v) {
                        return v.mode
                          ? v.type === "CONTAINS_ANY_IF_OTHER_FIELD_EQUALS" &&
                              v.mode === mode
                          : v.type === "CONTAINS_ANY_IF_OTHER_FIELD_EQUALS";
                      }
                    );
                    if (containsAnyIfOtherEquals.length > 0) {
                      if (
                        this.getFormDataValue(
                          containsAnyIfOtherEquals[0].otherFieldId
                        ) == containsAnyIfOtherEquals[0].otherFieldValue
                      ) {
                        setSectionProgress(
                          section,
                          component,
                          containsAnyIfOtherEquals[0].containedValues.includes(
                            value
                          )
                            ? "true"
                            : null
                        );
                      }
                    }
                    const notEqualToFieldValue = field.validations.filter(
                      function(v) {
                        return v.mode
                          ? v.type ===
                              "NOT_EQUAL_TO_FIELD_IF_OTHER_FIELD_EQUALS" &&
                              v.mode === mode
                          : v.type ===
                              "NOT_EQUAL_TO_FIELD_IF_OTHER_FIELD_EQUALS";
                      }
                    );
                    if (notEqualToFieldValue.length > 0) {
                      if (
                        this.getFormDataValue(
                          notEqualToFieldValue[0].otherFieldId
                        ) == notEqualToFieldValue[0].otherFieldValue &&
                        this.getFormDataValue(
                          notEqualToFieldValue[0].notEqualFieldId
                        ) != null && // Check if the value is not null
                        this.getFormDataValue(
                          notEqualToFieldValue[0].notEqualFieldId
                        ).includes(value)
                      ) {
                        setSectionProgress(section, component, null);
                      }
                    }
                  }
                }
              }
            }
          }
        } else {
          for (const component of section.components || []) {
            this.$set(component, "required", 0);
            this.$set(component, "processed", 0);
          }
        }
      }
      return true;
    },
    updateEmployeeList(employees) {
      this.tailboardFormSubmission.crewMembers = employees;
      this.setModified();
    },
    updateVisitorList(visitors) {
      this.tailboardFormSubmission.visitors = visitors.slice();
      this.setModified();
    },
    updateEmployeeVisitors(employeeVisitors) {
      this.$set(
        this.tailboardFormSubmission,
        "employeeVisitors",
        employeeVisitors
      );
      this.setModified();
    },
    textToHTML(txt) {
      return utils.parseTextToHTML(txt);
    },
    validSubmit() {
      let isValid = false;
      for (let i = 0; i < this.tailboardForm.sections.length; i++) {
        if (this.$refs[this.tailboardForm.sections[i].id + "-form"])
          isValid = this.$refs[
            this.tailboardForm.sections[i].id + "-form"
          ][0].validate();
        if (!isValid) {
          this.$emit(
            "alertError",
            `validation required in section : ${this.form.sections[i].title}`
          );
          break;
        }
      }
      this.valid = isValid;
      return this.valid;
    },
    getState() {
      let signatures = this.tailboardFormSubmission.signatures;

      // 1. FILTER by excluding visitors
      if (this.getComponentInApplicability("VISITOR_MANAGEMENT")) {
        signatures = signatures.filter(signature => signature.employee != null);
      }

      // // 2. FILTER by excluding employee visitors
      if (this.getComponentInApplicability("EMPLOYEE_VISITOR_MANAGEMENT")) {
        const visitorEmployeeIds = new Set(
          this.tailboardFormSubmission.employeeVisitors.map(
            visitor => visitor.employee.id
          )
        );
        signatures = signatures.filter(
          signature =>
            !signature.employee ||
            !visitorEmployeeIds.has(signature.employee.id)
        );
      }

      return this.totalRequiredProgress === 100
        ? this.signOffState === 100
          ? signatures.some(s => s.phase === "SUBMISSION")
            ? "SUBMITTED"
            : "SIGNED_OFF"
          : "COMPLETED"
        : "IN_PROGRESS";
    },
    async saveSubmissionFormAsync(url) {
      try {
        this.doneLoading = true;
        this.isLoading = true;
        this.currentStep = 1;
        const result = await this.saveFormAsync();
        if (result) {
          this.gotoURL(url);
        }
      } finally {
        this.doneLoading = false;
      }
    },
    async doneAsync() {
      this.saveSubmissionFormAsync(null);
    },

    cleanUpObject(obj) {
      var result = null;
      if (obj instanceof Array) {
        for (var i = 0; i < obj.length; i++) {
          result = this.cleanUpObject(obj[i]);
        }
      } else {
        for (var prop in obj) {
          if (prop === "__typename") {
            delete obj.__typename;
          }
          if (obj[prop] instanceof Object || obj[prop] instanceof Array)
            result = this.cleanUpObject(obj[prop]);
        }
      }
      return result;
    },

    async sendSubmissionFormAsync() {
      const convertArrayToObject = (array, key, prop) =>
        array.reduce((acc, curr) => {
          if (curr[prop]) acc[curr[key]] = curr[prop];
          return acc;
        }, {});

      try {
        const submission = await this.getTailboardFormSubmissionAsync(
          this.tailboardFormSubmission.id
        );
        if (submission) {
          const mapSubmission = convertArrayToObject(
            submission.inputFieldData,
            "inputFieldId",
            "value"
          );
          const mapFormData = convertArrayToObject(
            Object.values(this.formData),
            "inputFieldId",
            "value"
          );
          let formDataChanged =
            Object.keys(mapFormData).length !==
            Object.keys(mapSubmission).length;
          if (!formDataChanged)
            for (const key of Object.keys(mapFormData)) {
              if (mapSubmission[key] !== mapFormData[key]) {
                formDataChanged = true;
                break;
              }
            }
          const changedProperties = [
            "cableChamberFeeders",
            "childTailboardFormSubmissions",
            "confinedSpaceSubstanceTests",
            "crewLeaderId",
            "crewLeaderPhone",
            "crewMembers",
            "criticalTasksAssessment",
            "employeeEntryLogs",
            "hasCriticalTasks",
            "inapplicableComponents",
            "inapplicableSections",
            "mode",
            "riskAssessment",
            "signatures",
            "state",
            "supervisorId",
            "supervisorPhone",
            "tailboardFormHeader",
            "visitors",
            "employeeVisitors",
            "visitorsSectionId",
            "employeeVisitorsSectionId"
          ];
          let hasBeenChanged = false;
          for (const prop of changedProperties) {
            this.cleanUpObject(submission[prop]);
            this.cleanUpObject(this.tailboardFormSubmission[prop]);
            if (
              JSON.stringify(submission[prop]) !==
              JSON.stringify(this.tailboardFormSubmission[prop])
            ) {
              hasBeenChanged = true;
              break;
            }
          }
          if (!hasBeenChanged && !formDataChanged) return true;
        }
        this.tailboardFormSubmission.offline = true;
        this.tailboardFormSubmission.inputFieldData = Object.values(
          this.formData
        ).map(f => {
          return {
            id: f.id ? f.id : utils.getUUID(),
            inputFieldId: f.inputFieldId,
            value: f.value
          };
        });

        this.updateTailboardSubmissionState();
        if (this.tailboardFormSubmission.employeeEntryLogs.length > 0)
          this.tailboardFormSubmission.employeeEntryLogs.forEach(
            e => delete e.inValid
          );
        this.tailboardFormSubmission.hasCriticalTasks =
          this.tailboardFormSubmission.criticalTasksAssessment &&
          this.tailboardFormSubmission.criticalTasksAssessment.criticalTasksIds
            .length > 0;
        const isSaved = await tailboardController.saveSubmissionAsync(
          this.tailboardFormSubmission
        );

        if (isSaved) {
          if (!this.submissionId) {
            this.isModified = false;
            this.isLoading = true;
            this.$router
              .push(
                `/forms/${this.tailboardFormSubmission.formId}/submit/${this.tailboardFormSubmission.id}`
              )
              .catch(e => {
                console.error(e);
              });
          }
        }
        return isSaved;
      } catch (e) {
        this.$emit("alertError", e);
        return false;
      }
    },
    async saveFormAsync() {
      let result = false;
      if (this.jobDetailsComplete === this.jobDetailsRequiredFields) {
        this.$emit("sync", "Syncing");
        const progress = await this.getCurrentProgress();
        if (progress) result = await this.sendSubmissionFormAsync();
        this.$emit("sync", "Synced");
      }
      return result;
    },
    activeInapplicable(section, component) {
      return (
        this.inapplicableSectionIds.includes(section.id) ||
        this.inapplicableComponentIds.includes(component.id)
      );
    },
    getOtherFieldName(id) {
      for (const section of this.tailboardForm.sections || []) {
        for (const component of section.components || []) {
          for (let field of component.inputFields || []) {
            field = field || {};
            if (field.id === id) return this.textToHTML(field.name);
          }
        }
      }
    },
    initFormData() {
      if (this.tailboardForm) {
        for (const section of this.tailboardForm.sections || []) {
          for (const component of section.components || []) {
            this.$set(component, "required", 0);
            this.$set(component, "processed", 0);
            this.$set(component, "notified", 0);
            component.totalRequired = function() {
              return this.required - this.processed;
            };
            for (let field of component.inputFields || []) {
              field = field || {};
              // if (!this.formData[field.id]) {
              this.$set(this.formData, field.id, {
                inputFieldId: field.id,
                allowNotApplicable: field.allowNotApplicable,
                value: field.defaultValue
              });
              // }
            }
          }
          section.notified = function() {
            return this.components && this.components.length > 0
              ? this.components.reduce(function(result, c) {
                  return result + c.notified;
                }, 0)
              : 0;
          };
          section.processed = function() {
            return this.components && this.components.length > 0
              ? this.components.reduce(function(result, c) {
                  return result + c.processed;
                }, 0)
              : 0;
          };
          section.required = function() {
            return this.components && this.components.length > 0
              ? this.components.reduce(function(result, c) {
                  return result + c.required;
                }, 0)
              : 0;
          };
          section.progress = function() {
            const required = this.required();
            return required > 0
              ? Math.round((this.processed() / required) * 100)
              : 0;
          };
          section.totalRequired = function() {
            return this.required() - this.processed();
          };
        }
      }
    },

    async updateFormDataValue(inputFieldId, val, update = false) {
      this.$set(this.formData, inputFieldId, {
        inputFieldId,
        value: val ? `${val}` : val
      });
      this.setModified();
      if (update) {
        await this.saveFormAsync();
      }

      // this is a hack to workaround the fact that we can't trigger a
      // validation refresh on input B when input A changes.

      // this.buildSectionToResetMapping();
      // const sectionToReset = this.sectionToResetValidationMapping[inputFieldId];
      // if (sectionToReset && this.$refs[sectionToReset + "-form"]) {
      //   this.$refs[sectionToReset + "-form"][0].resetValidation();
      // }
    },

    buildSectionToResetMapping() {
      if (this.sectionToResetValidationMapping) {
        return;
      }
      const mapping = {};
      for (const currSection of this.tailboardForm.sections) {
        for (const currComponent of currSection.components) {
          for (const currInputField of currComponent.inputFields) {
            const otherFieldIds = (currInputField.validations || [])
              .map(e => e.notEqualFieldId)
              .filter(e => !!e);
            // TODO assumes fields that reference each other are always in the
            // same section.
            for (const currOther of otherFieldIds) {
              mapping[currOther] = currSection.id;
            }
            if (otherFieldIds.length) {
              mapping[currInputField.id] = currSection.id;
            }
          }
        }
      }
      this.sectionToResetValidationMapping = mapping;
    },

    sorted(list) {
      if (!list) return [];
      return list.concat().sort((a, b) => a.position - b.position);
    },

    updateSectionApplicability(sectionId, answer) {
      this.$refs[sectionId + "-form"][0].resetValidation();
      if (answer)
        this.tailboardFormSubmission.inapplicableSections.push(sectionId);
      else {
        const index = this.tailboardFormSubmission.inapplicableSections.indexOf(
          sectionId
        );
        if (index !== -1)
          this.tailboardFormSubmission.inapplicableSections.splice(index, 1);
      }
      this.setModified();
    },
    updateComponentApplicability(componentId, sectionId, answer) {
      this.$refs[sectionId + "-form"][0].resetValidation();
      if (answer)
        this.tailboardFormSubmission.inapplicableComponents.push(componentId);
      else {
        const index = this.tailboardFormSubmission.inapplicableComponents.indexOf(
          componentId
        );
        if (index !== -1)
          this.tailboardFormSubmission.inapplicableComponents.splice(index, 1);
      }
      this.setModified();
    },
    loadFormData(result) {
      result.inputFieldData.forEach(f => {
        this.$set(this.formData, f.inputFieldId, {
          ...this.formData[f.inputFieldId],
          id: f.id,
          inputFieldId: f.inputFieldId,
          value: f.value
        });
      });
    },

    initConfinedSpaceTests(componentId, sectionId) {
      if (this.tailboardFormSubmission.confinedSpaceSubstanceTests.length === 0)
        this.tailboardFormSubmission.confinedSpaceSubstanceTests.push({
          component_id: componentId,
          section_id: sectionId,
          time: dayjs().format()
        });
      return this.tailboardFormSubmission.confinedSpaceSubstanceTests;
    },

    initCableChamberFeeder(componentId, sectionId) {
      if (this.tailboardFormSubmission.cableChamberFeeders.length === 0)
        this.tailboardFormSubmission.cableChamberFeeders.push({
          section_id: sectionId,
          component_id: componentId,
          hazards: [],
          direction: ""
        });
      return this.tailboardFormSubmission.cableChamberFeeders;
    }
  }
};
</script>

<style scoped>
.p-child {
  margin-top: 18px !important ;
}
.w-100 {
  width: 100%;
}
.div-indicator {
  width: auto;
}
.div-indicator span {
  padding-top: 3px;
  text-align: center;
  float: right;
  width: 20px !important;
  height: 20px;
  border-radius: 10px;
  background-color: var(--v-error-base);
  color: white;
  font-size: 11px;
  margin-right: 2px;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul li ul {
  border-top: 0.8px solid #e5e8ee;
  padding-top: 5px;
  margin-left: 50px;
}

ul li {
  font-size: 16px;
  height: auto;
  min-height: 40px;
}
.component-selected {
  color: var(--v-primary-base);
}

.select-existing-child-form__item:not(.select-existing-child-form:last-of-type) {
  border-bottom: 1px solid #ebebeb;
}

.collaps {
  display: none;
}

.div-ipad {
  width: 100%;
  padding-top: 12px;
}

.nav-menu {
  position: absolute;
  top: 275px;
  left: 5px;
  bottom: 5px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: white;
  width: 290px;
}

.nav-menu-child {
  top: 250px;
}

.section-selected,
.nav-menu > li:hover {
  cursor: pointer;
  background-color: var(--v-secondary-base);
}

.submission-section.relative {
  position: relative;
}

.submission-section.absolute {
  position: absolute;
  top: 0;
  left: 305px;
  bottom: 0;
  right: 10px;
}

.full-screen {
  left: 55px !important;
}

.submission-nav,
.submission-side-bar {
  position: absolute;
  top: 0;
  left: 10px;
  bottom: 15px;
  padding: 5px;
}

.submission-nav {
  width: 300px;
}

.submission-side-bar {
  width: 42px;
  background-color: white;
  left: 15px;
  display: none;
  padding-top: 30px;
}

.side-bar {
  display: block;
}

.submission-main {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 10px;
  right: 0;
}

label {
  font-size: 10px;
}
.state-holder {
  position: relative;
  width: 100%;
  height: 100%;
}

.state-holder p {
  position: absolute;
  color: var(--v-primary-base);
  text-transform: uppercase;
  top: 200px;
  left: -30px;
  transform: rotate(-90deg);
  overflow: hidden;
  white-space: nowrap;
  /* Safari */
  -webkit-transform: rotate(-90deg);

  /* Firefox */
  -moz-transform: rotate(-90deg);

  /* IE */
  -ms-transform: rotate(-90deg);

  /* Opera */
  -o-transform: rotate(-90deg);

  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}

@media (max-width: 720px) {
}
</style>
