<template>
  <div class="d-flex w-100">
    <div class="mr-2 pa-2">
      <v-progress-circular color="primary" :value="section.progress()">
        <v-icon v-if="disabled">report_off</v-icon>
        <v-icon
          v-else-if="
            section.progress() === 100 ||
              (section.showErrors &&
                section.totalRequired() === 0 &&
                section.notified() === 0)
          "
          >done</v-icon
        >
      </v-progress-circular>
    </div>
    <p class="font-weight-bold mt-4 w-100">
      {{ section.title }}
    </p>
    <div
      v-if="
        section.showErrors &&
          currentStep != index + 1 &&
          (section.totalRequired() > 0 || section.notified() > 0)
      "
      class="div-indicator mt-4"
    >
      <span>{{ section.totalRequired() + section.notified() }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "SectionsList",
  props: {
    section: Object,
    currentStep: Number,
    disabled: Boolean,
    index: Number
  }
};
</script>
<style scoped>
.div-indicator {
  width: auto;
}
.w-100 {
  width: 100%;
}

.div-indicator span {
  padding-top: 3px;
  text-align: center;
  float: right;
  width: 22px !important;
  height: 22px;
  border-radius: 11px;
  background-color: var(--v-error-base);
  color: white;
  font-size: 11.5px;
  margin-right: 2px;
}
</style>
