var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.comment.show)?_c('div',{staticClass:"slide-bar d-flex",style:([
    _vm.standAlone
      ? {
          position: 'absolute',
          right: '6px',
          top: '34px',
          backgroundColor: 'white',
          boxShadow: '0 5px 5px 0 var(--v-border-base)',
          border: '1px solid var(--v-border-base)'
        }
      : {}
  ])},[(!_vm.expandComment)?_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){_vm.expandComment = !_vm.expandComment}}},[_vm._v(_vm._s(_vm.standAlone ? "arrow_left" : "arrow_right"))]):_vm._e(),_c('div',{staticClass:"pt-3 pr-1 pl-1"},[_c('v-icon',{attrs:{"color":_vm.comment.isCritical ? 'red' : 'primary'}},[_vm._v(_vm._s(_vm.getActiveIcon(_vm.comment.isCritical)))])],1),(_vm.expandComment)?_c('div',{staticClass:"caption d-flex"},[_c('div',{class:{ 'slide-bar-one-line': !_vm.comment.details }},[_c('p',{staticClass:"primary--text pa-0 ma-0"},[_vm._v(" "+_vm._s(_vm.comment.text)+" ")]),_c('span',[_vm._v(_vm._s(_vm.comment.details))])]),(_vm.expandComment)?_c('div',[_c('p',{on:{"click":function($event){return _vm.displayComment(_vm.submission.tailboardFormSubmissionReview)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mode_comment")]),_c('span',[_vm._v(_vm._s(_vm.comment.count))])],1)]):_vm._e(),_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){_vm.expandComment = !_vm.expandComment}}},[_vm._v(_vm._s(_vm.standAlone ? "arrow_right" : "arrow_left"))])],1):_vm._e(),_c('comment-dialog',{attrs:{"cancelAction":_vm.closeCommentDialog,"review":_vm.reviewData,"commentDialogShow":_vm.commentDialogShow}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }