<template>
  <div id="app">
    <v-app>
      <loading-screen :show="isLoading" />
      <v-snackbar
        top
        right
        :value="updateExists"
        :timeout="timeout"
        color="primary"
      >
        An update is available for Tailboard app
        <v-btn text @click="refreshApp"> Update </v-btn>
      </v-snackbar>
      <v-snackbar
        class="notification-bar"
        v-model="errorAlert"
        :timeout="timeout"
        top
        color="#F65353"
      >
        <template>
          <v-icon>warning</v-icon
          ><span class="text-capitalize">
            {{ errorMessage }}
          </span>
          <v-btn icon @click="errorAlert = false" class="float-right">
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar
        class="notification-bar"
        v-model="successAlert"
        :timeout="timeout"
        top
        color="#34B233"
      >
        <template>
          <v-icon class="pt-2">check_circle</v-icon>
          <span class="text-capitalize">
            {{ successMessage }}
          </span>

          <v-btn icon @click="successAlert = false" class="float-right">
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <v-app-bar
        dense
        class="v-app-bar-style pt-2"
        v-if="user"
        v-show="!isFullscreen"
        app
      >
        <template>
          <div class="flex-container" style="width:100%">
            <div class="flex-item d-flex">
              <router-link to="/">
                <img
                  width="110px"
                  height="43.2px"
                  src="/images/logo-toronto-hydro-light.png"
                  class="mr-4 ml-5"
                />
              </router-link>

              <v-btn icon color="pink" @click="menuVisible = !menuVisible">
                <v-icon>menu</v-icon>
              </v-btn>
            </div>
            <div class="flex-item-middle">
              <div v-if="disconnected">
                <span>
                  <v-icon>wifi_off</v-icon> App has lost server connection
                </span>
                <span v-if="lastSynced && user">
                  Last Synced {{ lastSynced }}
                </span>
              </div>
            </div>
            <div class="flex-item d-flex toolbar-title">
              <v-icon
                :class="recordingState.id ? 'live-recording' : ''"
                v-if="recordingState.id"
                class="mr-6"
                color="accent"
                >{{
                  recordingState.state === "pause" ? "pause" : "mic"
                }}</v-icon
              >
              <template v-if="!disconnected">
                <v-icon
                  :class="[
                    syncState === 'Synced' ? 'synced' : 'syncing',
                    rotate ? 'rotating' : ''
                  ]"
                  >loop</v-icon
                >
                <p class="mt-4" style="width: 80px">{{ syncState }}</p>
              </template>
              <v-icon>person</v-icon>
              <p class="mt-4 ml-2" style="white-space: nowrap;">
                {{ user.name }}
              </p>
            </div>
          </div>
        </template>
      </v-app-bar>

      <v-navigation-drawer
        v-if="user"
        v-model="menuVisible"
        fixed
        temporary
        app
      >
        <v-toolbar>
          <v-toolbar-title>Navigation</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-app-bar-nav-icon @click="menuVisible = !menuVisible">
              <v-icon>chevron_left</v-icon>
            </v-app-bar-nav-icon>
          </v-toolbar-items>
        </v-toolbar>
        <v-list>
          <template v-if="user && user.role === 'ADMIN'">
            <v-list-item to="/users" @click="menuVisible = false">
              <v-list-item-content>
                <v-list-item-title>Users</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/forms" @click="menuVisible = false">
              <v-list-item-content>
                <v-list-item-title>Forms</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/options" @click="menuVisible = false">
              <v-list-item-content>
                <v-list-item-title>Option Groups</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/hazards" @click="menuVisible = false">
              <v-list-item-content>
                <v-list-item-title>Hazards</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/criticals" @click="menuVisible = false">
              <v-list-item-content>
                <v-list-item-title>Critical Tasks</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/procedures" @click="menuVisible = false">
              <v-list-item-content>
                <v-list-item-title>Procedures</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/documents" @click="menuVisible = false">
              <v-list-item-content>
                <v-list-item-title>Documents</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/submissions" @click="menuVisible = false">
              <v-list-item-content>
                <v-list-item-title>Submissions</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-else-if="user && user.role === 'SAFETY'">
            <v-list-item to="/documents" @click="menuVisible = false">
              <v-list-item-content>
                <v-list-item-title>Documents</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/hazards" @click="menuVisible = false">
              <v-list-item-content>
                <v-list-item-title>Hazards</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/options" @click="menuVisible = false">
              <v-list-item-content>
                <v-list-item-title>Option Groups</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-else>
            <v-list-item to="/documents" @click="menuVisible = false">
              <v-list-item-content>
                <v-list-item-title>Documents</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-divider />
          <v-list-item @click="aboutDevice">
            <v-list-item-content>
              <v-list-item-title>Device</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="reloadApp" :disabled="disconnected">
            <v-list-item-content>
              <v-list-item-title>Reload app</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="logout" :disabled="disconnected">
            <v-list-item-content>
              <v-list-item-title>Sign Out</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <dialog-message
          :showDialogMessage="showAbout"
          displayCaption="Information"
          :displayText="deviceInformation"
          :okAction="aboutDevice"
        />
      </v-navigation-drawer>
      <v-main
        :style="{ padding: vMainPadding, marginTop: vMainMarginTop }"
        class="mt-5"
      >
        <v-container fluid>
          <router-view
            :user="user"
            @alertError="errorMessage = $event"
            @alertSuccess="successMessage = $event"
            @sync="setSyncState($event)"
            @navigate="goToURL($event)"
          />
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import loadingScreen from "@/components/loading-screen.vue";
import serviceController from "@/js/service-controller.js";
import dataAccess from "@/js/data-access.js";
import getEnv from "@/utilities/env.js";
import utils from "@/shared/Utils";
import { workers } from "@/shared/workers.js";
import dayjs from "@/plugins/dayjs.js";
import appsignal from "@/plugins/appsignal";
import dialogMessage from "@/components/submission/dialog-message.vue";
import {
  USER_TAILBOARD_FORM_NOTIFICATION_SUBSCRIPTION,
  DELETED_TAILBOARD_FORM_SUBMISSION_SUBSCRIPTION
} from "@/constants/form-queries";
import {
  DEFAULT_QUEUE_CACHE,
  LOCAL_STORAGE_SYNC_DATE_KEY,
  LOCAL_STORAGE_FILTER_STATE_KEY
} from "@/shared/consts.js";

function addBreadcrumb(action, metadata = {}) {
  appsignal.addBreadcrumb({
    category: "App.vue",
    action,
    metadata
  });
}

export default {
  name: "App",
  components: {
    loadingScreen,
    dialogMessage
  },
  data() {
    return {
      subscriptions: [],
      menuVisible: false,
      successMessage: "",
      errorMessage: "",
      successAlert: false,
      errorAlert: false,
      showAbout: false,
      deviceInformation: "",
      timeout: 8000,
      isOnline: navigator.onLine,
      syncState: "Synced",
      registration: null,
      updateExists: false,
      tailboardsUpdateWorker: null,
      rotate: false,
      isLoading: true,
      vMainPadding: "48px 0px 0px",
      vMainMarginTop: "20px !important"
    };
  },

  created() {
    document.addEventListener("swUpdated", this.updateAvailable, {
      once: true
    });
  },

  async beforeCreate() {
    this.$store.dispatch("fetchUser");
    await serviceController.initializeStoreAsync();
  },

  async mounted() {
    this.$store.commit("setOnLine", this.isOnline);
    let userAgent = window.navigator.platform.toLowerCase();
    //this.deviceInformation = `<b>Platform :</b>${window.clientInformation.platform} </br> <b>UserAgent :</b>${window.clientInformation.userAgent}<br/><b>Agent Platform :</b>${window.clientInformation.userAgentData.platform}<br/><b>App Version :</b>${window.clientInformation.appVersion}<br/><b>App Name :</b>${window.clientInformation.appName}`;
    const isIPad = /iphone|ipod|ipad/.test(userAgent)
      ? true
      : /macintel/.test(userAgent);
    this.$store.commit("setIsIPad", isIPad);
    window.addEventListener("online", () => {
      this.setOnline();
    });

    window.addEventListener("offline", () => {
      this.setOffline();
    });
    window.addEventListener("popstate", this.handleBackButton);
  },

  methods: {
    aboutDevice() {
      this.showAbout = !this.showAbout;
    },
    reloadApp() {
      let request = window.indexedDB.deleteDatabase(
        getEnv("VUE_APP_LOCAL_DATABASE")
      );
      (request.onsuccess = function() {
        console.info("Database deleted");
      }),
        (request.onerror = function() {
          console.warn("Couldn't delete database");
        });
      this.$router.push({ path: `/` });
      window.location.reload();
    },

    goToURL(url) {
      const submissioUrl = `/dashboard/${url.formId}/submit/${url.id}`;
      this.$router.push({ path: submissioUrl });
    },

    createNewUpdateWorker() {
      let worker = workers.updateWorker();
      worker.postMessage({
        uri: `${getEnv("VUE_APP_SERVER_URL")}/graphql`,
        token: localStorage.getItem("token"),
        dbName: getEnv("VUE_APP_LOCAL_DATABASE"),
        dbVersion: getEnv("VUE_APP_LOCAL_DATABASE_VERSION")
      });
      worker.onmessage = async e => {
        switch (e.data.status) {
          case 1: // done from uploading new tailbaordFormSubmissions
            this.$store.commit("setRefreshView");
            setTimeout(() => {
              this.isLoading = this.isBusy;
            }, 1000);
            break;

          case 2: // cleanup the local database from archived/deleted tailbaordFormSubmissions
            if (
              await dataAccess.deleteEntityById(
                e.data.id,
                "tailboardFormSubmissions"
              )
            )
              this.$store.commit("setRefreshView");
            break;
          case 3:
            if (!this.serverActivity) {
              worker.terminate();
            } else appsignal.sendError(e.data.exception);
        }
      };
      return worker;
    },

    startUpdateWorker() {
      console.info("Start update worker");
      if (!this.tailboardsUpdateWorker)
        this.tailboardsUpdateWorker = this.createNewUpdateWorker();
      this.setSyncState("Synced");
      this.lastSynced = Date.now();
      this.rotate = false;
    },
    stopUpdateWorker() {
      console.warn("Stop update worker");
      if (this.tailboardsUpdateWorker) this.tailboardsUpdateWorker.terminate();
      this.tailboardsUpdateWorker = null;
    },
    async uploadQueuedFiles() {
      // get any files which intersect with upload queue
      let uploadFiles = await utils.intersectCache();

      if (uploadFiles && uploadFiles.length > 0) {
        this.setSyncState("Syncing");
        let uploadFilesStatus = uploadFiles.length;
        let cacheUploadWorker = workers.uploadWorker();

        cacheUploadWorker.postMessage({
          action: "start",
          files: uploadFiles,
          uri: `${getEnv("VUE_APP_SERVER_URL")}`
        });
        this.rotate = true;
        cacheUploadWorker.onmessage = async e => {
          switch (e.data.status) {
            case 0:
              if (
                await utils.deleteCacheEntry(
                  DEFAULT_QUEUE_CACHE,
                  e.data.newFile.blobUrl
                )
              )
                uploadFilesStatus--;
              break;
            case 1:
              // we try again next time
              uploadFilesStatus--;
              break;
            case 3:
              appsignal.sendError(e.data.exception);
              break;
          }

          if (uploadFilesStatus === 0) {
            if (cacheUploadWorker) cacheUploadWorker.terminate();
            this.startUpdateWorker();
          }
        };
      } else {
        this.startUpdateWorker();
      }
    },
    async logout() {
      if (await serviceController.logoutUserAsync()) {
        localStorage.removeItem("token");
        localStorage.removeItem(LOCAL_STORAGE_FILTER_STATE_KEY);

        window.open("https://myaccount.microsoft.com", "_blank");
        window.location = `${getEnv(
          "VUE_APP_SERVER_URL"
        )}/login?redirect=${getEnv("VUE_APP_SERVER_CALLBACK")}`;
      }
    },
    updateAvailable(event) {
      this.registration = event.detail;
      this.updateExists = true;
      addBreadcrumb("Service Worker Update Available");
      console.info("Tailboard app apdated ", event);
    },
    refreshApp() {
      addBreadcrumb("Refresh App");
      if (!this.registration || !this.registration.waiting) return;
      this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
    },
    setOnline() {
      addBreadcrumb("Application Online");
      this.isOnline = true;
      this.$store.commit("setOnLine", this.isOnline);
    },
    setOffline() {
      addBreadcrumb("Application Offline");
      this.isOnline = false;
      this.$store.commit("setOnLine", this.isOnline);
    },
    setSyncState(state) {
      this.syncState = state;
    },
    updateVMainStyle(val) {
      this.vMainPadding = val ? "0" : "48px 0px 0px";
      this.vMainMarginTop = val ? "0px !important" : "20px !important";
    },
    handleBackButton() {
      this.$store.commit("setIsFullscreen", false);
    }
  },
  computed: {
    isBusy() {
      return this.$store.getters.getIsBusy;
    },
    disconnected() {
      return !this.isOnline || !this.serverActivity;
    },
    recordingState() {
      return this.$store.getters.getRecordingConference;
    },
    csrf() {
      return window.csrf_token;
    },
    user() {
      return this.$store.getters.getUser;
    },
    serverActivity() {
      return this.$store.getters.getServerActivity;
    },

    lastSynced: {
      get: () => {
        const storageValue = JSON.parse(
          localStorage.getItem(LOCAL_STORAGE_SYNC_DATE_KEY)
        );

        if (!storageValue) return null;

        return dayjs
          .utc(storageValue)
          .local()
          .format("MMM DD, YYYY hh:mm a");
      },
      set: val => {
        localStorage.setItem(LOCAL_STORAGE_SYNC_DATE_KEY, val);
      }
    },
    isFullscreen() {
      return this.$store.getters.getIsFullscreen;
    }
  },

  watch: {
    isBusy(value) {
      this.isLoading = value;
    },
    user(value) {
      if (!value)
        window.location = `${getEnv(
          "VUE_APP_SERVER_URL"
        )}/login?redirect=${getEnv("VUE_APP_SERVER_CALLBACK")}`;
    },
    serverActivity: {
      immediate: true,
      handler: async function(value) {
        if (value) {
          if (window.Worker) await this.uploadQueuedFiles();
          await serviceController.updateUser();
        } else {
          this.lastSynced = Date.now();

          setTimeout(() => {
            if (this.serverActivity === false) this.stopUpdateWorker();
            this.isLoading = this.isBusy;
          }, 4000);
        }
      }
    },
    errorMessage(val) {
      if (val) {
        this.errorAlert = true;
      } else {
        this.errorAlert = false;
      }
    },
    errorAlert(val) {
      if (!val) this.errorMessage = "";
    },
    successMessage(val) {
      if (val) {
        this.successAlert = true;
      } else {
        this.successAlert = false;
      }
    },
    successAlert(val) {
      if (!val) this.successMessage = "";
    },
    isFullscreen(val) {
      this.updateVMainStyle(val);
    }
  },
  beforeDestroy() {
    addBreadcrumb("App Teardown");
    window.removeEventListener("popstate", this.handleBackButton);
  },
  apollo: {
    $subscribe: {
      userTailboardFormNotification: {
        query: USER_TAILBOARD_FORM_NOTIFICATION_SUBSCRIPTION,
        variables() {
          return {
            userId: this.user.id
          };
        },
        skip() {
          return !this.serverActivity || !this.user;
        },
        async result(response) {
          const tailboardFormSubmission =
            response.data.userTailboardFormNotification.result;

          addBreadcrumb("User Tailboard Form Notification", {
            submissionId: tailboardFormSubmission.id
          });

          if (this.user && tailboardFormSubmission.updatedBy !== this.user.id) {
            const queue = await dataAccess.getEntitiesAsync("tailboardsQueue");
            const queueIds = queue.map(t => {
              return t.queueType === 0 ? t.id : t.ids[0];
            });
            if (!queueIds.includes(tailboardFormSubmission.id)) {
              const isSaved = await dataAccess.saveEntityByIdAsync(
                tailboardFormSubmission,
                "tailboardFormSubmissions"
              );
              if (isSaved)
                if (tailboardFormSubmission.parentTailboardFormSubmission) {
                  const {
                    id
                  } = tailboardFormSubmission.parentTailboardFormSubmission;
                  let parentData = await dataAccess.getEntityByIdAsync(
                    "tailboardFormSubmissions",
                    id
                  );

                  if (parentData.result) {
                    if (
                      parentData.result.childTailboardFormSubmissions.length ===
                        0 ||
                      parentData.result.childTailboardFormSubmissions.findIndex(
                        t => t.id === tailboardFormSubmission.id
                      ) === -1
                    ) {
                      parentData.result.childTailboardFormSubmissions.push({
                        formId: tailboardFormSubmission.formId,
                        id: tailboardFormSubmission.id,
                        insertedAt: tailboardFormSubmission.insertedAt,
                        mode: tailboardFormSubmission.mode,
                        state: tailboardFormSubmission.state,
                        tailboardForm: tailboardFormSubmission.tailboardForm,
                        user: tailboardFormSubmission.user
                      });
                      const parentSaved = await dataAccess.saveEntityAsync(
                        parentData.result.id,
                        parentData.result,
                        "tailboardFormSubmissions"
                      );
                      if (parentSaved) {
                        this.$store.commit("setNotifiedId", id);
                      }
                    } else
                      this.$store.commit(
                        "setNotifiedId",
                        tailboardFormSubmission.id
                      );
                  }
                } else
                  this.$store.commit(
                    "setNotifiedId",
                    tailboardFormSubmission.id
                  );
            }
          }
        }
      },
      deletedTailboardFormSubmission: {
        query: DELETED_TAILBOARD_FORM_SUBMISSION_SUBSCRIPTION,
        variables() {
          return {
            userId: this.user ? this.user.id : null
          };
        },
        skip() {
          return !this.serverActivity || !this.user;
        },
        async result(response) {
          const tailboardFormSubmission =
            response.data.deletedTailboardFormSubmission.result;
          const isDeleted = await dataAccess.deleteEntityById(
            tailboardFormSubmission.id,
            "tailboardFormSubmissions"
          );
          if (isDeleted)
            this.$store.commit("setNotifiedId", tailboardFormSubmission.id);
        }
      }
    }
  }
};
</script>

<style lang="scss">
/* This file is for your main application css. */
// TODO: Eventually distribute css to their respective components

$primary-color: #f2f4f8;
$secondary-color: #ffffff;
$accent-color: #eeaf30;
$error-color: #f65353;
$info-color: #34b233;
$success-color: #34b233;
$warning-color: #f65353;

$accent_primary: #005d55;
$accent_secondary: #eeaf30;
$accent_tertiary: #eeaf30;
$font-family: "Heebo";
$font-color-primary: #323335;
$btn-radius: 8px;

.synced {
  transition: transform 1s ease-in-out;
}

.rotating {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.syncing {
  transition: transform 1s ease-in-out;
  transform: rotateZ(360deg);
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 20px;
}

.cursor-pointer {
  cursor: pointer;
}

.form-builder {
  h1,
  h2 {
    font-weight: 300;
  }
}

@-moz-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* IE */
@-ms-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.text-capitalize {
  text-transform: capitalize;
}

.live-recording {
  color: red !important;
  -moz-animation: blink normal 1.5s infinite ease-in-out; /* Firefox */
  -webkit-animation: blink normal 1.5s infinite ease-in-out; /* Webkit */
  -ms-animation: blink normal 1.5s infinite ease-in-out; /* IE */
  animation: blink normal 1.5s infinite ease-in-out; /* Opera and prob css3 final iteration */
}

.remove-btn {
  margin-right: 10px !important ;
  flex-grow: 0 !important;
  flex-shrink: 0 !important;
}

.section-disabled {
  pointer-events: none;
  opacity: 0.6;
}

.section-enabled {
  pointer-events: visible !important;
  opacity: 1 !important;
}

.full-screen-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 70px;
  z-index: 1000;
  background-color: rgba(49, 46, 46, 0.85);
}

.toolbar-title {
  text-decoration: none;
  font-size: 14px !important;
  min-width: auto !important;
}

.v-main {
  position: relative;
}

.v-app-bar-style {
  height: 70px !important;
  padding: 2px;
}

.pdf-page {
  height: 100%;
  padding: 0 10mm;
}

.submission-header {
  padding: 16px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
  .v-btn {
    width: 120px;
    & + .v-btn {
      margin-left: 8px;
    }
  }
}

.submission-section {
  padding: 16px;
}

.submission-component {
  height: 100%;
}

.component-header {
  /* border-bottom: solid 1px #86aee7; */
  border-bottom: solid 1px #007970;
}

.expand-row,
.model-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
}

.model-header {
  z-index: 1;
  h2 {
    width: 100%;
  }
}

.action-row {
  cursor: pointer;
}

.flex {
  max-width: 100% !important;

  &.grow {
    flex-grow: 1 !important;
  }
}

.model-main {
  padding: 20px;
}

.model-main,
.form-builder-model {
  padding: 1rem;

  margin: auto;
  border-radius: 4px;

  > * .v-card {
    padding: 0;

    *:not(.v-card) {
      pointer-events: none;
    }

    .v-dialog__container,
    .v-btn,
    .v-dialog__container * {
      pointer-events: auto;
    }
  }

  &:not(.form-builder) > .v-card {
    padding: 16px 40px;
  }

  .card-header,
  .card-footer {
    padding: 0.25rem 0.75rem;
    display: flex;
    align-items: center;
    width: 100%;
  }

  .card-header {
    justify-content: space-between;
  }

  .card-footer {
    justify-content: center;
  }

  .no-children {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0.5rem;
    span {
      font-size: 1.25rem;
      font-weight: 600;
    }
  }

  .input-card {
    .v-dialog__container {
      position: absolute;
    }
  }
}

.form-builder {
  margin-top: 2rem;
}

.hidden {
  opacity: 0;
}

.input-card,
.component-card {
  margin: 0.5rem;
}

.component-card:not(.grid-layout) > .v-card,
.section-card > .v-card {
  width: calc(100% - 2rem);
  display: block;
  overflow: auto;
}

.input-card {
  &:not(.add-new) > div,
  .card-header {
    display: flex;
    padding: 0.25rem 0.125rem 0.25rem 0.75rem;
    justify-content: space-between;
    align-items: center;
  }

  p,
  h4 {
    padding-right: 0.5rem;
  }

  p {
    text-transform: capitalize;
    margin: 0;
    display: flex;
    align-items: center;
  }
}

.grid-layout {
  column-count: 3;

  .v-card {
    break-inside: avoid;
  }

  & > .card-header {
    column-span: all;
  }

  .submission-field {
    margin: 1rem;
  }
}

// TODO: This is too general and negatively affects other components.
.v-btn {
  margin: 0;
  &:not(.v-btn--icon) {
    text-transform: none;
    //font-size: 16px;
    //  min-width: 120px;
    // box-shadow: 0 2px 5px 0 rgba(35, 91, 168, 0.24) !important;
  }

  &.v-btn--icon {
    svg {
      height: 36px;
    }
  }
}

// .btn-bottom-container {
//   display: flex;
//   justify-content: center;
//   padding: 0 16px;
//   margin-bottom: 32px;
//   .v-btn {
//     width: 100%;
//     max-width: 350px;
//   }
// }

.main-card-header {
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > div {
    .v-btn {
      font-size: 1rem;
      width: 160px;

      & + .v-btn {
        margin-left: 1rem;
      }
    }
  }
}

img.form-modal-image {
  display: block;
  max-width: 200px;
  max-height: 50px;
  margin: 16px 0;
}

.v-card .add-new {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-card.add-new {
  flex-direction: column;
}

.v-card .add-new:hover {
  cursor: pointer;
  * {
    color: white;
  }
}

table {
  border-collapse: collapse;
}
tr,
td {
  border: none !important;
}

.v-tabs {
  .v-tabs__slider-wrapper {
    bottom: -2px;
    .v-tabs__slider {
      height: 4px;
    }
  }
  .v-tabs__wrapper {
    padding-bottom: 2px;
    .v-tabs__item--active {
      font-weight: bold;
    }
  }
}

.v-tabs__item {
  color: #a5a6a6;
}

.pages-count {
  width: 70px;
  font-size: 12px;
}

.file-input-label,
.file-input {
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
}
.file-input {
  display: flex;
  font-size: 16px;
  align-items: center;
  cursor: pointer;
}

.form-hint {
  font-size: 14px;
  margin-left: 24px;
  color: #a5a6a6;
}

.v-card__actions {
  padding: 16px;
}

.v-snack__wrapper.success,
.v-snack__wrapper.error {
  margin-top: 10px;
  background-color: white !important;
  border-radius: 8px;
  height: 44px;
  .v-icon {
    font-size: 20px;
  }
}
.v-snack__wrapper {
  .v-snack__content {
    height: 44px;
    border-radius: 8px;
    padding: 0 4px 0 12px;
  }

  &.success .v-snack__content {
    background-color: rgba(35, 168, 109, 0.15);
    border: solid 1px $success-color;
    color: $success-color;
  }

  &.success .v-snack__content .v-icon {
    color: $success-color;
  }

  &.error .v-snack__content {
    background-color: rgba(222, 58, 58, 0.15);
    border: solid 1px $error-color;
    color: $error-color;
  }
  &.error .v-snack__content .v-icon {
    color: $error-color;
  }
}
.submission-header-data {
  width: 100%;
  background-color: white;
  padding: 0;
  margin-bottom: 14px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.084),
    0 3px 10px 0 rgba(0, 0, 0, 0.074);
}

// moved from custom.css.eex

.v-toolbar,
.v-toolbar.primary,
.card-header,
.v-tabs .v-tabs__slider-wrapper .v-tabs__slider,
.v-pagination__item--active.primary {
  background-color: $accent_primary !important;
}

.v-toolbar,
.v-toolbar.primary {
  border-bottom: solid $accent_secondary 8px !important;
}

.v-toolbar * {
  color: $secondary-color !important;
}

.v-card {
  border-radius: 4px !important;
}

.submission-header {
  background-color: $secondary-color !important;
}

.v-application {
  background-color: $primary-color !important;
}

.card-header h1,
.card-header h2,
.card-header h3,
.card-header h4,
.card-header i {
  color: $secondary-color !important;
}

.droppable {
  background-color: $accent_secondary !important;
}

.input-card,
.v-card.section-card:not(.droppable) {
  background-color: $primary-color !important;
}

.v-btn.secondary *,
th.column,
.v-tabs__item--active,
.data-table-actions .v-select__selection,
.data-table-actions .v-icon {
  color: $accent_primary !important;
}

.v-input--checkbox i,
th.column .v-icon,
.data-table-actions,
.v-pagination__item:not(.v-pagination__item--active) {
  color: $accent_primary !important;
}

.v-card .add-new .v-icon,
.file-name.active {
  color: $font-color-primary;
}

.v-card .add-new:hover,
.file-input:hover,
.v-pagination__navigation:hover {
  background-color: $accent_secondary !important;
}

.v-pagination__navigation:hover g {
  stroke: white;
}

.v-card .add-new:hover i,
.file-input:hover * {
  color: $secondary-color !important;
}

.v-table tbody tr:nth-child(odd) {
  background-color: $accent_primary !important;
}

.v-table tbody tr:nth-child(even) {
  background-color: white !important;
}

.v-table tbody tr:hover {
  background-color: $accent_primary !important;
}

.yes-no-option {
  border-color: $accent_secondary;
  color: $accent_secondary;
  border-radius: $btn-radius;
}

.yes-no-option.selected,
.yes-no-option:hover {
  background-color: $accent_secondary;
  color: white;
}

.v-tabs .v-tabs__wrapper .v-tabs__container {
  border-bottom: solid 1px $accent_secondary;
}

.data-table-actions .v-pagination__navigation {
  border: solid 1px $accent_secondary;
}

.data-table-actions button {
  border-radius: $btn-radius;
}

.notification-bar.v-snack:not(.v-snack--absolute) {
  z-index: 1001;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  flex-flow: row;
  text-align: center;
}

.flex-item {
  flex: 0;
}

.flex-item-middle {
  flex: auto;
  padding: 15px 10px 0 10px;
  font-size: 14px;
  min-width: 242px;
}

.flex-item-middle span:nth-child(1) {
  float: left;
}
.flex-item-middle span:nth-child(2) {
  float: right;
}
.v-carousel__item {
  padding-bottom: 200px;
}
</style>
