import gql from "graphql-tag";

export const REVERSE_GEOCODE_QUERY = gql`
  query reverseGeocode($lat: Float!, $long: Float!) {
    reverseGeocode(lat: $lat, long: $long) {
      formattedAddress
    }
  }
`;

export const WEATHER_GEOCODE_QUERY = gql`
  query weatherForecast($lat: Float!, $long: Float!) {
    weatherForecast(lat: $lat, long: $long) {
      stationCode
      temperature
      heatIndex
      windChill
      description
    }
  }
`;
