<template>
  <preview-input-string :name="name" :value="employeeName" />
</template>

<script>
import PreviewInputString from "./preview-input-string.vue";

export default {
  name: "PreviewInputEmployee",
  props: {
    name: String,
    value: String
  },
  components: {
    PreviewInputString
  },
  computed: {
    employeeName() {
      const employee = this.$store.getters.getEmployees.find(
        e => e.id === this.value
      );

      if (employee) return `${employee.firstName} ${employee.lastName}`;

      return null;
    }
  }
};
</script>
