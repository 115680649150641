<template>
  <div class="pb-3">
    <v-row class="component-header">
      <v-spacer />
      <v-btn
        class="primary--text mb-1"
        text
        :disabled="disabled"
        @click="addNewVisitor"
      >
        <v-icon class="mr-3"> add_circle_outline </v-icon>
        Add Visitors
      </v-btn>
    </v-row>
    <v-row>
      <v-simple-table class="table-theme" fixed-header>
        <template>
          <thead>
            <tr>
              <th></th>
              <th>Name <span class="red--text ml-2">*</span></th>
              <th>Visit details</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(visitor, index) in visitorsList"
              :key="index + 1"
              style="height: 52px"
              :class="{ 'row-disabled': isSigned(visitor) }"
            >
              <td width="10">{{ `${index + 1}` }}</td>
              <td width="300">
                <v-text-field
                  v-model="visitor.name"
                  :disabled="disabled"
                  hide-details
                  dense
                  outlined
                  @blur="updateVisitors"
                  :ref="`visitor${index}_Name`"
                />
              </td>
              <td>
                <v-text-field
                  v-model="visitor.visitDetails"
                  :disabled="disabled || !visitor.name"
                  hide-details
                  dense
                  outlined
                  @blur="updateVisitors"
                />
              </td>
              <td class="section-enabled">
                <v-btn :disabled="disabled" icon>
                  <v-icon @click="deleteVisitor(visitor)"
                    >delete_outline</v-icon
                  >
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <dialog-message
        :showDialogMessage="showDialogMessage"
        displayCaption="Delete Visitor"
        :displayText="getSelectedVisitorName"
        :okAction="deleteAction"
        :cancelAction="cancelAction"
      />
    </v-row>
  </div>
</template>
<script>
import dialogMessage from "@/components/submission/dialog-message.vue";
export default {
  name: "VisitorManagment",
  components: {
    dialogMessage
  },
  props: {
    membersList: Array,
    disabled: Boolean,
    id: String,
    signatures: Array,
    refresh: { type: Function }
  },
  data() {
    return {
      visitor: null,
      visitorsList: [],
      visitorDialog: false,
      tempSelected: -1,
      showDialogMessage: false
    };
  },
  watch: {
    membersList: {
      deep: true,
      immediate: true,
      handler: function(value) {
        this.visitorsList = value ? value.map(v => JSON.parse(v)) : [];
      }
    }
  },

  updated: function() {
    this.$nextTick(function() {
      if (this.tempSelected !== this.visitorsList.length) {
        this.refresh(this.id, [
          this.visitorsList.filter(v => v.name !== "").length > 0 ? 1 : 0,
          this.disabled ? 0 : 1
        ]);
        this.tempSelected = this.visitorsList.length;
      }
    });
  },
  computed: {
    getSelectedVisitorName() {
      return this.visitor
        ? `Are you sure to delete visitor name : ${this.visitor.name}`
        : "";
    }
  },
  methods: {
    isSigned(visitor) {
      return this.signatures
        ? this.signatures.filter(x => !x.employee && x.name === visitor.name)
            .length > 0
        : false;
    },
    updateVisitors() {
      const visitors = this.visitorsList
        .filter(v => v.name)
        .map(v => JSON.stringify(v));
      this.$emit("change", visitors);
    },
    addNewVisitor() {
      if (this.visitorsList.filter(v => !v.name).length === 0)
        this.visitorsList.push({ name: "", visitDetails: "" });
      // Focus the new row's name input
      this.$nextTick(() => {
        this.$refs[`visitor${this.visitorsList.length - 1}_Name`][0].$el
          .getElementsByTagName("input")[0]
          .focus();
      });
    },
    getValidationResult() {
      return [this.visitorsList.filter(v => v.name).length > 0 ? 1 : 0, 1];
    },
    deleteAction() {
      if (this.visitor) {
        this.removeVisitor(this.visitor);
        this.visitor = null;
        this.showDialogMessage = false;
      }
    },
    cancelAction() {
      this.showDialogMessage = false;
    },
    deleteVisitor(visitor) {
      this.visitor = visitor;
      this.showDialogMessage = true;
    },
    removeVisitor(visitor) {
      let index = this.visitorsList.indexOf(visitor);
      if (index > -1) {
        this.visitorsList.splice(index, 1);
        this.$emit("delete", visitor.name);
        this.updateVisitors();
      }
    }
  }
};
</script>
<style scoped>
.row-disabled td:nth-child(-n + 2) {
  pointer-events: none;
  opacity: 0.6;
}
.table-theme {
  width: 100% !important;
}
.table-theme table th {
  color: #005d55 !important;
}

.table-theme table tr td:last-child {
  width: 1%;
  white-space: nowrap;
}
</style>
