<template>
  <div class="model-main">
    <div class="model-header">
      <h2>Submissions</h2>
    </div>
    <v-card>
      <v-tabs :hide-slider="display != 'index'">
        <v-tab v-if="display == 'index'" ripple>Checklist Forms</v-tab>
        <v-tab v-if="display == 'index'" ripple>Report Forms</v-tab>
        <v-tab v-if="display == 'index'" ripple>Hazard Assessment Forms</v-tab>
        <v-tab v-if="display == 'index'" ripple>Tailboard Forms</v-tab>
        <v-spacer />

        <v-btn
          class="primary"
          v-if="display == 'show'"
          @click="display = 'index'"
          >New</v-btn
        >
        <v-tab-item>
          <custom-table
            :columns="headers"
            :data="checklistFormSubmissions"
            :show="display == 'show'"
            @select="fetchPDF"
          />
        </v-tab-item>
        <v-tab-item>
          <custom-table
            :columns="headers"
            :data="reportFormSubmissions"
            :show="display == 'show'"
            @select="fetchPDF"
          />
        </v-tab-item>
        <v-tab-item>
          <custom-table
            :columns="headers"
            :data="hazardAssessmentFormSubmissions"
            :show="display == 'show'"
            @select="fetchPDF"
          />
        </v-tab-item>
        <v-tab-item>
          <custom-table
            :columns="tailboardHeaders"
            :data="tailboardFormSubmissions"
            :show="display == 'show'"
            @select="fetchPDF"
            @edit="edit($event)"
          />
        </v-tab-item>
      </v-tabs>
    </v-card>
  </div>
</template>

<script>
import {
  CHECKLIST_FORM_SUBMISSIONS_QUERY,
  REPORT_FORM_SUBMISSIONS_QUERY,
  HAZARD_ASSESSMENT_FORM_SUBMISSIONS_QUERY,
  TAILBOARD_FORM_SUBMISSIONS_QUERY
} from "../constants/form-queries";
import customTable from "./custom-table.vue";
import paginate from "../utilities/paginator";

export default {
  name: "Submissions",
  components: { customTable },
  data() {
    return {
      display: "index",
      headers: [
        { value: "formVersion", text: "Form Version", sortable: true },
        { value: "isLocked", text: "Locked?", sortable: true },
        { value: "insertedAt", text: "Created", sortable: true }
      ],
      tailboardHeaders: [
        { value: "formVersion", text: "Form Version", sortable: true },
        { value: "isLocked", text: "Locked?", sortable: true },
        { value: "insertedAt", text: "Created", sortable: true },
        { value: ["edit"], text: "Actions", sortable: false }
      ],
      tailboardFormSubmissions: []
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    }
  },
  mounted() {
    (async () => {
      const toCursor = res => res.data.tailboardFormSubmissions.metadata.after;
      const toEntries = res => res.data.tailboardFormSubmissions.entries;
      const fetchPage = after => {
        return this.$apollo.query({
          query: TAILBOARD_FORM_SUBMISSIONS_QUERY,
          variables: { after }
        });
      };

      this.tailboardFormSubmissions = await paginate({
        toCursor,
        toEntries,
        fetchPage
      });
    })();
  },
  methods: {
    fetchPDF(submission) {
      window.open(`/pdf/${submission.id}`, "_blank");
    },
    edit(submission) {
      this.$router.push(`/submissions/${submission.id}/attendees`);
    }
  },
  apollo: {
    checklistFormSubmissions: {
      query: CHECKLIST_FORM_SUBMISSIONS_QUERY,
      fetchPolicy: "cache-and-network"
    },
    reportFormSubmissions: {
      query: REPORT_FORM_SUBMISSIONS_QUERY,
      fetchPolicy: "cache-and-network"
    },
    hazardAssessmentFormSubmissions: {
      query: HAZARD_ASSESSMENT_FORM_SUBMISSIONS_QUERY,
      fetchPolicy: "cache-and-network"
    }
  }
};
</script>
