<template>
  <preview-form-component class="component-employee-management">
    <preview-form-title :level="3">Onsite Employees</preview-form-title>

    <preview-form-group
      v-if="submission.crewMembers && submission.crewMembers.length > 0"
    >
      <div v-for="member in submission.crewMembers" :key="member.id">
        <preview-input-string
          :name="member.employee.title"
          :value="member.employee.firstName + ' ' + member.employee.lastName"
        />
        <preview-input-string
          :name="'Vehicle:'"
          :value="member.vehicle && getAutomobileValue(member.vehicle.id)"
        />
        <preview-input-string
          :name="'Trailer:'"
          :value="member.trailer && getAutomobileValue(member.trailer.id)"
        />
      </div>
    </preview-form-group>
  </preview-form-component>
</template>

<script>
import PreviewFormComponent from "./preview-form-component.vue";
import PreviewFormGroup from "./preview-form-group.vue";
import PreviewFormTitle from "./preview-form-title.vue";
import PreviewInputString from "./preview-input-string.vue";

export default {
  name: "PreviewComponentJobDetails",
  props: {
    submission: Object
  },
  components: {
    PreviewFormComponent,
    PreviewFormGroup,
    PreviewFormTitle,
    PreviewInputString
  },
  methods: {
    getAutomobileValue(automobileId) {
      if (!automobileId) return "";

      const data = this.$store.getters.getVehicles.find(
        ({ id }) => id === automobileId
      );

      if (!data) return "";

      return `${data.vehicleRef} (${data.type})`;
    }
  }
};
</script>
