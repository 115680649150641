<template>
  <div>
    <!-- Check if isInApplicable is true, regardless of allVisitorSignatures -->
    <preview-form-component v-if="isInApplicable">
      <preview-form-title :level="3">Visitors/Contractors</preview-form-title>
      <preview-form-group>
        <h5 class="is-na preview-form-title preview-form-title-h5">N/A</h5>
      </preview-form-group>
    </preview-form-component>
    <!-- Otherwise, show the signatures if there are any -->
    <preview-form-component
      v-else-if="allVisitorSignatures && allVisitorSignatures.length > 0"
    >
      <preview-form-title :level="3">Visitors/Contractors</preview-form-title>
      <preview-form-group>
        <preview-input
          class="signature"
          v-for="signature in allVisitorSignatures"
          :key="signature.id"
          :disableGrid="true"
        >
          <preview-input-signature
            :name="'Visitor'"
            :owner="
              typeof signature.name === 'object'
                ? signature.name.name
                : signature.name
            "
            :value="signature.file"
            :signedAt="signature.signedAt"
          />
        </preview-input>
      </preview-form-group>
    </preview-form-component>
  </div>
</template>

<script>
import PreviewFormComponent from "./preview-form-component.vue";
import PreviewFormGroup from "./preview-form-group.vue";
import PreviewInputSignature from "./preview-input-signature.vue";
import PreviewFormTitle from "./preview-form-title.vue";
import PreviewInput from "./preview-input.vue";

export default {
  name: "PreviewComponentVisitorSignOff",
  props: {
    submission: Object,
    form: Object
  },
  components: {
    PreviewFormComponent,
    PreviewFormGroup,
    PreviewInput,
    PreviewFormTitle,
    PreviewInputSignature
  },
  computed: {
    allVisitorSignatures() {
      if (!this.submission.signatures) return [];

      return this.submission.signatures.filter(x => !x.employee);
    },
    isInApplicable() {
      const componentId = this.form.sections
        .find(s =>
          s.components.find(c => c.customType === "VISITOR_MANAGEMENT")
        )
        ?.components.find(c => c.customType === "VISITOR_MANAGEMENT")?.id;

      const isInApplicable = this.submission.inapplicableComponents.some(
        component => component === componentId
      );

      return isInApplicable;
    }
  }
};
</script>

<style>
.preview-form-title-h5 {
  padding: 1em;
  margin: 4px;
  background-color: var(--v-whiteBackground-base);
  font-size: 1em;
  border-radius: 0.2em;
}
</style>
