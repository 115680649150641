<template>
  <v-card class="form-builder-model form-builder">
    <div class="main-card-header">
      <h1>
        {{ form.title }}
        <form-modal
          :fields="formFields"
          title="Edit Form"
          :mutation="formUpdateMutation"
          :value="form"
          icon="edit"
          small
        />
      </h1>
      <div>
        <v-btn @click="openPreview" class="primary">
          Preview&nbsp;
          <v-icon>launch</v-icon>
        </v-btn>
        <v-btn class="primary" @click="expandAll = !expandAll">
          {{ expandAll ? "Collapse" : "Expand" }} All&nbsp;
          <v-icon v-show="!expandAll">expand_less</v-icon>
          <v-icon v-show="expandAll">expand_more</v-icon>
        </v-btn>
        <v-btn class="secondary" to="/forms">Back</v-btn>
      </div>
    </div>
    <v-card
      v-for="section in sorted(sections)"
      :key="section.id"
      :id="'section_' + section.id"
      class="mb-3 section-card section"
      draggable
      @dragstart.self="dragStart($event, section)"
      @drop.stop="onDrop($event, section)"
      @dragend.self="dragEnd('section')"
      @dragover.prevent
      @dragenter.prevent
      :ref="`section_${section.id}`"
    >
      <div class="card-header mb-2">
        <v-btn @click="toggleCollapsed(section.id)" color="primary" icon>
          <v-icon v-show="!collapsed[section.id]">expand_less</v-icon>
          <v-icon v-show="collapsed[section.id]">expand_more</v-icon>
        </v-btn>

        <h2>
          {{ section.title }}
          <form-modal
            :fields="sectionFields"
            title="Edit Section"
            :mutation="updateMutation('section')"
            :value="section"
            icon="edit"
            small
          />
        </h2>
        <v-btn @click="deleteSection(section.id)" color="primary" icon>
          <v-icon>close</v-icon>
        </v-btn>
      </div>
      <v-row class="mx-1 mb-1">
        <template v-for="(component, index) in sorted(section.components)">
          <v-col :key="component.id">
            <v-card
              v-show="!collapsed[section.id]"
              :id="'component_' + component.id"
              class="pb-1 component"
              draggable="true"
              @dragstart.stop="dragStart($event, component)"
              @dragend.self="dragEnd('component', section)"
              @drop.stop="onDrop($event, component)"
              @dragover.prevent
              @dragenter.prevent
              :ref="`component_${component.id}`"
            >
              <div class="card-header">
                <v-btn
                  @click="toggleCollapsed(component.id)"
                  color="primary"
                  icon
                >
                  <v-icon v-show="!collapsed[component.id]">expand_less</v-icon>
                  <v-icon v-show="collapsed[component.id]">expand_more</v-icon>
                </v-btn>
                <h3>
                  {{ component.title }}
                  <form-modal
                    :fields="componentFields"
                    title="Edit Component"
                    :mutation="updateMutation('component')"
                    :value="component"
                    icon="edit"
                    small
                  />
                </h3>
                <v-btn
                  @click.stop="deleteComponent(component.id)"
                  color="primary"
                  icon
                >
                  <v-icon>close</v-icon>
                </v-btn>
              </div>
              <v-row>
                <template v-for="(field, idx) in sorted(component.inputFields)">
                  <v-col :key="field.id">
                    <v-card
                      v-show="!collapsed[component.id]"
                      :key="field.id"
                      :id="'inputField_' + field.id"
                      class="ma-2 input-card inputField"
                      draggable="true"
                      @dragstart.self="dragStart($event, field)"
                      @dragend.self="dragEnd('inputField', component)"
                      @drop.stop="onDrop($event, field)"
                      @dragover.prevent
                      @dragenter.prevent
                      :ref="`inputField_${field.id}`"
                    >
                      <div class="card-header">
                        <h4>{{ field.name }}</h4>
                        <v-btn
                          @click.stop="deleteInput(field.id)"
                          color="primary"
                          small
                          icon
                        >
                          <v-icon small>close</v-icon>
                        </v-btn>
                      </div>
                      <div>
                        <p>
                          {{
                            field.inputType
                              .toLowerCase()
                              .replace("_no_", "/no/")
                              .replace("_", " ")
                          }}
                        </p>
                        <form-modal
                          :fields="inputFieldFields"
                          title="Edit Input Field"
                          :mutation="updateMutation('inputField')"
                          :value="field"
                          icon="edit"
                          small
                        />
                      </div>
                    </v-card>
                  </v-col>
                  <v-responsive
                    v-if="
                      component.layout === 'GRID' ? (idx + 1) % 3 == 0 : true
                    "
                    :key="idx"
                    width="100%"
                  />
                </template>
              </v-row>

              <!-- Don't need associated input fields for custom components -->
              <form-modal
                v-if="!component.customType"
                :fields="inputFieldFields"
                title="New Input Field"
                :mutation="inputFieldMutation"
                :value="{
                  componentId: component.id,
                  position:
                    component && component.inputFields
                      ? component.inputFields.length
                      : 0
                }"
                icon="add_box"
              >
                <template #activator="dialog">
                  <v-card
                    v-show="!collapsed[component.id]"
                    v-on="dialog.data.on"
                    class="input-card add-new"
                  >
                    <h4>Add Input Field</h4>
                    <v-icon>add_box</v-icon>
                  </v-card>
                </template>
              </form-modal>
            </v-card>
          </v-col>
          <v-responsive
            v-if="section.layout === 'GRID' ? (index + 1) % 3 == 0 : true"
            :key="index"
            width="100%"
          />
        </template>
      </v-row>

      <form-modal
        v-show="!collapsed[section.id]"
        :fields="componentFields"
        title="New Component"
        :mutation="componentMutation"
        :value="{
          sectionId: section.id,
          position:
            section && section.components ? section.components.length : 0
        }"
        icon="add_box"
      >
        <template #activator="dialog">
          <v-card
            v-show="!collapsed[section.id]"
            v-on="dialog.data.on"
            class="add-new"
            width="100%"
          >
            <h3 class="mr-2">Add Component</h3>
            <v-icon>add_box</v-icon>
          </v-card>
        </template>
      </form-modal>
    </v-card>

    <form-modal
      v-if="loaded"
      :fields="sectionFields"
      title="New Section"
      :mutation="sectionMutation"
      :value="{
        formId: this.formId,
        position: this.sections ? this.sections.length : 0
      }"
    >
      <template #activator="dialog">
        <v-card v-on="dialog.data.on" class="section-card add-new">
          <h3 class="mr-2">Add Section</h3>
          <v-icon>add_box</v-icon>
        </v-card>
      </template>
    </form-modal>
  </v-card>
</template>

<script>
import {
  CREATE_SECTION_MUTATION,
  UPDATE_SECTION_MUTATION,
  DELETE_SECTION_MUTATION,
  NEW_SECTION_SUBSCRIPTION,
  SECTIONS_QUERY,
  CREATE_COMPONENT_MUTATION,
  UPDATE_COMPONENT_MUTATION,
  DELETE_COMPONENT_MUTATION,
  NEW_SECTION_COMPONENT_SUBSCRIPTION,
  CREATE_INPUT_FIELD_MUTATION,
  UPDATE_INPUT_FIELD_MUTATION,
  DELETE_INPUT_FIELD_MUTATION,
  NEW_INPUT_FIELD_SUBSCRIPTION
} from "../constants/section-queries";
import {
  TAILBOARD_FORM_QUERY,
  UPDATE_TAILBOARD_FORM_MUTATION
} from "../constants/form-queries";

import formModal from "./form-modal.vue";
import appsignal from "@/plugins/appsignal";

export default {
  name: "FormBuilder",
  components: { formModal },
  props: ["formId"],
  data() {
    return {
      newSection: {},
      newComponent: {},
      newInputField: {},
      sections: [],
      currentForm: null,
      currentSection: null,
      currentDragged: null,
      form: {},
      expandAll: true,
      newRecord: {},
      // TODO: Separate form, section, component, into smaller components
      sectionFields: [
        { label: "Title", name: "title", type: "text", required: true },
        {
          label: "Description",
          name: "description",
          type: "text",
          required: true
        },
        {
          label: "Applicability Prompt",
          name: "applicabilityPrompt",
          type: "text"
        },
        {
          label: "Layout",
          name: "layout",
          type: "select",
          required: true,
          options: [
            { value: "TABLE", text: "Table" },
            { value: "GRID", text: "Grid" }
          ]
        }
      ],
      componentFields: [
        { label: "Title", name: "title", type: "text", required: true },
        {
          label: "Applicability Prompt",
          name: "applicabilityPrompt",
          type: "text"
        },
        {
          label: "Layout",
          name: "layout",
          type: "select",
          required: true,
          options: [
            { value: "TABLE", text: "Table" },
            { value: "GRID", text: "Grid" }
          ]
        },
        {
          label: "Custom Type",
          name: "customType",
          type: "select",
          options: [
            { value: "JOB_DETAILS", text: "Job Details" },
            { value: "EMPLOYEE_ENTRY_LOG", text: "Employee Entry Log" },
            { value: "EMPLOYEE_MANAGEMENT", text: "Employee Management" },
            { value: "EMPLOYEE_SIGN_OFF", text: "Employee Sign Off" },
            { value: "OWNER_SUBMISSION", text: "Owner Submission" },
            { value: "RISK_ASSESSMENT", text: "Risk Assessment" },
            { value: "VISITOR_MANAGEMENT", text: "Visitor Management" },
            {
              value: "EMPLOYEE_VISITOR_MANAGEMENT",
              text: "Employee Visitor Management"
            },
            { value: "VISITOR_SIGN_OFF", text: "Visitor Sign Off" },
            {
              value: "CONFINED_SPACE_SUBSTANCE_TEST",
              text: "Confined Space Substance Test"
            },
            { value: "CABLE_CHAMBER_FEEDER", text: "Cable Chamber Feeder" },
            {
              value: "CRITICAL_TASKS_ASSESSMENT",
              text: "Critical Tasks Assessment"
            }
          ]
        }
      ],
      formFields: [
        { label: "Title", name: "title", type: "text", required: true },
        { label: "Header", name: "header", type: "text", required: true },
        {
          label: "Instructions",
          name: "instructions",
          type: "text",
          required: true
        },
        {
          label: "Form Type",
          name: "type",
          type: "select",
          options: [
            {
              value: "SIMPLE",
              text: "Simple"
            },
            { value: "TAILBOARD", text: "Tailboard" }
          ],
          required: true
        },
        { label: "Logo", name: "logoFile", type: "file" }
      ],
      collapsed: {},
      updateQueue: [],
      loaded: false,

      dragItem: null,
      droppedOn: null
    };
  },
  computed: {
    componentMutation() {
      return CREATE_COMPONENT_MUTATION;
    },
    sectionMutation() {
      return CREATE_SECTION_MUTATION;
    },
    inputFieldMutation() {
      return CREATE_INPUT_FIELD_MUTATION;
    },
    formUpdateMutation() {
      return UPDATE_TAILBOARD_FORM_MUTATION;
    },
    inputFieldFields() {
      const options = [
        { value: "CHECKBOX", text: "Checkbox" },
        { value: "YES_NO_NA", text: "Yes or No" },
        { value: "BOOLEAN", text: "Boolean" },
        { value: "SIGNATURE", text: "Signature Box" },
        { value: "DRAWING", text: "Drawing" },
        { value: "STRING", text: "Text Box" },
        { value: "TEXT", text: "Text Area" },
        { value: "NUMBER", text: "Number Box" },
        { value: "DECIMAL", text: "Decimal" },
        { value: "PERCENT", text: "Percent" },
        { value: "SELECT_ONE", text: "Select One" },
        { value: "SELECT_MANY", text: "Select Many" },
        { value: "DATE", text: "Date" },
        { value: "DATETIME", text: "Datetime" },
        { value: "TIME", text: "Time" },
        { value: "ADDRESS", text: "Address" },
        { value: "WEATHER", text: "Weather" },
        { value: "TRAFFIC_PLAN", text: "Traffic Plan" },
        { value: "AUDIO", text: "Audio" },
        { value: "EMPLOYEE", text: "Employee" },
        { value: "EMPLOYEE_MANY", text: "Employees (Select Multiple)" },
        { value: "STATIC_TEXT", text: "Static Text" },
        { value: "PREVIEW", text: "Preview" },
        { value: "FILE", text: "File upload" },
        { value: "COMBOBOX", text: "Combobox" }
      ];

      return [
        { label: "Name", name: "name", type: "text", required: true },
        {
          label: "Input Type",
          name: "inputType",
          type: "select",
          required: true,
          options
        },
        { label: "Description", name: "description", type: "text" },
        { label: "Placeholder", name: "placeholderText", type: "text" },
        { label: "Allow N/A", name: "allowNotApplicable", type: "checkbox" },
        { label: "Related Asset", name: "relatedAsset", type: "file" }
      ];
    }
  },
  watch: {
    expandAll(val) {
      if (val) {
        this.collapsed = {};
      } else {
        for (const section of this.sections) {
          this.$set(this.collapsed, section.id, true);
          for (const component of section.components) {
            this.$set(this.collapsed, component.id, true);
          }
        }
      }
    }
  },
  created() {
    this.newSection = {
      formId: this.formId
    };
  },
  methods: {
    updateMutation(val) {
      switch (val) {
        case "section":
          return UPDATE_SECTION_MUTATION;
        case "component":
          return UPDATE_COMPONENT_MUTATION;
        case "inputField":
          return UPDATE_INPUT_FIELD_MUTATION;
        default:
          break;
      }
    },
    sorted(list) {
      if (!list) return [];
      return list.concat().sort((a, b) => a.position - b.position);
    },
    deleteSection(id) {
      const callback = result => {
        const deleted = result.data.deleteSection.id;

        this.sections = this.sections.filter(section => section.id !== deleted);

        const { offsetParent } = this.$refs[`section_${deleted}`];

        if (offsetParent) {
          this.resetSiblingPositions(offsetParent, "section");
        }
      };
      this.delete(DELETE_SECTION_MUTATION, id, callback);
    },
    deleteComponent(id) {
      const callback = result => {
        const deleted = result.data.deleteComponent.id;
        const el = document.getElementById("component_" + deleted);
        const containerParentEl = el.offsetParent;
        el.parentNode.removeChild(el);

        this.resetSiblingPositions(containerParentEl, "component");
      };
      this.delete(DELETE_COMPONENT_MUTATION, id, callback);
    },
    deleteInput(id) {
      const callback = result => {
        const deleted = result.data.deleteInputField.id;
        const el = document.getElementById("inputField_" + deleted);
        const containerParentEl = el.offsetParent;
        el.parentNode.removeChild(el);

        this.resetSiblingPositions(containerParentEl, "inputField");
      };
      this.delete(DELETE_INPUT_FIELD_MUTATION, id, callback);
    },
    async delete(mutation, id, callback) {
      try {
        const result = await this.$apollo.mutate({
          mutation,
          variables: { id }
        });
        callback(result);
      } catch (error) {
        appsignal.sendError(error);
      }
    },
    findById(id) {
      var result;
      this.sections.forEach(o => {
        if (o.id == id) {
          result = o;
          return;
        }
        if (o.components)
          o.components.forEach(c => {
            if (c.id == id) {
              result = c;
              return;
            }
            if (c.inputFields)
              c.inputFields.forEach(f => {
                if (f.id === id) {
                  result = f;
                  return;
                }
              });
          });
      });
      return result;
    },
    // getRefsByType(type) {
    //   // Get the $refs where equals type
    //   return Object.entries(this.$refs).filter(x => {
    //     return x[0].substring(x[0].indexOf(type), type.length) === type;
    //   });
    // },
    getTypeNodes(type) {
      return [...document.querySelectorAll(`.${type}:not(.add-new)`)];
    },
    dragStart(event, record) {
      event.dataTransfer.dropEffect = "move";
      event.dataTransfer.effectAllowed = "move";
      event.dataTransfer.setData("item-id", record.id);
    },
    dragEnd(type, parent = null) {
      const parentEl =
        parent && parent.id !== ""
          ? document.getElementById(
              `${parent.__typename[0].toLowerCase() +
                parent.__typename.slice(1)}_${parent.id}`
            )
          : this.getTypeNodes(type)[0].parentElement;

      if (this.setPosition(type, parentEl)) {
        this.updateMoved(type);
      }
    },
    onDrop(event, record) {
      const itemId = event.dataTransfer.getData("item-id");
      this.dragItem = this.findById(itemId);
      this.droppedOn = record;
    },
    resetSiblingPositions(container, targetType) {
      this.$nextTick(() => {
        const siblings = container.querySelectorAll(`[id^=${targetType}]`);

        siblings.forEach((x, i) => {
          const id = x.id.split("_")[1];
          const item = this.findById(id);
          if (item.position !== i) {
            item.position = i;
            this.addUpdated(item);
          }
        });

        this.updateMoved(targetType);
      });
    },
    setPosition(type, parentEl) {
      // Back out when:
      //  - items don't exist
      //  - types don't match
      //  - item is dropped on itself
      if (
        !(this.dragItem || this.draggedOn) ||
        this.dragItem.__typename !== this.droppedOn.__typename ||
        this.dragItem.id === this.droppedOn.id
      ) {
        return false;
      }
      const dragItemEl = document.getElementById(`${type}_${this.dragItem.id}`);
      const droppedOnEl = document.getElementById(
        `${type}_${this.droppedOn.id}`
      );

      // Unable to find matching element(s) (shouldn't happen if we've made this far)
      if (!dragItemEl || !droppedOnEl) {
        return false;
      }

      // We want to get the sibling index in order to set the position
      //   This is because some existing data items have duplicated positions
      //   or the positions do not match the indexes
      const droppedOnSiblings = [
        ...parentEl.querySelectorAll(`.${type}:not(.add-new)`)
      ];
      const dragItemElIndex = droppedOnSiblings.indexOf(dragItemEl);
      const droppedOnElIndex = droppedOnSiblings.indexOf(droppedOnEl);

      if (
        this.dragItem &&
        this.dragItem.__typename === this.droppedOn.__typename &&
        this.dragItem.id !== this.droppedOn.id
      ) {
        this.dragItem.position = droppedOnElIndex;
        this.droppedOn.position = dragItemElIndex;

        if (this.dragItem.position !== this.droppedOn.position) {
          this.dragItem.position = droppedOnElIndex;
          this.droppedOn.position = dragItemElIndex;
          this.addUpdated(this.dragItem);
          this.addUpdated(this.droppedOn);
        }
      }

      // Reset drag/drop items
      this.dragItem = null;
      this.droppedOn = null;

      console.warn("--> setPosition()");

      // Reset tracked drag/drop items
      this.dragItem = null;
      this.droppedOn = null;

      return true;
    },
    addUpdated(record) {
      if (!this.updateQueue.find(queued => queued.id === record.id)) {
        this.updateQueue.push(record);
      }
    },
    updateMoved(type) {
      for (const queued of this.updateQueue) {
        try {
          this.$apollo.mutate({
            mutation: this.updateMutation(type),
            variables: queued
          });
        } catch (error) {
          appsignal.sendError(error);
          break;
        }
      }
      this.updateQueue = [];
    },
    openPreview() {
      const type = this.form.type.toLowerCase();
      const url = `/preview/${type}/${this.formId}`;
      window.open(url, "_blank");
    },
    toggleCollapsed(key) {
      this.$set(this.collapsed, key, !this.collapsed[key]);
    }
  },
  apollo: {
    sections: {
      query: SECTIONS_QUERY,
      fetchPolicy: "cache-and-network",
      variables() {
        return {
          formId: this.formId
        };
      },
      result(result) {
        if (result.data && result.data.sections) {
          this.loaded = true;
        }
      },
      subscribeToMore: [
        {
          document: NEW_SECTION_SUBSCRIPTION,
          updateQuery: (previous, { subscriptionData }) => {
            if (!subscriptionData.data.newSection) return;
            const newSection = subscriptionData.data.newSection;
            return { sections: [...previous.sections, newSection] };
          }
        }
      ]
    },
    $subscribe: {
      newComponent: {
        query: NEW_SECTION_COMPONENT_SUBSCRIPTION,
        fetchPolicy: "cache-and-network",
        result(result) {
          const component = result.data.newComponent;

          const section = this.sections.findIndex(
            s => s.id === component.sectionId
          );
          this.sections[section].components = [
            ...this.sections[section].components,
            component
          ];
        }
      },
      newInputField: {
        query: NEW_INPUT_FIELD_SUBSCRIPTION,
        fetchPolicy: "cache-and-network",
        result(result) {
          const inputField = result.data.newInputField;

          const section = this.sections.findIndex(s => {
            const com = s.components.findIndex(
              c => c.id === inputField.componentId
            );
            return com != -1;
          });

          const component = this.sections[section].components.findIndex(
            c => c.id === inputField.componentId
          );

          this.sections[section].components[component].inputFields = [
            ...this.sections[section].components[component].inputFields,
            inputField
          ];
        }
      }
    },
    tailboardForm: {
      query: TAILBOARD_FORM_QUERY,
      fetchPolicy: "cache-and-network",
      variables() {
        return {
          formId: this.formId
        };
      },
      result(result) {
        if (result.data) {
          this.form = result.data.tailboardForm;
        }
      }
    }
  }
};
</script>
