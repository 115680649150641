import gql from "graphql-tag";

export const WORK_ORDER_FRAGMENT = gql`
  fragment WorkOrderEntity on WorkOrder {
    id
    costCenter
    date
    department
    description
    employeeRef
    location
    projectName
    rcNumber
    wbs
    workCenter
    workOrderNumber
    workOrderRef
  }
`;

export const VEHICLE_FRAGMENT = gql`
  fragment VehicleEntity on Vehicle {
    description
    vehicleRef
    isTrailer
    id
    make
    model
    status
    type
  }
`;
