<template>
  <preview-input :disableGrid="disableGrid || !name">
    <preview-input-name v-if="name" v-html="name" />
    <preview-input-value
      v-if="(value && value !== 'null') || value === 0"
      v-html="value"
    />
    <preview-input-value v-else>&mdash;</preview-input-value>
  </preview-input>
</template>

<script>
import PreviewInputName from "./preview-input-name.vue";
import PreviewInputValue from "./preview-input-value.vue";
import PreviewInput from "./preview-input.vue";

export default {
  name: "PreviewInputString",
  props: {
    name: String,
    value: [String, Number],
    maxLength: {
      type: Number,
      default: 30
    },
    lineBreak: {
      type: String,
      default: "auto",
      validator: value => ["auto", "long", "short"].indexOf(value) !== -1
    }
  },
  components: {
    PreviewInput,
    PreviewInputName,
    PreviewInputValue
  },
  computed: {
    disableGrid() {
      if (this.lineBreak === "short") return false;
      if (this.lineBreak === "long") return true;
      if (!this.name || (this.name && this.name.length > this.maxLength))
        return true;

      return false;
    }
  }
};
</script>
