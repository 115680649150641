<template>
  <div class="preview-input-signature">
    <preview-input-name v-if="name">{{ name }}</preview-input-name>

    <figure class="signature-figure">
      <div v-if="src" class="signature-image">
        <img :src="src" />
      </div>
      <p v-else>&mdash;</p>
      <figcaption v-if="owner || formattedDate" class="signature-caption">
        <span v-if="owner">{{ owner }},</span> {{ formattedDate }}
      </figcaption>
    </figure>
  </div>
</template>

<script>
import dayjs from "@/plugins/dayjs";
import getEnv from "@/utilities/env.js";

import PreviewInputName from "./preview-input-name.vue";

export default {
  name: "PreviewInputSignature",
  props: {
    value: String,
    name: String,
    signedAt: String,
    owner: String
  },
  components: {
    PreviewInputName
  },
  computed: {
    formattedDate() {
      if (!this.signedAt) return "";

      return dayjs(this.signedAt).format("YYYY-MM-DD HH:mma");
    },
    src() {
      return this.value && `${getEnv("VUE_APP_SERVER_URL")}/doc/${this.value}`;
    }
  }
};
</script>

<style scoped>
.preview-input-signature,
.signature-figure {
  break-inside: avoid;
  page-break-inside: avoid;
}

.signature-image {
  background-color: var(--v-whiteBackground-base);
  border-bottom: 1px solid var(--v-primary-base);
  border-radius: 4px;
  max-width: 300px;
}

.signature-image img {
  display: block;
  margin: 0 auto;
  width: 120px;
}

.signature-caption {
  color: var(--v-primary-base);
  font-size: 1.1em;
  font-weight: bold;
}
</style>
