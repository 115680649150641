<template>
  <component :is="as" class="preview-input-name"><slot></slot></component>
</template>

<script>
export default {
  name: "PreviewInputName",
  props: {
    as: {
      type: String,
      default: "p"
    }
  }
};
</script>

<style scoped>
.preview-input-name {
  font-size: 1em;
  margin: 0;
  padding: 4px;
}
</style>
