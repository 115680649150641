<template>
  <v-dialog :key="title" v-model="dialog" persistent max-width="500px">
    <template #activator="data">
      <slot name="activator" :data="data">
        <v-btn
          :draggable="isDraggable"
          @dragstart="$emit('dragstart', $event)"
          @dragend="$emit('dragend', $event)"
          :small="small"
          @mouseup="$emit('mouseup')"
          :class="{ primary: !icon }"
          :icon="Boolean(icon)"
          v-on="data.on"
        >
          <v-icon :small="small" v-if="icon">{{ icon }}</v-icon>
          <template v-else>
            {{ title }}
          </template>
        </v-btn>
      </slot>
    </template>

    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <v-alert :value="alert.msg !== ''" :type="alert.type">{{
        alert.msg
      }}</v-alert>
      <v-card-text>
        <v-container grid-list-md>
          <v-row>
            <v-col cols="12" v-for="field in fields" :key="field.label">
              <v-date-picker
                v-if="field.type == 'date'"
                :label="field.label + (field.required ? '*' : '')"
                :id="field.label"
                v-model="newData[field.name]"
              />
              <v-textarea
                outlined
                v-else-if="field.type == 'textarea'"
                :label="field.label + (field.required ? '*' : '')"
                :id="field.label"
                v-model="newData[field.name]"
              />
              <v-checkbox
                v-else-if="field.type == 'checkbox'"
                :label="field.label + (field.required ? '*' : '')"
                :id="field.label"
                v-model="newData[field.name]"
              />
              <v-select
                outlined
                v-else-if="field.type == 'select'"
                :label="field.label + (field.required ? '*' : '')"
                :items="field.options"
                v-model="newData[field.name]"
              />

              <v-text-field
                outlined
                v-else-if="field.type == 'number'"
                type="number"
                :label="field.label + (field.required ? '*' : '')"
                :id="field.label"
                v-model.number="newData[field.name]"
              />

              <template v-else-if="field.type == 'file'">
                <label class="file-input-label">{{
                  field.label + (field.required ? "*" : "")
                }}</label>
                <img
                  v-if="isUUID(newData[field.name])"
                  class="form-modal-image"
                  :src="'/doc/' + newData[field.name]"
                />
                <div class="file-input" :id="field.label" @click="chooseFile()">
                  <span
                    class="file-name"
                    :class="{ active: chosenFile[field.name] }"
                  >
                    {{ chosenFile[field.name] || "Choose File" }}
                  </span>
                </div>
                <input
                  :id="field.label + (field.required ? '*' : '')"
                  v-show="false"
                  ref="fileUpload"
                  type="file"
                  @change="updateFile($event.target.files[0], field.name)"
                />
              </template>

              <v-text-field
                outlined
                v-else
                :type="field.type"
                :label="field.label + (field.required ? '*' : '')"
                :id="field.label"
                v-model="newData[field.name]"
              />

              <template
                v-if="
                  field.name === 'inputType' &&
                    ['SELECT_ONE', 'SELECT_MANY'].includes(newData[field.name])
                "
              >
                <v-select
                  outlined
                  :label="'Option Group' + (field.required ? '*' : '')"
                  :items="optionGroups"
                  v-model="newData['optionGroupId']"
                />

                <v-checkbox
                  label="Allow Other"
                  v-model="newData['allowOther']"
                />
                <v-checkbox
                  label="Display Inline"
                  v-model="newData['displayInline']"
                />
              </template>

              <template
                v-if="field.name === 'inputType' && newData['inputType']"
              >
                <v-select
                  v-if="isBooleanInputType"
                  outlined
                  clearable
                  label="Default Value"
                  :items="[
                    { value: 'true', text: 'True' },
                    { value: 'false', text: 'False' }
                  ]"
                  v-model="newData['defaultValue']"
                  @click:clear="onDefaultValueClear()"
                />
                <v-select
                  v-else-if="newData['inputType'] === 'EMPLOYEE'"
                  outlined
                  clearable
                  label="Default Value"
                  :items="[
                    { value: 'CURRENT_USER', text: 'Current User' },
                    {
                      value: 'CURRENT_USER_SUPERVISOR',
                      text: 'Current User\'s Supervisor'
                    }
                  ]"
                  v-model="newData['defaultValue']"
                  @click:clear="onDefaultValueClear()"
                />
                <v-text-field
                  v-else
                  outlined
                  label="Default Value"
                  v-model="newData['defaultValue']"
                />
              </template>

              <!-- <div v-if="field.name === 'inputType' && newData[field.name]">
                <v-select
                  outlined
                  v-if="isBooleanType(field)"
                  label="Default Value"
                  :items="[
                    { value: 'true', text: 'True' },
                    { value: 'false', text: 'False' }
                  ]"
                  v-model="newData['defaultValue']"
                />
                <v-text-field
                  outlined
                  v-else-if="
                    newData[field.name] !== 'SIGNATURE' &&
                      newData[field.name] !== 'DRAWING'
                  "
                  :type="field.type"
                  label="Default Value"
                  v-model="newData['defaultValue']"
                />
              </div> -->
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click.native="closeForm"> Cancel </v-btn>
        <v-btn class="primary" @click.native="submitForm()">Submit</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  OPTION_GROUPS_QUERY,
  NEW_OPTION_GROUP_SUBSCRIPTION
} from "../constants/section-queries";

const BOOLEAN_TYPES = ["BOOLEAN", "CHECKBOX", "YES_NO_NA"];

export default {
  name: "FormModal",
  props: [
    "fields",
    "value",
    "title",
    "mutation",
    "small",
    "icon",
    "isDraggable",
    "show"
  ],
  data() {
    return {
      dialog: false,
      newData: { ...this.value },
      alert: {
        msg: "",
        type: "success"
      },
      optionGroups: [],
      chosenFile: {}
    };
  },
  computed: {
    dragStart() {
      return this.dragEvents ? this.dragEvents.dragStart : () => {};
    },

    mutationResultKey() {
      const mutationDefinition = this.mutation.definitions.find(
        x => x.operation === "mutation"
      );

      return mutationDefinition.name.value.replace("Mutation", "");
    },
    isBooleanInputType() {
      return this.newData.inputType
        ? BOOLEAN_TYPES.includes(this.newData.inputType)
        : false;
    }
  },
  watch: {
    show: {
      deep: true,
      handler(val) {
        this.dialog = val;
      }
    },
    value: {
      deep: true,
      handler(val) {
        this.newData = { ...val };
      }
    }
  },
  methods: {
    closeForm() {
      this.dialog = false;
      this.alert.msg = "";
      this.$emit("close");
    },
    async submitForm() {
      this.$apollo
        .mutate({
          mutation: this.mutation,
          variables: this.newData
        })
        .then(result => {
          this.dialog = false;
          this.alert.type = "success";
          this.alert.msg = "";
          this.newData = {
            ...result.data[this.mutationResultKey],
            ...this.value
          };
        })
        .catch(error => {
          this.alert.type = "error";
          this.alert.msg = `Please provide all required fields. ${error}`;
        });
      this.$emit(this.alert.type);
    },
    isUUID(string) {
      return /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/.test(
        string
      );
    },
    chooseFile() {
      this.$refs.fileUpload[0].click();
    },
    updateFile(file, field) {
      this.$set(this.chosenFile, field, file.name);

      const data = this.newData;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function() {
        data[field] = reader.result;
      };
    },
    onDefaultValueClear() {
      this.$nextTick(() => {
        this.newData["defaultValue"] = null;
      });
    }
  },
  apollo: {
    optionGroups: {
      query: OPTION_GROUPS_QUERY,
      fetchPolicy: "cache-and-network",
      update(data) {
        return data.optionGroups.map(x => {
          return { value: x.id, text: x.name };
        });
      },
      subscribeToMore: [
        {
          document: NEW_OPTION_GROUP_SUBSCRIPTION,
          updateQuery: (previous, { subscriptionData }) => {
            if (!subscriptionData.data.newOptionGroup) return;
            const newOptionGroup = subscriptionData.data.newOptionGroup;

            return {
              optionGroups: [...previous.optionGroups, newOptionGroup]
            };
          }
        }
      ]
    }
  }
};
</script>
<style scoped></style>
