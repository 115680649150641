/* eslint-disable no-console */

import { register } from "register-service-worker";
import getEnv from "@/utilities/env.js";

if (getEnv("NODE_ENV") === "production") {
  register(`${getEnv("BASE_URL")}service-worker.js`, {
    ready(registration) {
      console.log(
        "App is being served from cache by a service worker.\n" +
          "For more details, visit https://goo.gl/AFskqB"
      );
      Notification.requestPermission(function(status) {
        console.log("Status ", status);
      });
      document.dispatchEvent(
        new CustomEvent("swReady", { detail: registration })
      );
    },
    registered() {
      console.log("Service worker has been registered.");
    },
    cached() {
      console.log("Content has been cached for offline use.");
    },
    updatefound() {
      console.log("New content is downloading.");
    },
    updated(registration) {
      console.log("New content refresh.", registration);
      document.dispatchEvent(
        new CustomEvent("swUpdated", { detail: registration })
      );
    },
    offline() {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
      document.dispatchEvent(new CustomEvent("appOffline", { offline: true }));
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    }
  });

  navigator.serviceWorker.addEventListener("message", function(event) {
    var clientId = event.data.client;
    console.log("Client " + clientId + " says: " + event.data.message);
  });

  var refreshing;

  navigator.serviceWorker.addEventListener("controllerchange", () => {
    if (refreshing) return;
    refreshing = true;
    window.location.reload();
  });
}
