<template>
  <div class="preview-input-weather">
    <template v-if="value">
      <preview-input-string
        :name="'Temperature'"
        :value="value.temperature + '&deg;C'"
      />
      <preview-input-string :name="'Description'" :value="value.description" />
      <preview-input-string
        :name="'Heat Index'"
        :value="value.heatIndex + '&deg;C'"
      />
      <preview-input-string
        :name="'Wind Chill'"
        :value="value.windChill + '&deg;C'"
      />
    </template>
    <preview-input-name v-else>&ndash;</preview-input-name>
  </div>
</template>

<script>
import PreviewInputString from "./preview-input-string.vue";
import PreviewInputName from "./preview-input-name.vue";

export default {
  name: "PreviewInputWeather",
  props: {
    value: Object
  },
  components: {
    PreviewInputString,
    PreviewInputName
  }
};
</script>
