<template>
  <div class="preview-form-component">
    <preview-form-title v-if="title" :level="3">{{ title }}</preview-form-title>

    <slot></slot>
  </div>
</template>

<script>
import PreviewFormTitle from "./preview-form-title.vue";

export default {
  name: "PreviewFormComponent",
  props: {
    title: String
  },
  components: {
    PreviewFormTitle
  }
};
</script>

<style scoped>
.preview-form-component {
  column-count: 2;
  column-fill: balance;
  column-rule: 1px solid var(--v-light-darken1);
  margin-bottom: 16px;
}
</style>
