<template>
  <v-menu
    v-model="menu"
    min-width="290px"
    :close-on-content-click="false"
    :disabled="disabled"
    offset-y
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        outlined
        v-on="on"
        :id="id"
        :placeholder="placeholder"
        :value="datetimeFormatted"
        :rules="rules"
        append-icon="today"
        @click:append="getCurrentTimeAndUpdate"
        @click:clear="$emit('change', null)"
        :disabled="disabled"
        clearable
        readonly
      />
    </template>
    <v-date-picker :value="dateStr" @input="onDateChange($event)">
      <v-row align="center">
        <v-col>Time</v-col>
        <v-col>
          <vue-timepicker
            v-model="timeStr"
            hide-dropdown
            manual-input
            drop-direction="up"
            @input="onTimeChange($event)"
          ></vue-timepicker>
        </v-col>
      </v-row>
    </v-date-picker>
  </v-menu>
</template>
<script>
import dayjs from "@/plugins/dayjs";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import { parseDatetimeStr } from "@/utilities/datetimeUtilities";

function parseValues(val) {
  return {
    dateStr: parseDatetimeStr(val).format("YYYY-MM-DD"),
    timeStr: parseDatetimeStr(val).format("HH:mm")
  };
}

export default {
  name: "Datetimepicker",

  props: {
    id: String,
    label: String,
    placeholder: String,
    disabled: Boolean,
    initialValue: String,
    rules: Array
  },

  components: { VueTimepicker },

  data() {
    return {
      menu: false,
      ...parseValues(this.initialValue)
    };
  },

  methods: {
    onDateChange(val) {
      this.dateStr = val;
      this.$emit("change", this.datetimeISOString);
    },

    onTimeChange(val) {
      this.timeStr = val;
      this.$emit("change", this.datetimeISOString);
    },

    getCurrentTimeAndUpdate() {
      const now = parseDatetimeStr(Date.now());

      this.dateStr = now.format("YYYY-MM-DD");
      this.timeStr = now.format("HH:mm");

      this.$emit("change", this.datetimeISOString);
    }
  },

  watch: {
    menu: function(val) {
      if (val && !this.initialValue) this.getCurrentTimeAndUpdate();
    },
    initialValue: function(val, oldVal) {
      if (val !== oldVal) {
        const { dateStr, timeStr } = parseValues(val);
        this.dateStr = dateStr;
        this.timeStr = timeStr;
      }
    }
  },

  computed: {
    datetime() {
      return dayjs(`${this.dateStr} ${this.timeStr}`, "YYYY-MM-DD HH:mm");
    },

    datetimeFormatted() {
      return this.initialValue ? this.datetime.format("YYYY-MM-DD HH:mm") : "";
    },

    datetimeISOString() {
      return this.datetime.toISOString();
    }
  }
};
</script>

<style scoped>
.v-picker >>> .v-picker__actions {
  padding-top: 0px;
}

.v-picker >>> .vue__time-picker input {
  border-radius: 4px;
}
</style>
