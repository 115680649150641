<template>
  <div class="submission-header-data">
    <div class="pt-4 pr-4 pl-4">
      <div class="d-flex">
        <p>Job Details</p>
        <v-spacer />
        <div class="d-flex mr-2" v-if="!isChildForm">
          <p class="pr-2 pt-1 caption primary--text">Opened</p>
          <label class="switch">
            <input
              ref="closeFormOption"
              type="checkbox"
              :disabled="!isReadyToClosed || isClosed"
              @click="closeSubmissionForm"
              :checked="isClosed"
            />
            <span class="slider round"></span>
          </label>
          <p class="pl-2 pt-1 caption primary--text">Closed</p>
        </div>
        <div>
          <owner-submission
            v-if="!isChildForm"
            :tailboardFormSubmission="tailboardFormSubmission"
            :tailboardFormTitle="tailboardFormTitle"
            :validSubmit="validSubmit"
            :disabled="disabled"
            @signature="onEmitSignature"
          />
          <p v-if="closeHint" class="caption border--text ml-n4">
            <v-icon color="accent">info</v-icon> {{ closeHint }}
          </p>
        </div>
        <v-btn
          class="ml-2"
          :disabled="disabled"
          :loading="doneLoading"
          @click.native="done"
        >
          <v-icon class="mr-2">done</v-icon>
          Done
        </v-btn>
      </div>

      <v-row class="ma-0 pa-0">
        <v-col lg="9" xl="9" md="8">
          <job-information
            :tailboardFormSubmission="tailboardFormSubmission || {}"
          />
        </v-col>
        <v-col lg="3" xl="3" md="4" class="pa-0 pt-6 pb-3">
          <v-menu rounded="" offset-y>
            <template v-slot:activator="{ attrs, on }">
              <v-btn
                class="ml-2 whiteBackground primary--text float-right mt-4"
                :outlined="false"
                :disabled="isDisabled"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon class="mr-2" color="primary">add_circle_outline</v-icon>
                Form Actions
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                link
                :disabled="!isAdmin && !isFormOwner"
                @click="searchCrewDialog = true"
              >
                <v-list-item-title>Transfer Form Ownership</v-list-item-title>
              </v-list-item>
              <v-list-item
                link
                :disabled="isChildForm || (!isFormOwner && !isAdmin)"
                @click="addChildForm"
              >
                <v-list-item-title>Insert new child form</v-list-item-title>
              </v-list-item>
              <v-list-item
                link
                @click="lockTailboardFormAction"
                :disabled="!isOnline"
              >
                <v-list-item-title
                  >{{ lockCaption }} the form</v-list-item-title
                >
              </v-list-item>
              <v-list-item
                link
                :disabled="isDisabled || (!isSafety && !isAdmin)"
                @click="deleteChildForm"
              >
                <v-list-item-title>Delete this form</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn
            class="ml-2 float-right mt-4"
            :disabled="isDisabled || !isOnline"
            @click.native="previewPDF"
          >
            <v-icon class="mr-2">visibility</v-icon>
            Preview
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div
      class="progress-holder"
      v-if="lockProgressValue > 0 && lockProgressValue < 100"
    >
      <div class="d-flex progress-holder-div">
        <v-icon class="mr-1 mt-n3" color="primary">$vuetify.icons.lock</v-icon>
        <p class="mt-1">Form lock in progress</p>
      </div>
      <v-progress-linear
        background-color="secondary darken-2"
        height="30"
        class="mt-1"
        color="accent darken-1"
        :value="lockProgressValue"
      ></v-progress-linear>
    </div>

    <dialog-message
      :showDialogMessage="showDialogMessage"
      :displayCaption="dialogCaption"
      :displayText="dialogText"
      :okAction="okAction"
      :cancelAction="cancelAction"
    />
    <select-child-form
      :multiple="false"
      caption="Select Child Forms"
      :childFormsData="childForms"
      :showChildDialog="addChildDialog"
      :loading="loading"
      :selectAction="selectChildForm"
      :cancelAction="cancelAddChildAction"
    />
    <select-child-form
      :multiple="true"
      caption="Select Child To Lock"
      :childFormsData="activeChildForms"
      :showChildDialog="lockChildDialog"
      :loading="loading"
      :selectAction="lockChildFormsAction"
      :cancelAction="cancelLockChildAction"
      :selectItemAction="selectChildAction"
    />
    <employees-search
      :searchCrewDialogShow="searchCrewDialog"
      :membersList="[]"
      :title="transferTitle"
      :caption="
        `Current Owner: ${
          tailboardFormSubmission.user ? tailboardFormSubmission.user.name : ''
        }`
      "
      :multiple="false"
      :loading="loading"
      :cancelAction="cancelSearchAction"
      @done="transferOwnership"
    />
  </div>
</template>
<script>
import employeesSearch from "@/components/submission/employees-search.vue";
import jobInformation from "@/components/submission/job-information.vue";
import ownerSubmission from "@/components/submission/custom/owner-submission.vue";
import dialogMessage from "@/components/submission/dialog-message.vue";
import selectChildForm from "@/components/submission/select-child-form.vue";
import tailboardController from "@/js/tailboards-controller.js";
import dayjs from "@/plugins/dayjs";
import getEnv from "@/utilities/env.js";
import { workers } from "@/shared/workers.js";

export default {
  name: "FormToolbar",
  props: {
    childForms: Array,
    tailboardFormSubmission: Object,
    totalRequiredProgress: Number,
    tailboardForm: Object,
    tailboardFormTitle: String,
    relationFormsItems: Array,
    disabled: Boolean,
    done: { type: Function },
    validSubmit: { type: Function },
    closeFormAsync: { type: Function },
    saveFormAsync: { type: Function },
    userName: String,
    doneLoading: Boolean,
    isChildForm: Boolean,
    isOnline: Boolean
  },
  components: {
    ownerSubmission,
    dialogMessage,
    selectChildForm,
    employeesSearch,
    jobInformation
  },
  data() {
    return {
      lockProgressValue: 0,
      showDialogMessage: false,
      addChildDialog: false,
      lockChildDialog: false,
      loading: false,
      checked: true,
      dialogFormIndex: 0,
      searchCrewDialog: false,
      newChildForm: null,
      activeChildForms: []
    };
  },
  computed: {
    lockCaption() {
      return this.tailboardFormSubmission.isLocked ? "Unlock" : "Lock";
    },
    currentUser() {
      return this.$store.getters.getUser;
    },
    isAdmin() {
      return this.currentUser && this.currentUser.role === "ADMIN";
    },
    isSafety() {
      return this.currentUser && this.currentUser.role === "SAFETY";
    },
    isFormOwner() {
      return this.currentUser && this.tailboardFormSubmission.user
        ? this.tailboardFormSubmission.user.id === this.currentUser.id
        : false;
    },
    isDisabled() {
      return this.disabled || !this.tailboardFormSubmission.id;
    },

    dialogText() {
      switch (this.dialogFormIndex) {
        case 1:
          return "Deleting this form will delete all form data immediately.<br/>You can't undo this action.";
        case 2:
          return "All sections will be copied <br/>except address, crew huddle recordings & sign-off. <b>Are you sure?</b> ";
        case 3:
          return "Have any changes occurred to the job plan?<br/><br/>Note:  Changes that occurred on the job plan (e.g. changes to hazards/barriers) need to be documented,  and then communicated with the crew.<br/><br/>Would you like to proceed?";
        default:
          return "Before you can submit the form, please review the form and fill out any data that is required for the final submission";
      }
    },
    dialogCaption() {
      switch (this.dialogFormIndex) {
        case 1:
          return "<b>Are you sure you want to delete this form?</b><br/>";
        case 2:
          return "Copy This Form ?";
        case 3:
          return "Changes to the Job Plan";
        default:
          return "Close submission form";
      }
    },
    closeHint() {
      return (this.tailboardFormSubmission.state === "SUBMITTED" ||
        this.totalRequiredProgress === 100) &&
        !this.childCompleted
        ? "Review child forms"
        : null;
    },
    childCompleted() {
      return !this.tailboardFormSubmission.childTailboardFormSubmissions.some(
        c => c.state === "IN_PROGRESS"
      );
    },
    isReadyToClosed() {
      if (this.tailboardFormSubmission) {
        return (
          this.tailboardFormSubmission.state === "SUBMITTED" &&
          this.childCompleted
        );
      } else return false;
    },
    isClosed() {
      return this.tailboardFormSubmission
        ? this.tailboardFormSubmission.mode === "CLOSED"
        : false;
    },
    transferTitle() {
      return this.newChildForm
        ? "Create Child Form: Ownership"
        : "Transfer Ownership";
    }
  },
  methods: {
    async lockTailboardFormAction() {
      if (!this.tailboardFormSubmission.isLocked) {
        this.activeChildForms = this.relationFormsItems.map(c => {
          return {
            id: c.value.id,
            value: c.value.id,
            text: c.title,
            state: c.state,
            disabled: c.disabled,
            instructions: `Owner ${c.owner}`,
            created: c.created,
            inProgress: false,
            selected: false,
            isLocked: c.isLocked,
            isLockedById: c.isLockedBy ? c.isLockedBy.id : null,
            files: []
          };
        });

        if (this.activeChildForms.length) this.lockChildDialog = true;
        else this.lockForm();
      } else {
        const isUnlocked = await tailboardController.unLockTailboardFormSubmission(
          this.tailboardFormSubmission
        );
        if (isUnlocked) {
          this.tailboardFormSubmission.isLocked = false;
          this.tailboardFormSubmission.isLockedBy = null;
          this.lockChildDialog = false;
        }
      }
    },
    async selectChildAction(child) {
      if (child.selected) {
        child.inProgress = true;
        const requiredFiles = await tailboardController.getSubmissionFilesList(
          child.id
        );
        if (requiredFiles) {
          child.files = requiredFiles;
          child.inProgress = false;
        }
      } else {
        child.files = [];
      }
    },

    async lockForm() {
      this.lockProgressValue = 0;
      const lockedObj = {
        isLocked: true,
        isLockedBy: this.currentUser
      };
      const isLocked = await tailboardController.lockFormAction(
        this.tailboardFormSubmission.id,
        lockedObj
      );
      if (isLocked) {
        this.tailboardFormSubmission.isLocked = true;
        this.tailboardFormSubmission.isLockedBy = this.currentUser;
        const childToLock = this.activeChildForms.filter(c => c.selected);

        if (childToLock.length > 0) {
          const step = Math.ceil(100 / childToLock.length);
          for (let { id } of childToLock) {
            if (await tailboardController.lockFormAction(id, lockedObj)) {
              this.lockProgressValue += step;
            }
          }
        }
        this.lockChildDialog = false;
      }
    },

    async lockChildFormsAction() {
      let fetchList = [];
      fetchList = await tailboardController.getSubmissionFilesList(
        this.tailboardFormSubmission.id
      );
      this.activeChildForms.forEach(child => {
        if (child.selected) {
          fetchList.push(...child.files);
        }
      });
      if (fetchList.length > 0) {
        this.lockProgressValue = 0;
        const step = Math.ceil(100 / fetchList.length);
        let worker = workers.cacheWorker();
        worker.postMessage({
          type: "catch",
          files: fetchList,
          uri: `${getEnv("VUE_APP_SERVER_URL")}/doc/`
        });

        worker.addEventListener("message", async e => {
          if (e.data.type === "step") {
            this.lockProgressValue += step;
            if (this.lockProgressValue >= 100) {
              // success fetching all files 'lock the data'
              await this.lockForm();
            }
          }
        });
      } else {
        await this.lockForm();
      }
    },

    cancelCloseProcess() {
      this.$refs.closeFormOption.checked = false;
    },
    showEditConfirmation() {
      this.dialogFormIndex = 3;
      this.showDialogMessage = true;
    },
    cancelSearchAction() {
      this.resetDialogs();
    },
    async selectChildForm(child) {
      this.loading = true;
      // Add new child form
      this.newChildForm = await this.createChildForm(child);
      this.loading = false;
      this.addChildDialog = false;

      if (this.newChildForm) {
        // Open transfer ownership dialog
        this.searchCrewDialog = true;
        this.$emit("redirect", this.newChildForm);
      }
    },
    async transferOwnership(employee) {
      const submissionId =
        this.newChildForm && this.newChildForm.id
          ? this.newChildForm.id
          : this.tailboardFormSubmission.id;
      const newOwnerId =
        employee && employee.length > 0
          ? employee[0].employee.id
          : this.currentUser.id;
      this.loading = true;
      if (newOwnerId !== this.tailboardFormSubmission.user.id) {
        const changeOwnership = await tailboardController.changeSubmissionOwnership(
          newOwnerId,
          submissionId
        );
        // We want to redirect back to parent form
        //   when user transfers ownwership of child form
        if (changeOwnership) {
          if (this.tailboardFormSubmission.isLocked)
            await tailboardController.unLockTailboardFormSubmission(
              this.tailboardFormSubmission
            );
          const url =
            this.tailboardFormSubmission.tailboardForm.type === "SIMPLE"
              ? {
                  formId: this.tailboardFormSubmission
                    .parentTailboardFormSubmission.formId,
                  id: this.tailboardFormSubmission.parentTailboardFormSubmission
                    .id
                }
              : null;

          this.$emit("redirect", url);
        }
      } else this.resetDialogs();
    },

    resetDialogs() {
      this.loading = false;
      this.newChildForm = null;
      this.addChildDialog = false;
      this.searchCrewDialog = false;
    },

    addChildForm() {
      this.addChildDialog = true;
    },
    async deleteTailboardSubmissionForm() {
      const isDeleted = await tailboardController.deleteSubmissionAsync(
        this.tailboardFormSubmission.id
      );
      if (isDeleted) {
        this.showDialogMessage = false;
        if (this.tailboardFormSubmission.tailboardForm.type === "TAILBOARD")
          this.$emit("redirect", null);
        else
          this.$emit("redirect", {
            formId: this.tailboardFormSubmission.parentTailboardFormSubmission
              .formId,
            id: this.tailboardFormSubmission.parentTailboardFormSubmission.id
          });
      }
    },
    async createChildForm(child) {
      if (await this.saveFormAsync()) {
        const data = {
          header: this.tailboardFormSubmission.tailboardFormHeader,
          supervisorId: this.tailboardFormSubmission.supervisorId,
          supervisorPhone: this.tailboardFormSubmission.supervisorPhone,
          formId: child.value,
          submissionId: null,
          user: this.currentUser,
          hasRiskAssessment: false,
          insertedAt: dayjs.utc().format(),
          tailboardForm: {
            header: child.header,
            id: child.id,
            instructions: child.instructions,
            logoFile: child.logoFile,
            title: child.title,
            type: child.type
          },
          parent: {
            formId: this.tailboardFormSubmission.formId,
            id: this.tailboardFormSubmission.id,
            insertedAt: this.tailboardFormSubmission.insertedAt,
            tailboardForm: this.tailboardFormSubmission.tailboardForm
          }
        };
        return await tailboardController.createChildTailboardFormSubmission(
          data
        );
      }
    },
    cancelAddChildAction() {
      this.addChildDialog = false;
    },
    cancelLockChildAction() {
      this.lockChildDialog = false;
    },
    closeSubmissionForm() {
      this.dialogFormIndex = 0;
      this.showDialogMessage = true;
    },
    deleteChildForm() {
      this.dialogFormIndex = 1;
      this.showDialogMessage = true;
    },
    cancelAction() {
      this.showDialogMessage = false;
      switch (this.dialogFormIndex) {
        case 0:
          this.$refs.closeFormOption.checked = false;
          break;
        case 3:
          this.$emit("redirect", null);
      }
    },

    alertValidation(msg) {
      this.$emit("alertError", msg);
    },
    previewPDF() {
      window.open("/preview/" + this.tailboardFormSubmission.id);
      // let routeData = this.$router.resolve({
      //   path:
      // });
      // this.$router.push(
      //   `/custom/${this.tailboardFormSubmission.id}/${this.tailboardFormSubmission.state}`
      // );
    },
    okAction() {
      this.loading = true;
      switch (this.dialogFormIndex) {
        case 0: {
          this.closeFormAsync();
          break;
        }
        case 1:
          this.deleteTailboardSubmissionForm();
          break;
        case 3: {
          this.$emit("change", true);
        }
      }
      this.showDialogMessage = false;
      this.loading = false;
    },
    onEmitSignature(event, callback) {
      this.$emit("signature", event, callback);
    }
  }
};
</script>
<style scoped>
.progress-holder {
  width: 100%;
  position: relative;
}

.progress-holder-div {
  position: absolute;
  left: 40%;
  z-index: 999;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--v-background-base);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 4px;
  bottom: 4px;
  background-color: var(--v-primary-base);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--v-background-base);
}

input:disabled + .slider:before {
  background-color: var(--v-secondary-base);
}

input:checked + .slider:before {
  -webkit-transform: translateX(30px);
  -ms-transform: translateX(30px);
  transform: translateX(30px);
}

.slider.round {
  border-radius: 15px;
}

.slider.round:before {
  border-radius: 50%;
}

.form-actions {
  padding-top: 5px;
  float: right !important;
}

.v-btn-disable {
  pointer-events: none;
}

.v-btn {
  background-color: var(--v-primary-base) !important;
  color: white !important;
  height: 32px !important;
  font-size: 12px !important;
}

.btn-width {
  margin: 0 !important;
  width: 61px;
}
</style>
