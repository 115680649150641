import dataAccess from "@/js/data-access.js";
import utils from "@/shared/Utils";
import dayjs from "@/plugins/dayjs";

export default {
  getOfflineSubmissionDTO(submissionForm) {
    let submissionData = {
      queueType: 0,
      isLocked: submissionForm.isLocked,
      isLockedById: submissionForm.isLockedBy
        ? submissionForm.isLockedBy.id
        : null,
      cableChamberFeeders: submissionForm.cableChamberFeeders,
      confinedSpaceSubstanceTests: submissionForm.confinedSpaceSubstanceTests,
      crewLeaderId: submissionForm.crewLeaderId,
      crewLeaderPhone: submissionForm.crewLeaderPhone,
      crewMembers: submissionForm.crewMembers.map(c => {
        return {
          id: c.id,
          employeeId: c.employee ? c.employee.id : null,
          trailerId: c.trailer ? c.trailer.id : null,
          vehicleId: c.vehicle ? c.vehicle.id : null
        };
      }),
      criticalTasksAssessment: submissionForm.criticalTasksAssessment,
      employeeEntryLogs: submissionForm.employeeEntryLogs,
      formId: submissionForm.formId,
      id: submissionForm.id,
      inapplicableComponents: submissionForm.inapplicableComponents,
      inapplicableSections: submissionForm.inapplicableSections,
      inputFieldData: submissionForm.inputFieldData,
      mode: submissionForm.mode,
      hasCriticalTasks: submissionForm.hasCriticalTasks,
      parentTailboardFormSubmissionId: submissionForm.parentTailboardFormSubmission
        ? submissionForm.parentTailboardFormSubmission.id
        : null,
      riskAssessment: submissionForm.riskAssessment
        ? {
            hazardIds: submissionForm.riskAssessment.hazardIds,
            id: submissionForm.riskAssessment.id,
            jobSteps: submissionForm.riskAssessment.jobSteps,
            workTypeId: submissionForm.riskAssessment.workType
              ? submissionForm.riskAssessment.workType.id
              : null
          }
        : null,
      state: submissionForm.state,
      supervisorId: submissionForm.supervisorId,
      supervisorPhone: submissionForm.supervisorPhone,
      tailboardFormHeader: submissionForm.tailboardFormHeader,
      userId: submissionForm.user.id,
      visitors: submissionForm.visitors,
      employeeVisitors: submissionForm.employeeVisitors,
      visitorsSectionId: submissionForm.visitorsSectionId,
      employeeVisitorsSectionId: submissionForm.employeeVisitorsSectionId,
      signatures: submissionForm.signatures.map(s => {
        return {
          employeeId: s.employee ? s.employee.id : null,
          file: s.file,
          id: s.id,
          mode: s.mode,
          name: s.name,
          phase: s.phase,
          signedAt: s.signedAt
        };
      }),
      tailboardFormSubmissionReview: submissionForm.tailboardFormSubmissionReview
        ? {
            comment: submissionForm.tailboardFormSubmissionReview.comment,
            tailboardFormSubmissionId: submissionForm.id,
            insertedAt: submissionForm.tailboardFormSubmissionReview.insertedAt,
            updatedAt: submissionForm.tailboardFormSubmissionReview.updatedAt,
            id: submissionForm.tailboardFormSubmissionReview.id,
            userId: submissionForm.tailboardFormSubmissionReview.user.id
          }
        : null,
      endDayReviewedAt: submissionForm.endDayReviewedAt,
      endDayReviewed: submissionForm.endDayReviewed,
      endDayReviewedBy: submissionForm.endDayReviewedBy,
      endDayReviewedByName: submissionForm.endDayReviewedByName,
      midDayReviewed: submissionForm.midDayReviewed,
      midDayReviewedAt: submissionForm.midDayReviewedAt,
      midDayReviewedBy: submissionForm.midDayReviewedBy,
      midDayReviewedByName: submissionForm.midDayReviewedByName,
      incidentReported: submissionForm.incidentReported,
      incidentReportedAt: submissionForm.incidentReportedAt,
      incidentReportedById: submissionForm.incidentReportedBy
        ? submissionForm.incidentReportedBy.id
        : null
    };

    if (submissionData.riskAssessment) {
      submissionData.riskAssessment.jobSteps.forEach(job => {
        if (!job.name) job.name = `Job Step ${job.step}`;
      });
      delete submissionData.riskAssessment.__typename;
      submissionData.riskAssessment.jobSteps.forEach(e => {
        delete e.__typename;
        e.jobStepHazards.forEach(h => {
          delete h.__typename;
        });
      });
    }
    delete submissionData.tailboardFormHeader.__typename;
    if (submissionData.criticalTasksAssessment)
      delete submissionData.criticalTasksAssessment.__typename;
    submissionData.employeeEntryLogs.forEach(e => {
      delete e.__typename;
    });
    submissionData.confinedSpaceSubstanceTests.forEach(test => {
      delete test.__typename;
    });
    submissionData.cableChamberFeeders.forEach(cable => {
      delete cable.__typename;
    });
    submissionData.inputFieldData.forEach(data => {
      delete data.__typename;
    });

    return submissionData;
  },

  generateFormSubmission(data = {}) {
    const uuid = utils.getUUID();
    const { submissionId, user, insertedAt } = data;
    const isCrewLeader = !!(
      !submissionId &&
      user?.employee?.title?.toLowerCase()?.includes("crew leader")
    );

    let submission = {
      id: uuid,
      formId: data.formId,
      isLocked: false,
      isLockedBy: null,
      updatedAt: insertedAt,
      updatedBy: user ? user.id : null,
      supervisorId: data.supervisorId,
      supervisorPhone: data.supervisorPhone,
      crewLeaderId: isCrewLeader ? "CURRENT_USER" : null,
      crewLeaderPhone: isCrewLeader ? user.employee.phoneNumber : null,
      endDayReviewed: false,
      endDayReviewedAt: null,
      endDayReviewedByName: null,
      midDayReviewed: false,
      midDayReviewedAt: null,
      midDayReviewedByName: null,
      hasCriticalTasks: false,
      incidentReported: false,
      incidentReportedAt: null,
      incidentReportedBy: null,
      employeeEntryLogs: [],
      formVersion: 1616000545,
      inapplicableSections: [],
      inapplicableComponents: [],
      visitors: [],
      employeeVisitors: [],
      crewMembers: [],
      inputFieldData: [],
      signatures: [],
      insertedAt: insertedAt,
      mode: "OPEN",
      pdfFile: null,
      state: "IN_PROGRESS",
      offline: true,
      user,
      riskAssessment: data.hasRiskAssessment
        ? {
            id: utils.getUUID(),
            tailboardFormSubmissionId: uuid,
            workType: null,
            hazardIds: [],
            jobSteps: [
              {
                id: utils.getUUID(),
                name: "Job Step 1",
                step: 1,
                employeeIds: [],
                jobStepHazards: [
                  {
                    id: utils.getUUID(),
                    source: null,
                    position: 1,
                    barriers: [],
                    hazardId: null
                  }
                ]
              }
            ]
          }
        : null,
      confinedSpaceSubstanceTests: [],
      cableChamberFeeders: [],
      criticalTasksAssessment: {
        criticalTasksIds: [],
        id: utils.getUUID()
      },
      childTailboardFormSubmissions: [],
      tailboardFormHeader: {
        esrr: null,
        date: data.dateTime,
        dept: !submissionId && user ? user.employee.departmentName : null,
        id: utils.getUUID(),
        location: null,
        projectName: null,
        rcNumber: !submissionId && user ? user.employee.rcNumber : null,
        wbs: null,
        workDescription: null,
        workOrderNumber: null,
        fieldActivityId: null
      },
      tailboardForm: data.tailboardForm,
      parentTailboardFormSubmission: data.parent,
      tailboardFormSubmissionReview: null
    };

    if (user)
      submission.crewMembers.push({
        employee: data.user.employee,
        trailer: null,
        vehicle: null
      });
    return submission;
  },

  async updateChildTailboardFormSubmission(childData) {
    let parentData = await dataAccess.getEntityByIdAsync(
      "tailboardFormSubmissions",
      childData.parentTailboardFormSubmission.id
    );
    if (parentData.result) {
      const index = parentData.result.childTailboardFormSubmissions.findIndex(
        c => c.id === childData.id
      );
      if (index !== -1) {
        parentData.result.childTailboardFormSubmissions[index].state =
          childData.state;
        parentData.result.childTailboardFormSubmissions[index].user =
          childData.user;
        // handle parent submitted state
        // const signIndex = parentData.result.signatures.findIndex(
        //   s => s.phase === "SUBMISSION"
        // );

        // if (signIndex !== -1) {
        //   parentData.result.signatures.splice(signIndex, 1);s
        //   if (parentData.result.state === "SUBMITTED")
        //     parentData.result.state = "SIGNED_OFF";
        // }
        return await this.saveTailboardFromSubmissionAsync(parentData.result);
        // return await dataAccess.saveEntityByIdAsync(
        //   parentData.result,
        //   "tailboardFormSubmissions"
        // );
      }
    }
    return false;
  },

  async createChildTailboardFormSubmission(data) {
    let childData = this.generateFormSubmission(data);
    let parentData = await dataAccess.getEntityByIdAsync(
      "tailboardFormSubmissions",
      childData.parentTailboardFormSubmission.id
    );

    if (parentData.result) {
      // const index = parentData.result.signatures.findIndex(
      //   s => s.phase === "SUBMISSION"
      // );
      // if (index !== -1) parentData.result.signatures.splice(index, 1);
      childData.crewLeaderId = parentData.result.crewLeaderId;
      childData.crewLeaderPhone = parentData.result.crewLeaderPhone;
      childData.tailboardFormHeader = parentData.result.tailboardFormHeader;
      parentData.result.childTailboardFormSubmissions.push({
        formId: childData.formId,
        id: childData.id,
        insertedAt: childData.insertedAt,
        mode: "OPEN",
        state: "IN_PROGRESS",
        tailboardForm: childData.tailboardForm,
        user: childData.user
      });
      let isSaved = await this.saveTailboardFromSubmissionAsync(childData);
      if (isSaved)
        isSaved = await this.saveTailboardFromSubmissionAsync(
          parentData.result
        );
      return isSaved ? { formId: childData.formId, id: childData.id } : null;
    }
    return null;
  },

  async changeSubmissionOwnership(ownerId, id) {
    let submissionData = await dataAccess.getEntityByIdAsync(
      "tailboardFormSubmissions",
      id
    );
    if (submissionData.result) {
      let user = await dataAccess.getEntityByIndexAsync(
        "users",
        "employeeId",
        ownerId
      );
      if (user.result) {
        submissionData.result.user = user.result;
        submissionData.result.crewLeaderId = null;
        submissionData.result.crewLeaderPhone = null;
        if (submissionData.result.tailboardForm.type === "SIMPLE") {
          await this.updateChildTailboardFormSubmission(submissionData.result);
        }
        return await this.saveTailboardFromSubmissionAsync(
          submissionData.result
        );
      } else console.error("user not exist");
    }
    return false;
  },

  async getTailboardFormByIdAsync(id) {
    let response = await dataAccess.getEntityByIdAsync("tailboardForms", id);
    return response.result;
  },

  async copyTailboardFormSubmissionAsync(submissionId, insertDate, user) {
    let newTailbordSubmission = await dataAccess.getEntityByIdAsync(
      "tailboardFormSubmissions",
      submissionId
    );
    if (newTailbordSubmission.result) {
      let clearList = [];
      const tailboardForm = await dataAccess.getEntityByIdAsync(
        "tailboardForms",
        newTailbordSubmission.result.formId
      );
      tailboardForm.result.sections.forEach(s => {
        s.components.forEach(c => {
          c.inputFields.forEach(f => {
            if (f.inputType === "AUDIO" || f.inputType === "FILE") {
              clearList.push(f.id);
            }
          });
        });
      });
      newTailbordSubmission.result.inputFieldData = newTailbordSubmission.result.inputFieldData.map(
        field => {
          return {
            id: utils.getUUID(),
            inputFieldId: field.inputFieldId,
            value:
              clearList.length > 0 && clearList.includes(field.inputFieldId)
                ? null
                : field.value
          };
        }
      );

      const tailboardId = utils.getUUID();
      newTailbordSubmission.result.id = tailboardId;
      newTailbordSubmission.result.insertedAt = dayjs
        .utc()
        .format("YYYY-MM-DD, HH:mm");
      newTailbordSubmission.result.offline = true;
      newTailbordSubmission.result.tailboardFormHeader.esrr = null;
      newTailbordSubmission.result.tailboardFormHeader.location = "";
      newTailbordSubmission.result.tailboardFormHeader.date = dayjs
        .utc()
        .format("YYYY-MM-DD");
      newTailbordSubmission.result.tailboardFormHeader.id = utils.getUUID();
      newTailbordSubmission.result.criticalTasksAssessment.criticalTasksIds = [];
      newTailbordSubmission.result.criticalTasksAssessment.id = utils.getUUID();
      newTailbordSubmission.result.state = "IN_PROGRESS";
      newTailbordSubmission.result.mode = "OPEN";
      newTailbordSubmission.result.signatures = [];
      newTailbordSubmission.result.tailboardFormSubmissionReview = null;
      newTailbordSubmission.result.crewMembers.forEach(
        c => (c.id = utils.getUUID())
      );
      newTailbordSubmission.result.childTailboardFormSubmissions = [];
      newTailbordSubmission.result.user = user;
      newTailbordSubmission.result.riskAssessment.id = utils.getUUID();
      newTailbordSubmission.result.riskAssessment.tailboardFormSubmissionId = tailboardId;
      newTailbordSubmission.result.riskAssessment.jobSteps.forEach(j => {
        j.id = utils.getUUID();
        j.jobStepHazards.forEach(h => (h.id = utils.getUUID()));
      });
      newTailbordSubmission.result.endDayReviewed = false;
      newTailbordSubmission.result.endDayReviewedAt = null;
      newTailbordSubmission.result.endDayReviewedByName = null;
      newTailbordSubmission.result.midDayReviewed = false;
      newTailbordSubmission.result.midDayReviewedAt = null;
      newTailbordSubmission.result.midDayReviewedByName = null;
      newTailbordSubmission.result.esrr = null;
      const saved = await dataAccess.saveEntityByIdAsync(
        newTailbordSubmission.result,
        "tailboardFormSubmissions"
      );
      return saved
        ? {
            formId: newTailbordSubmission.result.formId,
            submissionId: tailboardId
          }
        : null;
    }
    return null;
  },

  async getTailboardFormSubmissionAsync(submissionId, state) {
    let response = await dataAccess.getEntityByIdAsync(
      state === "ARCHIVED"
        ? "archivedTailboardFormSubmissions"
        : "tailboardFormSubmissions",
      submissionId
    );
    return response.result;
  },

  async addIncidentReport(incidentObject) {
    let tailboardSubmission = await dataAccess.getEntityByIdAsync(
      "tailboardFormSubmissions",
      incidentObject.id
    );
    if (tailboardSubmission.result) {
      tailboardSubmission.result.incidentReported =
        incidentObject.incidentReported;
      tailboardSubmission.result.incidentReportedAt =
        incidentObject.incidentReportedAt;
      tailboardSubmission.result.incidentReportedBy =
        incidentObject.incidentReportedBy;
      return await this.saveSubmissionAsync(tailboardSubmission.result);
    } else return false;
  },

  async saveSubmissionAsync(tailboardFormSubmission) {
    if (tailboardFormSubmission.tailboardForm.type === "SIMPLE") {
      await this.updateChildTailboardFormSubmission(tailboardFormSubmission);
    }
    return await this.saveTailboardFromSubmissionAsync(tailboardFormSubmission);
  },

  async saveTailboardFromSubmissionAsync(tailboardFormSubmission) {
    let isSaved = await this.queueTailboardFormSubmissionAsync(
      tailboardFormSubmission
    );
    if (isSaved)
      isSaved = await dataAccess.saveEntityByIdAsync(
        tailboardFormSubmission,
        "tailboardFormSubmissions"
      );
    return isSaved;
  },

  async lockFormAction(id, lockedObj) {
    let request = await dataAccess.getEntityByIdAsync(
      "tailboardFormSubmissions",
      id
    );

    if (request.result) {
      let submission = request.result;
      submission.isLocked = lockedObj.isLocked;
      submission.isLockedBy = lockedObj.isLockedBy;
      return await this.saveTailboardFromSubmissionAsync(submission);
    }
  },

  async unLockTailboardFormSubmission(tailboardFormSubmission) {
    const lockedObj = {
      isLocked: false,
      isLockedBy: null
    };
    let isUnLocked = await this.lockFormAction(
      tailboardFormSubmission.id,
      lockedObj
    );

    if (isUnLocked) {
      for (let {
        id
      } of tailboardFormSubmission.childTailboardFormSubmissions) {
        //if (user.id === tailboardFormSubmission.user.id) {
        const isChildUnLocked = await this.lockFormAction(id, lockedObj);
        isUnLocked = isUnLocked && isChildUnLocked;
        //}
      }
      return isUnLocked;
    }
  },

  async getSubmissionFilesList(tailboardFormSubmissionId) {
    let request = await dataAccess.getEntityByIdAsync(
      "tailboardFormSubmissions",
      tailboardFormSubmissionId
    );

    if (request.result) {
      let tailboardFormSubmission = request.result;
      let tailboardFormRequest = await dataAccess.getEntityByIdAsync(
        "tailboardForms",
        tailboardFormSubmission.tailboardForm.id
      );

      if (tailboardFormRequest) {
        let tailboardForm = tailboardFormRequest.result;
        let fetchList = [];
        let getInputFieldFiles = (inputField, isJSON) => {
          const inputItem = tailboardFormSubmission.inputFieldData.filter(
            i => i.inputFieldId === inputField.id
          );
          inputItem.forEach(input => {
            if (input.value && input.value !== "N/A") {
              const files = isJSON
                ? JSON.parse(input.value)
                : input.value.split(",");
              files.forEach(f => {
                fetchList.push({
                  file: `${f.blobUrl || f}`
                });
              });
            }
          });
        };
        tailboardFormSubmission.signatures.forEach(s =>
          fetchList.push({
            file: s.file
          })
        );
        const jsonTypes = ["AUDIO", "FILE"];
        const uuidTypes = ["DRAWING", "SIGNATURE", "TRAFFIC_PLAN"];
        const types = jsonTypes.concat(uuidTypes);
        tailboardForm.sections.forEach(s => {
          s.components.forEach(c => {
            c.inputFields.forEach(f => {
              if (types.includes(f.inputType)) {
                getInputFieldFiles(f, jsonTypes.includes(f.inputType));
              }
            });
          });
        });
        return fetchList;
      }
    }
  },

  async queueTailboardFormSubmissionAsync(tailboardFormSubmission) {
    return await dataAccess.saveEntityAsync(
      tailboardFormSubmission.id,
      this.getOfflineSubmissionDTO(tailboardFormSubmission),
      "tailboardsQueue"
    );
  },

  async deleteSubmissionAsync(formId) {
    let isSuccessed = false;
    let formData = await dataAccess.getEntityByIdAsync(
      "tailboardFormSubmissions",
      formId
    );
    if (formData.result) {
      if (formData.result.tailboardForm.type === "SIMPLE") {
        const parentId = formData.result.parentTailboardFormSubmission.id;
        let parentData = await dataAccess.getEntityByIdAsync(
          "tailboardFormSubmissions",
          parentId
        );
        if (parentData.result) {
          const currentChild = parentData.result.childTailboardFormSubmissions.findIndex(
            c => c.id === formId
          );
          if (currentChild !== -1) {
            parentData.result.childTailboardFormSubmissions.splice(
              currentChild,
              1
            );
            await this.saveTailboardFromSubmissionAsync(parentData.result);
          }
        }
      } else {
        formData.result.childTailboardFormSubmissions.forEach(async child => {
          await dataAccess.deleteEntityById(
            child.id,
            "tailboardFormSubmissions"
          );
        });
      }

      const isDeleted = await dataAccess.deleteEntityById(
        formId,
        "tailboardFormSubmissions"
      );
      if (isDeleted) {
        isSuccessed = await dataAccess.saveEntityAsync(
          formId,
          {
            queueType: 1,
            ids: [formId]
          },
          "tailboardsQueue"
        );
      }
    }
    return isSuccessed;
  },

  async getTailboardFormSubmissionsAsync(archived) {
    let entityObject = await dataAccess.getEntitiesAsync(
      archived ? "archivedTailboardFormSubmissions" : "tailboardFormSubmissions"
    );
    return Object.keys(entityObject).length === 0
      ? []
      : entityObject
          .filter(f => f.tailboardForm.type === "TAILBOARD")
          .sort((a, b) => new Date(b.insertedAt) - new Date(a.insertedAt));
  },

  async getOfflineTailbaordSubmissionAsync() {
    return await dataAccess.getEntitiesAsync("tailboardFormSubmissions");
  }
};
