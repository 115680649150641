<template>
  <div class="preview-input-traffic-plan">
    <div class="background-wrapper" :style="backgroundStyle">
      <img v-if="trafficSource" :src="trafficSource" />
      <p v-else>No traffic plan was designed.</p>
    </div>
  </div>
</template>

<script>
import getEnv from "@/utilities/env.js";
import dataAccess from "@/js/data-access.js";
import utils from "@/shared/Utils.js";
import {
  DEFAULT_TRAFFIC_PLAN_BACKGROUND_NAME,
  DEFAULT_TRAFFIC_PLAN_BACKGROUND_UUID
} from "@/shared/consts.js";

export default {
  name: "PreviewInputTrafficPlan",
  props: {
    value: String
  },
  data() {
    return {
      backgroundAssetSources: [],
      trafficSource: null,
      backgroundSource: null
    };
  },
  async created() {
    if (!this.value) return;

    const [backgroundUUID, bitmapUUID] = this.value.split(",");

    this.backgroundSource = await this.getBackgroundAssetSource(backgroundUUID);

    // NOTE: same implementation as traffic-plan.vue:630
    this.trafficSource = await utils.mapSubmissionFileURLToCache(
      `${getEnv("VUE_APP_SERVER_URL")}/doc/${bitmapUUID}`
    );
  },
  computed: {
    backgroundStyle() {
      if (!this.backgroundSource) return {};

      return { "background-image": `url(${this.backgroundSource})` };
    }
  },
  methods: {
    async getBackgroundAssetSource(backgroundUUID) {
      let backgroundAssetSources = [];

      // Add default (blank) background asset
      backgroundAssetSources.push({
        name: DEFAULT_TRAFFIC_PLAN_BACKGROUND_NAME,
        id: DEFAULT_TRAFFIC_PLAN_BACKGROUND_UUID,
        source: await utils.mapSubmissionFileURLToCache(
          `${getEnv(
            "VUE_APP_SERVER_URL"
          )}/doc/${DEFAULT_TRAFFIC_PLAN_BACKGROUND_UUID}`
        ),
        loading: false
      });

      const trafficAssets = await dataAccess.getEntityIndexAsync(
        "trafficAssets",
        "type",
        "BACKGROUND"
      );

      let selectedSource;

      for (let { name, file } of trafficAssets) {
        const source = await utils.mapSubmissionFileURLToCache(
          `${getEnv("VUE_APP_SERVER_URL")}/doc/${file}`
        );

        const backgroundAssetSource = {
          name,
          source,
          id: file,
          loading: true
        };

        if (file === backgroundUUID) selectedSource = backgroundAssetSource;

        backgroundAssetSources.push(backgroundAssetSource);
      }

      if (!selectedSource) return;

      return selectedSource.source;
    }
  }
};
</script>

<style scoped>
.preview-input-traffic-plan {
  column-span: all;
}

.background-wrapper {
  background-size: contain;
  background-position: center;
  display: table;
  margin: 0 auto;
}
</style>
