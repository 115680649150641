<template>
  <preview-form-component
    class="preview-component-confined-space-substance-test"
  >
    <preview-form-title :level="3"
      >Confined Space Substance Test</preview-form-title
    >

    <preview-form-group>
      <table class="printable-table">
        <thead>
          <tr>
            <th width="10%">Sample Location</th>
            <th width="15%">O2</th>
            <th width="18%">LEL/COMBEX</th>
            <th width="15%">CO</th>
            <th width="10%">H2S</th>
            <th>Other</th>
          </tr>
          <tr class="fine-print-table">
            <td></td>
            <td><i>min 19.5% max 23%</i></td>
            <td>
              <i>
                (max 5% - hot work)<br />
                (max 10% - cold work)<br />
                (max 25% - inspection)
              </i>
            </td>
            <td>
              <i>
                max 25 ppm<br />
                TWA* or 77 ppm
              </i>
            </td>
            <td><i>max 10 ppm</i></td>
            <td><i>(Ammonia, benzene ect.)</i></td>
          </tr>
        </thead>
        <tbody
          v-if="
            submission.confinedSpaceSubstanceTests &&
              submission.confinedSpaceSubstanceTests.length > 0
          "
        >
          <template
            v-for="(substance, index) in submission.confinedSpaceSubstanceTests"
          >
            <tr :key="substance.id + '-test'">
              <td colspan="6" class="test-number">
                TEST {{ index + 1 }}&nbsp;&mdash;&nbsp;({{
                  formatTime(substance.time)
                }})
              </td>
            </tr>
            <tr :key="substance.id + '-top'">
              <td>TOP</td>
              <td>{{ substance.topO2 }}</td>
              <td>{{ substance.topLel }}</td>
              <td>{{ substance.topCo }}></td>
              <td>{{ substance.topH2s }}</td>
              <td>{{ substance.topOther }}</td>
            </tr>
            <tr :key="substance.id + '-middle'">
              <td>MIDDLE</td>
              <td>{{ substance.midO2 }}</td>
              <td>{{ substance.midLel }}</td>
              <td>{{ substance.midCo }}></td>
              <td>{{ substance.midH2s }}</td>
              <td>{{ substance.midOther }}</td>
            </tr>
            <tr :key="substance.id + '-bottom'">
              <td>BOTTTOM</td>
              <td>{{ substance.bottomO2 }}</td>
              <td>{{ substance.bottomLel }}</td>
              <td>{{ substance.bottomCo }}></td>
              <td>{{ substance.bottomH2s }}</td>
              <td>{{ substance.bottomOther }}</td>
            </tr>
          </template>
        </tbody>
      </table>
    </preview-form-group>
  </preview-form-component>
</template>

<script>
import dayjs from "@/plugins/dayjs";
import PreviewFormComponent from "./preview-form-component.vue";
import PreviewFormGroup from "./preview-form-group.vue";
import PreviewFormTitle from "./preview-form-title.vue";

export default {
  name: "PreviewComponentConfinedSpaceSubstanceTest",
  props: {
    submission: Object
  },
  components: {
    PreviewFormComponent,
    PreviewFormGroup,
    PreviewFormTitle
  },
  methods: {
    formatTime(time) {
      return dayjs(time).format("YYYY-MM-DD HH:mma");
    }
  }
};
</script>

<style scoped>
.printable-table {
  column-span: all;
  border-collapse: separate;
  border-spacing: 2px;
  border: 1px solid var(--v-primary-base);
  border-radius: 4px;
  margin: 0 auto;
  width: calc(100% - 16px);
}

.printable-table tr {
  background-color: var(--v-whiteBackground-base);
}

.printable-table tr td {
  background-color: var(--v-whiteBackground-base);
  padding: 4px;
}

.printable-table thead tr th {
  color: var(--v-whiteBackground-base);
  background-color: var(--v-primary-base);
  padding: 0.24em;
}

.printable-table tr.fine-print-table td {
  font-size: 0.8em;
  background-color: var(--v-primary-lighten4);
}

.test-number {
  font-weight: 700;
}
</style>
