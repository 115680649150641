<template>
  <v-dialog
    v-model="searchCrewDialog"
    persistent
    max-width="512px"
    max-height="538px"
  >
    <v-card>
      <v-card-title>
        <v-row class="flex-column ma-0">
          <v-col cols="12" class="pa-0">
            <span class="headline">{{ title }}</span>
          </v-col>
          <v-col class="pa-0 d-flex align-end justify-end">
            <span class="caption">{{ caption }}</span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-container>
          <div class="div-crew-search">
            <v-row class="d-flex pl-2">
              <div
                class="div-selected-crew"
                v-for="(crew, index) in selected"
                :key="index"
              >
                {{ `${crew.employee.firstName} ${crew.employee.lastName}` }}
                <v-btn icon @click="removeCrewMember(crew)"
                  ><v-icon>cancel</v-icon></v-btn
                >
              </div>
            </v-row>
            <v-row class="pa-2">
              <v-text-field
                outlined
                hide-details
                label="Search or type to add new name"
                prepend-inner-icon="search"
                v-model="search"
                clearable
                @click:clear="clearSearch"
              >
                <template v-slot:append>
                  <v-fade-transition leave-absolute>
                    <v-progress-circular
                      v-if="isLoading"
                      size="24"
                      color="primary"
                      indeterminate
                    >
                    </v-progress-circular>
                  </v-fade-transition>
                </template>
              </v-text-field>
            </v-row>
          </div>
          <v-list two-line>
            <template v-for="(item, index) in filteredItems">
              <v-list-item
                :key="item.id + index + 1"
                ripple
                @click="selectedCrewMember(item)"
              >
                <v-list-item-content>
                  <v-list-item-title>{{
                    `${item.firstName} ${item.lastName}`
                  }}</v-list-item-title>
                  <v-list-item-subtitle class="gray--text">{{
                    item.title
                  }}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action class="cursor-pointer">
                  <v-icon v-if="isSelected(item)" color="primary">
                    add_circle_outline
                  </v-icon>
                  <v-icon v-else color="red"> remove_circle_outline </v-icon>
                </v-list-item-action>
              </v-list-item>
              <v-divider
                v-if="index + 1 < filteredItems.length"
                :key="index"
              ></v-divider>
            </template>
          </v-list>
        </v-container>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="cancelAction"> Cancel </v-btn>
        <v-btn
          class="primary"
          :loading="loading"
          :disabled="loading"
          @click="okAction"
        >
          Done
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "EmployeesSearch",
  props: {
    multiple: Boolean,
    title: String,
    caption: String,
    loading: Boolean,
    membersList: Array,
    searchCrewDialogShow: Boolean,
    cancelAction: { type: Function }
  },
  data() {
    return {
      search: null,
      selected: [],
      selectedEmployee: null
    };
  },
  watch: {
    membersList: {
      immediate: true,
      deep: true,
      handler: function(items) {
        this.selected = items;
      }
    }
  },

  computed: {
    employees() {
      return this.$store.getters.getEmployees.map(e => {
        return {
          id: e.id,
          crewRef: e.crewRef,
          crewName: e.crewName,
          departmentName: e.departmentName,
          email: e.email,
          firstName: e.firstName,
          lastName: e.lastName,
          groupName: e.groupName,
          phoneNumber: e.phoneNumber,
          title: e.title,
          employeeRef: e.employeeRef,
          searchText: `${e.departmentName} ${e.firstName} ${e.lastName} ${e.groupName} ${e.title}`.toUpperCase()
        };
      });
    },
    isSelected() {
      return item => {
        return (
          this.selected.findIndex(
            n => n.employee && n.employee.employeeRef === item.employeeRef
          ) < 0
        );
      };
    },
    searchCrewDialog() {
      return this.searchCrewDialogShow;
    },
    isLoading() {
      return false;
    },
    filteredItems() {
      const searchEmployee = this.search ? this.search.toUpperCase() : "";
      return searchEmployee === ""
        ? this.employees.filter(e => e.crewRef === this.crewRef)
        : this.employees
            .filter(e => e.searchText.includes(searchEmployee))
            .slice(0, 100);
    },
    crewRef() {
      return this.user && this.user.employee
        ? this.user.employee.crewRef
        : null;
    },
    ...mapState(["user"])
  },
  methods: {
    clearSearch() {
      this.search = null;
    },
    selectedCrewMember(crew) {
      if (crew.employeeRef != this.user.employee.employeeRef) {
        const i = this.selected.findIndex(
          n => n.employee && n.employee.employeeRef === crew.employeeRef
        );
        if (i > -1) {
          this.selected.forEach(selectedItem => {
            if (selectedItem.employee.id === crew.id) {
              this.removeCrewMember(selectedItem);
            }
          });
        } else {
          if (!this.multiple) this.selected.splice(0);

          this.selected.push({
            employee: crew,
            trailer: { value: null, text: null },
            vehicle: { value: null, text: null }
          });
        }
        this.$emit("crew-member-clicked", crew);
        this.search = null;
      }
    },
    okAction() {
      this.$emit("done", this.selected);
    },
    removeCrewMember(crew) {
      if (crew.employee.employeeRef != this.user.employee.employeeRef) {
        const i = this.selected.findIndex(
          n =>
            n.employee && n.employee.employeeRef === crew.employee.employeeRef
        );
        if (i > -1) {
          this.$emit("delete", crew);
          this.selected.splice(i, 1);
        }
      }
    }
  }
};
</script>
<style scoped>
.v-list {
  height: 200px; /* or any height you want */
  overflow-y: auto;
}

.div-selected-crew {
  margin-top: 7px;
  padding-left: 5px;
  border-radius: 5px;
  height: 35px;
  margin-right: 5px;
  background-color: rgba(0, 93, 85, 0.1);
  color: #005d55;
}
.div-crew-search {
  border-radius: 5px;
  padding: 15px;
  border: 0.5px solid #005d55;
}
.div-signature {
  padding: 10px 10px 10px 16px;
  border-radius: 5px;
  background-color: #d2d2d2;
  color: rgba(50, 51, 53, 0.5);
  width: 180px;
  height: 40px;
}
</style>
