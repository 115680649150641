<template>
  <div class="form-details">
    <div>
      <label>Date Initiated</label>
      <p>{{ header.date }}</p>
    </div>
    <div>
      <label>Work Order #</label>
      <p>{{ header.workOrderNumber }}</p>
    </div>
    <div>
      <label>WBS # </label>
      <p>{{ header.wbs }}</p>
    </div>
    <div>
      <label>Field Activity ID</label>
      <p>{{ header.fieldActivityId }}</p>
    </div>
    <div>
      <label>Department </label>
      <p>{{ header.dept }}</p>
    </div>
    <div>
      <label>Cost Center #</label>
      <p>{{ header.rcNumber }}</p>
    </div>
    <div>
      <label>Project Name </label>
      <p>
        {{ header.projectName }}
      </p>
    </div>
    <div
      class="d-flex"
      v-if="
        tailboardFormSubmission.isLocked && tailboardFormSubmission.isLockedBy
      "
    >
      <v-icon class="mr-1" color="primary">$vuetify.icons.lock</v-icon>
      <div>
        <label>Locked By</label>
        <p>
          {{ tailboardFormSubmission.isLockedBy.name || "" }}
        </p>
      </div>
    </div>
    <div>
      <label>Work Site Location </label>
      <p>{{ header.location }}</p>
    </div>
    <preview-slidebar
      :submission="tailboardFormSubmission"
      :standAlone="false"
    />
  </div>
</template>
<script>
import previewSlidebar from "@/components/preview-slidebar.vue";
export default {
  name: "JobInformation",
  props: {
    tailboardFormSubmission: Object
  },
  components: {
    previewSlidebar
  },
  computed: {
    header() {
      return this.tailboardFormSubmission.tailboardFormHeader || {};
    }
  }
};
</script>

<style scoped>
.form-details {
  width: 100%;
}

.form-details div {
  float: left;
  margin-right: 15px;
  min-height: 50px;
  height: auto;
}

.form-details p {
  max-width: 270px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
label {
  font-size: 12px;
  font-weight: 500 !important;
}
p {
  font-size: 14px;
}

p {
  padding: 0 !important;
  margin: 0 !important;
}
</style>
