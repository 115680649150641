<template>
  <v-form v-model="isValid">
    <v-row
      v-for="(entry, index) in displayEntries"
      :key="`entry-row_${index}`"
      no-gutters
    >
      <v-col class="flex-grow-1 flex-shrink-0">
        <v-row>
          <v-col cols="4" class="py-0">
            <label :for="`entry-employee_${index}`">Employee</label>
            <employee-autocomplete
              :id="`entry-employee_${index}`"
              :initialValue="entry.employeeId"
              :multiple="false"
              :rules="[rules.required]"
              :crewMembers="crewMembers"
              @change="entry.employeeId = $event"
            />
          </v-col>
          <v-col cols="4" class="py-0">
            <label :for="`entry-timein_${index}`">Time In</label>
            <v-text-field
              type="time"
              outlined
              :value="dateToTimeString(entry.timeIn)"
              :rules="[rules.required]"
              @input="entry.timeIn = timeToDateTime(entry.timeIn, $event)"
              :id="`entry-timein_${index}`"
            />
          </v-col>
          <v-col cols="4" class="py-0">
            <label :for="`entry-timeout_${index}`">Time Out</label>
            <v-text-field
              type="time"
              outlined
              :value="dateToTimeString(entry.timeOut)"
              :rules="[rules.required]"
              @input="entry.timeOut = timeToDateTime(entry.timeOut, $event)"
              :id="`entry-timeout_${index}`"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col class="flex-grow-0 flex-shrink-1 pt-10">
        <div style="width: 50px !important" class="d-flex pr-2">
          <v-btn @click="removeEntryByIndex(index)" icon class="ml-2">
            <v-icon>delete_outline</v-icon>
          </v-btn>
          <v-icon color="red" v-if="entry && entry.inValid()">warning</v-icon>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn :disabled="disabled" class="primary" @click.stop="addNewEntry"
          >Add Time Entry</v-btn
        >
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import dayjs from "@/plugins/dayjs";
import employeeAutocomplete from "@/components/submission/employee-autocomplete.vue";
import utils from "@/shared/Utils";

export default {
  name: "EmployeeEntryLog",
  components: {
    employeeAutocomplete
  },
  props: {
    disabled: Boolean,
    membersList: Array,
    entries: Array,
    id: String,
    sectionId: String,
    crewMembers: Array
  },

  data() {
    return {
      isValid: false,
      rules: {
        required: value => {
          return !this.disabled ? !!value || "Required." : true;
        }
      }
    };
  },
  computed: {
    dateToTimeString() {
      return date => {
        return date ? `${dayjs(date).format("HH:mm")}` : "";
      };
    },
    displayEntries() {
      let entries = this.entries;
      entries.forEach(e => {
        e.inValid = function() {
          if (e.timeOut && e.timeIn && e.employeeId) {
            const endTime = dayjs(e.timeOut);
            const diff = endTime.diff(e.timeIn);
            return diff < 0;
          } else {
            return true;
          }
        };
      });
      return entries || [this.newEntry()];
    }
  },

  methods: {
    newEntry() {
      return {
        id: utils.getUUID(),
        employeeId: null,
        componentId: this.id,
        sectionId: this.sectionId,
        timeOut: null,
        timeIn: null,
        inValid: function() {
          if (this.timeOut && this.timeIn && this.employeeId) {
            const endTime = dayjs(this.timeOut);
            const diff = endTime.diff(this.timeIn);
            return diff < 0;
          } else {
            return true;
          }
        }
      };
    },
    // If the entries prop changes, update the internal displayEntries property with it

    removeEntryByIndex(entryIndex) {
      this.displayEntries.splice(entryIndex, 1);
    },

    timeToDateTime(entry, time) {
      const datetime = entry
        ? `${dayjs(entry).format("YYYY-MM-DD")} ${time}`
        : `${dayjs().format("YYYY-MM-DD")} ${time}`;
      return dayjs(datetime).toISOString();
    },

    addNewEntry() {
      this.displayEntries.push(this.newEntry());
    },
    getValidationResult() {
      let required = true;
      this.displayEntries.forEach(entry => {
        if (entry.timeOut && entry.timeIn && entry.employeeId) {
          const endTime = dayjs(entry.timeOut);
          const diff = endTime.diff(entry.timeIn);
          required = diff < 0;
        } else {
          required = true;
        }
      });
      return [!required && this.isValid ? 1 : 0, 1];
    }
  },
  watch: {
    crewMembers: {
      immediate: true,
      deep: true,
      handler(newValue) {
        const entriesToRemove = [];

        this.entries.forEach((entry, index) => {
          if (
            !newValue.some(
              newEntry => newEntry.employee.id === entry.employeeId
            )
          ) {
            entriesToRemove.push(index);
          }
        });

        for (let i = entriesToRemove.length - 1; i >= 0; i--) {
          this.removeEntryByIndex(entriesToRemove[i]);
        }
      }
    }
  }
};
</script>

<style scoped>
label {
  color: var(--v-primary-base) !important;
  margin-bottom: 5px !important;
}

.remove-button-column {
  max-width: 80px;
  vertical-align: top;
}
</style>
