<template>
  <div class="model-main">
    <div class="model-header">
      <h2>Options</h2>
      <form-modal
        v-if="
          display == 'index' && (user.role == 'ADMIN' || user.role == 'SAFETY')
        "
        :fields="fields"
        :title="mutationLabel"
        :mutation="mutation"
        :show="showData"
        :value="values"
        @success="closeForm"
        @close="closeForm"
      />
      <v-btn v-if="display == 'show'" @click="display = 'index'">Back</v-btn>
    </div>
    <v-card>
      <v-tabs
        v-model="currentTab"
        @change="updateMutation($event)"
        :hide-slider="display != 'index'"
      >
        <v-tab v-if="display == 'index'" ripple>Options</v-tab>
        <v-tab
          v-if="
            display == 'index' &&
              (user.role == 'ADMIN' || user.role == 'SAFETY')
          "
          ripple
          >Option Groups</v-tab
        >
        <v-tab-item>
          <custom-table
            :columns="optionHeaders"
            :data="options"
            :show="display == 'show'"
            @show="display = 'show'"
            @delete="deleteOption"
            @edit="editData"
          />
        </v-tab-item>
        <v-tab-item v-if="user.role == 'ADMIN' || user.role == 'SAFETY'">
          <custom-table
            :columns="groupHeaders"
            :data="optionGroupsData"
            :show="display == 'show'"
            @show="display = 'show'"
            @delete="deleteOptionGroup"
            @edit="editData"
          />
        </v-tab-item>
      </v-tabs>
    </v-card>
  </div>
</template>

<script>
import {
  OPTIONS_QUERY,
  OPTION_GROUPS_QUERY,
  CREATE_OPTION_MUTATION,
  UPDATE_OPTION_MUTATION,
  DELETE_OPTION_MUTATION,
  DELETE_OPTION_GROUP_MUTATION,
  CREATE_OPTION_GROUP_MUTATION,
  UPDATE_OPTION_GROUP_MUTATION,
  NEW_OPTION_SUBSCRIPTION,
  NEW_OPTION_GROUP_SUBSCRIPTION
} from "../constants/section-queries";
import formModal from "./form-modal.vue";
import customTable from "./custom-table.vue";
import appsignal from "@/plugins/appsignal";

export default {
  name: "OptionGroups",
  components: { formModal, customTable },
  data() {
    return {
      values: {},
      showData: false,
      display: "index",
      currentTab: 0,
      mutation: CREATE_OPTION_MUTATION,
      mutationLabel: "New Option",
      groupFields: [
        { label: "Name", name: "name", type: "text", required: true },
        {
          label: "Sort",
          name: "sort",
          type: "select",
          options: [
            { value: "NAME", text: "Name" },
            { value: "POSITION", text: "Position" }
          ],
          required: true
        },
        {
          label: "Description",
          name: "description",
          type: "text",
          required: true
        }
      ],
      optionFields: [
        { label: "Key", name: "key", type: "text", required: true },
        { label: "Value", name: "value", type: "text", required: true },
        { label: "Position", name: "position", type: "number", required: true }
      ],
      groupHeaders: [
        { value: "name", text: "Name", sortable: true },
        { value: "description", text: "Description", sortable: false },
        { value: "sort", text: "Sort", sortable: false },
        { value: ["edit", "delete"], text: "Actions", sortable: false }
      ],
      optionHeaders: [
        { value: "key", text: "Key", sortable: true },
        { value: "value", text: "Value", sortable: true },
        { value: "position", text: "Position", sortable: true },
        { value: ["edit", "delete"], text: "Actions", sortable: false }
      ]
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    fields() {
      return this.currentTab === 1 ? this.groupFields : this.optionFields;
    },
    optionGroupsData() {
      return this.optionGroups;
    }
  },
  methods: {
    closeForm() {
      this.mutation =
        this.currentTab === 1
          ? CREATE_OPTION_GROUP_MUTATION
          : CREATE_OPTION_MUTATION;
      this.values = {};
      this.showData = false;
    },
    editData(item) {
      this.mutation =
        this.currentTab === 1
          ? UPDATE_OPTION_GROUP_MUTATION
          : UPDATE_OPTION_MUTATION;
      this.values =
        this.currentTab === 1
          ? {
              description: item.description,
              id: item.id,
              name: item.name,
              sort: item.sort
            }
          : item;
      this.showData = true;
    },
    async delete(mutation, id, callback) {
      try {
        const result = await this.$apollo.mutate({
          mutation,
          variables: { id: id }
        });
        callback(result);
      } catch (error) {
        appsignal.sendError(error);
      }
    },
    async deleteOptionGroup(item) {
      const callback = result => {
        this.optionGroups.splice(
          this.optionGroups.findIndex(
            i => i.id === result.data.deleteOptionGroup.id
          ),
          1
        );
      };
      await this.delete(DELETE_OPTION_GROUP_MUTATION, item.id, callback);
    },

    async deleteOption(item) {
      const callback = result => {
        this.options.splice(
          this.options.findIndex(i => i.id === result.data.deleteOption.id),
          1
        );
      };
      await this.delete(DELETE_OPTION_MUTATION, item.id, callback);
    },
    updateMutation(val) {
      if (val === 1) {
        this.mutation = CREATE_OPTION_GROUP_MUTATION;
        this.mutationLabel = "New Option Group";
      } else {
        this.mutation = CREATE_OPTION_MUTATION;
        this.mutationLabel = "New Option";
      }
    }
  },
  apollo: {
    optionGroups: {
      query: OPTION_GROUPS_QUERY,
      fetchPolicy: "cache-and-network",
      update(data) {
        this.optionFields[3] = {
          label: "Option Group",
          name: "optionGroupId",
          type: "select",
          options: data.optionGroups.map(x => {
            return { value: x.id, text: x.name };
          }),
          required: true
        };
        return data.optionGroups;
      },
      subscribeToMore: [
        {
          document: NEW_OPTION_GROUP_SUBSCRIPTION,
          updateQuery: (previous, { subscriptionData }) => {
            if (!subscriptionData.data.newOptionGroup) return;
            const newOptionGroup = subscriptionData.data.newOptionGroup;

            return {
              optionGroups: [...previous.optionGroups, newOptionGroup]
            };
          }
        }
      ]
    },
    options: {
      query: OPTIONS_QUERY,
      fetchPolicy: "cache-and-network",
      subscribeToMore: [
        {
          document: NEW_OPTION_SUBSCRIPTION,
          updateQuery: (previous, { subscriptionData }) => {
            if (!subscriptionData.data.newOption) return;
            const newOption = subscriptionData.data.newOption;

            return {
              options: [...previous.options, newOption]
            };
          }
        }
      ]
    }
  }
};
</script>
