import Vue from "vue";
import Vuex from "vuex";
import serviceController from "@/js/service-controller.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    refreshView: 0,
    isBusy: false,
    notifiedId: null,
    recordingConference: { id: null, state: null },
    user: null,
    onLine: navigator.onLine,
    serverActivity: false,
    hazards: [],
    hazardCategories: [],
    barriers: [],
    workTypes: [],
    employees: [],
    criticalTasks: [],
    vehicles: [],
    isIPad: false,
    isFullscreen: false
  },
  getters: {
    getIsBusy: state => {
      return state.isBusy;
    },
    getIsIPad: state => {
      return state.isIPad;
    },
    getRecordingConference: state => {
      return state.recordingConference;
    },
    getUser: state => {
      return state.user;
    },
    getConnected: state => {
      return state.onLine;
    },
    getHazards: state => {
      return state.hazards;
    },
    getHazardCategories: state => {
      const items = state.hazardCategories;
      // Item Object has 'sort' key it's value is another key which has the order value
      if (items[0]) {
        const orderKey = items[0].sort.toLowerCase();
        return items.sort((a, b) => {
          return a[orderKey] < b[orderKey]
            ? -1
            : a[orderKey] > b[orderKey]
            ? 1
            : 0;
        });
      } else return state.hazardCategories;
    },
    getBarriers: state => {
      return state.barriers;
    },
    getWorkTypes: state => {
      const workTypes = state.workTypes || [];
      return workTypes.sort((a, b) => {
        const targetA = a.name.toLowerCase();
        const targetB = b.name.toLowerCase();
        return targetA < targetB ? -1 : targetA > targetB ? 1 : 0;
      });
    },
    getEmployees: state => {
      return state.employees;
    },
    getCriticalTasks: state => {
      return state.criticalTasks;
    },
    getVehicles: state => {
      return state.vehicles;
    },
    getServerActivity: state => {
      return state.serverActivity;
    },
    getRefreshView: state => {
      return state.refreshView;
    },
    getNotifiedId: state => {
      return state.notifiedId;
    },
    getIsFullscreen: state => {
      return state.isFullscreen;
    }
  },
  mutations: {
    setRecordingConference: (state, recordingConference) => {
      state.recordingConference.id = recordingConference.id;
      state.recordingConference.state = recordingConference.state;
    },
    setIsBusy: (state, isBusy) => {
      state.isBusy = isBusy;
    },
    setIsIPad: (state, isIPad) => {
      state.isIPad = isIPad;
    },
    setUser: (state, user) => {
      state.user = user;
    },
    setOnLine: (state, status) => {
      state.onLine = status;
    },
    setHazards: (state, hazards) => {
      state.hazards = hazards;
    },
    setHazardCategories: (state, hazardCategories) => {
      state.hazardCategories = hazardCategories;
    },
    setBarriers: (state, barriers) => {
      state.barriers = barriers;
    },
    setWorkTypes: (state, workTypes) => {
      state.workTypes = workTypes;
    },
    setEmployees: (state, employees) => {
      state.employees = employees;
    },
    setCriticalTasks: (state, criticalTasks) => {
      state.criticalTasks = criticalTasks;
    },
    setVehicles: (state, vehicles) => {
      state.vehicles = vehicles;
    },
    setServerActivity: (state, active) => {
      state.serverActivity = active;
    },
    setRefreshView: state => {
      state.refreshView += 1;
    },
    setNotifiedId: (state, notifiedId) => {
      state.notifiedId = notifiedId;
    },
    setIsFullscreen: (state, isFullscreen) => {
      state.isFullscreen = isFullscreen;
    }
  },
  actions: {
    async fetchUser({ commit }) {
      commit("setUser", await serviceController.getUser());
    },
    async fetchHazards({ commit }) {
      commit(
        "setHazards",
        await serviceController.getObjectStoreAsync("hazards")
      );
    },
    async fetchHazardCategories({ commit }) {
      commit(
        "setHazardCategories",
        await serviceController.getObjectStoreAsync("hazardCategories")
      );
    },
    async fetchBarriers({ commit }) {
      commit(
        "setBarriers",
        await serviceController.getObjectStoreAsync("barriers")
      );
    },
    async fetchWorkTypes({ commit }) {
      commit(
        "setWorkTypes",
        await serviceController.getObjectStoreAsync("workTypes")
      );
    },
    async fetchEmployees({ commit }) {
      commit(
        "setEmployees",
        await serviceController.getObjectStoreAsync("employees")
      );
    },
    async fetchCriticalTasks({ commit }) {
      commit(
        "setCriticalTasks",
        await serviceController.getObjectStoreAsync("criticalTasks")
      );
    },
    async fetchVehicles({ commit }) {
      commit(
        "setVehicles",
        await serviceController.getObjectStoreAsync("vehicles")
      );
    }
  }
});
