<template>
  <preview-input-string
    :name="name"
    :value="value"
    :lineBreak="
      typeof value === 'string' && value.length > 70 ? 'long' : 'auto'
    "
  />
</template>

<script>
import PreviewInputString from "./preview-input-string.vue";

export default {
  name: "PreviewInputText",
  props: {
    value: {
      type: String,
      default: ""
    },
    name: String
  },
  components: { PreviewInputString }
};
</script>
