<template>
  <div>
    <v-row class="component-header mt-2">
      <p>Applicable Energies & Hazard Categories</p>
      <v-spacer />
    </v-row>
    <v-row>
      <hazard-selection
        ref="hazardSelection"
        :disabled="disabled"
        :hazardIds="hazardIds"
        :selectedHazards="selectedHazards"
        @change="onHazardSelectionChange($event)"
        @delete="RemoveSelectedHazard($event)"
      />
    </v-row>
    <v-row>
      <job-steps
        :disabled="disabled"
        :jobSteps="jobSteps"
        :selectedHazards="selectedHazards"
        :workType="workType"
        :employees="employees"
        ref="jobStepSection"
        @change="onJobStepsChange($event)"
        @refresh="$emit('refresh')"
      />
    </v-row>
  </div>
</template>
<script>
import JobSteps from "@/components/submission/custom/job-steps.vue";
import HazardSelection from "@/components/submission/custom/hazard-selection.vue";
export default {
  name: "RiskAssessment",

  props: {
    tailboardFormSubmission: Object,
    disabled: Boolean,
    id: String
  },

  components: {
    JobSteps,
    HazardSelection
  },

  data() {
    return {
      selectedHazards: []
    };
  },

  watch: {
    hazardIds: {
      immediate: true,
      deep: true,
      handler: function(val) {
        this.selectedHazards = this.hazardsList.filter(h => val.includes(h.id));
      }
    }
  },

  computed: {
    hazardsList() {
      return this.$store.getters.getHazards;
    },

    selectedWorkType() {
      return this.riskAssessment && this.riskAssessment.workType
        ? this.riskAssessment.workType
        : null;
    },
    riskAssessment() {
      return this.tailboardFormSubmission.riskAssessment;
    },

    riskAssessmentId() {
      if (this.riskAssessment) {
        return this.riskAssessment.id;
      } else {
        return null;
      }
    },

    jobSteps() {
      return this.riskAssessment ? this.riskAssessment.jobSteps : [];
    },

    hazardIds() {
      return this.riskAssessment ? this.riskAssessment.hazardIds : [];
    },

    workType() {
      return this.riskAssessment ? this.riskAssessment.workType : null;
    },

    employees() {
      return this.tailboardFormSubmission
        ? this.tailboardFormSubmission.crewMembers
        : [];
    }
  },

  methods: {
    RemoveSelectedHazard(id) {
      this.selectedHazards.splice(
        this.selectedHazards.findIndex(h => h.id === id),
        1
      );
      this.$refs.jobStepSection.removeHazards(id);
    },
    getValidationResult() {
      return this.$refs.jobStepSection.getRequiredFields();
    },
    onHazardSelectionChange(selectedHazards) {
      const riskAssessment = {
        id: this.riskAssessmentId,
        jobSteps: JSON.parse(
          JSON.stringify(this.riskAssessment.jobSteps),
          (key, value) => (key === "__typename" ? undefined : value)
        ),
        workType: this.selectedWorkType,
        hazardIds: selectedHazards.map(h => h.id)
      };
      this.$emit("change", riskAssessment);
    },

    onJobStepsChange(job) {
      const riskAssessment = {
        id: this.riskAssessmentId,
        jobSteps: job.jobSteps,
        workType: job.workType,
        hazardIds: this.selectedHazards.map(h => h.id)
      };
      this.$emit("change", riskAssessment);
    }
  }
};
</script>
