<template>
  <preview-input :disableGrid="disableGrid">
    <preview-input-name v-if="name" v-html="name" />
    <preview-input-value
      class="preview-input-yes-no-na-value"
      v-html="value || '&mdash;'"
    />
  </preview-input>
</template>

<script>
import PreviewInput from "./preview-input.vue";
import PreviewInputName from "./preview-input-name.vue";
import PreviewInputValue from "./preview-input-value.vue";

export default {
  name: "PreviewInputYesNoNa",
  props: {
    value: String,
    name: String
  },
  components: {
    PreviewInput,
    PreviewInputName,
    PreviewInputValue
  },
  computed: {
    disableGrid() {
      let length = 0;

      if (this.value) length += this.value.length;
      if (this.name) length += this.name.length;

      return length >= 35;
    }
  }
};
</script>

<style scoped>
.preview-input-yes-no-na-value {
  color: var(--v-primary-base);
}
</style>
