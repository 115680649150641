<template>
  <div
    :style="[
      standAlone
        ? {
            position: 'absolute',
            right: '6px',
            top: '34px',
            backgroundColor: 'white',
            boxShadow: '0 5px 5px 0 var(--v-border-base)',
            border: '1px solid var(--v-border-base)'
          }
        : {}
    ]"
    class="slide-bar d-flex"
    v-if="comment.show"
  >
    <v-icon
      color="primary"
      v-if="!expandComment"
      @click="expandComment = !expandComment"
      >{{ standAlone ? "arrow_left" : "arrow_right" }}</v-icon
    >
    <div class="pt-3 pr-1 pl-1">
      <v-icon :color="comment.isCritical ? 'red' : 'primary'">{{
        getActiveIcon(comment.isCritical)
      }}</v-icon>
    </div>
    <div v-if="expandComment" class="caption d-flex">
      <div :class="{ 'slide-bar-one-line': !comment.details }">
        <p class="primary--text pa-0 ma-0">
          {{ comment.text }}
        </p>
        <span>{{ comment.details }}</span>
      </div>
      <div v-if="expandComment">
        <p @click="displayComment(submission.tailboardFormSubmissionReview)">
          <v-icon color="primary">mode_comment</v-icon
          ><span>{{ comment.count }}</span>
        </p>
      </div>
      <v-icon color="primary" @click="expandComment = !expandComment">{{
        standAlone ? "arrow_right" : "arrow_left"
      }}</v-icon>
    </div>
    <comment-dialog
      :cancelAction="closeCommentDialog"
      :review="reviewData"
      :commentDialogShow="commentDialogShow"
    />
  </div>
</template>
<script>
import commentDialog from "./comment-dialog.vue";
export default {
  name: "PreviewSlidebar",
  props: {
    submission: Object,
    standAlone: Boolean
  },
  components: {
    commentDialog
  },
  data() {
    return {
      commentDialogShow: false,
      styleObject: {},
      expandComment: false
    };
  },
  computed: {
    hasCriticalTasks() {
      return (
        this.submission.criticalTasksAssessment &&
        this.submission.criticalTasksAssessment.criticalTasksIds.length > 0
      );
    },
    reviewData() {
      return this.submission
        ? {
            user: this.submission.tailboardFormSubmissionReview
              ? this.submission.tailboardFormSubmissionReview.user.name
              : "",
            insertedAt: this.submission.insertedAt,
            comment: this.submission.tailboardFormSubmissionReview
              ? this.submission.tailboardFormSubmissionReview.comment
              : ""
          }
        : {};
    },
    comment() {
      const isExist =
        this.submission &&
        this.submission.tailboardFormSubmissionReview &&
        this.submission.tailboardFormSubmissionReview.comment;
      return {
        show:
          this.submission.mode === "CLOSED" ||
          this.submission.endDayReviewed ||
          (this.submission.tailboardFormSubmissionReview &&
            this.submission.tailboardFormSubmissionReview.comment) ||
          this.hasCriticalTasks,
        isCritical: this.hasCriticalTasks,
        text: this.submission.endDayReviewed
          ? "Reviewed"
          : this.hasCriticalTasks
          ? this.submission.midDayReviewed && this.submission.mode === "OPEN"
            ? "Reviewed"
            : this.submission.mode === "CLOSED"
            ? "Awaiting final review"
            : "Critical task review"
          : "Awaiting final review",
        details:
          this.submission.endDayReviewed || this.submission.mode === "CLOSED"
            ? null
            : this.hasCriticalTasks
            ? this.submission.midDayReviewed
              ? "Proceed with work"
              : "Do not start work"
            : null,
        count: isExist ? "1" : "0"
      };
    }
  },
  methods: {
    getActiveIcon(critical) {
      let iconFile = "notifications_none";
      if (this.submission.mode === "CLOSED") {
        iconFile = this.submission.endDayReviewed
          ? "$vuetify.icons.notificationNone"
          : "$vuetify.icons.notificationPrimary";
      } else if (critical) {
        iconFile = this.submission.midDayReviewed
          ? "$vuetify.icons.notificationImportant"
          : "$vuetify.icons.notification";
      }
      return iconFile;
    },
    closeCommentDialog() {
      this.commentDialogShow = false;
    },
    displayComment() {
      this.commentDialogShow = true;
    }
  }
};
</script>

<style scoped>
.slide-bar {
  line-height: 20px;
  height: 50px;
  width: auto;
  z-index: 1 !important;
}

.slide-bar-one-line {
  line-height: 45px !important;
}

.slide-bar div:nth-child(2) div:first-child {
  line-height: 20px;
  text-align: left;
  min-width: 132px;
  padding: 4px 8px 0 4px;
  border-right: 1px solid var(--v-border-base);
}

.slide-bar div:nth-child(2) div:nth-child(2) {
  padding: 13px 2px 0 4px;
  position: relative;
  color: white;
}

.slide-bar div:nth-child(2) div:nth-child(2) p > span {
  position: absolute;
  top: 13px;
  right: 10px;
  z-index: 1;
  font-weight: normal;
  cursor: pointer;
}

.slide-bar div:nth-child(2) span {
  font-weight: bold;
}
</style>
