var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"submission-main"},[(_vm.collaps)?_c('div',{staticClass:"vertical-line"}):_vm._e(),_c('div',{staticClass:"submission-nav white mt-4 ml-4",class:_vm.collaps ? 'collaps' : ''},[_c('div',{staticStyle:{"height":"40px"}},[(!_vm.collaps)?_c('div',{staticClass:"d-flex float-left"},[_c('p',{staticClass:"text-decoration-underline cursor-pointer",on:{"click":function($event){return _vm.$router.push('/')}}},[_vm._v(" Dashboard ")]),_c('v-icon',{staticClass:"mt-n4",attrs:{"color":"primary"}},[_vm._v("navigate_next")]),_c('p',[_vm._v("Current Form")])],1):_vm._e(),_c('v-btn',{staticClass:"float-right",attrs:{"fab":"","x-small":"","color":"white"},on:{"click":function($event){_vm.collaps = !_vm.collaps}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm.collaps ? "keyboard_arrow_right" : "keyboard_arrow_left"))])],1)],1),(!_vm.collaps)?_c('div',[_c('p',{staticClass:"ma-0 primary--text"},[_vm._v("Form title")]),_c('p',[_vm._v(" "+_vm._s(_vm.tailboardFormSubmission.tailboardForm ? _vm.tailboardFormSubmission.tailboardForm.title : "")+" ")]),_c('v-divider',{}),(_vm.tailboardFormSubmission)?_c('p',{staticClass:"caption"},[_vm._v(" Created : "+_vm._s(_vm._f("parseDate")(_vm.tailboardFormSubmission.insertedAt))+" ")]):_vm._e(),(
          _vm.submissionReview.state === 'SUBMITTED' &&
            _vm.submissionReview.mode === 'CLOSED' &&
            _vm.copyIsAvailable
        )?_c('v-btn',{staticClass:"primary mb-3",on:{"click":_vm.copyFormSubmission}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("content_copy")]),_vm._v(" Copy Form ")],1):_vm._e()],1):_vm._e()]),_c('div',{ref:"sectionContainerId",staticClass:"submission-section overflow-y-auto overflow-x-hidden"},[_c('div',{staticClass:"submission-header-data pa-3"},[_c('div',{staticClass:"header-panel"},[_c('div',[_c('p',[_vm._v("Job Details")]),_c('job-information',{attrs:{"tailboardFormSubmission":_vm.tailboardFormSubmission,"submissionHeader":_vm.tailboardFormSubmission.tailboardFormHeader
                ? _vm.tailboardFormSubmission.tailboardFormHeader || {}
                : {}}})],1),_c('div',[(
              !_vm.tailboardFormSubmission.isLocked &&
                (_vm.isFormSupervisor || _vm.isEditable) &&
                _vm.submissionReview.mode === 'OPEN'
            )?_c('v-btn',{staticClass:"float-right mb-2",attrs:{"text":""},on:{"click":_vm.openSubmissionForm}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("edit")]),_vm._v("Edit ")],1):_vm._e(),_c('v-btn',{staticClass:"primary float-right mb-3",on:{"click":_vm.saveChanges}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("done")]),_vm._v(" Done ")],1),_c('v-btn',{staticClass:"float-right",class:[
              _vm.submissionReview.isReviewed ? 'white primary--text' : 'primary'
            ],attrs:{"disabled":_vm.submissionReview.isReviewed || !_vm.readyToReview},on:{"click":function($event){_vm.showDialogMessage = true}}},[(_vm.submissionReview.isReviewed)?_c('v-icon',{staticClass:"mr-2"},[_vm._v("done")]):_vm._e(),_vm._v(" "+_vm._s(_vm.submissionReview.isReviewed ? "Reviewed" : "Mark as reviewed")+" ")],1)],1)])]),_c('div',{staticClass:"submission-header-data pa-3"},[_c('div',{staticClass:"component-header d-flex"},[_c('h3',[_vm._v("Comment")]),_c('v-spacer'),_c('p',{domProps:{"innerHTML":_vm._s(_vm.submissionReview.reviewDetails)}})],1),_c('v-textarea',{staticClass:"mt-2",attrs:{"hide-details":"","outlined":"","auto-grow":"","readonly":!_vm.isFormSupervisor || _vm.submissionReview.isReviewed},model:{value:(_vm.submissionReview.comment),callback:function ($$v) {_vm.$set(_vm.submissionReview, "comment", $$v)},expression:"submissionReview.comment"}})],1),_c('div',{staticClass:"submission-header-data pa-4"},[_c('h3',{staticClass:"component-header mb-3"},[_vm._v("Review Form")]),_c('preview',{ref:("preview-" + _vm.submissionId)},[_c('preview-form',{attrs:{"submissionId":_vm.submissionId,"state":_vm.state}})],1)],1)]),_c('dialog-message',{attrs:{"showDialogMessage":_vm.showDialogMessage,"displayCaption":"Review","displayText":"Are you sure you want to mark this form as reviewed ? ","okAction":_vm.markReviewed,"cancelAction":_vm.cancelAction}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }