<template>
  <v-input :rules="rules" :value="value">
    <div :class="{ disabled: disabled }">
      <div :ref="id" class="canvas-input" :class="{ disabled: isDisabled }">
        <canvas
          :width="canvasWidth"
          :height="canvasHeight"
          :ref="id + '-canvas'"
        />
      </div>
      <div>
        <v-btn v-show="value && showClearBtn" @click="clearCanvas()">
          <v-icon left>delete_outline</v-icon>
          Clear</v-btn
        >
      </div>
    </div>
  </v-input>
</template>

<script>
import SignaturePad from "signature_pad";
import getEnv from "@/utilities/env.js";
import utils from "@/shared/Utils";
import { workers } from "@/shared/workers.js";
import appsignal from "@/plugins/appsignal";

export default {
  name: "canvas-input",

  props: {
    value: String,
    asset: String,
    id: String,
    disabled: Boolean,
    rules: Array,
    canvasWidth: {
      type: Number,
      default: 450
    },
    canvasHeight: {
      type: Number,
      default: 150
    },
    showClearBtn: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      isDirty: false,
      isUploading: false,
      resizeTimer: 0,
      signaturePad: null,
      backgroundImage: new Image(),
      blobData: null
    };
  },
  watch: {
    blobData(value) {
      this.updateCanvas(value);
    },
    id: {
      immediate: true,
      handler: function() {
        this.isDirty = false;
      }
    },
    value: {
      immediate: true,
      deep: true,
      handler: async function(val) {
        if (this.isDirty || !val) return;

        if (!val.includes("data:image/png;base64")) {
          const cacheUrl = await utils.mapUrlToCache(
            `${getEnv("VUE_APP_SERVER_URL")}/doc/${val}`,
            "image/png"
          );

          if (cacheUrl) this.blobData = cacheUrl;
          else {
            const response = await fetch(
              `${getEnv("VUE_APP_SERVER_URL")}/doc/${val}`
            );

            if (!response.ok) {
              const text = await response.text();
              const error = new Error(
                `fetch failed w/ ${response.status}\n\n${text}`
              );
              appsignal.sendError(error);
              throw error;
            }
            const data = await response.blob();
            const reader = new FileReader();

            reader.readAsDataURL(data);
            reader.onloadend = () => {
              this.blobData = reader.result;
            };
          }
        } else this.blobData = val;
      }
    }
  },

  async mounted() {
    const canvas = this.getCanvas();

    this.signaturePad = new SignaturePad(canvas, {
      onEnd: this.saveImage,
      minWidth: 1,
      maxWidth: 1
    });

    this.drawBackground(this.$refs[this.id + "-canvas"]);
  },

  computed: {
    isDisabled() {
      return this.disabled || this.isUploading;
    }
  },

  methods: {
    changeBackground(blob) {
      const img = new Image();

      img.onload = () => {
        this.backgroundImage = img;
        const canvas = this.$refs[this.id + "-canvas"];
        this.drawBackground(canvas);
      };

      img.src = URL.createObjectURL(blob);
    },

    updateCanvas(base64data) {
      const canvas = this.getCanvas();
      if (canvas)
        this.signaturePad.fromDataURL(base64data, {
          width: canvas.width,
          height: canvas.height
        });
    },

    getCanvas() {
      return this.$refs[this.id + "-canvas"];
    },

    drawBackground(canvas) {
      if (!this.backgroundImage) return;

      const height =
        (this.backgroundImage.height * canvas.width) /
        this.backgroundImage.width;
      const ctx = canvas.getContext("2d");
      const gco = ctx.globalCompositeOperation;

      ctx.globalCompositeOperation = "destination-over"; // draw background behind existing content
      ctx.drawImage(this.backgroundImage, 0, 0, canvas.width, height);
      ctx.globalCompositeOperation = gco;
    },
    saveImage() {
      this.isDirty = true;
      this.isUploading = true;

      const fileUUID = utils.getUUID();
      const byteString = atob(this.signaturePad.toDataURL().split(",")[1]);
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const uintArray = new Uint8Array(arrayBuffer);

      for (let i = 0; i < byteString.length; i++) {
        uintArray[i] = byteString.charCodeAt(i);
      }

      let the_file = new Blob([arrayBuffer], { type: "image/png" });
      let worker = workers.uploadWorker();

      worker.postMessage({
        action: "start",
        files: [{ file: the_file, uuid: fileUUID }],
        uri: `${getEnv("VUE_APP_SERVER_URL")}`
      });

      worker.onmessage = e => {
        switch (e.data.status) {
          case 0:
          case 1:
            this.emitVal(fileUUID);
            this.isUploading = false;
            break;
          case 3:
            appsignal.sendError(e.data.exception);
            break;
        }
      };
    },

    clearCanvas() {
      this.emitVal("");
      this.signaturePad.clear();

      if (this.asset) this.drawBackground(this.$refs[this.id + "-canvas"]);
    },

    emitVal(val) {
      this.isDirty = true;
      this.$emit("input", val);
    }
  }
};
</script>

<style>
.canvas-input {
  display: block;
  min-height: auto;
  height: auto;
  width: 100%;
}

.canvas-input canvas {
  border: 1px solid #a3a2a2;
  border-radius: 5px;
}

.canvas-input.disabled {
  pointer-events: none;
  opacity: 0.4;
}
</style>
