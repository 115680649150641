<template>
  <preview-input-date
    :name="name"
    :value="value"
    :format="'YYYY-MM-DD  HH:mma'"
  />
</template>

<script>
import PreviewInputDate from "./preview-input-date.vue";

export default {
  name: "PreviewInputDatetime",
  props: {
    value: String,
    name: String
  },
  components: { PreviewInputDate }
};
</script>
