<template>
  <preview-form-component class="preview-component-employee-entry-log">
    <preview-form-title :level="3">Employee Entry Log</preview-form-title>

    <preview-form-group>
      <table class="printable-table">
        <thead>
          <tr>
            <th>Employee</th>
            <th>Time In</th>
            <th>Time Out</th>
          </tr>
        </thead>
        <tbody
          v-if="
            submission.employeeEntryLogs &&
              submission.employeeEntryLogs.length > 0
          "
        >
          <tr v-for="entry in submission.employeeEntryLogs" :key="entry.id">
            <td>{{ employeesNameById[entry.employeeId] }}</td>
            <td>{{ formatTime(entry.timeIn) }}</td>
            <td>{{ formatTime(entry.timeOut) }}</td>
          </tr>
        </tbody>
      </table>
    </preview-form-group>
  </preview-form-component>
</template>

<script>
import dayjs from "@/plugins/dayjs";

import PreviewFormTitle from "./preview-form-title.vue";
import PreviewFormComponent from "./preview-form-component.vue";
import PreviewFormGroup from "./preview-form-group.vue";

export default {
  name: "PreviewComponentEmployeeEntryLog",
  props: {
    submission: Object
  },
  components: {
    PreviewFormComponent,
    PreviewFormGroup,
    PreviewFormTitle
  },
  computed: {
    employeesNameById() {
      return this.$store.getters.getEmployees.reduce(
        (obj, { id, firstName, lastName }) => {
          obj[id] = `${firstName} ${lastName}`;
          return obj;
        },
        {}
      );
    }
  },
  methods: {
    formatTime(time) {
      return dayjs(time).format("YYYY-MM-DD HH:mma");
    }
  }
};
</script>

<style scoped>
.printable-table {
  column-span: all;
  border-collapse: separate;
  border-spacing: 2px;
  border: 1px solid var(--v-primary-base);
  border-radius: 4px;
  margin: 0 auto;
  width: calc(100% - 16px);
}

.printable-table tr {
  background-color: var(--v-whiteBackground-base);
}

.printable-table tr td {
  background-color: var(--v-whiteBackground-base);
  padding: 4px;
}

.printable-table thead tr th {
  color: var(--v-whiteBackground-base);
  background-color: var(--v-primary-base);
  padding: 0.24em;
}
</style>
