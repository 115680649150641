<template>
  <preview-form-component>
    <preview-form-title :level="3"
      >Toronto Hydro Employees (Crew Members & TH Visitors)
    </preview-form-title>
    <preview-form-group
      v-if="allEmployeeSignatures && allEmployeeSignatures.length > 0"
    >
      <preview-input
        class="signature"
        v-for="signature in allEmployeeSignatures"
        :key="signature.id"
        :disableGrid="true"
      >
        <preview-input-signature
          :name="signature.employee.title"
          :owner="
            signature.employee.firstName + ' ' + signature.employee.lastName
          "
          :value="signature.file"
          :signedAt="signature.signedAt"
        />
      </preview-input>
    </preview-form-group>
  </preview-form-component>
</template>

<script>
import PreviewFormComponent from "./preview-form-component.vue";
import PreviewFormGroup from "./preview-form-group.vue";
import PreviewInputSignature from "./preview-input-signature.vue";
import PreviewFormTitle from "./preview-form-title.vue";
import PreviewInput from "./preview-input.vue";

export default {
  name: "PreviewComponentEmployeeSignOff",
  props: {
    submission: Object,
    form: Object
  },
  components: {
    PreviewFormComponent,
    PreviewFormGroup,
    PreviewInput,
    PreviewFormTitle,
    PreviewInputSignature
  },
  computed: {
    allEmployeeSignatures() {
      if (!this.submission.signatures) return [];

      let signatures = this.submission.signatures;
      if (this.isInApplicable) {
        signatures = this.filterByCrewMemberOnly;
      }

      return signatures.filter(x => !!x.employee);
    },
    isInApplicable() {
      const componentId = this.form.sections
        .find(s =>
          s.components.find(c => c.customType === "EMPLOYEE_VISITOR_MANAGEMENT")
        )
        ?.components.find(c => c.customType === "EMPLOYEE_VISITOR_MANAGEMENT")
        ?.id;

      const isInApplicable = this.submission.inapplicableComponents.some(
        component => component === componentId
      );

      return isInApplicable;
    },
    filterByCrewMemberOnly() {
      const crewMembers = this.submission.signatures.filter(
        signature =>
          signature.employee &&
          this.submission.crewMembers.some(
            crewMember =>
              crewMember.employee &&
              crewMember.employee.id === signature.employee.id
          )
      );

      return crewMembers;
    }
  }
};
</script>
