<template>
  <div class="preview-form">
    <header class="header">
      <img class="logo" v-if="logo" :src="logo" />

      <preview-form-title :level="1">{{ form.title }}</preview-form-title>
    </header>

    <div
      class="sections"
      v-if="form && form.sections && form.sections.length > 0"
    >
      <preview-form-section
        v-for="section in sorted(form.sections)"
        :key="section.id"
        :title="section.title"
      >
        <preview-form-title
          v-if="isInaplicableSection(section.id)"
          :level="4"
          :isNA="true"
        >
          N/A
        </preview-form-title>

        <template
          v-else-if="section.components && section.components.length > 0"
        >
          <template v-for="component in sorted(section.components)">
            <preview-form-component
              v-if="!component.customType"
              :key="component.id"
              :title="component.title"
            >
              <preview-form-title
                v-if="isInaplicableComponent(component.id)"
                :level="5"
                :isNA="true"
              >
                N/A
              </preview-form-title>

              <template
                v-else-if="
                  component.inputFields && component.inputFields.length > 0
                "
              >
                <preview-form-group
                  :disableGrid="component.inputFields.length < 2"
                >
                  <component
                    v-for="input in sorted(component.inputFields)"
                    v-bind:is="getInputComponent(input.inputType)"
                    :key="input.id"
                    :name="input.name"
                    :value="getInputValue(input.id, input.inputType)"
                    :field="input"
                  />
                </preview-form-group>
              </template>
            </preview-form-component>

            <!-- custom component -->
            <component
              v-else
              v-bind:is="getCustomComponent(component.customType)"
              :key="component.id"
              :submission="submission"
              :inapplicationComponent="isInaplicableComponent(component.id)"
              :form="form"
              :component="component"
            />
          </template>
        </template>
      </preview-form-section>
    </div>
    <template
      v-if="
        submission &&
          submission.childTailboardFormSubmissions &&
          submission.childTailboardFormSubmissions.length > 0
      "
    >
      <template v-for="child in submission.childTailboardFormSubmissions">
        <preview-form
          :key="child.id"
          :submissionId="child.id"
          :state="child.state"
        />
      </template>
    </template>
  </div>
</template>

<script>
import tailboardController from "@/js/tailboards-controller.js";

import PreviewFormComponent from "./preview-form-component.vue";
import PreviewFormGroup from "./preview-form-group.vue";
import PreviewFormTitle from "./preview-form-title.vue";
import PreviewFormSection from "./preview-form-section.vue";
import PreviewInputSelectMany from "./preview-input-select-many.vue";
import PreviewInputSelectOne from "./preview-input-select-one.vue";
import PreviewInputString from "./preview-input-string.vue";
import PreviewInputNumber from "./preview-input-number.vue";
import PreviewInputAddress from "./preview-input-address.vue";
import PreviewInputDate from "./preview-input-date.vue";
import PreviewInputDatetime from "./preview-input-datetime.vue";
import PreviewInputTime from "./preview-input-time.vue";
import PreviewInputEmployee from "./preview-input-employee.vue";
import PreviewInputEmployeeMany from "./preview-input-employee-many.vue";
import PreviewInputWeather from "./preview-input-weather.vue";
import PreviewInputPreview from "./preview-input-preview.vue";
import PreviewInputAudio from "./preview-input-audio.vue";
import PreviewInputFile from "./preview-input-file.vue";
import PreviewInputCheckbox from "./preview-input-checkbox.vue";
import PreviewInputYesNoNa from "./preview-input-yes-no-na.vue";
import PreviewInputSignature from "./preview-input-signature.vue";
import PreviewInputDrawing from "./preview-input-drawing.vue";
import PreviewInputText from "./preview-input-text.vue";
import PreviewInputCombobox from "./preview-input-text.vue";
import PreviewInputStaticText from "./preview-input-static-text.vue";
import PreviewInputTrafficPlan from "./preview-input-traffic-plan.vue";
import PreviewComponentJobDetails from "./preview-component-job-details.vue";
import PreviewComponentEmployeeManagement from "./preview-component-employee-management.vue";
import PreviewComponentVisitorManagement from "./preview-component-visitor-management.vue";
import PreviewComponentEmployeeVisitorManagement from "./preview-component-visitor-employee-management.vue";
import PreviewComponentRiskAssessment from "./preview-component-risk-assessment.vue";
import PreviewComponentVisitorSignOff from "./preview-component-visitor-sign-off.vue";
import PreviewComponentEmployeeSignOff from "./preview-component-employee-sign-off.vue";
import PreviewComponentEmployeeEntryLog from "./preview-component-employee-entry-log.vue";
import PreviewComponentConfinedSpaceSubstanceTest from "./preview-component-confined-space-substance-test.vue";
import PreviewComponentCableChamberFeeder from "./preview-component-cable-chamber-feeder.vue";
import PreviewComponentCriticalTasksAssessment from "./preview-component-critical-tasks-assessment.vue";

export default {
  name: "PreviewForm",
  props: {
    submissionId: String,
    state: String,
    tailboardForm: Object,
    tailboardSubmission: Object,
    logo: String
  },
  data() {
    return {
      form: this.tailboardForm || {},
      submission: this.tailboardSubmission || {}
    };
  },
  components: {
    PreviewFormSection,
    PreviewFormComponent,
    PreviewFormTitle,
    PreviewFormGroup,
    PreviewInputSelectMany,
    PreviewInputSelectOne,
    PreviewInputString,
    PreviewInputNumber,
    PreviewInputAddress,
    PreviewInputDate,
    PreviewInputDatetime,
    PreviewInputTime,
    PreviewInputEmployee,
    PreviewInputEmployeeMany,
    PreviewInputCheckbox,
    PreviewInputYesNoNa,
    PreviewInputSignature,
    PreviewInputDrawing,
    PreviewInputWeather,
    PreviewInputAudio,
    PreviewInputFile,
    PreviewInputText,
    PreviewInputCombobox,
    PreviewInputStaticText,
    PreviewInputTrafficPlan,
    PreviewInputPreview,
    PreviewComponentJobDetails,
    PreviewComponentEmployeeManagement,
    PreviewComponentVisitorManagement,
    PreviewComponentEmployeeVisitorManagement,
    PreviewComponentRiskAssessment,
    PreviewComponentVisitorSignOff,
    PreviewComponentEmployeeSignOff,
    PreviewComponentEmployeeEntryLog,
    PreviewComponentConfinedSpaceSubstanceTest,
    PreviewComponentCableChamberFeeder,
    PreviewComponentCriticalTasksAssessment
  },
  async mounted() {
    if (!this.submissionId) return;

    this.submission = await tailboardController.getTailboardFormSubmissionAsync(
      this.submissionId,
      this.state
    );

    if (!this.submission || !this.submission.formId)
      throw Error(`No submission found with id ${this.submissionId}`);

    this.form = await tailboardController.getTailboardFormByIdAsync(
      this.submission.formId
    );

    if (!this.form)
      throw Error(`No form found with id ${this.submission.formId}`);
  },
  methods: {
    sorted(list) {
      if (!list) return [];
      return list.concat().sort((a, b) => a.position - b.position);
    },
    isInaplicableSection(sectionId) {
      if (
        !this.submission ||
        !sectionId ||
        !this.submission.inapplicableSections
      )
        return;

      return this.submission.inapplicableSections.find(id => id === sectionId);
    },
    isInaplicableComponent(componentId) {
      if (
        !this.submission ||
        !componentId ||
        !this.submission.inapplicableComponents
      )
        return;

      return this.submission.inapplicableComponents.find(
        id => id === componentId
      );
    },
    getCustomComponent(customType) {
      return `preview-component-${customType
        .toLowerCase()
        .split("_")
        .join("-")}`;
    },
    getInputComponent(inputType) {
      return `preview-input-${inputType
        .toLowerCase()
        .split("_")
        .join("-")}`;
    },
    getInputValue(inputId, type) {
      if (!inputId || !this.submission || !this.submission.inputFieldData)
        return;

      const data = this.submission.inputFieldData.find(
        ({ inputFieldId }) => inputFieldId === inputId
      );

      if (!data) return;

      const { value } = data;
      if (!value || value === "N/A") return;

      switch (type) {
        case "SELECT_MANY":
          return value.split(",");

        case "CHECKBOX":
          return !!value;

        case "NUMBER":
          return parseFloat(value);

        case "WEATHER":
        case "AUDIO":
        case "FILE":
          return JSON.parse(value);

        case "YES_NO_NA":
          if (value === "true" || value === 1 || value === true) return "YES";
          if (value === "false" || value === 0 || value === false) return "NO";
          return value;

        default:
          return value;
      }
    }
  }
};
</script>

<style scoped>
.preview-form {
  font-weight: 300;
  font-size: 14px;
  padding-top: 28px;
  margin: auto;
  max-width: 1024px;
}

.sections {
  page-break-after: always;
}

.form-section {
  page-break-inside: avoid;
  padding-bottom: 1.4em;
  border-radius: 8px;
  position: relative;
  background-color: var(--v-secondary-base);
  border: 1px solid var(--v-primary-lighten3);
  margin-bottom: 1.4em;
}
</style>
