var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex w-100"},[_c('div',{staticClass:"mr-2 pa-2"},[_c('v-progress-circular',{attrs:{"color":"primary","value":_vm.section.progress()}},[(_vm.disabled)?_c('v-icon',[_vm._v("report_off")]):(
          _vm.section.progress() === 100 ||
            (_vm.section.showErrors &&
              _vm.section.totalRequired() === 0 &&
              _vm.section.notified() === 0)
        )?_c('v-icon',[_vm._v("done")]):_vm._e()],1)],1),_c('p',{staticClass:"font-weight-bold mt-4 w-100"},[_vm._v(" "+_vm._s(_vm.section.title)+" ")]),(
      _vm.section.showErrors &&
        _vm.currentStep != _vm.index + 1 &&
        (_vm.section.totalRequired() > 0 || _vm.section.notified() > 0)
    )?_c('div',{staticClass:"div-indicator mt-4"},[_c('span',[_vm._v(_vm._s(_vm.section.totalRequired() + _vm.section.notified()))])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }