import gql from "graphql-tag";

const SECTION_ENTITY_FRAGMENT = gql`
  fragment SectionEntity on Section {
    id
    title
    description
    layout
    position
    tableHeadings
    applicabilityPrompt
    components {
      title
      layout
      position
      id
      applicabilityPrompt
      customType
      inputFields {
        id
        componentId
        name
        defaultValue
        description
        inputType
        position
        relatedAsset
        placeholderText
        allowOther
        allowNotApplicable
        displayInline
        optionGroupId
        options {
          value
          text
        }
      }
    }
  }
`;

export const SECTIONS_QUERY = gql`
  query getSections($formId: ID!) {
    sections(formId: $formId) {
      ...SectionEntity
    }
  }
  ${SECTION_ENTITY_FRAGMENT}
`;

export const CREATE_SECTION_MUTATION = gql`
  mutation createSectionMutation(
    $formId: ID!
    $layout: Layout!
    $title: String!
    $description: String
    $position: Int!
    $tableHeadings: [String]
    $applicabilityPrompt: String
  ) {
    createSection(
      formId: $formId
      layout: $layout
      position: $position
      tableHeadings: $tableHeadings
      title: $title
      description: $description
      applicabilityPrompt: $applicabilityPrompt
    ) {
      ...SectionEntity
    }
  }
  ${SECTION_ENTITY_FRAGMENT}
`;

export const UPDATE_SECTION_MUTATION = gql`
  mutation updateSectionMutation(
    $id: ID!
    $layout: Layout
    $title: String
    $description: String
    $position: Int
    $tableHeadings: [String]
    $applicabilityPrompt: String
  ) {
    updateSection(
      id: $id
      layout: $layout
      position: $position
      tableHeadings: $tableHeadings
      title: $title
      description: $description
      applicabilityPrompt: $applicabilityPrompt
    ) {
      ...SectionEntity
    }
  }
  ${SECTION_ENTITY_FRAGMENT}
`;

export const DELETE_SECTION_MUTATION = gql`
  mutation deleteSectionMutation($id: ID!) {
    deleteSection(id: $id) {
      id
    }
  }
`;

export const NEW_SECTION_SUBSCRIPTION = gql`
  subscription sectionSubscription {
    newSection {
      ...SectionEntity
    }
  }
  ${SECTION_ENTITY_FRAGMENT}
`;

// Components

const COMPONENT_ENTITY_FRAGMENT = gql`
  fragment ComponentEntity on Component {
    title
    layout
    position
    id
    sectionId
    applicabilityPrompt
    customType
    inputFields {
      id
      name
      defaultValue
      description
      inputType
      placeholderText
      options {
        value
        text
      }
    }
  }
`;

export const NEW_SECTION_COMPONENT_SUBSCRIPTION = gql`
  subscription componentSubscription {
    newComponent {
      ...ComponentEntity
    }
  }
  ${COMPONENT_ENTITY_FRAGMENT}
`;

export const CREATE_COMPONENT_MUTATION = gql`
  mutation createComponentMutation(
    $sectionId: ID!
    $title: String!
    $position: Int!
    $layout: Layout!
    $applicabilityPrompt: String
    $customType: CustomComponentType
  ) {
    createComponent(
      title: $title
      position: $position
      layout: $layout
      sectionId: $sectionId
      applicabilityPrompt: $applicabilityPrompt
      customType: $customType
    ) {
      ...ComponentEntity
    }
  }
  ${COMPONENT_ENTITY_FRAGMENT}
`;

export const UPDATE_COMPONENT_MUTATION = gql`
  mutation updateComponentMutation(
    $id: ID!
    $title: String!
    $position: Int!
    $layout: Layout!
    $applicabilityPrompt: String
    $customType: CustomComponentType
  ) {
    updateComponent(
      id: $id
      title: $title
      position: $position
      layout: $layout
      applicabilityPrompt: $applicabilityPrompt
      customType: $customType
    ) {
      ...ComponentEntity
    }
  }
  ${COMPONENT_ENTITY_FRAGMENT}
`;

export const DELETE_COMPONENT_MUTATION = gql`
  mutation deleteComponentMutation($id: ID!) {
    deleteComponent(id: $id) {
      id
    }
  }
`;

const INPUT_FIELD_ENTITY_FRAGMENT = gql`
  fragment InputFieldEntity on InputField {
    id
    componentId
    defaultValue
    description
    descriptor
    inputType
    name
    placeholderText
    relatedAsset
    validations {
      # TODO: Move into fragment entity
      id
    }
    position
    allowOther
    allowNotApplicable
    displayInline
    optionGroupId
  }
`;

export const NEW_INPUT_FIELD_SUBSCRIPTION = gql`
  subscription inputFieldSubscription {
    newInputField {
      ...InputFieldEntity
    }
  }
  ${INPUT_FIELD_ENTITY_FRAGMENT}
`;

export const CREATE_INPUT_FIELD_MUTATION = gql`
  mutation createInputFieldMutation(
    $name: String
    $componentId: ID!
    $optionGroupId: ID
    $defaultValue: String
    $description: String
    $descriptor: String
    $position: Int
    $placeholderText: String
    $relatedAsset: String
    $inputType: InputType!
    $validations: [InputFieldValidationInput]
    $allowOther: Boolean
    $allowNotApplicable: Boolean
    $displayInline: Boolean
  ) {
    createInputField(
      componentId: $componentId
      optionGroupId: $optionGroupId
      defaultValue: $defaultValue
      description: $description
      descriptor: $descriptor
      inputType: $inputType
      name: $name
      position: $position
      placeholderText: $placeholderText
      relatedAsset: $relatedAsset
      validations: $validations
      allowOther: $allowOther
      allowNotApplicable: $allowNotApplicable
      displayInline: $displayInline
    ) {
      ...InputFieldEntity
    }
  }
  ${INPUT_FIELD_ENTITY_FRAGMENT}
`;

export const UPDATE_INPUT_FIELD_MUTATION = gql`
  mutation updateInputFieldMutation(
    $componentId: ID!
    $id: ID!
    $name: String
    $optionGroupId: ID
    $defaultValue: String
    $description: String
    $descriptor: String
    $position: Int
    $placeholderText: String
    $relatedAsset: String
    $inputType: InputType
    $validations: [InputFieldValidationInput]
    $allowOther: Boolean
    $allowNotApplicable: Boolean
    $displayInline: Boolean
  ) {
    updateInputField(
      componentId: $componentId
      id: $id
      optionGroupId: $optionGroupId
      defaultValue: $defaultValue
      description: $description
      descriptor: $descriptor
      inputType: $inputType
      name: $name
      position: $position
      placeholderText: $placeholderText
      relatedAsset: $relatedAsset
      validations: $validations
      allowOther: $allowOther
      allowNotApplicable: $allowNotApplicable
      displayInline: $displayInline
    ) {
      ...InputFieldEntity
    }
  }
  ${INPUT_FIELD_ENTITY_FRAGMENT}
`;

export const DELETE_INPUT_FIELD_MUTATION = gql`
  mutation deleteInputFieldMutation($id: ID!) {
    deleteInputField(id: $id) {
      id
    }
  }
`;

// Option Groups

const OPTION_GROUP_ENTITY_FRAGMENT = gql`
  fragment OptionGroupEntity on OptionGroup {
    id
    name
    description
    sort
    options {
      id
      key
      value
      position
      optionGroupId
      optionGroup {
        description
        id
        name
        sort
      }
    }
  }
`;

const OPTION_ENTITY_FRAGMENT = gql`
  fragment OptionEntity on Option {
    id
    key
    value
    position
    optionGroupId
    optionGroup {
      description
      id
      name
      sort
    }
  }
`;

export const OPTION_GROUPS_QUERY = gql`
  {
    optionGroups {
      ...OptionGroupEntity
    }
  }
  ${OPTION_GROUP_ENTITY_FRAGMENT}
`;

export const CREATE_OPTION_GROUP_MUTATION = gql`
  mutation createOptionGroupMutation(
    $name: String!
    $description: String!
    $sort: Sort!
    $options: [Map]
  ) {
    createOptionGroup(
      name: $name
      description: $description
      sort: $sort
      options: $options
    ) {
      ...OptionGroupEntity
    }
  }
  ${OPTION_GROUP_ENTITY_FRAGMENT}
`;

export const UPDATE_OPTION_GROUP_MUTATION = gql`
  mutation updateOptionGroupMutation(
    $id: ID!
    $name: String!
    $description: String!
    $sort: Sort!
    $options: [Map]
  ) {
    updateOptionGroup(
      id: $id
      name: $name
      description: $description
      sort: $sort
      options: $options
    ) {
      ...OptionGroupEntity
    }
  }
  ${OPTION_GROUP_ENTITY_FRAGMENT}
`;

export const NEW_OPTION_GROUP_SUBSCRIPTION = gql`
  subscription optionGroupSubscription {
    newOptionGroup {
      ...OptionGroupEntity
    }
  }
  ${OPTION_GROUP_ENTITY_FRAGMENT}
`;

export const DELETE_OPTION_GROUP_MUTATION = gql`
  mutation deleteOptionGroup($id: ID!) {
    deleteOptionGroup(id: $id) {
      id
    }
  }
`;

export const OPTIONS_QUERY = gql`
  {
    options {
      ...OptionEntity
    }
  }
  ${OPTION_ENTITY_FRAGMENT}
`;

export const CREATE_OPTION_MUTATION = gql`
  mutation createOptionMutation(
    $key: String!
    $value: String!
    $position: Int!
    $optionGroupId: ID!
  ) {
    createOption(
      key: $key
      value: $value
      position: $position
      optionGroupId: $optionGroupId
    ) {
      ...OptionEntity
    }
  }
  ${OPTION_ENTITY_FRAGMENT}
`;

export const UPDATE_OPTION_MUTATION = gql`
  mutation updateOptionMutation(
    $id: ID!
    $key: String!
    $value: String!
    $position: Int!
    $optionGroupId: ID!
  ) {
    updateOption(
      id: $id
      key: $key
      value: $value
      position: $position
      optionGroupId: $optionGroupId
    ) {
      ...OptionEntity
    }
  }
  ${OPTION_ENTITY_FRAGMENT}
`;

export const NEW_OPTION_SUBSCRIPTION = gql`
  subscription optionSubscription {
    newOption {
      ...OptionEntity
    }
  }
  ${OPTION_ENTITY_FRAGMENT}
`;

export const DELETE_OPTION_MUTATION = gql`
  mutation deleteOption($id: ID!) {
    deleteOption(id: $id) {
      id
    }
  }
`;

// Groups/Category

const GROUP_ENTITY_FRAGMENT = gql`
  fragment GroupEntity on Group {
    name
    description
    position
    sort
    id
  }
`;

export const DOCUMENT_GROUPS_QUERY = gql`
  {
    documentGroups {
      ...GroupEntity
    }
  }
  ${GROUP_ENTITY_FRAGMENT}
`;

export const PROCEDURE_GROUPS_QUERY = gql`
  {
    procedureGroups {
      ...GroupEntity
    }
  }
  ${GROUP_ENTITY_FRAGMENT}
`;

export const HAZARD_CATEGORIES_QUERY = gql`
  {
    hazardCategories {
      ...GroupEntity
    }
  }
  ${GROUP_ENTITY_FRAGMENT}
`;

export const CREATE_DOCUMENT_GROUP_MUTATION = gql`
  mutation createDocumentGroupMutation(
    $name: String!
    $description: String
    $position: Int!
    $sort: Sort!
  ) {
    createDocumentGroup(
      name: $name
      description: $description
      position: $position
      sort: $sort
    ) {
      ...GroupEntity
    }
  }
  ${GROUP_ENTITY_FRAGMENT}
`;

export const UPDATE_DOCUMENT_GROUP_MUTATION = gql`
  mutation updateDocumentGroupMutation(
    $id: ID!
    $name: String!
    $description: String
    $position: Int!
    $sort: Sort!
  ) {
    updateDocumentGroup(
      id: $id
      name: $name
      description: $description
      position: $position
      sort: $sort
    ) {
      ...GroupEntity
    }
  }
  ${GROUP_ENTITY_FRAGMENT}
`;

export const DELETE_DOCUMENT_GROUP_MUTATION = gql`
  mutation deleteDocumentGroupMutation($id: ID!) {
    deleteDocumentGroup(id: $id) {
      id
    }
  }
`;

export const NEW_DOCUMENT_GROUP_SUBSCRIPTION = gql`
  subscription documentGroupSubscription {
    newDocumentGroup {
      ...GroupEntity
    }
  }
  ${GROUP_ENTITY_FRAGMENT}
`;

export const CREATE_PROCEDURE_GROUP_MUTATION = gql`
  mutation createProcedureGroupMutation(
    $name: String!
    $description: String
    $position: Int!
    $sort: Sort!
  ) {
    createProcedureGroup(
      name: $name
      description: $description
      position: $position
      sort: $sort
    ) {
      ...GroupEntity
    }
  }
  ${GROUP_ENTITY_FRAGMENT}
`;

export const NEW_PROCEDURE_GROUP_SUBSCRIPTION = gql`
  subscription procedureGroupSubscription {
    newProcedureGroup {
      ...GroupEntity
    }
  }
  ${GROUP_ENTITY_FRAGMENT}
`;

export const CREATE_HAZARD_CATEGORY_MUTATION = gql`
  mutation createHazardCategoryMutation(
    $name: String!
    $description: String
    $position: Int!
    $sort: Sort!
  ) {
    createHazardCategory(
      name: $name
      description: $description
      position: $position
      sort: $sort
    ) {
      ...GroupEntity
    }
  }
  ${GROUP_ENTITY_FRAGMENT}
`;

export const NEW_HAZARD_CATEGORY_SUBSCRIPTION = gql`
  subscription hazardCatergorySubscription {
    newHazardCategory {
      ...GroupEntity
    }
  }
  ${GROUP_ENTITY_FRAGMENT}
`;

const DOCUMENT_ENTITY_FRAGMENT = gql`
  fragment DocumentEntity on Document {
    id
    name
    externalFile
    externalFileUrl
    position
    insertedAt
    updatedAt
    documentGroup {
      sort
      position
      description
      name
      id
    }
  }
`;

const PROCEDURE_ENTITY_FRAGMENT = gql`
  fragment ProcedureEntity on Procedure {
    name
    externalFile
    position
  }
`;

const HAZARD_ASSESSMENT_ENTITY_FRAGMENT = gql`
  fragment HazardAssessmentEntity on HazardAssessment {
    name
    externalFile
    position
  }
`;

export const DOCUMENTS_QUERY = gql`
  {
    documents {
      ...DocumentEntity
    }
  }
  ${DOCUMENT_ENTITY_FRAGMENT}
`;

export const PROCEDURES_QUERY = gql`
  {
    procedures {
      ...ProcedureEntity
    }
  }
  ${PROCEDURE_ENTITY_FRAGMENT}
`;

export const HAZARD_ASSESSMENTS_QUERY = gql`
  {
    hazardAssessments {
      ...HazardAssessmentEntity
    }
  }
  ${HAZARD_ASSESSMENT_ENTITY_FRAGMENT}
`;

// TODO: Refactor fragments, queries, mutations, subscriptions into separate files (see src/graphql/accounts)
const HAZARD_ENTITY_FRAGMENT = gql`
  fragment HazardEntity on Hazard {
    id
    name
    position
    hazardCategory {
      sort
      description
      name
      position
      id
    }
  }
`;

export const HAZARDS_QUERY = gql`
  {
    hazards {
      ...HazardEntity
    }
  }
  ${HAZARD_ENTITY_FRAGMENT}
`;

const BARRIER_ENTITY_FRAGMENT = gql`
  fragment BarrierEntity on Barrier {
    id
    name
    hazardId
  }
`;

export const BARRIERS_QUERY = gql`
  query getBarriers($hazardId: ID) {
    barriers(hazardId: $hazardId) {
      messages {
        field
        code
        message
      }
      result {
        ...BarrierEntity
      }
      successful
    }
  }
  ${BARRIER_ENTITY_FRAGMENT}
`;

const WORK_TYPE_ENTITY_FRAGMENT = gql`
  fragment WorkTypeEntity on WorkType {
    id
    name
    jobSteps
  }
`;

export const WORK_TYPES_QUERY = gql`
  query getWorkTypes {
    workTypes {
      messages {
        field
        code
        message
      }
      result {
        ...WorkTypeEntity
      }
      successful
    }
  }
  ${WORK_TYPE_ENTITY_FRAGMENT}
`;

const RISK_ASSESSMENT_ENTITY_FRAGMENT = gql`
  fragment RiskAssessmentEntity on RiskAssessment {
    id
    tailboardFormSubmissionId
    workType {
      ...WorkTypeEntity
    }
    hazardIds
    jobSteps {
      id
      name
      step
      employeeIds
      jobStepHazards {
        id
        source
        position
        barriers
        hazardId
      }
    }
  }
  ${WORK_TYPE_ENTITY_FRAGMENT}
`;

export const RISK_ASSESSMENT_QUERY = gql`
  query riskAssessment($id: ID!) {
    riskAssessment(id: $id) {
      successful
      messages {
        field
        message
      }
      result {
        ...RiskAssessmentEntity
      }
    }
  }
  ${RISK_ASSESSMENT_ENTITY_FRAGMENT}
`;

export const CREATE_DOCUMENT_MUTATION = gql`
  mutation createDocumentMutation(
    $name: String!
    $externalFile: ID
    $externalFileUrl: ID
    $position: Int!
    $documentGroupId: ID!
  ) {
    createDocument(
      name: $name
      externalFile: $externalFile
      externalFileUrl: $externalFileUrl
      position: $position
      documentGroupId: $documentGroupId
    ) {
      ...DocumentEntity
    }
  }
  ${DOCUMENT_ENTITY_FRAGMENT}
`;

export const UPDATE_DOCUMENT_MUTATION = gql`
  mutation createDocumentMutation(
    $id: ID!
    $name: String!
    $externalFile: ID
    $externalFileUrl: ID
    $position: Int!
    $documentGroupId: ID!
  ) {
    updateDocument(
      id: $id
      name: $name
      externalFile: $externalFile
      externalFileUrl: $externalFileUrl
      position: $position
      documentGroupId: $documentGroupId
    ) {
      ...DocumentEntity
    }
  }
  ${DOCUMENT_ENTITY_FRAGMENT}
`;

export const DELETE_DOCUMENT_MUTATION = gql`
  mutation deleteDocumentMutation($id: ID!) {
    deleteDocument(id: $id) {
      id
    }
  }
`;

export const NEW_DOCUMENT_SUBSCRIPTION = gql`
  subscription documentSubscription {
    newDocument {
      ...DocumentEntity
    }
  }
  ${DOCUMENT_ENTITY_FRAGMENT}
`;

export const CREATE_PROCEDURE_MUTATION = gql`
  mutation createProcedureMutation(
    $name: String!
    $externalFile: ID
    $position: Int!
    $procedureGroupId: ID!
  ) {
    createProcedure(
      name: $name
      externalFile: $externalFile
      position: $position
      procedureGroupId: $procedureGroupId
    ) {
      ...ProcedureEntity
    }
  }
  ${PROCEDURE_ENTITY_FRAGMENT}
`;

export const NEW_PROCEDURE_SUBSCRIPTION = gql`
  subscription procedureSubscription {
    newProcedure {
      ...ProcedureEntity
    }
  }
  ${PROCEDURE_ENTITY_FRAGMENT}
`;

export const CREATE_HAZARD_ASSESSMENT_MUTATION = gql`
  mutation createHazardAssessmentMutation(
    $name: String!
    $externalFile: ID
    $position: Int!
    $hazardCategoryId: ID!
  ) {
    createHazardAssessment(
      name: $name
      externalFile: $externalFile
      position: $position
      hazardCategoryId: $hazardCategoryId
    ) {
      ...HazardAssessmentEntity
    }
  }
  ${HAZARD_ASSESSMENT_ENTITY_FRAGMENT}
`;

export const NEW_HAZARD_ASSESSMENT_SUBSCRIPTION = gql`
  subscription hazardAssessmentSubscription {
    newHazardAssessment {
      ...HazardAssessmentEntity
    }
  }
  ${HAZARD_ASSESSMENT_ENTITY_FRAGMENT}
`;

export const CREATE_HAZARD_MUTATION = gql`
  mutation createHazardMutation(
    $name: String!
    $position: Int!
    $hazardCategoryId: ID!
  ) {
    createHazard(
      name: $name
      position: $position
      hazardCategoryId: $hazardCategoryId
    ) {
      name
      position
    }
  }
`;

export const NEW_HAZARD_SUBSCRIPTION = gql`
  subscription hazardSubscription {
    newHazard {
      name
      position
    }
  }
`;
