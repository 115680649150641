<template>
  <v-radio-group
    v-model="value"
    :disabled="disabled"
    @change="$emit('change', $event)"
    :rules="rules"
  >
    <v-radio
      v-for="item in items"
      :key="item.value"
      :label="item.text"
      :value="item.text"
      :disabled="disabled"
    />
    <v-radio
      v-if="allowOther"
      :value="otherValue.text"
      @click="$refs[`radioGroup_OtherText_${id}`].focus()"
    >
      <template v-slot:label>
        <v-text-field
          class="pa-0 ma-0"
          placeholder="Other"
          v-model="otherValue.text"
          :disabled="disabled"
          @change="$emit('change', $event)"
          single-line
          :ref="`radioGroup_OtherText_${id}`"
        />
      </template>
    </v-radio>
  </v-radio-group>
</template>
<script>
export default {
  name: "SelectOneRadioGroup",

  props: {
    id: String,
    items: Array,
    initialValue: [String, Number],
    allowOther: Boolean,
    disabled: Boolean,
    rules: Array
  },
  data() {
    return {
      value: this.initialValue,
      otherValue: {
        value: this.items.length,
        text:
          this.allowOther &&
          this.items.findIndex(x => x.text === this.initialValue) < 0
            ? this.initialValue
            : null
      }
    };
  },
  watch: {
    initialValue: {
      immediate: true,
      deep: true,
      handler: function(val) {
        const list = [...this.items, this.otherValue];
        const item =
          typeof val === "number" ? list[val] : list.find(x => x.text === val);
        const retval = item ? item.text : null;
        this.value = retval;
      }
    }
  }
};
</script>
