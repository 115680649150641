<template>
  <preview-input class="preview-input-any-file" :disableGrid="true">
    <preview-input-name v-if="name">{{ name }}</preview-input-name>

    <ul class="files" v-if="files && files.length > 0">
      <preview-input-value
        v-for="(item, index) in files"
        :key="item.blobUrl"
        :as="'li'"
      >
        <a
          class="files-anchor"
          :href="item.downloadUrl"
          target="_blank"
          :download="
            getFileName({ file: item, position: index, withExtension: true })
          "
        >
          <span class="file-name">
            {{ getFileName({ file: item, position: index }) }}
          </span>
          <v-icon class="files-icon" v-if="getFileIcon(item.type)" small>
            {{ getFileIcon(item.type) }}
          </v-icon>
        </a>
      </preview-input-value>
    </ul>
    <preview-input-value>&mdash;</preview-input-value>
  </preview-input>
</template>

<script>
import utils from "@/shared/Utils";
import getEnv from "@/utilities/env.js";

import PreviewInputName from "./preview-input-name.vue";
import PreviewInputValue from "./preview-input-value.vue";
import PreviewInput from "./preview-input.vue";

export default {
  name: "PreviewInputAnyFile",
  props: {
    name: String,
    value: Array,
    fileNamingFunction: Function
  },
  data: () => ({
    files: null
  }),
  components: {
    PreviewInputName,
    PreviewInputValue,
    PreviewInput
  },
  created() {
    this.updateFiles(this.value);
  },
  computed: {
    isOnline() {
      return (
        this.$store.getters.getConnected &&
        this.$store.getters.getServerActivity
      );
    }
  },
  watch: {
    value(newFiles) {
      this.updateFiles(newFiles);
    },
    isOnline() {
      this.updateFiles(this.value);
    }
  },
  methods: {
    // TODO: this needs more testing after integrating with cache files
    async getDownloadUrlFromCache({ blobUrl, type }) {
      const cached = await utils.getCachedData(
        "tailboard-submissions-files",
        blobUrl
      );

      if (!cached) return null;

      const bodyBlob = await cached.blob();
      const blobResult = new Blob(bodyBlob, { type });

      return URL.createObjectURL(blobResult);
    },
    async updateFiles(files) {
      if (!files || (files instanceof Array && files.length <= 0)) {
        this.files = [];
        return;
      }

      let allFiles = [];

      for (let file of files) {
        const downloadUrl = this.isOnline
          ? `${getEnv("VUE_APP_SERVER_URL")}/doc/${file.blobUrl}`
          : await this.getDownloadUrlFromCache(file);

        allFiles.push({
          ...file,
          downloadUrl
        });
      }

      this.files = allFiles;
    },
    getFileName({ file, position, withExtension }) {
      let fileName = this.fileNamingFunction
        ? this.fileNamingFunction({ file, position, withExtension })
        : file.name;

      if (withExtension) fileName += `.${file.type.split("/").pop()}`;

      return fileName;
    },
    getFileIcon(type) {
      // NOTE: add icon types as required
      if (type.includes("image")) return "image";
      if (type.includes("pdf")) return "picture_as_pdf";
      if (type.includes("audio")) return "volume_up";

      return null;
    }
  }
};
</script>

<style scoped>
.files {
  page-break-inside: avoid;
  list-style: none;
  margin: 0 0 4px 0;
  padding: 0;
}

.files-anchor {
  align-items: center;
  display: flex;
  gap: 4px;
  text-decoration: none;
}

.files-anchor[href] .file-name {
  text-decoration: underline;
}
</style>
