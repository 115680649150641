<template>
  <div>
    <v-form v-model="isValid">
      <v-row>
        <v-expansion-panels
          multiple
          :disabled="disabled"
          flat
          v-model="activePanels"
        >
          <v-expansion-panel
            expand
            v-for="(feeder, i) in cableChamberFeeders"
            :key="i"
          >
            <v-expansion-panel-header>
              <template v-slot:default="{ open }">
                <v-row no-gutters>
                  <p class="font-weight-bold text-h6">
                    Condition monitoring set
                  </p>
                  <span v-if="!open && feeder.feederDesignation">{{
                    `${feeder.feederDesignation}`
                  }}</span>
                  <v-divider class="ml-3 mt-4" />
                  <v-btn
                    @click.stop="removeFeeder(i)"
                    :disabled="disabled"
                    v-if="i !== 0"
                    class="remove-btn"
                    small
                    text
                  >
                    <v-icon class="mr-2">delete_outline</v-icon>
                    Remove
                  </v-btn>
                </v-row>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="pl-4">
                <v-row>
                  <label>Feeder Designation</label>
                  <combobox
                    source="feeders"
                    :initialValue="feeder.feederDesignation"
                    :disabled="disabled"
                    :rules="[rules.required]"
                    @change="feeder.feederDesignation = $event"
                  />
                </v-row>
                <v-row>
                  <template v-for="(hazard, idx) in getHazardItems(feeder)">
                    <div
                      :key="'col-' + idx"
                      class="hazard-item"
                      :class="[
                        hazard.selected
                          ? 'div-border-active'
                          : 'div-border-normal'
                      ]"
                    >
                      <v-checkbox
                        dense
                        class="pa-0 ma-0 pt-3 pl-2"
                        style="width: 100%"
                        :disabled="disabled"
                        :id="'hazardCheck-' + idx"
                        :label="hazard.name"
                        v-model="hazard.selected"
                        @change="onSelectHazard($event, feeder, hazard.name)"
                      />
                    </div>
                    <v-responsive
                      v-if="(idx + 1) % 3 == 0"
                      :key="idx"
                      width="100%"
                    />
                  </template>
                </v-row>
                <v-row class="mt-10">
                  <v-col class="pr-15">
                    <label>Duct #</label>
                    <v-text-field
                      :disabled="disabled"
                      class="mt-2"
                      counter="255"
                      maxlength="255"
                      :rules="[rules.required]"
                      outlined
                      v-model="feeder.ductNumber"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <label>Direction</label>
                    <v-radio-group
                      v-model="feeder.direction"
                      :disabled="disabled"
                      class="pa-0 ma-0 pt-1"
                    >
                      <v-row>
                        <template
                          v-for="(dirction, idx) in getDirctions(feeder)"
                        >
                          <div
                            :key="'col-' + idx"
                            class="direction-item"
                            :class="[
                              dirction.selected
                                ? 'div-border-active'
                                : dirction.name
                                ? 'div-border-normal'
                                : ''
                            ]"
                          >
                            <v-radio
                              v-if="dirction.name"
                              dense
                              class="pa-2"
                              style="width: 100%"
                              :disabled="disabled"
                              :id="'dirctionRadio-' + idx"
                              :label="dirction.name"
                              :value="dirction.name"
                            />
                            <v-spacer v-else></v-spacer>
                          </div>
                          <v-responsive
                            v-if="(idx + 1) % 3 == 0"
                            :key="idx"
                            width="100%"
                          />
                        </template>
                      </v-row>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <label>Temperature</label>
                <v-row>
                  <v-col>
                    <p>Max. Temp</p>
                    <v-text-field
                      type="number"
                      :rules="[rules.required]"
                      outlined
                      :disabled="disabled"
                      v-model.number="feeder.maxTemp"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <p>Spot. Temp(c)</p>
                    <v-text-field
                      type="number"
                      :rules="[rules.required]"
                      outlined
                      :disabled="disabled"
                      v-model.number="feeder.spotTemp"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <p>ΔS:</p>
                    <v-text-field
                      type="number"
                      :rules="[rules.required]"
                      outlined
                      :disabled="disabled"
                      v-model.number="feeder.deltaS"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <p>Comment on Hazard Identified</p>
                <v-textarea
                  rows="1"
                  auto-grow
                  outlined
                  :disabled="disabled"
                  v-model="feeder.comments"
                  :rules="[rules.required]"
                ></v-textarea>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
      <v-row class="mt-5 mr-5"
        ><v-spacer></v-spacer
        ><v-btn
          :disabled="disabled"
          @click.stop="addNewFeeder"
          class="primary pa-5"
          ><v-icon class="mr-2">add_circle_outline</v-icon> Add Another
          Feeder</v-btn
        ></v-row
      >
    </v-form>
  </div>
</template>
<script>
import utils from "@/shared/Utils";
import combobox from "@/components/submission/combobox.vue";
export default {
  name: "CableChamberFeeder",
  components: {
    combobox
  },
  props: {
    disabled: Boolean,
    id: String,
    cableChamberFeederData: Array,
    sectionId: String
  },
  data() {
    return {
      isValid: false,
      rules: {
        required: value => {
          return !this.disabled ? !!value || "Required." : true;
        },

        min: value => {
          return !this.disabled && value ? value >= 0 || `Minimum is 0` : true;
        }
      }
    };
  },
  watch: {
    cableChamberFeeders(newValue, oldValue) {
      if (
        oldValue[0].hasOwnProperty("id") && // eslint-disable-line
        newValue[0].hasOwnProperty("id") // eslint-disable-line
      ) {
        this.$emit("change", true);
      }
    }
  },
  computed: {
    cableChamberFeeders() {
      return this.cableChamberFeederData;
    },
    activePanels: {
      get: function() {
        return this.cableChamberFeeders.map((c, i) => i);
      },
      set: function() {}
    },
    getHazardItems() {
      let hazards = [
        "Leekers",
        "Needs Patch",
        "Needs Piece Out",
        "Bonding",
        "Joint Collapse",
        "Joint Swollen",
        "Asbestos",
        "Racking",
        "Spare (remove)",
        "Replace Cable",
        "Sleeves",
        "Working Deadend (remove)",
        "XLPE",
        "PILC"
      ];
      return feeder =>
        hazards.map(h => {
          return {
            name: h,
            selected: feeder.hazards ? feeder.hazards.includes(h) : false
          };
        });
    },
    getDirctions() {
      let dirctions = [
        "North West",
        "North",
        "North East",
        "West",
        null,
        "East",
        "South West",
        "South",
        "South East"
      ];
      return feeder =>
        dirctions.map(d => {
          return { name: d, selected: feeder.direction === d };
        });
    }
  },
  methods: {
    getValidationResult() {
      return [this.isValid ? 1 : 0, 1];
    },
    onSelectHazard(selected, feeder, hazard) {
      if (selected) {
        if (!feeder.hazards || !feeder.hazards.includes(hazard))
          feeder.hazards.push(hazard);
      } else {
        feeder.hazards.splice(feeder.hazards.indexOf(hazard), 1);
      }
    },
    removeFeeder(index) {
      this.cableChamberFeeders.splice(index, 1);
    },
    addNewFeeder() {
      this.cableChamberFeeders.push({
        id: utils.getUUID(),
        component_id: this.id,
        hazards: [],
        direction: "",
        section_id: this.sectionId
      });
    }
  }
};
</script>
<style scoped>
span {
  font-style: italic !important;
  margin: 10px !important;
}
p {
  padding: 0 !important;
  margin: 0 !important;
  font-size: medium;
}
label {
  color: var(--v-primary-base) !important;
  font-weight: bold !important;
  font-size: large !important;
  width: 100%;
  margin-bottom: 5px;
}
.hazard-item,
.direction-item {
  height: auto;
  margin: 3px;
}

.hazard-item {
  width: 32.4%;
}

.direction-item {
  width: 30%;
}

.div-border-active {
  background-color: var(--v-light-base);
  border: 0.8px solid var(--v-primary-base);
}

.div-border-normal {
  border: 0.8px solid var(--v-border-base);
}
</style>
