<template>
  <div class="preview-container">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "Preview"
};
</script>
<style scoped>
.preview-container {
  position: relative;
}
</style>
