import gql from "graphql-tag";
import {
  WORK_ORDER_FRAGMENT,
  VEHICLE_FRAGMENT
} from "@/graphql/external/fragments.js";

export const WORK_ORDERS_QUERY = gql`
  query workOrders($inUse: Boolean, $searchTerm: String, $limit: Int) {
    workOrders(inUse: $inUse, searchTerm: $searchTerm, limit: $limit) {
      successful
      result {
        ...WorkOrderEntity
      }
    }
  }
  ${WORK_ORDER_FRAGMENT}
`;

export const VEHICLES_QUERY = gql`
  query vehicles {
    vehicles {
      successful
      result {
        ...VehicleEntity
      }
    }
  }
  ${VEHICLE_FRAGMENT}
`;

export const FEEDERS_QUERY = gql`
  query feeders {
    feeders {
      successful
      result {
        id
        externalRef
      }
    }
  }
`;

export const STRUCTURES_QUERY = gql`
  query structures {
    structures {
      successful
      result {
        id
        externalRef
      }
    }
  }
`;
