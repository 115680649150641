<template>
  <div
    v-if="submission.riskAssessment"
    class="preview-component-risk-assessment"
  >
    <preview-form-component v-if="submission.riskAssessment.workType">
      <preview-form-title :level="3">Work Type</preview-form-title>

      <preview-form-group>
        <preview-input-string
          :name="'Work Type'"
          :value="submission.riskAssessment.workType.name"
        />
      </preview-form-group>
    </preview-form-component>

    <preview-form-component>
      <preview-form-title :level="3">Job Steps</preview-form-title>

      <ul class="job-steps-list">
        <preview-form-group
          class="job-steps-item"
          :as="'li'"
          v-for="step in steps"
          :key="step.id"
        >
          <preview-input-name class="job-steps-step"
            >{{ step.step }}. {{ step.name }}</preview-input-name
          >
          <template v-if="step.employeeIds">
            <preview-input-name>
              <span class="job-steps-title">Employees:</span>
              <span v-if="!step.employeeIds || step.employeeIds.length <= 0"
                >&mdash;</span
              >
            </preview-input-name>

            <ul v-if="step.employeeIds && step.employeeIds.length > 0">
              <li v-for="id in step.employeeIds" :key="id">
                <preview-input-employee :value="id" />
              </li>
            </ul>
          </template>

          <template
            v-if="step.jobStepHazards && step.jobStepHazards.length > 0"
          >
            <!-- <preview-input-name class="job-steps-title">Hazards:</preview-input-name> -->

            <ul>
              <li v-for="hazard in step.jobStepHazards" :key="hazard.id">
                <preview-input-name>
                  <span class="job-steps-title">Hazard:</span>
                  {{ hazard.hazardId ? getHazardName(hazard.hazardId) : "—" }}
                </preview-input-name>

                <preview-input-name>
                  <span class="job-steps-title">Source:</span>
                  {{ hazard.source || "—" }}
                </preview-input-name>

                <preview-input-name>
                  <span class="job-steps-title">Barriers:</span>
                  {{
                    hazard.barriers && hazard.barriers.length > 0
                      ? hazard.barriers.join(", ")
                      : "—"
                  }}
                </preview-input-name>
              </li>
            </ul>
          </template>
        </preview-form-group>
      </ul>
    </preview-form-component>
  </div>
</template>

<script>
import PreviewFormComponent from "./preview-form-component.vue";
import PreviewFormGroup from "./preview-form-group.vue";
import PreviewFormTitle from "./preview-form-title.vue";
import PreviewInputEmployee from "./preview-input-employee.vue";
import PreviewInputName from "./preview-input-name.vue";
import PreviewInputString from "./preview-input-string.vue";

export default {
  name: "PreviewComponentRiskAssessment",
  props: {
    submission: Object
  },
  components: {
    PreviewFormComponent,
    PreviewFormTitle,
    PreviewFormGroup,
    PreviewInputString,
    PreviewInputEmployee,
    PreviewInputName
  },
  computed: {
    steps() {
      if (!this.submission.riskAssessment) return [];

      return this.submission.riskAssessment.jobSteps
        .concat()
        .sort((a, b) => a.step - b.step);
    }
  },
  methods: {
    getHazardName(hazardId) {
      const hazard = this.$store.getters.getHazards.find(
        ({ id }) => id === hazardId
      );
      if (!hazard) return "";

      return hazard.name;
    }
  }
};
</script>

<style scoped>
.job-steps-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.job-steps-title,
.job-steps-step {
  font-weight: 700;
}

.job-steps-item {
  page-break-inside: avoid;
  margin-bottom: 8px;
}
</style>
