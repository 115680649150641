<template>
  <v-autocomplete
    outlined
    attach
    return-object
    v-model="selected"
    :id="id"
    :placeholder="placeholder"
    :disabled="disabled"
    :items="employeeItems"
    item-text="text"
    item-value="value"
    :search-input.sync="search"
    :clearable="true"
    chips
    small-chips
    :multiple="multiple"
    :rules="rules"
    @change="onChange"
    :menu-props="{
      allowOverflow: false,
      offsetY: true,
      closeOnClick: true
    }"
  ></v-autocomplete>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "EmployeeAutocomplete",

  props: {
    populateProperty: String,
    id: String,
    placeholder: String,
    disabled: Boolean,
    multiple: Boolean,
    rules: Array,
    initialValue: String,
    crewMembers: Array
  },

  data() {
    return {
      selected: null,
      search: null
    };
  },
  watch: {
    watch: {
      search: {
        immediate: true,
        handler(value) {
          if (!value) return;

          const searchEmployee = value.toUpperCase();
          let employees = this.hasCrewMembers
            ? this.crewMembers
            : this.$store.getters.getEmployees;

          this.employeeItems = employees
            .filter(
              ({ employee }) =>
                employee.firstName.toUpperCase().includes(searchEmployee) ||
                employee.lastName.toUpperCase().includes(searchEmployee)
            )
            .slice(0, 50)
            .map(({ employee }) => this.toItem(employee));
        }
      }
    },
    initialValue: {
      immediate: true,
      deep: true,
      handler: function() {
        this.updateBySelectedEmployee(this.employeeItems);
      }
    },
    employeeItems: {
      immediate: true,
      deep: true,
      handler: function(value) {
        this.updateBySelectedEmployee(value);
      }
    },
    crewMembers: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (Array.isArray(this.selected) && newValue) {
          newValue.forEach(crewMember => {
            this.selected = this.selected.filter(
              s => s.value !== crewMember.id
            );
          });
        }
      }
    }
  },
  computed: {
    employeeItems: {
      get: function() {
        if (this.hasCrewMembers) {
          return this.crewMembers.map(member => this.toItem(member.employee));
        }

        return this.$store.getters.getEmployees.map(this.toItem);
      },
      set: function() {}
    },
    initialEmployeeId() {
      switch (this.initialValue) {
        case "":
          return [];
        case "CURRENT_USER":
          return [this.currentEmployeeId];
        case "CURRENT_USER_SUPERVISOR":
          return [this.currentSupervisorEmployeeId];
        default:
          return this.multiple && this.initialValue
            ? this.initialValue.split(",")
            : [this.initialValue];
      }
    },

    currentEmployeeId() {
      return this.user && this.user.employee ? this.user.employee.id : null;
    },

    currentSupervisorEmployeeId() {
      return this.user && this.user.employee && this.user.employee.supervisor
        ? this.user.employee.supervisor.id
        : null;
    },

    crewRef() {
      return this.user && this.user.employee
        ? this.user.employee.crewRef
        : null;
    },
    hasCrewMembers() {
      return this.crewMembers && this.crewMembers.length > 0;
    },

    ...mapState(["user"])
  },

  methods: {
    updateBySelectedEmployee(employeesList) {
      const employees = employeesList.filter(e =>
        this.initialEmployeeId.includes(e.value)
      );
      if (employees.length > 0) {
        if (this.multiple) {
          this.selected = employees;
          this.$emit("change", employees.map(s => s.value).join(","));

          if (this.populateProperty) {
            const toPopulate = employees
              .map(s => s[this.populateProperty])
              .join(",");

            if (toPopulate && toPopulate.length > 0)
              this.$emit("populate", toPopulate);
          }
        } else {
          this.selected = employees[0];
          this.$emit("change", employees[0].value);

          if (this.populateProperty) {
            const toPopulate = this.selected[this.populateProperty];

            if (toPopulate) this.$emit("populate", toPopulate);
          }
        }
      }
    },
    toItem({ id, firstName, lastName, ...e }) {
      return {
        value: id,
        text: `${firstName} ${lastName}`,
        [this.populateProperty]: this.populateProperty
          ? e[this.populateProperty]
          : null
      };
    },
    onChange() {
      if (this.selected) {
        const result = this.multiple
          ? this.selected.map(s => s.value).join(",")
          : this.selected.value;

        this.search = null;
        this.$emit("change", result);

        if (this.populateProperty) {
          const toPopulate = this.multiple
            ? this.selected.map(s => s[this.populateProperty]).join(",")
            : this.selected[this.populateProperty];

          this.$emit("populate", toPopulate);
        }
      } else {
        this.$emit("change", null);
        if (this.populateProperty) this.$emit("populate", null);

        this.$nextTick(() => {
          this.selected = null;
          this.search = null;
        });
      }
    }
  }
};
</script>
