import gql from "graphql-tag";
import { CRITICAL_TASKS_ENTITY_FRAGMENT } from "@/graphql/criticaltasks/fragments.js";

export const CREATE_CRITICAL_TASK_MUTATION = gql`
  mutation createCriticalTaskMutation($name: String!, $position: Int!) {
    createCriticalTask(name: $name, position: $position) {
      messages {
        field
        code
        message
      }
      result {
        ...CriticalTasksEntity
      }
      successful
    }
  }
  ${CRITICAL_TASKS_ENTITY_FRAGMENT}
`;

export const UPDATE_CRITICAL_TASK_MUTATION = gql`
  mutation updateCriticalTaskMutation(
    $id: ID!
    $name: String!
    $position: Int!
  ) {
    updateCriticalTask(id: $id, name: $name, position: $position) {
      messages {
        field
        code
        message
      }
      result {
        ...CriticalTasksEntity
      }
      successful
    }
  }
  ${CRITICAL_TASKS_ENTITY_FRAGMENT}
`;

export const DELETE_CRITICAL_TASK_MUTATION = gql`
  mutation deleteCriticalTaskMutation($id: ID!) {
    deleteCriticalTask(id: $id) {
      messages {
        field
        code
        message
      }
      result {
        ...CriticalTasksEntity
      }
      successful
    }
  }
  ${CRITICAL_TASKS_ENTITY_FRAGMENT}
`;
