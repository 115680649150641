<template>
  <div class="model-main justify-content-center">
    <div class="model-header">
      <h2>Hazards</h2>
      <form-modal
        v-if="
          display == 'index' && (user.role == 'ADMIN' || user.role == 'SAFETY')
        "
        :fields="fields"
        :title="mutationLabel"
        :mutation="mutation"
        :value="{}"
      />
      <v-btn v-if="display == 'show'" @click="display = 'index'">Back</v-btn>
    </div>
    <v-card>
      <v-tabs
        v-model="currentTab"
        @change="updateMutation($event)"
        :hide-slider="display != 'index'"
      >
        <v-tab v-if="display == 'index'" ripple>Hazards</v-tab>
        <v-tab v-if="display == 'index'" ripple>Hazard Assessments</v-tab>
        <v-tab
          v-if="
            display == 'index' &&
              (user.role == 'ADMIN' || user.role == 'SAFETY')
          "
          ripple
          >Hazard Categories</v-tab
        >
        <v-tab-item>
          <custom-table
            :columns="hazardHeaders"
            :data="hazards"
            :show="display == 'show'"
            @show="fetchPDF"
          />
        </v-tab-item>
        <v-tab-item>
          <custom-table
            :columns="hazardHeaders"
            :data="hazardAssessments"
            :show="display == 'show'"
            @show="display = 'show'"
          />
        </v-tab-item>
        <v-tab-item v-if="user.role == 'ADMIN' || user.role == 'SAFETY'">
          <custom-table
            :columns="categoryHeaders"
            :data="hazardCategories"
            :show="display == 'show'"
            @show="display = 'show'"
          />
        </v-tab-item>
      </v-tabs>
    </v-card>
  </div>
</template>

<script>
import {
  HAZARDS_QUERY,
  HAZARD_ASSESSMENTS_QUERY,
  HAZARD_CATEGORIES_QUERY,
  CREATE_HAZARD_MUTATION,
  CREATE_HAZARD_ASSESSMENT_MUTATION,
  CREATE_HAZARD_CATEGORY_MUTATION,
  NEW_HAZARD_SUBSCRIPTION,
  NEW_HAZARD_ASSESSMENT_SUBSCRIPTION,
  NEW_HAZARD_CATEGORY_SUBSCRIPTION
} from "../constants/section-queries";
import formModal from "./form-modal.vue";
import customTable from "./custom-table.vue";

export default {
  name: "Hazards",
  components: { formModal, customTable },
  data() {
    return {
      display: "index",
      currentTab: 0,
      mutation: CREATE_HAZARD_MUTATION,
      mutationLabel: "New Hazard",
      fields: [],
      categoryFields: [
        { label: "Name", name: "name", type: "text" },
        { label: "Description", name: "description", type: "text" },
        { label: "Position", name: "position", type: "number" },
        {
          label: "Sort",
          name: "sort",
          type: "select",
          options: [
            { value: "NAME", text: "Name" },
            { value: "POSITION", text: "Position" }
          ]
        }
      ],
      hazardFields: [
        { label: "Name", name: "name", type: "text" },
        { label: "Position", name: "position", type: "number" }
      ],
      hazardAssessmentFields: [
        { label: "Name", name: "name", type: "text" },
        { label: "External File", name: "externalFile", type: "text" },
        { label: "Position", name: "position", type: "number" }
      ],
      categoryHeaders: [
        { value: "name", text: "Name", sortable: true },
        { value: "description", text: "Description", sortable: false },
        { value: "sort", text: "Sort", sortable: false },
        { value: "position", text: "Position", sortable: true },
        { value: "action", text: "Actions", sortable: false }
      ],
      hazardHeaders: [
        { value: "name", text: "Name", sortable: true },
        { value: "position", text: "Position", sortable: true },
        { value: "action", text: "Actions", sortable: false }
      ]
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    }
  },
  methods: {
    updateMutation(val) {
      if (val === 2) {
        this.mutation = CREATE_HAZARD_CATEGORY_MUTATION;
        this.mutationLabel = "New Hazard Category";
        this.fields = this.categoryFields;
      } else if (val === 1) {
        this.mutation = CREATE_HAZARD_ASSESSMENT_MUTATION;
        this.mutationLabel = "New Hazard Assessment";
        this.fields = this.hazardAssessmentFields;
      } else {
        this.mutation = CREATE_HAZARD_MUTATION;
        this.mutationLabel = "New Hazard";
        this.fields = this.hazardFields;
      }
    },
    fetchPDF(document) {
      window.open("/doc/" + document.externalFile, "_blank");
    }
  },
  apollo: {
    hazardCategories: {
      query: HAZARD_CATEGORIES_QUERY,
      fetchPolicy: "cache-and-network",
      update(data) {
        const field = {
          label: "Hazard Category",
          name: "hazardCategoryId",
          type: "select",
          options: data.hazardCategories.map(x => {
            return { value: x.id, text: x.name };
          })
        };
        this.hazardFields[2] = field;
        this.hazardAssessmentFields[3] = field;
        return data.hazardCategories;
      },
      subscribeToMore: [
        {
          document: NEW_HAZARD_CATEGORY_SUBSCRIPTION,
          updateQuery: (previous, { subscriptionData }) => {
            if (!subscriptionData.data.newHazardCategory) return;
            const newHazardCategory = subscriptionData.data.newHazardCategory;

            return {
              hazardCategories: [
                ...previous.hazardCategories,
                newHazardCategory
              ]
            };
          }
        }
      ]
    },
    hazards: {
      query: HAZARDS_QUERY,
      fetchPolicy: "cache-and-network",
      subscribeToMore: [
        {
          document: NEW_HAZARD_SUBSCRIPTION,
          updateQuery: (previous, { subscriptionData }) => {
            if (!subscriptionData.data.newHazard) return;
            const newHazard = subscriptionData.data.newHazard;

            return {
              hazards: [...previous.hazards, newHazard]
            };
          }
        }
      ]
    },
    hazardAssessments: {
      query: HAZARD_ASSESSMENTS_QUERY,
      fetchPolicy: "cache-and-network",
      subscribeToMore: [
        {
          document: NEW_HAZARD_ASSESSMENT_SUBSCRIPTION,
          updateQuery: (previous, { subscriptionData }) => {
            if (!subscriptionData.data.newHazardAssessment) return;
            const newHazardAssessment =
              subscriptionData.data.newHazardAssessment;

            return {
              hazardAssessments: [
                ...previous.hazardAssessments,
                newHazardAssessment
              ]
            };
          }
        }
      ]
    }
  }
};
</script>
