export default function getEnv(name) {
  if (
    window.configuration &&
    window.configuration[name] &&
    window.configuration[name] != "" &&
    window.configuration[name] != "undefined"
  ) {
    return window.configuration[name];
  } else {
    return process.env[name];
  }
}
