<template>
  <div class="model-main justify-content-center">
    <div class="model-header">
      <h2>Critical Tasks</h2>
      <form-modal
        v-if="display == 'index'"
        :fields="fields"
        title="New Critical Task"
        :mutation="mutation"
        :show="showData"
        :value="values"
        @success="closeForm"
        @close="closeForm"
      />
      <!-- <v-btn v-if="display == 'show'" @click="display = 'index'">Back</v-btn> -->
    </div>
    <v-card>
      <v-tabs v-model="currentTab">
        <v-tab ripple>Critical Tasks</v-tab>
        <v-tab-item>
          <custom-table
            :columns="hazardHeaders"
            :data="criticalTasks"
            displayText="Are you sure you would like to delete this Critical task?"
            @show="display = 'show'"
            @delete="deleteCriticalTask"
            @edit="editCriticalTask"
          />
        </v-tab-item>
      </v-tabs>
    </v-card>
  </div>
</template>

<script>
import {
  CRITICAL_TASKS_QUERY,
  NEW_CRITICAL_TASK_SUBSCRIPTION
} from "@/graphql/criticaltasks/queries.js";
import {
  CREATE_CRITICAL_TASK_MUTATION,
  UPDATE_CRITICAL_TASK_MUTATION,
  DELETE_CRITICAL_TASK_MUTATION
} from "@/graphql/criticaltasks/mutations.js";
import formModal from "./form-modal.vue";
import customTable from "./custom-table.vue";
import appsignal from "@/plugins/appsignal";

export default {
  name: "Criticals",
  components: { formModal, customTable },
  data() {
    return {
      values: {},
      showData: false,
      display: "index",
      currentTab: 0,
      mutation: CREATE_CRITICAL_TASK_MUTATION,
      fields: [
        { label: "Name", name: "name", type: "text" },
        { label: "Position", name: "position", type: "number" }
      ],

      hazardHeaders: [
        { value: "name", text: "Name", sortable: true },
        { value: "position", text: "Position", sortable: true },
        { value: ["edit", "delete"], text: "Actions", sortable: false }
      ]
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    }
  },
  methods: {
    closeForm() {
      this.mutation = CREATE_CRITICAL_TASK_MUTATION;
      this.values = {};
      this.showData = false;
    },
    editCriticalTask(item) {
      this.mutation = UPDATE_CRITICAL_TASK_MUTATION;
      this.values = item;
      this.showData = true;
    },
    async delete(mutation, id, callback) {
      try {
        const result = await this.$apollo.mutate({
          mutation,
          variables: { id: id }
        });
        callback(result);
      } catch (error) {
        appsignal.sendError(error);
      }
    },
    async deleteCriticalTask(item) {
      const callback = result => {
        this.criticalTasks.splice(
          this.criticalTasks.findIndex(
            i => i.id === result.data.deleteCriticalTask.id
          ),
          1
        );
      };
      await this.delete(DELETE_CRITICAL_TASK_MUTATION, item.id, callback);
    }
  },

  apollo: {
    criticalTasks: {
      query: CRITICAL_TASKS_QUERY,
      fetchPolicy: "cache-and-network",
      subscribeToMore: [
        {
          document: NEW_CRITICAL_TASK_SUBSCRIPTION,
          updateQuery: (previous, { subscriptionData }) => {
            if (!subscriptionData.data.newCriticalTask) return;
            const newCriticalTask = subscriptionData.data.newCriticalTask;
            return {
              criticalTasks: [...previous.criticalTasks, newCriticalTask]
            };
          }
        }
      ]
    }
  }
};
</script>
