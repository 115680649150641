import gql from "graphql-tag";

const TRAFFIC_ASSET_FRAGMENT = gql`
  fragment TrafficAssetEntity on TrafficAssetsPayload {
    result {
      id
      name
      file
      type
    }
  }
`;

export const TRAFFIC_ASSETS_QUERY = gql`
  query trafficAssets {
    trafficAssets(type: null) {
      ...TrafficAssetEntity
    }
  }
  ${TRAFFIC_ASSET_FRAGMENT}
`;
