<template>
  <preview-input-string
    :name="name"
    :value="field.description"
    :lineBreak="'long'"
  />
</template>

<script>
import PreviewInputString from "./preview-input-string.vue";

export default {
  name: "PreviewInputStaticText",
  props: {
    field: Object,
    name: String
  },
  components: { PreviewInputString }
};
</script>
