<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="50"
    viewBox="0 0 50 50"
  >
    <g
      fill="none"
      fill-rule="evenodd"
      stroke="#005d55"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    >
      <path
        d="M30.978 29H32.5S37 28.413 37 24a4.991 4.991 0 0 0-5.18-4.99A6.991 6.991 0 0 0 25.5 15a6.993 6.993 0 0 0-6.98 6.58A3.75 3.75 0 0 0 14 25.25c0 3.808 4 3.75 4 3.75h1.993M25 35V24M22 32l3 3 3-3"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Download"
};
</script>
