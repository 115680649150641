<template>
  <preview-input-signature :value="value" :name="name" />
</template>

<script>
import PreviewInputSignature from "./preview-input-signature.vue";

export default {
  name: "PreviewInputDrawing",
  props: {
    value: String,
    name: String
  },
  components: {
    PreviewInputSignature
  }
};
</script>
