<template>
  <v-dialog
    v-model="commentDialogShow"
    persistent
    max-width="512px"
    max-height="438px"
  >
    <v-card>
      <v-card-title>
        <span class="headline">View Comment</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <p>
            {{ review.user }} -
            {{ review.insertedAt | parseDate }}
          </p>
          <div class="comment-style">
            {{ review.comment }}
          </div>
        </v-container>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="cancelAction"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import dayjs from "@/plugins/dayjs";
export default {
  name: "CommentDialog",
  props: {
    commentDialogShow: Boolean,
    review: Object,
    cancelAction: { type: Function }
  },
  filters: {
    parseDate(val) {
      return dayjs
        .utc(val)
        .local()
        .format("YYYY-MM-DD, HH:mm");
    }
  }
};
</script>
