import gql from "graphql-tag";

export const EMPLOYEE_ENTITY_FRAGMENT = gql`
  fragment EmployeeEntity on Employee {
    id
    accountRef
    crewName
    crewRef
    departmentName
    departmentRef
    email
    employeeRef
    firstName
    groupName
    groupRef
    lastName
    level
    rcNumber
    phoneNumber
    supervisorEmployeeRef
    title
  }
`;

export const USER_ENTITY_FRAGMENT = gql`
  fragment UserEntity on User {
    id
    name
    email
    role
    employee {
      ...EmployeeEntity
    }
  }
  ${EMPLOYEE_ENTITY_FRAGMENT}
`;
