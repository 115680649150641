<template>
  <preview-input-string :name="name" :value="formattedDate" />
</template>

<script>
import dayjs from "@/plugins/dayjs";
import PreviewInputString from "./preview-input-string.vue";

export default {
  name: "PreviewInputDate",
  props: {
    value: String,
    name: String,
    format: {
      type: String,
      default: "YYYY-MM-DD"
    }
  },
  components: { PreviewInputString },
  computed: {
    formattedDate() {
      return this.value ? dayjs(this.value).format(this.format) : null;
    }
  }
};
</script>
