<template>
  <div style="width: 100%">
    <div class="component-header my-2">
      <p>Select Work Type (Optional)</p>
      <v-spacer />
    </div>
    <div>
      <h5 class="primary--text my-5">Work Type</h5>
      <v-select
        v-model="selectedWorkType"
        :items="workTypeItems"
        item-text="name"
        :disabled="disabled"
        item-value="id"
        class="mt-4"
        ref="workTypeSelect"
        return-object
        outlined
        attach
        append-outer-icon="clear"
        @click:append-outer="confirmClearWorkType"
        @change="confirmChangeWorkType($event)"
      />
    </div>
    <div class="component-header my-2">
      <p>Job Steps</p>
      <v-spacer />
    </div>
    <v-form v-model="valid">
      <v-expansion-panels :disabled="disabled">
        <v-expansion-panel v-for="(jobStep, i) in formData" :key="i">
          <div
            draggable="true"
            @dragstart.self="dragStart($event, jobStep.step)"
            @dragend.stop="dragEnd()"
            @drop="onDrop($event, jobStep)"
            @dragover.prevent
            @dragenter.prevent
          >
            <v-expansion-panel-header>
              <span class="pl-4 text-caption font-italic">
                <b>{{ `Step ${i + 1}` }}</b>
                {{ selectedWorkType ? `${jobStep.name}` : jobStepNameText(i) }}
                <span class="patch" v-if="getRequired(jobStep) > 0">{{
                  getRequired(jobStep)
                }}</span>
              </span>
              <v-spacer />
              <v-btn
                @click.stop="removeJobStep(i)"
                :disabled="formData.length === 1"
                class="remove-btn"
                small
                text
              >
                <v-icon class="mr-2">delete_outline</v-icon>
                Remove
              </v-btn>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="8">
                  <label :for="'jobStep' + i"
                    >Job Step<span class="red--text ml-2">*</span></label
                  >
                  <v-text-field
                    :id="'jobStep' + i"
                    v-model="jobStep.name"
                    :placeholder="`Job Step ${jobStep.step}`"
                    counter="255"
                    maxlength="255"
                    :rules="rules"
                    @blur="emitChanges"
                    outlined
                    required
                    dense
                  />
                </v-col>
                <v-col cols="4">
                  <label :for="'Employees' + i"
                    >Employees <span class="red--text ml-2">*</span></label
                  >
                  <v-select
                    :id="'Employees' + i"
                    v-model="jobStep.employeeIds"
                    :items="employeeItems"
                    attach
                    outlined
                    @blur="emitChanges"
                    multiple
                    style="min-height:67px!important"
                    chips
                    dense
                  >
                    <template slot="selection" slot-scope="data">
                      <span class="pr-2 selected-barrier">
                        {{ data.item.text }}
                        <v-icon
                          small
                          @click="
                            onRemoveItem(jobStep.employeeIds, data.item.value)
                          "
                          >close</v-icon
                        ></span
                      >
                    </template>
                    <template slot="item" slot-scope="data">
                      <span class="pb-1 pt-2">{{ data.item.text }}</span>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row
                v-for="(jobStepHazard, j) in jobStep.jobStepHazards"
                :key="j"
                no-gutters
              >
                <v-col class="flex-grow-1 flex-shrink-0">
                  <v-row>
                    <v-col cols="4" class="py-0">
                      <label :for="'hazard' + j"
                        >{{ `Hazard ${j + 1}`
                        }}<span class="red--text ml-2">*</span></label
                      >
                      <v-select
                        :id="'hazard' + j"
                        :value="jobStepHazard.hazardId"
                        :items="hazardItems"
                        :rules="rules"
                        attach
                        @input="updatejobStepHazard(i, j, $event)"
                        outlined
                        required
                        dense
                      >
                        <template slot="selection" slot-scope="data">
                          <div>{{ data.item.text }}</div>
                        </template>
                        <template slot="item" slot-scope="data">
                          <span class="pb-1 pt-2">{{ data.item.text }}</span>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col cols="4" class="py-0">
                      <label :for="'source' + j">Source</label>
                      <v-text-field
                        :id="'source' + j"
                        counter="255"
                        maxlength="255"
                        @blur="emitChanges"
                        v-model="jobStepHazard.source"
                        outlined
                        dense
                      />
                    </v-col>
                    <v-col cols="4" class="py-0">
                      <label :for="'barriers' + j"
                        >Barriers<span class="red--text ml-2">*</span></label
                      >
                      <v-combobox
                        v-model="jobStepHazard.barriers"
                        :items="barrierItems(jobStepHazard.hazardId)"
                        :id="'barriers' + j"
                        attach
                        outlined
                        multiple
                        @blur="emitChanges"
                        dense
                      >
                        <template v-slot:selection="{ item }">
                          <span class="pr-2 selected-barrier"
                            >{{ item }}
                            <v-icon
                              small
                              @click="
                                onRemoveItem(jobStepHazard.barriers, item)
                              "
                              >close</v-icon
                            >
                          </span>
                        </template>
                        <template v-slot:item="{ item }">
                          <span class="pr-1 ma-1">{{ item }} </span>
                        </template>
                      </v-combobox>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col class="flex-grow-0 flex-shrink-1 pt-4">
                  <v-btn
                    @click="removeJobStepHazard(jobStep.jobStepHazards, j)"
                    icon
                    class="ml-2"
                  >
                    <v-icon>delete_outline</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn class="primary" @click.stop="newJobStepHazard(i)"
                    >Assign Hazard</v-btn
                  >
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </div>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-row class="pt-3">
        <v-col>
          <v-btn
            class="primary"
            :disabled="disabled"
            @click.stop="newJobStep({})"
            >New Job Step</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
    <dialog-message
      :showDialogMessage="showDialogMessage"
      displayCaption="Are you sure?"
      :displayText="displayText"
      :okAction="okAction"
      :cancelAction="cancelAction"
    />
  </div>
</template>
<script>
import dialogMessage from "@/components/submission/dialog-message.vue";
import utils from "@/shared/Utils";

export default {
  name: "JobSteps",

  props: {
    jobSteps: Array,
    selectedHazards: Array,
    employees: Array,
    workType: Object,
    disabled: Boolean
  },
  components: {
    dialogMessage
  },

  data() {
    return {
      valid: false,
      showDialogMessage: false,
      formData: [],
      requiredData: {},
      rules: [v => !!v || "Required"],
      selectedWorkType: null,
      proposedWorkType: null,
      clearDialog: false,
      defaultNewJobStepMessage: "New Job Step - Click to expand"
    };
  },

  computed: {
    displayText() {
      return this.clearDialog
        ? "All jobs steps will be removed if you clear the work type"
        : "All jobs steps will be replaced if you change the work type";
    },

    employeeItems() {
      return this.employees.map(this.toEmployeeItem);
    },

    barrierItems() {
      return hazardsId =>
        this.$store.getters.getBarriers
          .filter(h => hazardsId === h.hazardId)
          .map(x => x.name);
    },

    hazardItems() {
      return this.selectedHazards.map(this.toItem);
    },

    workTypeItems() {
      return this.$store.getters.getWorkTypes;
    },
    jobStepNameText: vm => index => {
      return vm.formData && vm.formData[index].name
        ? vm.formData[index].name
        : vm.defaultNewJobStepMessage;
    }
  },

  watch: {
    jobSteps: {
      immediate: true,
      deep: true,
      handler: function(val) {
        if (val && val.length > 0) {
          this.formData = val
            .map(this.toJobStepInput)
            .sort((a, b) => a.step - b.step);
        } else {
          if (this.formData.length === 0) {
            this.newJobStep();
          }
        }
        this.$emit("refresh");
      }
    },
    workType: {
      immediate: true,
      deep: true,
      handler: function(val) {
        this.selectedWorkType = val;
      }
    }
  },

  methods: {
    updatejobStepHazard(jobIndex, stepHazardIndex, hazardId) {
      const currentStep = this.formData[jobIndex].jobStepHazards[
        stepHazardIndex
      ];
      if (currentStep.hazardId !== hazardId) {
        currentStep.barriers = null;
        currentStep.hazardId = hazardId;
        this.emitChanges();
      }
    },
    dragStart(event, jobStep) {
      event.dataTransfer.dropEffect = "move";
      event.dataTransfer.effectAllowed = "move";
      event.dataTransfer.setData("jobStep", jobStep);
    },
    dragEnd() {
      this.formData.sort((a, b) => a.step - b.step);
      this.emitChanges();
    },
    onDrop(event, record) {
      let itemId = event.dataTransfer.getData("jobStep");
      if (parseInt(itemId)) {
        const tempStep = record.step;
        if (record.step < itemId) {
          for (let i = record.step - 1; i < itemId - 1; i++) {
            if (this.formData[i].step !== itemId) this.formData[i].step += 1;
          }
        } else {
          for (let i = record.step - 1; i > itemId - 1; i--) {
            if (this.formData[i].step !== itemId) this.formData[i].step -= 1;
          }
        }
        this.formData[itemId - 1].step = tempStep;
      }
    },
    cancelAction() {
      if (!this.clearDialog) {
        this.selectedWorkType = this.proposedWorkType;
      }
      this.showDialogMessage = false;
    },
    okAction() {
      this.clearDialog ? this.clearWorkType() : this.changeWorkType();
      this.showDialogMessage = false;
    },
    removeHazards(hazard) {
      const hazardOwner = this.formData.find(x =>
        x.jobStepHazards.find(y => y.hazardId === hazard)
      );

      if (hazardOwner && hazardOwner.jobStepHazards.length === 1) {
        this.removeJobStep(this.formData.findIndex(i => i === hazardOwner));
        return;
      }

      this.formData.forEach(e => {
        if (e.jobStepHazards) {
          e.jobStepHazards = e.jobStepHazards.filter(
            h => h.hazardId !== hazard
          );
        }
      });
      this.emitChanges();
    },
    getRequired(input) {
      this.setRequiredData(input);
      let result = this.requiredData[input.step];
      return result ? `${result}` : "";
    },
    setRequiredData(input) {
      let hasData = 0;
      let required = 2;

      if (input.name !== "") hasData++;
      if (input.employeeIds && input.employeeIds.length !== 0) hasData++;
      if (input.jobStepHazards) {
        input.jobStepHazards.forEach(h => {
          required += 2;
          if (h.hazardId) hasData++;
          if (h.barriers && h.barriers.length != 0) hasData++;
        });
      }
      this.requiredData[input.step] = required - hasData;
      return { required: required, hasData: hasData };
    },
    getRequiredFields() {
      let count = 0;
      let totalRequired = 0;
      this.formData.forEach(e => {
        const { required, hasData } = this.setRequiredData(e);
        count += hasData;
        totalRequired += required;
      });
      return [count, totalRequired];
    },

    toEmployeeItem(e) {
      return {
        value: e.employee.id,
        text: `${e.employee.firstName} ${e.employee.lastName}`
      };
    },
    toItem({ id, name }) {
      return {
        value: id,
        text: name
      };
    },

    toJobStepInput(jobStep) {
      return {
        id: jobStep.id ? jobStep.id : utils.getUUID(),
        name: jobStep.name,
        step: jobStep.step,
        employeeIds: jobStep.employeeIds,
        jobStepHazards: jobStep.jobStepHazards
          .map(this.toJobStepHazardInput)
          .sort((a, b) => a.position - b.position)
      };
    },

    toJobStepHazardInput(jobStepHazard) {
      return {
        id: jobStepHazard.id ? jobStepHazard.id : utils.getUUID(),
        source: jobStepHazard.source,
        position: jobStepHazard.position,
        hazardId: jobStepHazard.hazardId,
        barriers: jobStepHazard.barriers
      };
    },

    newJobStepHazard(index) {
      const totalSteps = this.formData[index].jobStepHazards.length + 1;
      this.formData[index].jobStepHazards.push({
        id: utils.getUUID(),
        source: null,
        position: totalSteps,
        hazardId: null,
        barriers: []
      });
      this.emitChanges();
    },

    newJobStep(attrs = {}) {
      const totalSteps = this.formData.length + 1;
      this.formData.push({
        id: utils.getUUID(),
        name: "",
        step: totalSteps,
        employeeIds: [],
        jobStepHazards: [
          {
            id: utils.getUUID(),
            source: null,
            position: 1,
            hazardId: null,
            barriers: []
          }
        ],
        ...attrs
      });
      this.emitChanges();
    },

    removeJobStep(index) {
      const jobSteps = [...this.formData];
      jobSteps.splice(index, 1);
      this.formData = jobSteps.map(this.remapStep);
      if (this.formData.length === 0) this.newJobStep();
      this.emitChanges();
    },

    removeJobStepHazard(jobStepHazards, index) {
      jobStepHazards.splice(index, 1);

      for (let i = 0; i < jobStepHazards.length; i++) {
        jobStepHazards[i].position = i + 1;
      }
      this.emitChanges();
    },

    remapStep(jobStep, index) {
      return {
        ...jobStep,
        step: index + 1
      };
    },

    emitChanges() {
      const riskAssessment = {
        jobSteps: this.formData,
        workType: this.selectedWorkType
      };
      this.$emit("change", riskAssessment);
    },

    overwriteJobSteps() {
      if (this.selectedWorkType) {
        this.formData.splice(0);
        this.requiredData = {};
        for (let name of this.selectedWorkType.jobSteps) {
          this.newJobStep({ name });
        }
        this.emitChanges();
      }
    },

    confirmClearWorkType() {
      if (this.formData.length) {
        this.clearDialog = true;
        this.showDialogMessage = true;
      } else {
        this.clearWorkType();
      }
    },

    clearWorkType() {
      this.selectedWorkType = null;
      this.proposedWorkType = null;
      this.formData.splice(0);
      this.newJobStep();
      this.emitChanges();
      this.clearDialog = false;
    },

    confirmChangeWorkType() {
      if (
        this.proposedWorkType &&
        this.proposedWorkType !== this.selectedWorkType
      ) {
        this.clearDialog = false;
        this.showDialogMessage = true;
      } else {
        if (!this.proposedWorkType) this.changeWorkType();
      }
    },

    changeWorkType() {
      this.proposedWorkType = this.selectedWorkType;
      this.overwriteJobSteps();
      this.emitChanges();
    },

    onRemoveItem(list, item) {
      const i = list.indexOf(item);
      list.splice(i, 1);
      this.emitChanges();
    }
  }
};
</script>
<style scoped>
label {
  color: var(--v-primary-base) !important;
  margin-bottom: 5px !important;
}

.patch {
  padding-top: 1px;
  text-align: center;
  float: left;
  width: 22px !important;
  height: 22px;
  border-radius: 11px;
  background-color: var(--v-error-base);
  color: white;
  font-size: 12px;
  margin-right: 5px;
}

.selected-barrier {
  /* border: 0.7px solid lightgray;*/
  border-radius: 5px;
  padding: 4px;
  margin: 4px;
  background-color: #d8d8d8;
}
</style>
