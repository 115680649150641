<template>
  <preview-form-component class="component-employee-visitor-management">
    <preview-form-title :level="3"
      >Toronto Hydro Employee Visitors</preview-form-title
    >
    <preview-form-group v-if="inapplicationComponent">
      <h5 class="is-na preview-form-title preview-form-title-h5">N/A</h5>
    </preview-form-group>
    <preview-form-group
      class="employee-visitors-list"
      :as="'ul'"
      v-else-if="employeeVisitors && employeeVisitors.length > 0"
    >
      <li
        class="employee-visitor"
        v-for="employeeVisitor in employeeVisitors"
        :key="employeeVisitor.id"
      >
        <preview-input-string
          :name="'Employee Visitor'"
          :value="employeeVisitor.name"
        />
        <preview-input-string :name="'Title'" :value="employeeVisitor.title" />
        <preview-input-string
          :name="'Visitor Details'"
          :value="employeeVisitor.visitDetails"
        />
      </li>
    </preview-form-group>
  </preview-form-component>
</template>

<script>
import PreviewFormComponent from "./preview-form-component.vue";
import PreviewFormGroup from "./preview-form-group.vue";
import PreviewFormTitle from "./preview-form-title.vue";
import PreviewInputString from "./preview-input-string.vue";

export default {
  name: "PreviewEmployeeVisitorManagement",
  props: {
    submission: Object,
    inapplicationComponent: String
  },
  components: {
    PreviewFormComponent,
    PreviewFormGroup,
    PreviewFormTitle,
    PreviewInputString
  },
  computed: {
    employeeVisitors() {
      if (
        !this.submission.employeeVisitors ||
        !Array.isArray(this.submission.employeeVisitors)
      )
        return [];

      return this.submission.employeeVisitors.map(employeeVisitor => {
        const { employee } = employeeVisitor;
        return {
          name: employee.firstName + " " + employee.lastName,
          title: employee.title,
          visitDetails: employeeVisitor.visitDetails
        };
      });
    }
  }
};
</script>

<style scoped>
.employee-visitors-list {
  list-style: none;
  margin: 0;
  padding: 4px 8px;
}

.employee-visitor {
  page-break-inside: avoid;
}

.preview-form-title-h5 {
  padding: 1em;
  margin: 4px;
  background-color: var(--v-whiteBackground-base);
  font-size: 1em;
  border-radius: 0.2em;
}
</style>
