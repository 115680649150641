<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="50"
    viewBox="0 0 50 50"
  >
    <g
      fill="none"
      fill-rule="evenodd"
      stroke="#005d55"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    >
      <path d="M30.25 34h-10.5L19 19h12zM28 19h-6v-3h6zM17 19h16" />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Trash"
};
</script>
