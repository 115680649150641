<template>
  <v-input :rules="rules" :value="value">
    <div class="mt-3">
      <v-checkbox
        class="mt-n2"
        v-for="item in items"
        v-model="itemsSelected"
        :key="item.value"
        :label="item.text"
        :value="item.value"
        :disabled="disabled"
        @change="$emit('change', value)"
      />
      <div class="d-flex mt-n3">
        <v-checkbox
          class="pa-0"
          v-if="allowOther"
          :input-value="otherValue != ''"
          value
          :disabled="disabled"
          @change="otherChecked($event)"
        />
        <v-text-field
          v-if="allowOther"
          class="pa-0 my-input"
          placeholder="Other"
          v-model="otherValue"
          :disabled="disabled"
          @input="otherInputChanged"
        />
      </div>
    </div>
  </v-input>
</template>
<script>
export default {
  name: "SelectManyCheckboxGroup",

  props: {
    items: Array,
    allowOther: Boolean,
    disabled: Boolean,
    initialValue: Array,
    rules: Array
  },

  data() {
    return {
      itemsSelected: [],
      otherValue: [],
      isOtherChecked: false
    };
  },

  watch: {
    initialValue: {
      immediate: true,
      deep: true,
      handler: function(val) {
        let itemsTextArray = this.items.map(i => i.value);
        this.itemsSelected = val.filter(v => itemsTextArray.includes(v));
        this.otherValue = val.filter(v => !itemsTextArray.includes(v));
        this.isOtherChecked = this.otherValue != "";
      }
    }
  },

  computed: {
    value() {
      let result = this.isOtherChecked
        ? [...this.itemsSelected, this.otherValue]
        : this.itemsSelected;
      return result.join();
    }
  },

  methods: {
    otherChecked(e) {
      this.isOtherChecked = this.otherValue.length > 0 ? e : e === 1;
      this.$emit("change", this.value);
    },
    otherInputChanged() {
      this.isOtherChecked = this.otherValue != "";
      this.$emit("change", this.value);
    }
  }
};
</script>
