<template>
  <div>
    <v-row class="pa-0 ma-0">
      <v-col>
        <label>Date Initiated<span>*</span></label>
        <datepicker
          :value="jobDetailsData.tailboardFormHeader.date"
          :disabled="true"
          :rules="[rules.required]"
          @change="onJobDetailsHeaderChange('date', $event)"
        />
        <label>ESRR #</label>
        <v-text-field
          outlined
          counter="255"
          maxlength="255"
          placeholder="ERSS #"
          :value="jobDetailsData.tailboardFormHeader.esrr"
          @input="onJobDetailsHeaderChange('esrr', $event)"
        />
        <label>Work Order #</label>
        <v-combobox
          v-if="isOnline"
          outlined
          :items="workOrderItems"
          :value="jobDetailsData.tailboardFormHeader.workOrderNumber"
          @input="onWorkOrderChange($event)"
        />
        <v-text-field
          v-else
          outlined
          placeholder="Work order number"
          :value="jobDetailsData.tailboardFormHeader.workOrderNumber"
          @input="onJobDetailsHeaderChange('workOrderNumber', $event)"
        />
        <label>WBS #</label>
        <v-text-field
          outlined
          counter="255"
          maxlength="255"
          placeholder="WBS #"
          :value="jobDetailsData.tailboardFormHeader.wbs"
          @input="onJobDetailsHeaderChange('wbs', $event)"
        />
        <label>Field Activity ID</label>
        <v-text-field
          outlined
          counter="10"
          maxlength="10"
          :rules="[rules.maxDigit]"
          placeholder="Field Activity ID"
          :value="jobDetailsData.tailboardFormHeader.fieldActivityId"
          @keypress="checkForNumber"
          @blur="validateIsNumber"
          @input="onJobDetailsHeaderChange('fieldActivityId', $event)"
        />
        <label>Department<span>*</span></label>
        <v-text-field
          outlined
          placeholder="Department"
          :value="jobDetailsData.tailboardFormHeader.dept"
          counter="255"
          maxlength="255"
          :rules="[rules.required]"
          @input="onJobDetailsHeaderChange('dept', $event)"
        />
        <label>Cost Center #<span>*</span></label>
        <v-text-field
          outlined
          placeholder="Cost Center #"
          :value="jobDetailsData.tailboardFormHeader.rcNumber"
          counter="4"
          maxlength="4"
          :rules="[rules.required, rules.max]"
          @input="onJobDetailsHeaderChange('rcNumber', $event)"
        />
        <label>Project Name<span>*</span></label>
        <v-text-field
          outlined
          placeholder="Project Name"
          :value="jobDetailsData.tailboardFormHeader.projectName"
          counter="255"
          maxlength="255"
          :rules="[rules.required]"
          @input="onJobDetailsHeaderChange('projectName', $event)"
        />
        <label>Work Site Location<span>*</span></label>
        <gps-address
          placeholder="Work Site Location"
          :value="jobDetailsData.tailboardFormHeader.location"
          :rules="[rules.required]"
          :isOnline="isOnline"
          @change="onJobDetailsHeaderChange('location', $event)"
        />
        <label>Work Description<span>*</span></label>
        <v-textarea
          outlined
          rows="3"
          auto-grow
          placeholder="Work Description"
          :rules="[rules.required]"
          :value="jobDetailsData.tailboardFormHeader.workDescription"
          @input="onJobDetailsHeaderChange('workDescription', $event)"
        />
      </v-col>
    </v-row>
    <v-row class="pa-0 ma-0">
      <v-col>
        <label>Supervisor<span>*</span></label>
        <employee-autocomplete
          id="supervisor-id"
          populateProperty="phoneNumber"
          placeholder="Supervisor"
          :disabled="false"
          :initialValue="
            jobDetailsData.supervisorId
              ? jobDetailsData.supervisorId
              : 'CURRENT_USER_SUPERVISOR'
          "
          :multiple="false"
          @populate="onJobDetailsChange('supervisorPhone', $event)"
          @change="onJobDetailsChange('supervisorId', $event)"
        />
      </v-col>
      <v-col>
        <label>Supervisor phone<span>*</span></label>
        <v-text-field
          outlined
          counter="14"
          maxlength="14"
          type="tel"
          :rules="[rules.required, rules.phoneLength]"
          v-model="supervisorPhone"
          @input="onJobDetailsChange('supervisorPhone', $event)"
          placeholder="(000) 000-0000"
        />
      </v-col>
    </v-row>
    <v-row class="pa-0 ma-0">
      <v-col>
        <label>Crew leader</label>
        <employee-autocomplete
          id="crewLeader-id"
          populateProperty="phoneNumber"
          placeholder="Crew leader"
          :disabled="false"
          :initialValue="jobDetailsData.crewLeaderId"
          :multiple="false"
          @populate="onJobDetailsChange('crewLeaderPhone', $event)"
          @change="onJobDetailsChange('crewLeaderId', $event)"
        />
      </v-col>
      <v-col>
        <label>Crew leader phone</label>
        <v-text-field
          outlined
          counter="14"
          maxlength="14"
          type="tel"
          :rules="[rules.phoneLength]"
          v-model="crewLeaderPhone"
          @input="onJobDetailsChange('crewLeaderPhone', $event)"
          placeholder="(000) 000-0000"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Datepicker from "@/components/submission/datepicker.vue";
import GpsAddress from "@/components/submission/gps-address.vue";
import { WORK_ORDERS_QUERY } from "@/graphql/external/queries.js";
import EmployeeAutocomplete from "@/components/submission/employee-autocomplete.vue";
import { phoneMask } from "@/utilities/masks.js";

export default {
  name: "JobDetails",

  props: {
    jobDetailsData: {},
    isOnline: Boolean
  },

  components: {
    Datepicker,
    GpsAddress,
    EmployeeAutocomplete
  },

  data() {
    return {
      searchTerm: "",
      rules: {
        required: value => !!value || "This field is required",
        max: value =>
          (value && value.length < 5) ||
          `This field must be less than or equal to 4 char`,
        maxDigit: value =>
          (value && value.length === 10) ||
          `This field must be equal to 10 Digit`,
        phoneLength: value =>
          (value &&
            value.replace(/\D/g, "").length === 10 &&
            value.length >= 14) ||
          "Please enter a valid phone number in '647-123-1234’ format"
      },
      crewLeaderPhone: "",
      supervisorPhone: ""
    };
  },
  computed: {
    serverActivity() {
      return this.$store.getters.getServerActivity;
    },
    workOrderItems() {
      return this.workOrders ? this.workOrders.map(w => w.workOrderNumber) : [];
    },
    rcNumberErrors() {
      return this.errorMessages
        ? this.errorMessages
            .filter(m => m.field === "rcNumber")
            .map(m => m.message)
        : [];
    }
  },

  // Previously we were using :value on inputs
  // and because of that vue was rendering
  // stale values from jobDetails whenever
  // a refresh was being done on the parent form.
  // That's because whenever saving the form
  // we had to revert the masked value to raw
  // save to the DB and then revert back to masked.
  //
  // So to keep things contained I kept the mask
  // local, on this component and whenever the value
  // is sent back to the parent form its transformed
  // back again to raw numbers.
  // This fixed the stale numbers and also improved performance
  // as now this component isn't re-rendered multiple times
  watch: {
    "jobDetailsData.crewLeaderPhone": function(val, oldVal) {
      if (val === oldVal) return;
      if (val) val = phoneMask(val).masked;

      this.crewLeaderPhone = val;
    },
    "jobDetailsData.supervisorPhone": function(val, oldVal) {
      if (val === oldVal) return;
      if (val) val = phoneMask(val).masked;

      this.supervisorPhone = val;
    }
  },

  methods: {
    validateIsNumber() {
      if (!parseInt(this["fieldActivityId"])) {
        this.$emit(`update:fieldActivityId`, null);
        this.$emit("change", true);
      }
    },
    onWorkOrderChange(input) {
      this.searchTerm = input;
      this.onJobDetailsHeaderChange("workOrderNumber", this.searchTerm);
    },
    onJobDetailsHeaderChange(property, value) {
      if (this.jobDetailsData.tailboardFormHeader[property] !== value) {
        this.jobDetailsData.tailboardFormHeader[property] = value;
        this.$emit("change", true);
      }
    },
    onJobDetailsChange(property, value) {
      // revert back to raw numbers to save to the DB
      if (["supervisorPhone", "crewLeaderPhone"].includes(property) && value)
        value = phoneMask(value).raw;

      if (this.jobDetailsData[property] === value) return;

      this.jobDetailsData[property] = value;
      this.$emit("change", true);
    },
    checkForNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    }
  },

  apollo: {
    workOrders: {
      query: WORK_ORDERS_QUERY,
      fetchPolicy: "cache-and-network",
      variables() {
        return {
          inUse: false,
          searchTerm: this.searchTerm,
          limit: 20
        };
      },
      skip() {
        return !this.serverActivity;
      },
      update(data) {
        const { successful, result } = data.workOrders;
        return successful ? result : [];
      }
    }
  }
};
</script>
<style scoped>
span {
  color: red !important;
  margin-left: 5px !important;
}

label {
  color: var(--v-primary-base) !important;
}
</style>
