<template>
  <div class="mt-3">
    <v-checkbox
      class="mt-n2"
      v-for="critical in criticalTasks"
      v-model="criticalSelected"
      :key="critical.id"
      :label="critical.name"
      :value="critical.id"
      :disabled="disabled"
      @change="selectCriticalTask"
    />
    <dialog-message
      :showDialogMessage="showDialogMessage"
      displayCaption="Critical Tasks"
      displayText="Critical task identified, the tailboard form and conference will need to be reviewed by the supervisor before beginning work"
      :okAction="cancelAction"
      :cancelAction="cancelAction"
    />
  </div>
</template>
<script>
import dialogMessage from "@/components/submission/dialog-message.vue";
export default {
  name: "CriticalTasksAssessment",

  components: {
    dialogMessage
  },
  props: {
    disabled: Boolean,
    criticalTasksData: Array
  },

  data() {
    return {
      criticalSelected: this.criticalTasksData,
      showDialogMessage: false
    };
  },
  computed: {
    criticalTasks() {
      return this.$store.getters.getCriticalTasks;
    }
  },
  methods: {
    selectCriticalTask() {
      this.$emit("change", this.criticalSelected);
      this.showDialogMessage = this.criticalSelected.length > 0;
    },
    cancelAction() {
      this.showDialogMessage = false;
    }
  }
};
</script>
