var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"div-top-line"},[(_vm.formCase === 0)?_c('v-data-iterator',{staticClass:"pt-2",attrs:{"items":_vm.activeTailboards,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"options":{
      itemsPerPageOptions: _vm.paginationOptions.map(function (x) { return x.value; }),
      pagination: _vm.pageCount,
      page: _vm.page
    },"hide-default-footer":"","no-data-text":""},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"caption primary--text",attrs:{"value":true,"color":"secondary","icon":"information"}},[_vm._v(" Sorry, no data available. ")])]},proxy:true},{key:"default",fn:function(props){return [_c('v-row',{attrs:{"no-gutters":""}},_vm._l((props.items),function(tailboard){return _c('v-col',{key:tailboard.id,staticStyle:{"position":"relative"},attrs:{"cols":"12","sm":"12","md":"6","lg":"6"}},[_c('preview-slidebar',{attrs:{"submission":tailboard,"standAlone":true},on:{"show":function($event){return _vm.$emit('show', $event)}}}),_c('submission-card',{attrs:{"submission":tailboard},on:{"pdf":function($event){return _vm.fetchPDF(tailboard.id)},"edit":function($event){return _vm.editSubmission($event)},"show":function($event){return _vm.$emit('show', tailboard.tailboardFormSubmissionReview)}}})],1)}),1)]}}],null,false,804199146)}):_c('v-data-table',{staticClass:"elevation-1 table-theme",attrs:{"headers":_vm.headers,"items":_vm.activeTailboardsList,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","page":_vm.page,"options":{
      itemsPerPageOptions: _vm.paginationOptions.map(function (x) { return x.value; }),
      pagination: _vm.pageCount,
      page: _vm.page
    }},on:{"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item",fn:function(row){return [_c('tr',{class:row.item.type === 'TAILBOARD' ? 'white' : 'active'},[_c('td',[_c('preview-slidebar',{attrs:{"submission":row.item,"standAlone":false},on:{"show":function($event){return _vm.$emit('show', $event)}}})],1),_c('td',{staticClass:"project-name"},[_vm._v(" "+_vm._s(row.item.projectName)+" ")]),_c('td',{staticClass:"date-initiated"},[_vm._v(" "+_vm._s(_vm._f("parseDate")(row.item.insertedAt))+" ")]),_c('td',{staticClass:"work-location"},[_vm._v(" "+_vm._s(row.item.location)+" ")]),_c('td',{staticClass:"onsite-leader"},[_vm._v(_vm._s(row.item.user.name))]),_c('td',{staticClass:"work-order"},[_vm._v(" "+_vm._s(row.item.workOrderNumber)+" ")]),_c('td',{staticClass:"mode"},[_vm._v(_vm._s(_vm.pascalCase(row.item.mode)))]),_c('td',{staticClass:"status"},[_vm._v(_vm._s(_vm.pascalCase(row.item.state)))]),_c('td',{staticClass:"actions"},[_c('div',{staticClass:"d-flex"},[_c('v-btn',{attrs:{"disabled":row.item.type !== 'TAILBOARD'
                  ? row.item.user.id !== _vm.user.id
                  : false,"icon":""},on:{"click":function($event){return _vm.editSubmission(row.item)}}},[_c('v-icon',{attrs:{"size":"40"}},[_vm._v("$vuetify.icons.pdf")])],1),(
                row.item.state === 'SUBMITTED' ||
                  row.item.state === 'ARCHIVED'
              )?_c('v-btn',{attrs:{"icon":"","disabled":!row.item.id},on:{"click":function($event){return _vm.fetchPDF(row.item.id)}}},[_c('v-icon',{attrs:{"size":"40"}},[_vm._v(_vm._s(row.item.id ? "$vuetify.icons.download" : "$vuetify.icons.disabledDownload"))])],1):_vm._e(),(_vm.user.role === 'SAFETY')?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.incidentReportedConfirmation(row.item)}}},[(row.item.incidentReported)?_c('v-icon',[_vm._v("$vuetify.icons.alarm")]):_c('v-icon',[_vm._v("$vuetify.icons.warning")])],1):_vm._e()],1)])])]}}])}),_c('v-row',{staticClass:"text-center pt-2"},[_c('v-col',{staticClass:"d-flex pl-3 pt-3",attrs:{"cols":"auto"}},[_c('h5',{staticClass:"mt-3 mr-1"},[_vm._v("Rows per Page:")]),_c('v-select',{staticClass:"mt-1 pages-count",attrs:{"solo":"","items":_vm.paginationOptions,"dense":""},model:{value:(_vm.itemsPerPage),callback:function ($$v) {_vm.itemsPerPage=$$v},expression:"itemsPerPage"}})],1),_c('v-spacer'),_c('v-col',[_c('v-pagination',{staticClass:"float-right",attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1),_c('dialog-message',{attrs:{"showDialogMessage":_vm.showDialogMessage,"displayCaption":"Incident Report","displayText":_vm.dialogText,"okAction":_vm.incidentReported,"cancelAction":_vm.cancelAction}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }