var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-row',[_c('v-expansion-panels',{attrs:{"multiple":"","disabled":_vm.disabled,"flat":""},model:{value:(_vm.activePanels),callback:function ($$v) {_vm.activePanels=$$v},expression:"activePanels"}},_vm._l((_vm.cableChamberFeeders),function(feeder,i){return _c('v-expansion-panel',{key:i,attrs:{"expand":""}},[_c('v-expansion-panel-header',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var open = ref.open;
return [_c('v-row',{attrs:{"no-gutters":""}},[_c('p',{staticClass:"font-weight-bold text-h6"},[_vm._v(" Condition monitoring set ")]),(!open && feeder.feederDesignation)?_c('span',[_vm._v(_vm._s(("" + (feeder.feederDesignation))))]):_vm._e(),_c('v-divider',{staticClass:"ml-3 mt-4"}),(i !== 0)?_c('v-btn',{staticClass:"remove-btn",attrs:{"disabled":_vm.disabled,"small":"","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.removeFeeder(i)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("delete_outline")]),_vm._v(" Remove ")],1):_vm._e()],1)]}}],null,true)}),_c('v-expansion-panel-content',[_c('div',{staticClass:"pl-4"},[_c('v-row',[_c('label',[_vm._v("Feeder Designation")]),_c('combobox',{attrs:{"source":"feeders","initialValue":feeder.feederDesignation,"disabled":_vm.disabled,"rules":[_vm.rules.required]},on:{"change":function($event){feeder.feederDesignation = $event}}})],1),_c('v-row',[_vm._l((_vm.getHazardItems(feeder)),function(hazard,idx){return [_c('div',{key:'col-' + idx,staticClass:"hazard-item",class:[
                      hazard.selected
                        ? 'div-border-active'
                        : 'div-border-normal'
                    ]},[_c('v-checkbox',{staticClass:"pa-0 ma-0 pt-3 pl-2",staticStyle:{"width":"100%"},attrs:{"dense":"","disabled":_vm.disabled,"id":'hazardCheck-' + idx,"label":hazard.name},on:{"change":function($event){return _vm.onSelectHazard($event, feeder, hazard.name)}},model:{value:(hazard.selected),callback:function ($$v) {_vm.$set(hazard, "selected", $$v)},expression:"hazard.selected"}})],1),((idx + 1) % 3 == 0)?_c('v-responsive',{key:idx,attrs:{"width":"100%"}}):_vm._e()]})],2),_c('v-row',{staticClass:"mt-10"},[_c('v-col',{staticClass:"pr-15"},[_c('label',[_vm._v("Duct #")]),_c('v-text-field',{staticClass:"mt-2",attrs:{"disabled":_vm.disabled,"counter":"255","maxlength":"255","rules":[_vm.rules.required],"outlined":""},model:{value:(feeder.ductNumber),callback:function ($$v) {_vm.$set(feeder, "ductNumber", $$v)},expression:"feeder.ductNumber"}})],1),_c('v-col',[_c('label',[_vm._v("Direction")]),_c('v-radio-group',{staticClass:"pa-0 ma-0 pt-1",attrs:{"disabled":_vm.disabled},model:{value:(feeder.direction),callback:function ($$v) {_vm.$set(feeder, "direction", $$v)},expression:"feeder.direction"}},[_c('v-row',[_vm._l((_vm.getDirctions(feeder)),function(dirction,idx){return [_c('div',{key:'col-' + idx,staticClass:"direction-item",class:[
                            dirction.selected
                              ? 'div-border-active'
                              : dirction.name
                              ? 'div-border-normal'
                              : ''
                          ]},[(dirction.name)?_c('v-radio',{staticClass:"pa-2",staticStyle:{"width":"100%"},attrs:{"dense":"","disabled":_vm.disabled,"id":'dirctionRadio-' + idx,"label":dirction.name,"value":dirction.name}}):_c('v-spacer')],1),((idx + 1) % 3 == 0)?_c('v-responsive',{key:idx,attrs:{"width":"100%"}}):_vm._e()]})],2)],1)],1)],1),_c('label',[_vm._v("Temperature")]),_c('v-row',[_c('v-col',[_c('p',[_vm._v("Max. Temp")]),_c('v-text-field',{attrs:{"type":"number","rules":[_vm.rules.required],"outlined":"","disabled":_vm.disabled},model:{value:(feeder.maxTemp),callback:function ($$v) {_vm.$set(feeder, "maxTemp", _vm._n($$v))},expression:"feeder.maxTemp"}})],1),_c('v-col',[_c('p',[_vm._v("Spot. Temp(c)")]),_c('v-text-field',{attrs:{"type":"number","rules":[_vm.rules.required],"outlined":"","disabled":_vm.disabled},model:{value:(feeder.spotTemp),callback:function ($$v) {_vm.$set(feeder, "spotTemp", _vm._n($$v))},expression:"feeder.spotTemp"}})],1),_c('v-col',[_c('p',[_vm._v("ΔS:")]),_c('v-text-field',{attrs:{"type":"number","rules":[_vm.rules.required],"outlined":"","disabled":_vm.disabled},model:{value:(feeder.deltaS),callback:function ($$v) {_vm.$set(feeder, "deltaS", _vm._n($$v))},expression:"feeder.deltaS"}})],1)],1),_c('p',[_vm._v("Comment on Hazard Identified")]),_c('v-textarea',{attrs:{"rows":"1","auto-grow":"","outlined":"","disabled":_vm.disabled,"rules":[_vm.rules.required]},model:{value:(feeder.comments),callback:function ($$v) {_vm.$set(feeder, "comments", $$v)},expression:"feeder.comments"}})],1)])],1)}),1)],1),_c('v-row',{staticClass:"mt-5 mr-5"},[_c('v-spacer'),_c('v-btn',{staticClass:"primary pa-5",attrs:{"disabled":_vm.disabled},on:{"click":function($event){$event.stopPropagation();return _vm.addNewFeeder.apply(null, arguments)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("add_circle_outline")]),_vm._v(" Add Another Feeder")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }