<template>
  <div
    class="preview-component-cable-chamber-feeder"
    v-if="
      submission.cableChamberFeeders &&
        submission.cableChamberFeeders.length > 0
    "
  >
    <preview-form-component
      v-for="(feeder, index) in submission.cableChamberFeeders"
      :key="feeder.id"
    >
      <preview-form-title :level="3">
        Cable Chamber Feeder {{ index + 1 }}
      </preview-form-title>

      <preview-form-group>
        <preview-input-string
          :lineBreak="'long'"
          :name="'Designation'"
          :value="feeder.feederDesignation"
        />

        <preview-input-string
          :lineBreak="'long'"
          :name="'Hazards'"
          :value="feeder.hazards.join(',')"
        />

        <preview-input-string
          :lineBreak="'long'"
          :name="'Duct Number'"
          :value="feeder.ductNumber"
        />

        <preview-input-string
          :lineBreak="'long'"
          :name="'Direction'"
          :value="feeder.direction"
        />

        <preview-input-string
          :lineBreak="'long'"
          :name="'Max Temp'"
          :value="feeder.maxTemp"
        />

        <preview-input-string
          :lineBreak="'long'"
          :name="'Spot Temp'"
          :value="feeder.spotTemp"
        />

        <preview-input-string
          :lineBreak="'long'"
          :name="'Delta S'"
          :value="feeder.deltaS"
        />

        <preview-input-string
          :lineBreak="'long'"
          :name="'Comments'"
          :value="feeder.comments"
        />
      </preview-form-group>
    </preview-form-component>
  </div>
</template>

<script>
import PreviewFormComponent from "./preview-form-component.vue";
import PreviewFormGroup from "./preview-form-group.vue";
import PreviewFormTitle from "./preview-form-title.vue";
import PreviewInputString from "./preview-input-string.vue";

export default {
  name: "PreviewComponentCableChamberFeeder",
  props: {
    submission: Object
  },
  components: {
    PreviewFormComponent,
    PreviewFormGroup,
    PreviewFormTitle,
    PreviewInputString
  }
};
</script>
