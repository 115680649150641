<template>
  <v-dialog
    v-model="filterDialogShow"
    max-width="666px"
    max-height="438px"
    @keydown.esc="cancelAction(false)"
  >
    <v-card>
      <v-card-title>
        <span class="headline"
          >Filter{{
            filterName ? `: ${ucfirst(filterName)} Tailboards` : ""
          }}</span
        >
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-container>
          <v-row>
            <v-text-field
              outlined
              class="ma-2"
              v-model="searchTerm"
              label="Search across all fields"
              prepend-inner-icon="search"
              clearable
              @click:clear="clearSearchTerm"
          /></v-row>
          <v-row
            v-for="(filter, index) in filterList"
            :key="`filter_${index}_${filter.id}`"
          >
            <v-col align="center" class="d-md-flex">
              <!-- <v-combobox
                outlined
                v-model="filter.selectedBoolean"
                label="Boolean"
                class="mr-2"
                :items="booleanItems"
              /> -->
              <v-combobox
                outlined
                label="Select Field"
                v-model="filter.selectedFieldType"
                :items="fieldTypes"
                class="mr-2"
                @change="onFieldTypeChange($event, index)"
              />
              <v-combobox
                outlined
                label="Operator"
                v-model="filter.selectedOperator"
                :items="
                  filter.selectedFieldType
                    ? filter.selectedFieldType.operators
                    : []
                "
                class="mr-2"
              />
              <v-text-field
                v-if="isTextField(filter) && !isDateField(filter)"
                outlined
                label="Criteria"
                v-model="filter.selectedCriteria"
                class="mr-2"
              >
              </v-text-field>
              <v-combobox
                v-if="!isTextField(filter) && !isDateField(filter)"
                outlined
                label="Criteria"
                v-model="filter.selectedCriteria"
                :items="
                  filter.selectedFieldType
                    ? filter.selectedFieldType.criterias
                    : []
                "
                class="mr-2"
              />
              <v-menu
                v-if="isTextField(filter) && isDateField(filter)"
                v-model="toggleDatePicker"
                min-width="290px"
                max-width="290px"
                :close-on-content-click="false"
                offset-y
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="filter.selectedCriteria"
                    v-on="on"
                    label="Criteria"
                    :placeholder="defaultDate"
                    class="mr-2"
                    outlined
                    append-icon="today"
                    readonly
                  />
                </template>
                <v-date-picker
                  v-model="date"
                  no-title
                  @input="formatDate(index)"
                ></v-date-picker>
              </v-menu>
              <v-btn class="mt-2" @click.stop="removeFilter(index)" icon>
                <v-icon>delete_outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-btn class="primary--text mb-1" text @click="addNewFilter">
              <v-icon class="mr-3"> add_circle_outline </v-icon>
              Add Filter
            </v-btn>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="cancel"> Cancel </v-btn>
        <v-btn class="primary" @click="done">
          Done
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { LOCAL_STORAGE_FILTER_STATE_KEY } from "@/shared/consts.js";
import dayjs from "@/plugins/dayjs";

export default {
  name: "FiltersDialog",
  props: {
    filterDialogShow: {
      type: Boolean,
      default: false
    },
    filterName: String,
    fieldName: String,
    doneAction: { type: Function },
    cancelAction: { type: Function }
  },
  data() {
    return {
      toggleDatePicker: false,
      defaultDate: dayjs().format("YYYY-MM-DD"),
      date: dayjs().format("YYYY-MM-DD"),
      searchTerm: null,
      booleanItems: ["OR", "AND"],
      operators: [],
      criterias: [],
      filterList: [],
      defaultFilterList: [
        {
          selectedFieldType: null,
          selectedOperator: "",
          selectedCriteria: ""
        }
      ],
      fieldTypes: [
        {
          value: "tailboardFormHeader.rcNumber",
          text: "Cost Center",
          operators: [
            { value: "EQUALS_TO", text: "Equals to" },
            { value: "CONTAINS", text: "Contains" },
            { value: "DOES_NOT_CONTAIN", text: "Doesn't contain" }
          ]
        },
        {
          value: "insertedAt",
          text: "Created at",
          type: "date",
          operators: [
            { value: "EQUALS_TO", text: "Equals to" },
            { value: "GREATER_THAN", text: "Greater than" },
            { value: "LESS_THAN", text: "Less than" }
          ]
        },
        {
          value: "tailboardFormHeader.dept",
          text: "Department",
          operators: [
            { value: "EQUALS_TO", text: "Equals to" },
            { value: "CONTAINS", text: "Contains" },
            { value: "DOES_NOT_CONTAIN", text: "Doesn't contain" }
          ]
        },
        {
          value: "tailboardFormHeader.location",
          text: "Location",
          operators: [
            { value: "EQUALS_TO", text: "Equals to" },
            { value: "CONTAINS", text: "Contains" },
            { value: "DOES_NOT_CONTAIN", text: "Doesn't contain" }
          ]
        },
        {
          value: "user.name",
          text: "Onsite leader",
          type: null,
          operators: [
            { value: "EQUALS_TO", text: "Equals to" },
            { value: "CONTAINS", text: "Contains" },
            { value: "DOES_NOT_CONTAIN", text: "Doesn't contain" }
          ]
        },
        {
          value: "tailboardFormHeader.projectName",
          text: "Project name",
          operators: [
            { value: "EQUALS_TO", text: "Equals to" },
            { value: "CONTAINS", text: "Contains" },
            { value: "DOES_NOT_CONTAIN", text: "Doesn't contain" }
          ]
        },
        {
          value: "tailboardFormHeader.workOrderNumber",
          text: "Work Order Number",
          operators: [
            { value: "EQUALS_TO", text: "Equals to" },
            { value: "CONTAINS", text: "Contains" },
            { value: "DOES_NOT_CONTAIN", text: "Doesn't contain" }
          ]
        },
        {
          value: "tailboardFormHeader.wbs",
          text: "WBS",
          operators: [
            { value: "EQUALS_TO", text: "Equals to" },
            { value: "CONTAINS", text: "Contains" },
            { value: "DOES_NOT_CONTAIN", text: "Doesn't contain" }
          ]
        },
        {
          value: "mode",
          text: "Mode",
          operators: [{ value: "EQUALS_TO", text: "Equals to" }],
          criterias: [
            { value: "OPEN", text: "Open" },
            { value: "CLOSED", text: "Closed" }
          ]
        },
        {
          value: "state",
          text: "State",
          operators: [{ value: "EQUALS_TO", text: "Equals to" }],
          criterias: [
            { value: "IN_PROGRESS", text: "In progress" },
            { value: "COMPLETED", text: "Completed" },
            { value: "SIGNED_OFF", text: "Signed off" },
            { value: "SUBMITTED", text: "Submitted" },
            { value: "CANCELLED", text: "Cancelled" },
            { value: "ARCHIVED", text: "Archived" }
          ]
        }
      ],
      tempSearchTerm: "",
      tempFilters: []
    };
  },
  created() {
    const localFilters = localStorage.getItem(LOCAL_STORAGE_FILTER_STATE_KEY);

    if (localFilters) {
      const parsedFilters = JSON.parse(localFilters);
      const option = parsedFilters[this.fieldName];

      if (option) {
        const { filterList, searchTerm } = option;

        this.searchTerm = searchTerm ? searchTerm : "";
        this.filterList = filterList ? filterList : [];
      }
    }
  },
  computed: {
    //eslint-disable-next-line no-unused-vars
    isDateField: vm => filter => {
      const result = filter.selectedFieldType
        ? filter.selectedFieldType.type === "date"
        : false;
      return result;
    },
    //eslint-disable-next-line no-unused-vars
    isTextField: vm => filter => {
      return (
        filter.selectedFieldType &&
        !(filter.selectedFieldType.criterias instanceof Object)
      );
    }
  },
  watch: {
    filterDialogShow: function(state) {
      if (state) {
        if (this.filterList.length)
          this.tempFilters = this.filterList.concat([]);
        else
          this.filterList = [{ ...this.defaultFilterList[0], id: Date.now() }];

        this.tempSearchTerm = this.searchTerm;
      }
    }
  },
  methods: {
    done() {
      this.tempSearchTerm = "";
      this.tempFilters = [];

      this.doneAction(this.filterList, this.searchTerm);
    },
    cancel() {
      this.searchTerm = this.tempSearchTerm;
      this.tempSearchTerm = "";

      this.filterList = this.tempFilters.map(f => ({ ...f, id: Date.now() }));
      this.tempFilters = [];

      this.cancelAction(false);
    },
    clearAll() {
      this.clearSearchTerm();
      this.clearFilterList();
      this.addNewFilter();
    },
    clearFilterList() {
      this.tempFilters = this.filterList.concat([]);
      this.filterList = [];
    },
    clearSearchTerm() {
      this.tempSearchTerm = this.searchTerm;
      this.searchTerm = null;
    },
    formatDate(index) {
      this.filterList[index].selectedCriteria = this.date;
      this.toggleDatePicker = false;
    },
    onFieldTypeChange(filter, index) {
      this.filterList[index].selectedOperator = null;
      this.filterList[index].selectedCriteria = null;

      this.operators = filter.operators;
      this.criterias = filter.criterias;
    },
    addNewFilter() {
      this.filterList.push({
        selectedFieldType: null,
        selectedOperator: "",
        selectedCriteria: "",
        id: Date.now()
      });
    },
    removeFilter(index) {
      // Store a copy of the original filters when dialog was opened
      if (this.filterList.length > 1) this.filterList.splice(index, 1);
      else this.filterList = [];
    },
    ucfirst(val) {
      return val && val[0].toUpperCase() + val.slice(1);
    }
  }
};
</script>
