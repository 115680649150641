<template>
  <div class="pb-3">
    <v-row class="component-header">
      <v-spacer />
      <v-btn
        class="primary--text mb-1"
        text
        :disabled="disabled"
        @click="openEmployeeSearchDialog"
      >
        <v-icon class="mr-3"> add_circle_outline </v-icon>
        Add Employee Visitors
      </v-btn>
      <employees-search
        :searchCrewDialogShow="showEmployeeSearchDialog"
        :membersList="employees"
        title="Add Employee Visitors"
        :multiple="true"
        :cancelAction="cancelEmployeeSearchDialog"
        @done="doneEmployeeSearchDialog"
        @delete="deleteEmployee"
      />
    </v-row>
    <v-row>
      <v-simple-table class="table-theme" fixed-header>
        <template>
          <thead>
            <tr>
              <th style="width: 45px;"></th>
              <th>Name <span class="red--text ml-2">*</span></th>
              <th>Title</th>
              <th>Visit Details</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(employeeVisitor, index) in employees"
              :key="index + 1"
              style="height: 52px"
            >
              <td width="10">{{ `${index + 1}` }}</td>
              <td width="300" :class="{ 'disabled-cell': disabled }">
                {{
                  `${employeeVisitor.employee.firstName} ${employeeVisitor.employee.lastName}`
                }}
              </td>
              <td width="300" :class="{ 'disabled-cell': disabled }">
                {{ `${employeeVisitor.employee.title}` }}
              </td>
              <td>
                <v-text-field
                  v-model="employeeVisitor.visitDetails"
                  :disabled="disabled"
                  hide-details
                  dense
                  outlined
                  maxLength="255"
                  @blur="
                    updateVisitDetails($event, employeeVisitor.employee.id)
                  "
                />
              </td>
              <td class="section-enabled">
                <v-btn :disabled="disabled" icon>
                  <v-icon
                    @click="openDeleteEmployeeVisitorDialog(employeeVisitor)"
                    >delete_outline</v-icon
                  >
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <dialog-message
        :showDialogMessage="showDeleteDialogMessage"
        displayCaption="Delete Employee Visitor"
        :displayText="deleteDialogMessageDisplayText"
        :okAction="okDeleteEmployeeAction"
        :cancelAction="cancelDeleteEmployeeAction"
      />
      <dialog-message
        :showDialogMessage="showIneligibleDialogMessage"
        displayCaption="Ineligible Employee Visitor"
        :displayText="ineligibleDialogMessageDisplayText"
        :okAction="okIneligiblemployeeAction"
        okButtonText="Ok"
      />
    </v-row>
  </div>
</template>
<script>
import employeesSearch from "@/components/submission/employees-search.vue";
import dialogMessage from "@/components/submission/dialog-message.vue";

export default {
  name: "VisitorEmployeeManagement",
  components: {
    dialogMessage,
    employeesSearch
  },
  props: {
    crewMembers: {
      type: Array,
      default: () => []
    },
    employeeVisitors: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    refresh: { type: Function },
    id: String
  },
  data() {
    return {
      employeeToDelete: null,
      invalidEmployees: [], // Array of first and last names
      showDeleteDialogMessage: false,
      showEmployeeSearchDialog: false,
      showIneligibleDialogMessage: false,
      tempSelected: -1
    };
  },
  watch: {
    employeeVisitors: {
      immediate: true,
      deep: true,
      handler(newValue, oldVal) {
        if (newValue !== oldVal) {
          this.validateEmployees();

          if (this.invalidEmployees && this.invalidEmployees.length > 0) {
            this.removeInvalidEmployees();
            this.showIneligibleDialogMessage = true;
          }
        }
      }
    }
  },
  updated: function() {
    this.$nextTick(function() {
      if (this.tempSelected !== this.employeeVisitors.length) {
        this.refresh(this.id, [
          this.employeeVisitors.length > 0 ? 1 : 0,
          this.disabled ? 0 : 1
        ]);
        this.tempSelected = this.employeeVisitors.length;
      }
    });
  },
  computed: {
    employees() {
      return this.employeeVisitors.map(e => {
        return {
          employeeId: e.employee.id,
          employee: e.employee,
          visitDetails: e.visitDetails
        };
      });
    },
    /**
     * Returns the dialog message for an employee that
     * will be deleted from the list
     */
    deleteDialogMessageDisplayText() {
      return this.employeeToDelete
        ? `Are you sure you want to delete visitor name: : ${this.employeeToDelete.employee.firstName} ${this.employeeToDelete.employee.lastName}`
        : "";
    },
    ineligibleDialogMessageDisplayText() {
      if (!this.invalidEmployees || this.invalidEmployees.length === 0) {
        return "";
      }

      const names = this.invalidEmployees
        .map(ie => `${ie.employee.firstName} ${ie.employee.lastName}`)
        .join(", ");

      return `The following employee(s) could not be added because they are listed on the Employee Information page: ${names}`;
    }
  },

  methods: {
    mapAndEmitEmployeeVisitors() {
      let result = this.employees.map(e => {
        return {
          employeeId: e.employee.id,
          employee: e.employee,
          visitDetails: e.visitDetails
        };
      });

      this.$emit("change", result);
    },
    /**
     * @param {object} e - DOM element
     * @param {string} employeeVisitorId
     */
    updateVisitDetails(e, employeeVisitorId) {
      let updatedDetails = e.target.value;
      const index = this.employees.findIndex(
        ev => ev.employee.id === employeeVisitorId
      );

      if (index !== -1) {
        this.$set(this.employees[index], "visitDetails", updatedDetails);

        this.mapAndEmitEmployeeVisitors();
      }
    },
    validateEmployees() {
      this.invalidEmployees = []; // Reset or initialize the array

      this.crewMembers.forEach(crewMember => {
        const found = this.employees.some(
          visitor => visitor.employee.id === crewMember.employee.id
        );
        if (found) {
          this.invalidEmployees.push(crewMember);
        }
      });
    },
    removeInvalidEmployees() {
      this.$emit("delete", this.invalidEmployees);
    },

    /*--------------------
    DELETE EMPLOYEE DIALOG
    ---------------------*/

    /**
     * @param {object} employee
     */
    openDeleteEmployeeVisitorDialog(employee) {
      this.employeeToDelete = employee;
      this.showDeleteDialogMessage = true;
    },
    okDeleteEmployeeAction() {
      if (this.employeeToDelete) {
        this.$emit("delete", [this.employeeToDelete.employee.employeeRef]);
        this.employeeToDelete = null;
      }

      this.showDeleteDialogMessage = false;
    },
    deleteEmployee(employee) {
      this.$emit("delete", [employee]);
    },
    cancelDeleteEmployeeAction() {
      this.showDeleteDialogMessage = false;
    },

    /*--------------------
    SEARCH EMPLOYEE DIALOG
    ---------------------*/

    openEmployeeSearchDialog() {
      this.showEmployeeSearchDialog = true;
    },
    cancelEmployeeSearchDialog() {
      this.showEmployeeSearchDialog = false;
    },
    doneEmployeeSearchDialog() {
      this.mapAndEmitEmployeeVisitors();
      this.showEmployeeSearchDialog = false;
    },

    okIneligiblemployeeAction() {
      this.showIneligibleDialogMessage = false;
    }
  }
};
</script>
<style scoped>
.row-disabled td:nth-child(-n + 2) {
  pointer-events: none;
  opacity: 0.6;
}
.table-theme {
  width: 100% !important;
}
.table-theme table th {
  color: #005d55 !important;
}

.table-theme table tr td:last-child {
  width: 1%;
  white-space: nowrap;
}
.disabled-cell {
  opacity: 0.6;
}
</style>
