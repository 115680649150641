<template>
  <div>
    <v-btn class="accent" :disabled="!isSignedOff" @click="submitReview">
      <v-icon>arrow_upward</v-icon> Submit
    </v-btn>
    <v-dialog persistent v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Owner Submission</span>
          <v-spacer />
          <v-btn @click.stop="clear" text
            ><v-icon left>delete_outline</v-icon>Clear</v-btn
          >
        </v-card-title>
        <v-divider />
        <v-card-text>
          <p class="caption mt-2">
            {{ submitDialogMessage }}
          </p>
          <h3 class="body-1 my-4">{{ ownerFullName }}</h3>
          <canvas-input
            v-if="owner"
            ref="canvasInput"
            :id="owner.id"
            :value="signatureData"
            :showClearBtn="false"
            @input="signatureData = $event"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="cancel">Cancel</v-btn>
          <v-btn
            class="primary"
            @click="submit"
            :disabled="!signatureData"
            :loading="loading"
            >Save Signature</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <dialog-message
      :showDialogMessage="showDialogMessage"
      displayCaption="Submit Form"
      displayText="Reminder: Did you document and communicate any changes to the job plan that occurred during the job?<br/><br/>Are you sure that you want to submit this form to your supervisor for an end-of-day review? You will not be able to make any further changes to the form."
      :okAction="okAction"
      :cancelAction="cancelAction"
    />
  </div>
</template>
<script>
import CanvasInput from "@/components/submission/canvas-input.vue";
import { mapState } from "vuex";
import DialogMessage from "@/components/submission/dialog-message.vue";

export default {
  name: "OwnerSubmission",

  props: {
    disabled: Boolean,
    tailboardFormSubmission: Object,
    tailboardFormTitle: String,
    validSubmit: { type: Function }
  },

  components: {
    CanvasInput,
    DialogMessage
  },

  data() {
    return {
      showDialogMessage: false,
      dialog: false,
      loading: false,
      signatureData: null
    };
  },

  computed: {
    signIsExist() {
      return (
        this.tailboardFormSubmission.signatures.findIndex(
          s => s.phase === "SUBMISSION"
        ) !== -1
      );
    },
    submitDialogMessage() {
      return this.tailboardFormSubmission &&
        this.tailboardFormSubmission.mode === "CLOSED"
        ? "Through initialing, the undersigned acknowledges that this site has been left in a condition that presents no undue hazard to the public."
        : "Through initialing, the undersigned acknowledges that the appropriate job steps, all known and foreseeable hazards, and sufficient barriers have been identified and agreed upon (by all employees, visitors and contractors) to be put in place to create a safe work area. The job plan will follow the Toronto Hydro Construction Verification Program, where appropriate.";
    },
    submissionId() {
      return this.tailboardFormSubmission
        ? this.tailboardFormSubmission.id
        : null;
    },

    isSignedOff() {
      return this.tailboardFormSubmission
        ? this.tailboardFormSubmission.state === "SIGNED_OFF" &&
            !this.disabled &&
            this.isOwner &&
            !this.signIsExist
        : false;
    },

    owner() {
      return this.tailboardFormSubmission
        ? this.tailboardFormSubmission.user
        : null;
    },

    ownerFullName() {
      return this.owner ? this.owner.name : null;
    },

    isOwner() {
      return this.user && this.owner ? this.owner.id === this.user.id : false;
    },

    projectName() {
      return this.tailboardFormSubmission
        ? this.tailboardFormSubmission.projectName
        : null;
    },

    ...mapState(["user"])
  },

  methods: {
    submitReview() {
      if (this.tailboardFormSubmission.mode === "CLOSED")
        this.showDialogMessage = true;
      else this.dialog = true;
    },
    okAction() {
      this.dialog = true;
      this.showDialogMessage = false;
    },
    cancelAction() {
      this.showDialogMessage = false;
    },
    clear() {
      this.$refs.canvasInput.clearCanvas();
    },

    cancel() {
      this.dialog = false;
      this.clear();
    },

    submit() {
      // TODO WE NEED TO VISIT THIS SECTION AGAIN  FOR VALIDATION ON DIFFERENT SECTION

      //if (this.validSubmit()) {
      this.loading = true;

      this.$emit(
        "signature",
        {
          employee: this.user.employee,
          file: this.signatureData
        },
        () => {
          this.cancel();
          this.loading = false;
        }
      );
    }
  }
};
</script>
<style scoped>
.v-btn {
  height: 32px !important;
  color: black !important;
}
</style>
