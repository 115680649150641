<template>
  <div class="white">
    <v-row class="pt-3 pr-5">
      <v-spacer />
      <v-btn class="primary" @click="getBack"
        ><v-icon small>arrow_back_ios</v-icon> Back</v-btn
      >
    </v-row>
    <preview :ref="`preview-${submissionId}`">
      <preview-form :submissionId="submissionId" :state="state" />
    </preview>
  </div>
</template>
<script>
import Preview from "@/components/submission/preview.vue";
import PreviewForm from "@/components/submission/preview-form/preview-form.vue";
export default {
  name: "CustomPreview",
  components: {
    Preview,
    PreviewForm
  },
  props: {
    state: String,
    submissionId: {
      type: String,
      default: null
    }
  },
  methods: {
    getBack() {
      this.$router.back();
    }
  }
};
</script>
