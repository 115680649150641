<template>
  <div class="select-many-input">
    <select-many-checkbox-group
      v-if="displayInline"
      v-model="value"
      :items="items"
      :initialValue="value"
      :allowOther="allowOther"
      :rules="rules"
      :disabled="disabled"
      @change="$emit('change', $event)"
    />
    <template v-else>
      <allow-other-combobox
        v-if="allowOther"
        :id="id"
        :placeholder="placeholder"
        :multiple="true"
        :initialValue="initialValue"
        :initialItems="items"
        :rules="rules"
        :disabled="disabled"
        @change="$emit('change', $event)"
      />
      <v-select
        outlined
        v-else
        :items="items"
        :placeholder="placeholder"
        v-model="value"
        :disabled="disabled"
        :rules="rules"
        multiple
        @input="$emit('change', $event.join())"
      />
    </template>
  </div>
</template>
<script>
import SelectManyCheckboxGroup from "@/components/submission/select-many-checkbox-group.vue";
import AllowOtherCombobox from "@/components/submission/allow-other-combobox.vue";

export default {
  name: "SelectMany",

  props: {
    id: String,
    items: Array,
    placeholder: String,
    disabled: Boolean,
    allowOther: Boolean,
    displayInline: Boolean,
    initialValue: String,
    rules: Array
  },
  watch: {
    initialValue: {
      immediate: true,
      deep: true,
      handler: function(val) {
        this.value = val ? val.split(",") : [];
      }
    }
  },

  components: { SelectManyCheckboxGroup, AllowOtherCombobox },

  data() {
    return {
      value: this.initialValue ? this.initialValue.split(",") : []
    };
  },

  methods: {
    onChange(val) {
      this.$emit("change", val.join());
    }
  }
};
</script>
