<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="50"
    viewBox="0 0 50 50"
  >
    <g
      fill="none"
      fill-rule="evenodd"
      stroke="#007970"
      stroke-linecap="round"
      stroke-width="2.5"
    >
      <path d="M20 19l4 6-4 6M25 19l4 6-4 6" />
    </g>
  </svg>
</template>

<script>
export default {
  name: "PaginationRight"
};
</script>
