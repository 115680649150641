import gql from "graphql-tag";
import {
  TAILBOARD_FORM_ENTITY_FRAGMENT,
  FORM_SUBMISSION_ENTITY_FRAGMENT,
  TAILBOARD_FORM_SUBMISSION_COMMENT_ENTITY_FRAGMENT,
  TAILBOARD_FORM_SUBMISSION_ENTITY_FRAGMENT,
  TAILBOARD_FORM_SUBMISSION_PAGE_ENTITY_FRAGMENT
} from "@/constants/fragments.js";

export const CREATE_TAILBOARD_FORM_MUTATION = gql`
  mutation createTailboardFormMutation(
    $title: String!
    $header: String!
    $instructions: String!
    $logoFile: String
    $type: FormType!
  ) {
    createTailboardForm(
      title: $title
      header: $header
      instructions: $instructions
      logoFile: $logoFile
      type: $type
    ) {
      ...TailboardFormEntity
    }
  }
  ${TAILBOARD_FORM_ENTITY_FRAGMENT}
`;

export const UPDATE_TAILBOARD_FORM_MUTATION = gql`
  mutation updateTailboardFormMutation(
    $id: ID!
    $title: String
    $header: String
    $instructions: String
    $logoFile: String
    $type: FormType!
  ) {
    updateTailboardForm(
      id: $id
      title: $title
      header: $header
      instructions: $instructions
      logoFile: $logoFile
      type: $type
    ) {
      ...TailboardFormEntity
    }
  }
  ${TAILBOARD_FORM_ENTITY_FRAGMENT}
`;

export const UPDATE_TAILBOARD_FORM_CHILDREN_MUTATION = gql`
  mutation updateTailboardFormChildrenMutation($id: ID!, $childIds: [ID!]) {
    updateTailboardFormChildren(id: $id, childIds: $childIds) {
      messages {
        field
        code
        message
      }
      result {
        ...TailboardFormEntity
      }
      successful
    }
  }
  ${TAILBOARD_FORM_ENTITY_FRAGMENT}
`;

export const DELETE_TAILBOARD_FORM_MUTATION = gql`
  mutation deleteTailboardFormMutation($id: ID!) {
    deleteTailboardForm(id: $id) {
      ...TailboardFormEntity
    }
  }
  ${TAILBOARD_FORM_ENTITY_FRAGMENT}
`;

export const NEW_TAILBOARD_FORM_SUBSCRIPTION = gql`
  subscription tailboardFormSubscription {
    newTailboardForm {
      ...TailboardFormEntity
    }
  }
  ${TAILBOARD_FORM_ENTITY_FRAGMENT}
`;

// Checklist Form

export const CHECKLIST_FORM_SUBMISSIONS_QUERY = gql`
  {
    checklistFormSubmissions {
      ...FormSubmissionEntity
    }
  }
  ${FORM_SUBMISSION_ENTITY_FRAGMENT}
`;

export const CREATE_CHECKLIST_FORM_SUBMISSION_MUTATION = gql`
  mutation createChecklistFormSubmissionMutation(
    $userId: ID!
    $formId: ID!
    $data: Map!
    $isLocked: Boolean
  ) {
    createChecklistFormSubmission(
      userId: $userId
      formId: $formId
      data: $data
      isLocked: $isLocked
    ) {
      ...FormSubmissionEntity
    }
  }
  ${FORM_SUBMISSION_ENTITY_FRAGMENT}
`;

// Hazard Assessment Form

export const HAZARD_ASSESSMENT_FORM_SUBMISSIONS_QUERY = gql`
  {
    hazardAssessmentFormSubmissions {
      ...FormSubmissionEntity
    }
  }
  ${FORM_SUBMISSION_ENTITY_FRAGMENT}
`;

export const CREATE_HAZARD_ASSESSMENT_FORM_SUBMISSION_MUTATION = gql`
  mutation createHazardAssessmentFormSubmissionMutation(
    $userId: ID!
    $formId: ID!
    $data: Map!
    $isLocked: Boolean
  ) {
    createHazardAssessmentFormSubmission(
      userId: $userId
      formId: $formId
      data: $data
      isLocked: $isLocked
    ) {
      ...FormSubmissionEntity
    }
  }
  ${FORM_SUBMISSION_ENTITY_FRAGMENT}
`;

// Report Form

export const REPORT_FORM_SUBMISSIONS_QUERY = gql`
  {
    reportFormSubmissions {
      ...FormSubmissionEntity
    }
  }
  ${FORM_SUBMISSION_ENTITY_FRAGMENT}
`;

export const CREATE_REPORT_FORM_SUBMISSION_MUTATION = gql`
  mutation createReportFormSubmissionMutation(
    $userId: ID!
    $formId: ID!
    $data: Map!
    $isLocked: Boolean
  ) {
    createReportFormSubmission(
      userId: $userId
      formId: $formId
      data: $data
      isLocked: $isLocked
    ) {
      ...FormSubmissionEntity
    }
  }
  ${FORM_SUBMISSION_ENTITY_FRAGMENT}
`;

// Tailboard Form

export const TAILBOARD_FORMS_QUERY = gql`
  {
    tailboardForms {
      ...TailboardFormEntity
    }
  }
  ${TAILBOARD_FORM_ENTITY_FRAGMENT}
`;

export const TAILBOARD_FORM_QUERY = gql`
  query getTailboardForm($formId: String!) {
    tailboardForm(formId: $formId) {
      ...TailboardFormEntity
    }
  }
  ${TAILBOARD_FORM_ENTITY_FRAGMENT}
`;

export const TAILBORD_FORM_SUBMISSION_COMMENT_QUERY = gql`
  query tailboardFormSubmission($id: ID!) {
    tailboardFormSubmission(id: $id) {
      messages {
        field
        code
        message
      }
      result {
        ...TailboardFormSubmissionCommentEntity
      }
      successful
    }
  }
  ${TAILBOARD_FORM_SUBMISSION_COMMENT_ENTITY_FRAGMENT}
`;

export const TAILBOARD_FORM_SUBMISSION_QUERY = gql`
  query tailboardFormSubmission($id: ID!) {
    tailboardFormSubmission(id: $id) {
      messages {
        field
        code
        message
      }
      result {
        ...TailboardFormSubmissionEntity
      }
      successful
    }
  }
  ${TAILBOARD_FORM_SUBMISSION_ENTITY_FRAGMENT}
`;

export const TAILBOARD_FORM_SUBMISSIONS_QUERY = gql`
  query tailboardFormSubmissions(
    $formType: FormType
    $searchTerm: String
    $filters: [FilterInput]
    $after: String
  ) {
    tailboardFormSubmissions(
      formType: $formType
      searchTerm: $searchTerm
      filters: $filters
      after: $after
    ) {
      ...TailboardFormSubmissionPageEntity
    }
  }
  ${TAILBOARD_FORM_SUBMISSION_PAGE_ENTITY_FRAGMENT}
`;

export const DELETE_TAILBOARD_FORM_SUBMISSION_MUTATION = gql`
  mutation deleteTailboardFormSubmissionMutation($id: ID!) {
    deleteTailboardFormSubmission(id: $id) {
      messages {
        field
        code
        message
      }
      result {
        ...TailboardFormSubmissionEntity
      }
      successful
    }
  }
  ${TAILBOARD_FORM_SUBMISSION_ENTITY_FRAGMENT}
`;

export const ARCHIVED_TAILBOARD_FORM_SUBMISSIONS_QUERY = gql`
  query archivedTailboardFormSubmissions(
    $searchTerm: String
    $filters: [FilterInput]
  ) {
    archivedTailboardFormSubmissions(
      searchTerm: $searchTerm
      filters: $filters
    ) {
      ...TailboardFormSubmissionEntity
    }
  }
  ${TAILBOARD_FORM_SUBMISSION_ENTITY_FRAGMENT}
`;

export const UPDATE_TAILBOARD_FORM_SUBMISSION_ATTENDEES_MUTATION = gql`
  mutation updateTailboardFormSubmissionAttendeesMutation(
    $userId: ID!
    $submissionId: ID!
    $attendees: [String]
  ) {
    updateTailboardFormSubmissionAttendees(
      userId: $userId
      submissionId: $submissionId
      attendees: $attendees
    ) {
      attendees
    }
  }
`;

export const ATTENDEES_QUERY = gql`
  {
    getAttendees
  }
`;

export const TAILBOARD_FORM_SUBMISSIONS_UPDATED_SUBSCRIPTION = gql`
  subscription tailboardFormSubmissionsUpdated {
    tailboardFormSubmissionsUpdated {
      messages {
        field
        code
        message
      }
      result {
        ...TailboardFormSubmissionEntity
      }
      successful
    }
  }
  ${TAILBOARD_FORM_SUBMISSION_ENTITY_FRAGMENT}
`;
export const USER_TAILBOARD_FORM_NOTIFICATION_SUBSCRIPTION = gql`
  subscription userTailboardFormNotification($userId: ID!) {
    userTailboardFormNotification(userId: $userId) {
      messages {
        field
        code
        message
      }
      result {
        ...TailboardFormSubmissionEntity
      }
      successful
    }
  }
  ${TAILBOARD_FORM_SUBMISSION_ENTITY_FRAGMENT}
`;

export const DELETED_TAILBOARD_FORM_SUBMISSION_SUBSCRIPTION = gql`
  subscription deletedTailboardFormSubmission($userId: ID!) {
    deletedTailboardFormSubmission(userId: $userId) {
      messages {
        field
        code
        message
      }
      result {
        id
      }
      successful
    }
  }
`;

export const TAILBOARD_FORM_SUBMISSION_UPDATED_SUBSCRIPTION = gql`
  subscription tailboardFormSubmissionUpdated($id: ID!) {
    tailboardFormSubmissionUpdated(id: $id) {
      messages {
        field
        code
        message
      }
      result {
        ...TailboardFormSubmissionEntity
      }
      successful
    }
  }
  ${TAILBOARD_FORM_SUBMISSION_ENTITY_FRAGMENT}
`;
