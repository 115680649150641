<template>
  <preview-input-string :name="name" :value="value" />
</template>

<script>
import PreviewInputString from "./preview-input-string.vue";

export default {
  name: "PreviewInputNumber",
  props: {
    value: Number,
    name: String
  },
  components: { PreviewInputString }
};
</script>
