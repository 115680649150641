<template>
  <preview-input :disableGrid="true">
    <preview-input-name>{{ name }}</preview-input-name>

    <div class="selected-items" v-if="value && value.length > 0">
      <preview-input-value v-for="item in value" :key="item">
        {{ item }}
      </preview-input-value>
    </div>
    <preview-input-value v-else>&mdash;</preview-input-value>
  </preview-input>
</template>

<script>
import PreviewInputName from "./preview-input-name.vue";
import PreviewInputValue from "./preview-input-value.vue";
import PreviewInput from "./preview-input.vue";

export default {
  name: "PreviewFormSelectMany",
  props: {
    value: Array,
    name: String
  },
  components: {
    PreviewInputName,
    PreviewInputValue,
    PreviewInput
  }
};
</script>

<style scoped>
.selected-items {
  page-break-inside: avoid;
}
</style>
