<template>
  <div>
    <v-row>
      <v-alert
        v-if="tailboardFormSubmission.state === 'IN_PROGRESS'"
        type="warning"
        width="100%"
        class="mt-2"
        text
        dense
        >Please ensure form is completed before signing off</v-alert
      >
      <v-simple-table class="table-theme" fixed-header>
        <template>
          <thead>
            <tr>
              <th>Name</th>
              <th>Signature</th>
              <th></th>
            </tr>
          </thead>
          <tbody v-if="!hideVisitors">
            <tr
              v-for="(visitor, index) in visitorsList"
              :key="index + 1"
              style="height: 52px"
            >
              <td>{{ `${index + 1}. ${visitor.name}` }}</td>
              <td style="width: 180px">
                <v-btn
                  :disabled="
                    tailboardFormSubmission.state !== 'COMPLETED' || disabled
                  "
                  @click.stop="addSignature(index, visitor.name)"
                  width="186"
                  text
                  outlined
                >
                  <v-img
                    v-if="validUrl(visitor.url)"
                    height="34"
                    width="102"
                    :src="visitor.url"
                  />
                  <span v-else class="text-capitalize" style="width: 122px"
                    >Add Signature</span
                  >
                  <v-icon right>border_color</v-icon>
                </v-btn>
              </td>
              <td>
                <v-menu :disabled="!visitor.url" rounded="" offset-y>
                  <template v-slot:activator="{ attrs, on }">
                    <v-btn v-bind="attrs" v-on="on" icon>
                      <v-icon>more_horiz</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item btn @click="clearByLine(visitor.name)">
                      <v-list-item-title
                        ><v-icon left>delete_outline</v-icon
                        >Clear</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-row>
    <v-dialog persistent v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Add Signature</span>
          <v-spacer />
          <v-btn @click.stop="clear" text>
            <v-icon left>delete_outline</v-icon>Clear
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <p class="caption mt-2">
            Through initialing, the undersigned acknowledges that he/she has
            been made aware of the hazards and agrees to follow the direction of
            the On Site leader.
          </p>
          <h3 class="body-1 my-4">{{ page }}. {{ activeVisitor }}</h3>
          <canvas-input
            v-if="activeVisitor"
            ref="canvasInput"
            :disabled="disabled"
            :id="activeVisitor"
            :value="activeSignature"
            :showClearBtn="false"
            @input="signatureData = $event"
          />
        </v-card-text>
        <v-pagination
          v-model="page"
          :length="visitors.length"
          @input="setActiveVisitorByPage($event)"
          class="mb-4"
        />
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="cancel">Cancel</v-btn>
          <v-btn
            class="primary"
            @click="signOff"
            :disabled="hasActiveSignature || !signatureData || disabled"
            :loading="loading"
            >Save Signature</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import CanvasInput from "@/components/submission/canvas-input.vue";
import utils from "@/shared/Utils";
import getEnv from "@/utilities/env.js";

export default {
  name: "VisitorSignoff",

  props: {
    disabled: Boolean,
    tailboardFormSubmission: Object,
    tailboardFormTitle: String,
    visitorManagementComponentId: String,
    shouldHideVisitors: Boolean
  },

  components: {
    CanvasInput
  },

  data() {
    return {
      dialog: false,
      loading: false,
      activeVisitor: null,
      page: 1,
      signatureData: null,
      visitorsList: [],
      hideVisitors: false
    };
  },

  watch: {
    visitors: {
      handler: function(value) {
        if (value) {
          this.mappingVisitorsSignature(value);
        }
      }
    },
    componentInApplicabilities: {
      handler: function() {
        this.hideVisitors = this.tailboardFormSubmission.inapplicableComponents.some(
          component => component === this.visitorManagementComponentId
        );
      }
    },
    hideVisitors: {
      handler: function() {
        const currentVisitorsValue = this.getCurrentVisitorsValue();
        this.mappingVisitorsSignature(currentVisitorsValue);
      },
      immediate: true,
      deep: true
    },
    shouldHideVisitors: {
      handler() {
        this.hideVisitors = this.shouldHideVisitors;
      },
      immediate: true
    }
  },
  computed: {
    allVisitorSignatures() {
      return this.tailboardFormSubmission.signatures
        ? this.tailboardFormSubmission.signatures.filter(x => !x.employee)
        : [];
    },
    // Object mapping visitor names to signatures
    visitorSignatures() {
      return this.allVisitorSignatures.reduce((obj, signature) => {
        obj[signature.name] = signature;
        return obj;
      }, {});
    },

    activeSignature() {
      if (!this.activeVisitor) return null;
      const signature = this.visitorSignatures[this.activeVisitor];

      return signature ? signature.file : null;
    },

    hasActiveSignature() {
      return Boolean(this.activeSignature);
    },
    componentInApplicabilities() {
      return this.tailboardFormSubmission.inapplicableComponents;
    },
    visitors() {
      let visitors = this.tailboardFormSubmission.visitors.map(v =>
        JSON.parse(v)
      );
      visitors.forEach(async v => {
        if (this.visitorSignatures[v.name]) {
          v.url = `${getEnv("VUE_APP_SERVER_URL")}/doc/${
            this.visitorSignatures[v.name].file
          }`;
        }
      });
      return visitors;
    }
  },

  methods: {
    validUrl(url) {
      try {
        new URL(url);
        return true;
      } catch (e) {
        return false;
      }
    },
    getCurrentVisitorsValue() {
      return this.visitors;
    },
    mappingVisitorsSignature(value) {
      if (this.hideVisitors) {
        value = [];
      }

      this.visitorsList = [];
      value.forEach(async v => {
        let signatrueObject = {
          name: v.name,
          url: ""
        };
        const cacheUrl = await utils.mapUrlToCache(v.url, "image/png");
        signatrueObject.url = cacheUrl ? cacheUrl : v.url;
        this.visitorsList.push(signatrueObject);
      });
    },

    getNotifications() {
      return this.visitors.length - this.allVisitorSignatures.length;
    },
    addSignature(index, visitor) {
      this.activeVisitor = visitor;
      this.page = index + 1;
      this.dialog = true;
    },

    setActiveVisitorByPage(page) {
      this.$refs.canvasInput.clearCanvas();
      this.activeVisitor = this.visitors[page - 1].name;
      this.page = page;
    },
    clearByLine(visitorName) {
      const signature = this.visitorSignatures[visitorName];
      if (signature) this.$emit("delete", visitorName);
    },
    clear() {
      if (this.$refs.canvasInput) {
        if (this.activeVisitor) {
          const signature = this.visitorSignatures[this.activeVisitor];
          if (signature) this.$emit("delete", this.activeVisitor);
          this.signatureData = null;
        }
        this.$refs.canvasInput.clearCanvas();
      }
    },

    cancel() {
      this.$refs.canvasInput.clearCanvas();
      this.signatureData = null;
      this.dialog = false;
    },

    signOff() {
      this.loading = true;

      this.$emit(
        "signature",
        {
          file: this.signatureData,
          name: this.activeVisitor
        },
        () => {
          this.loading = false;

          if (this.visitors.length === this.allVisitorSignatures.length)
            this.cancel();
          else {
            const next =
              this.visitors.findIndex(
                ({ name, url }) => name !== this.activeVisitor && !url
              ) + 1;

            this.setActiveVisitorByPage(next);
          }
        }
      );
    }
  }
};
</script>
<style scoped>
.table-theme {
  width: 100% !important;
}
.table-theme table th {
  color: #005d55 !important;
}
.signature-image {
  border: 1px solid gainsboro;
  border-radius: 5px;
}
</style>
