<template>
  <div>
    <div class="model-main justify-content-center">
      <div class="model-header">
        <h2>Users</h2>
        <!-- <h2>{{ display == "new" ? "New User" : "Users" }}</h2> -->
        <!-- <form-modal
          v-if="display == 'index'"
          :fields="fields"
          title="New User"
          :mutation="createUserMutation"
          :value="user"
        />
        <v-btn v-if="display == 'show'" @click="display = 'index'">Back</v-btn> -->
      </div>
      <v-card>
        <custom-table
          :columns="columns"
          :data="users"
          @delete="deleteUser($event)"
          @edit="editUser($event)"
        />
      </v-card>
    </div>
    <user-edit
      :key="user.id"
      :value="user"
      :dialog="dialog"
      @close="dialog = false"
    />
  </div>
</template>

<script>
import { USERS_QUERY } from "@/graphql/accounts/queries.js";
import {
  // CREATE_USER_MUTATION,
  DELETE_USER_MUTATION
} from "@/graphql/accounts/mutations.js";
import {
  USER_CREATED_SUBSCRIPTION,
  USER_UPDATED_SUBSCRIPTION,
  USER_ROLE_UPDATED_SUBSCRIPTION,
  USER_DELETED_SUBSCRIPTION
} from "@/graphql/accounts/subscriptions.js";
// import formModal from "./form-modal.vue";
import customTable from "./custom-table.vue";
import userEdit from "@/components/user-edit.vue";

export default {
  name: "Users",

  components: {
    // formModal,
    customTable,
    userEdit
  },

  data() {
    return {
      display: "index",
      user: {},
      fields: [
        { label: "Name", name: "name", type: "text" },
        { label: "Email", name: "email", type: "text" },
        {
          label: "Role",
          name: "role",
          type: "select",
          options: [
            { value: "ADMIN", text: "Admin" },
            { value: "SAFETY", text: "Safety" },
            { value: "MANAGER", text: "Manager" },
            { value: "SUPERVISOR", text: "Supervisor" },
            { value: "USER", text: "User" }
          ]
        }
      ],
      updateFields: [
        { label: "Name", name: "name", type: "text" },
        { label: "Email", name: "email", type: "text" }
      ],
      users: [],
      columns: [
        { value: "name", text: "Name", sortable: true },
        { value: "email", text: "Email", sortable: false },
        { value: "role", text: "Role", sortable: true },
        { value: ["edit", "delete"], text: "Actions", sortable: false }
      ],
      dialog: false
    };
  },

  computed: {
    // createUserMutation() {
    //   return CREATE_USER_MUTATION;
    // }
  },

  methods: {
    editUser(user) {
      this.user = user;
      this.dialog = true;
    },

    deleteUser(user) {
      const opts = {
        mutation: DELETE_USER_MUTATION,
        variables: {
          id: user.id
        }
      };

      this.$apollo.mutate(opts).then(this.onFulfilled, this.onRejected);
    },

    onFulfilled({ data }) {
      const { successful, messages } = data.deleteUser;

      if (!successful) {
        console.error(messages);
      }
    },

    onRejected(error) {
      console.error(error);
    }
  },

  apollo: {
    users: {
      query: USERS_QUERY,
      fetchPolicy: "cache-and-network",
      subscribeToMore: [
        {
          document: USER_CREATED_SUBSCRIPTION,
          updateQuery: (previous, { subscriptionData }) => {
            if (!subscriptionData.data.userCreated) return previous;

            const { successful, result } = subscriptionData.data.userCreated;

            if (!successful) return previous;

            const users = [...previous.users];
            users.push(result);

            const updatedUsers = {
              ...previous,
              users: users
            };

            return updatedUsers;
          }
        },
        {
          document: USER_UPDATED_SUBSCRIPTION,
          updateQuery: (previous, { subscriptionData }) => {
            if (!subscriptionData.data.userUpdated) return previous;

            const { successful, result } = subscriptionData.data.userUpdated;

            if (!successful) return previous;

            let arrIndex = null;

            for (let i = 0; i < previous.users.length; i++) {
              if (previous.users[i].id === result.id) {
                arrIndex = i;
                break;
              }
            }

            if (arrIndex === null) return previous;

            const users = [...previous.users];
            const updatedUser = {
              ...users[arrIndex],
              name: result.name,
              email: result.email
            };
            users[arrIndex] = updatedUser;

            const updatedUsers = {
              ...previous,
              users: users
            };

            return updatedUsers;
          }
        },
        {
          document: USER_ROLE_UPDATED_SUBSCRIPTION,
          updateQuery: (previous, { subscriptionData }) => {
            if (!subscriptionData.data.userRoleUpdated) return previous;

            const {
              successful,
              result
            } = subscriptionData.data.userRoleUpdated;

            if (!successful) return previous;

            let arrIndex = null;

            for (let i = 0; i < previous.users.length; i++) {
              if (previous.users[i].id === result.id) {
                arrIndex = i;
                break;
              }
            }

            if (arrIndex === null) return previous;

            const users = [...previous.users];
            const updatedUser = {
              ...users[arrIndex],
              role: result.role
            };
            users[arrIndex] = updatedUser;

            const updatedUsers = {
              ...previous,
              users: users
            };

            return updatedUsers;
          }
        },
        {
          document: USER_DELETED_SUBSCRIPTION,
          updateQuery: (previous, { subscriptionData }) => {
            if (!subscriptionData.data.userDeleted) return previous;

            const { successful, result } = subscriptionData.data.userDeleted;

            if (!successful) return previous;

            const users = previous.users.filter(x => {
              return x.id !== result.id;
            });

            const updatedUsers = {
              ...previous,
              users: users
            };

            return updatedUsers;
          }
        }
      ]
    }
  }
};
</script>
