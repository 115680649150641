<template>
  <v-row class="mt-5" :key="id">
    <h3 class="ml-5">{{ title }}</h3>
    <v-spacer></v-spacer>
    <div class="mr-5">
      <v-btn
        :disabled="disabled || recordingInProgress === false"
        :class="[recordingInProgress ? '' : 'primary']"
        @click="startRecording()"
        :loading="busy"
      >
        <v-icon :color="recordingInProgress ? 'red' : ''" class="mr-2">{{
          state === "pause" ? "pause" : "mic"
        }}</v-icon>
        {{ recordingState }}
      </v-btn>
      <v-btn
        :class="[recordingInProgress ? 'primary' : '']"
        class="ml-2"
        :disabled="state === 'inactive' || disabled"
        @click="stopRecording()"
        :loading="busy"
      >
        <v-icon class="mr-2" :color="recordingInProgress ? 'red' : ''"
          >stop</v-icon
        >
        Stop
      </v-btn>
      <v-menu
        rounded=""
        v-model="showRecordingMenu"
        offset-y
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ attrs, on }">
          <v-btn
            :disabled="
              disabled ||
                recordings.length === 0 ||
                recordingInProgress !== null
            "
            class="ml-2 whiteBackground primary--text"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon class="mr-2" color="primary">playlist_play</v-icon>
            Recordings List
          </v-btn>
        </template>
        <v-card>
          <div class="recording-list">
            <h4 class="mb-2">Recordings</h4>
            <v-expansion-panels multiple>
              <v-expansion-panel
                v-for="(recording, idx) in recordings"
                :key="recording.ts"
              >
                <v-expansion-panel-header class="caption"
                  ><span>{{ `Recording ${idx + 1} - ${recording.time}` }}</span>
                  <v-spacer></v-spacer>
                  <v-btn
                    :disabled="disabled"
                    @click.stop="deleteRecording(recording)"
                    class="remove-btn"
                    small
                    icon
                  >
                    <v-icon>delete_outline</v-icon>
                  </v-btn>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div
                    class="d-flex"
                    :class="{
                      'section-disabled': recording.loaded || disabled
                    }"
                  >
                    <audio
                      :src="recording.blobUrl"
                      :type="recording.type"
                      @loadstart="onLoadstart(idx)"
                      @canplaythrough="onCanPlayThrough(idx)"
                      @canplay="clearPlaybackStatus(idx)"
                      @stalled="onStalled(idx)"
                      @loadeddata="clearPlaybackStatus(idx)"
                      @playing="clearPlaybackStatus(idx)"
                      @error="onLoadError(idx, $event)"
                      controls="true"
                    />
                    <v-progress-circular
                      v-if="recording.loaded"
                      class="mt-3 ml-1"
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                    <!-- <v-btn class="mt-3 ml-2" color="primary" icon>
                      <v-icon color="primary">file_download</v-icon>
                    </v-btn> -->
                  </div>
                  <v-alert
                    v-if="recording.playbackStatus === 'error'"
                    class="mt-2"
                    color="red"
                    dense
                    outlined
                    type="error"
                  >
                    Failed to load audio
                    <v-btn class="ml-2" outlined small @click="retryLoad(idx)"
                      >Retry</v-btn
                    >
                  </v-alert>
                  <v-alert
                    v-if="recording.playbackStatus === 'stalled'"
                    class="mt-2"
                    color="blue"
                    type="info"
                  >
                    It's still loading, please be patient...
                  </v-alert>
                  <!-- <div>
                  size: {{ recording.size | fileSizeToHumanSize }}, type:
                  {{ recording.mimeType }}
                </div> -->
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
          <v-card-actions>
            <v-spacer />
            <v-btn text @click="showRecordingMenu = false"> Close </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <v-menu
        rounded=""
        v-model="showMenu"
        offset-y
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ attrs, on }">
          <v-btn
            :disabled="recordingInProgress !== null"
            class="ml-2 whiteBackground primary--text"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon class="mr-2" color="primary">settings_voice</v-icon>
            Settings
          </v-btn>
        </template>
        <v-card>
          <div style="width: 400px; padding: 15px 10px">
            <label for="selectedDevice-id" class="font-weight-black caption"
              >Microphone</label
            >
            <div class="d-flex mt-n2">
              <v-select
                id="selectedDevice-id"
                :items="availableDevices"
                v-model="selectedDeviceId"
                label="Select Mic"
                single-line
                item-text="name"
                item-value="device.deviceId"
              ></v-select>
              <v-btn
                class="mt-5 ml-2"
                color="primary"
                icon
                small
                @click="enumerateDevicesWithPermission"
              >
                <v-icon>remove_red_eye</v-icon>
              </v-btn>
            </div>
            <div class="d-flex mt-4">
              <v-slider
                class="font-weight-black"
                label="Mic Gain"
                :max="500"
                v-model="micGainSlider"
              ></v-slider>
              <label
                class="primary--text mr-2 mt-1 caption"
                style="width: 25px"
                >{{ micGain }}</label
              >
            </div>
            <v-switch
              v-model="enableEchoCancellation"
              label="Enable echo cancellation"
            >
            </v-switch>
            <v-select
              class="ma-2"
              :items="encoders"
              v-model="selectedEncoder"
              label="Select Encoder"
              single-line
              item-text="name"
              item-value="id"
              :disabled="recordingInProgress"
            />
          </div>
          <v-card-actions>
            <v-spacer />
            <v-btn text @click="showMenu = false"> Close </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </div>
    <dialog-message
      :showDialogMessage="showDialogMessage"
      displayCaption="Delete"
      displayText="Are you sure to delete the file?"
      :okAction="okAction"
      :cancelAction="cancelAction"
    />
  </v-row>
</template>

<script>
import dayjs from "@/plugins/dayjs";
import AudioRecorderService from "@/shared/AudioRecorderService";
import { workers } from "@/shared/workers.js";
import utils from "@/shared/Utils";
import dialogMessage from "@/components/submission/dialog-message.vue";
import getEnv from "@/utilities/env.js";
import appsignal from "@/plugins/appsignal";

const encoders = [
  { id: "wav", name: "audio/wav - custom - mono", contentType: "audio/x-wav" }
  // { id: "mp3", name: "audio/mpeg - mono/128kbps" },
  // { id: "ogg", name: "audio/ogg - mono/~128kps" }
];
export default {
  name: "AudioRecorder",
  props: {
    disabled: Boolean,
    title: String,
    initialValue: String,
    id: String
  },
  components: {
    dialogMessage
  },
  filters: {
    fileSizeToHumanSize(val) {
      return utils.humanFileSize(val, true);
    }
  },
  data() {
    return {
      recordings: [],
      busy: false,
      state: "",
      encoders: encoders,
      hasMediaRecorder: window.MediaRecorder || false,
      selectedEncoder: {
        id: encoders[0].id,
        name: encoders[0].name,
        contentType: encoders[0].contentType
      },
      showMenu: false,
      showRecordingMenu: false,
      showDialogMessage: false,
      availableDevices: [],
      selectedDeviceId: null,
      enableEchoCancellation: true,
      supportedMimeTypes: [],
      micGainSlider: 100,
      micGain: 1.0,
      cleanupWhenFinished: true,
      addDynamicsCompressor: false,
      selectedRecording: null,
      recorderService: null
    };
  },
  created() {
    this.recorderService = new AudioRecorderService();
    this.recorderService.config.usingMediaRecorder = false;

    this.hasMediaRecorder = this.recorderService.config.usingMediaRecorder;
    this.recorderService.em.addEventListener("recording", evt =>
      this.onNewRecording(evt)
    );
    this.enumerateDevices();
  },
  beforeDestroy() {
    if (this.recordingInProgress === true) {
      this.stopRecording();
    }
  },
  computed: {
    recordingInProgress() {
      const recordingId = this.$store.getters.getRecordingConference.id;
      return recordingId === this.id
        ? true
        : recordingId === null
        ? null
        : false;
    },
    recordingState() {
      switch (this.state) {
        case "pause":
          return `Resume Recording`;
        case "recording":
          return `Pause Recording`;
        default:
          return "Record Conference";
      }
    }
  },
  watch: {
    disabled(value) {
      if (value && this.state === "recording")
        this.recorderService.pauseRecording();
    },
    initialValue: {
      deep: true,
      immediate: true,
      handler: async function(value) {
        let recordingList = value ? JSON.parse(value) : [];
        this.recordings = recordingList
          ? recordingList.map(r => {
              return {
                blobUrl: `${getEnv("VUE_APP_SERVER_URL")}/doc/${r.blobUrl}`,
                uuid: r.blobUrl,
                ts: Math.random(),
                type: r.type ? r.type : this.selectedEncoder.contentType,
                size: r.size,
                createdTime: r.createdTime,
                time: dayjs
                  .utc(r.createdTime)
                  .local()
                  .format("YYYY-MM-DD, HH:mm"),
                loaded: false,
                playbackStatus: null
              };
            })
          : [];
        for (let i = 0; i < this.recordings.length; i++) {
          const cacheUrl = await utils.mapUrlToCache(
            this.recordings[i].blobUrl,
            this.selectedEncoder.contentType
          );
          if (cacheUrl) this.recordings[i].blobUrl = cacheUrl;
        }
      }
    },
    recorderService: {
      immediate: true,
      deep: true,
      handler: function(value) {
        if (value) {
          this.state = value.state;
          if (value.state !== "inactive")
            this.$store.commit("setRecordingConference", {
              id: this.id,
              state: value.state
            });
        }
      }
    },
    cleanupWhenFinished() {
      this.recorderService.config.stopTracksAndCloseCtxWhenFinished = this.cleanupWhenFinished;
    },
    micGainSlider() {
      this.micGain = (this.micGainSlider * 0.01).toFixed(2);
      this.recorderService.setMicGain(this.micGain);
    }
  },
  methods: {
    onStalled(idx) {
      this.recordings[idx].playbackStatus = "stalled";
    },
    clearPlaybackStatus(idx) {
      this.recordings[idx].playbackStatus = null;
    },
    onLoadError(idx, event) {
      const msg = "Failed to load audio.";
      try {
        const realErr = event.target.error || {};
        const errMsg = `${realErr.constructor.name} msg=${realErr.message} code=${realErr.code}`;
        console.error(
          `${msg} See https://developer.mozilla.org/en-US/docs/Web/API/MediaError/code for code meaning`,
          errMsg
        );
      } catch (err2) {
        console.warn("Error while handling an error. Second error:", err2);
        console.error(msg, event);
      }
      this.recordings[idx].loaded = false;
      this.recordings[idx].playbackStatus = "error";
    },
    retryLoad(idx) {
      this.recordings[idx].playbackStatus = null;
      // TODO yes, this is a dirty hack but I couldn't figure another way to
      // reset a failed audio element. Fix this if you can.
      const stash = this.recordings[idx].blobUrl;
      this.recordings[idx].blobUrl = false;
      this.$nextTick().then(() => {
        this.recordings[idx].blobUrl = stash;
      });
    },
    onCanPlayThrough(index) {
      this.recordings[index].loaded = false;
    },
    onLoadstart(idx) {
      this.recordings[idx].loaded = true;
    },
    deleteRecording(recording) {
      this.selectedRecording = recording;
      this.showDialogMessage = true;
    },
    okAction() {
      this.recordings.splice(
        this.recordings.findIndex(r => r.ts === this.selectedRecording.ts),
        1
      );
      URL.revokeObjectURL(this.selectedRecording.blobUrl);
      this.saveChanges();
      this.selectedRecording = null;
      this.showDialogMessage = false;
    },
    cancelAction() {
      this.selectedRecording = null;
      this.showDialogMessage = false;
    },
    enumerateDevicesWithPermission() {
      navigator.mediaDevices
        .getUserMedia({ audio: true, deviceId: "default" })
        .then(stream => {
          this.enumerateDevices();
          this.stream = stream;
        })
        .catch(error => {
          appsignal.sendError(error);
        });
    },
    setupAvailDeviceNames() {
      let availDevices = [];
      this.enumeratedDevices.forEach(device => {
        if (device.kind === "audioinput") {
          if (!device.label) {
            this.enumeratedDevicesPermissionNeeded = true;
            availDevices.push({
              name: "Audio Input " + (availDevices.length + 1),
              device: device
            });
          } else {
            availDevices.push({ name: device.label, device: device });
          }
        }
      });
      this.availableDevices = availDevices;
    },
    enumerateDevices() {
      navigator.mediaDevices
        .enumerateDevices()
        .then(devices => {
          this.enumeratedDevices = devices;
          this.setupAvailDeviceNames();
          if (
            this.availableDevices &&
            this.availableDevices.length > 1 &&
            this.availableDevices[0].device
          ) {
            this.selectedDeviceId = this.availableDevices[0].device.deviceId;
          }
          if (this.stream) {
            this.stream.getTracks().forEach(track => track.stop());
            this.stream = null;
          }
        })
        .catch(error => {
          appsignal.sendError(error);
          this.enumeratedDevices.push({
            id: "-",
            kind: "error",
            deviceId: "error"
          });
        });
    },

    startRecording() {
      switch (this.state) {
        case "inactive": {
          this.recorderService.config.deviceId = this.selectedDeviceId;
          this.recorderService.config.manualEncoderId = this.selectedEncoder.id;
          this.recorderService.config.stopTracksAndCloseCtxWhenFinished = this.cleanupWhenFinished;
          this.recorderService.config.createDynamicsCompressorNode = this.addDynamicsCompressor;
          this.recorderService.config.enableEchoCancellation = this.enableEchoCancellation;
          this.recorderService
            .startRecording()
            .then(() => {})
            .catch(error => {
              console.error("Exception while start recording: " + error);
              alert("Exception while start recording: " + error.message);
            });
          break;
        }
        case "recording":
          this.recorderService.pauseRecording();
          break;
        case "pause":
          this.recorderService.resumeRecording();
          break;
      }
    },
    stopRecording() {
      this.recorderService.stopRecording();
      this.$store.commit("setRecordingConference", { id: null, state: null });
    },
    saveChanges() {
      let newResult = this.recordings.map(r => {
        return {
          blobUrl: r.uuid,
          createdTime: r.createdTime,
          type: r.type,
          size: r.size
        };
      });
      this.$emit(
        "change",
        newResult.length > 0 ? JSON.stringify(newResult) : null
      );
    },
    async onNewRecording(evt) {
      try {
        this.busy = true;
        const filesList = [];
        filesList.push({
          file: evt.detail.recording,
          uuid: utils.getUUID(),
          type: this.selectedEncoder.contentType
        });
        let worker = workers.uploadWorker();
        worker.postMessage({
          action: "start",
          files: filesList,
          uri: `${getEnv("VUE_APP_SERVER_URL")}`
        });
        worker.onmessage = e => {
          switch (e.data.status) {
            case 0:
            case 1:
              {
                let newFile = e.data.newFile;
                newFile.createdTime = dayjs.utc().format();
                newFile.time = dayjs().format("YYYY-MM-DD, HH:mm");
                newFile.loaded = false;
                this.recordings.push(newFile);
                this.saveChanges();
              }
              break;
            case 3:
              appsignal.sendError(e.data.exception);
              break;
          }
        };
      } finally {
        this.busy = false;
      }
    }
  }
};
</script>
<style scoped>
audio:focus {
  outline: none;
}

.recording-list {
  width: 400px;
  padding: 15px 10px 5px 10px;
  max-height: 450px;
  overflow-y: auto;
}
</style>
