<template>
  <v-text-field
    outlined
    :id="id"
    type="number"
    :value="percent"
    :placeholder="placeHolderText"
    :disabled="disabled"
    suffix="%"
    :rules="rules"
    @input="updateInput($event)"
    @keypress="checkForNumeric"
  />
</template>
<script>
export default {
  name: "percentage",
  props: {
    value: String,
    placeholder: String,
    disabled: Boolean,
    id: String,
    rules: Array
  },
  computed: {
    percent() {
      return Number((this.value * 100).toPrecision(3));
    },
    placeHolderText() {
      return this.value === "N/A" ? "N/A" : this.placeholder;
    }
  },
  methods: {
    updateInput($event) {
      this.$emit("change", $event / 100);
    },

    checkForNumeric($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      } else {
        let inputValue = $event.target.value;
        if (keyCode == 46 && inputValue.indexOf(".") != -1) {
          $event.preventDefault();
        }
      }
    }
  }
};
</script>
