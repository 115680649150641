<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="50"
    viewBox="0 0 50 50"
  >
    <g
      fill="none"
      fill-rule="evenodd"
      stroke="#005d55"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    >
      <path
        d="M20.709 33.66L15 35l1.34-5.708L30.11 15.517c.69-.69 1.81-.69 2.499 0l1.874 1.873c.688.69.688 1.807 0 2.497L20.709 33.661zM32 21l-3-3M20 33l-3-3"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Edit"
};
</script>
