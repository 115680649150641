<template>
  <preview-form-component class="component-visitor-management">
    <preview-form-title :level="3">VISITORS/CONTRACTORS</preview-form-title>
    <!-- Conditionally display N/A or the list -->
    <preview-form-group v-if="inapplicationComponent">
      <h5 class="is-na preview-form-title preview-form-title-h5">N/A</h5>
    </preview-form-group>
    <preview-form-group
      class="visitors-list"
      :as="'ul'"
      v-else-if="visitors && visitors.length > 0"
    >
      <li class="visitor" v-for="visitor in visitors" :key="visitor.id">
        <preview-input-string :name="'Visitor'" :value="visitor.name" />
        <preview-input-string
          :name="'Visitor Details'"
          :value="visitor.visitDetails"
        />
      </li>
    </preview-form-group>
  </preview-form-component>
</template>

<script>
import PreviewFormComponent from "./preview-form-component.vue";
import PreviewFormGroup from "./preview-form-group.vue";
import PreviewFormTitle from "./preview-form-title.vue";
import PreviewInputString from "./preview-input-string.vue";

export default {
  name: "PreviewComponentVisitorManagement",
  props: {
    submission: Object,
    inapplicationComponent: String
  },
  components: {
    PreviewFormComponent,
    PreviewFormGroup,
    PreviewFormTitle,
    PreviewInputString
  },
  computed: {
    visitors() {
      if (!this.submission.visitors) return [];

      return this.submission.visitors.map(v => JSON.parse(v));
    }
  }
};
</script>

<style scoped>
.visitors-list {
  list-style: none;
  margin: 0;
  padding: 4px 8px;
}

.visitor {
  page-break-inside: avoid;
}

.preview-form-title-h5 {
  padding: 1em;
  margin: 4px;
  background-color: var(--v-whiteBackground-base);
  font-size: 1em;
  border-radius: 0.2em;
}
</style>
