var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ 'text--disabled': _vm.isDisabled }},[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isFullscreen),expression:"!isFullscreen"}],staticClass:"pa-0 ma-0"},[_c('div',{staticClass:"pa-0 ma-0"},[_c('div',{staticClass:"d-flex"},[_c('label',{staticClass:"primary--text pa-0 ma-0",class:{ 'primary--text text--lighten-4': _vm.isDisabled },attrs:{"for":_vm.field.id},domProps:{"innerHTML":_vm._s(_vm.textToHTML(_vm.field.name))}}),(_vm.displayAsterisk)?_c('span',{staticClass:"red--text ml-2"},[_vm._v("*")]):_vm._e(),(_vm.field.inputType == 'CHECKBOX')?_c('v-checkbox',{staticClass:"pa-0 ma-0 ml-2",attrs:{"id":_vm.field.id,"placeholder":_vm.field.placeholderText,"false-value":'false',"true-value":'true',"value":_vm.value,"input-value":_vm.value,"rules":[
            _vm.rules.required,
            _vm.rules.requiredIfOtherFieldEquals,
            _vm.rules.containsAnyIfOtherFieldEquals,
            _vm.rules.notEqualToField
          ],"disabled":_vm.isDisabled,"hide-details":""},on:{"change":function($event){return _vm.$emit('change', $event)}}}):_vm._e()],1),_c('p',{staticClass:"caption pa-0 ma-0",domProps:{"innerHTML":_vm._s(_vm.textToHTML(_vm.field.description))}})]),_c('v-spacer'),(_vm.field.allowNotApplicable)?_c('v-checkbox',{staticClass:"pa-0 ma-0 mr-2",attrs:{"input-value":_vm.isDisabled,"value":"","disabled":_vm.disabled,"label":"N/A"},on:{"change":function($event){return _vm.setNotApplicable($event)}}}):_vm._e()],1),_c('v-row',{staticClass:"pa-0 ma-0"},[(_vm.field.inputType == 'STRING')?_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isFullscreen),expression:"!isFullscreen"}],attrs:{"outlined":"","id":_vm.field.id,"placeholder":_vm.field.placeholderText || _vm.field.defaultValue,"value":_vm.value,"counter":_vm.maxLimit,"maxlength":_vm.maxLimit,"disabled":_vm.isDisabled,"rules":[
        _vm.rules.required,
        _vm.rules.requiredIfOtherFieldEquals,
        _vm.rules.containsAnyIfOtherFieldEquals,
        _vm.rules.notEqualToField,
        _vm.rules.min,
        _vm.rules.max
      ]},on:{"input":function($event){return _vm.$emit('change', $event)},"blur":_vm.checkForDefaultValue}}):(_vm.field.inputType == 'DECIMAL' || _vm.field.inputType == 'NUMBER')?_c('numeric',{attrs:{"id":_vm.field.id,"placeholder":_vm.field.placeholderText,"value":_vm.value,"rules":[
        _vm.rules.required,
        _vm.rules.requiredIfOtherFieldEquals,
        _vm.rules.containsAnyIfOtherFieldEquals,
        _vm.rules.notEqualToField,
        _vm.rules.min,
        _vm.rules.max
      ],"isDecimal":_vm.field.inputType == 'DECIMAL',"disabled":_vm.isDisabled},on:{"change":function($event){return _vm.$emit('change', $event)}}}):(_vm.field.inputType == 'PERCENT')?_c('percentage',{attrs:{"id":_vm.field.id,"placeholder":_vm.field.placeholderText,"value":_vm.value,"rules":[
        _vm.rules.required,
        _vm.rules.requiredIfOtherFieldEquals,
        _vm.rules.containsAnyIfOtherFieldEquals,
        _vm.rules.notEqualToField,
        _vm.rules.min,
        _vm.rules.max
      ],"disabled":_vm.isDisabled},on:{"change":function($event){return _vm.$emit('change', $event)}}}):(_vm.field.inputType == 'ADDRESS')?_c('gps-address',{attrs:{"id":_vm.field.id,"placeholder":_vm.field.placeholderText,"value":_vm.value,"rules":[
        _vm.rules.required,
        _vm.rules.requiredIfOtherFieldEquals,
        _vm.rules.containsAnyIfOtherFieldEquals,
        _vm.rules.notEqualToField
      ],"disabled":_vm.isInactive,"isOnline":_vm.isOnline},on:{"change":function($event){return _vm.$emit('change', $event)}}}):(_vm.field.inputType == 'WEATHER')?_c('gps-weather',{attrs:{"id":_vm.field.id,"placeholder":_vm.field.placeholderText,"value":_vm.value,"rules":[_vm.rules.required, _vm.rules.requiredIfOtherFieldEquals],"disabled":_vm.isInactive,"isOnline":_vm.isOnline},on:{"change":function($event){return _vm.$emit('change', $event)}}}):(_vm.field.inputType == 'TEXT')?_c('v-textarea',{attrs:{"outlined":"","id":_vm.field.id,"placeholder":_vm.field.placeholderText,"value":_vm.value,"disabled":_vm.isDisabled,"auto-grow":"","rules":[
        _vm.rules.required,
        _vm.rules.requiredIfOtherFieldEquals,
        _vm.rules.containsAnyIfOtherFieldEquals,
        _vm.rules.notEqualToField,
        _vm.rules.min,
        _vm.rules.max
      ]},on:{"input":function($event){return _vm.$emit('change', $event)}}}):(_vm.field.inputType == 'YES_NO_NA')?_c('yes-no',{attrs:{"id":_vm.field.id,"value":_vm.value,"disabled":_vm.isDisabled,"rules":[
        _vm.rules.required,
        _vm.rules.requiredIfOtherFieldEquals,
        _vm.rules.containsAnyIfOtherFieldEquals,
        _vm.rules.notEqualToField
      ],"hide-details":""},on:{"change":function($event){return _vm.$emit('change', $event)}}}):(_vm.field.inputType == 'BOOLEAN')?_c('v-switch',{attrs:{"id":_vm.field.id,"false-value":'false',"true-value":'true',"value":_vm.value,"input-value":_vm.value,"disabled":_vm.isDisabled,"rules":[
        _vm.rules.required,
        _vm.rules.requiredIfOtherFieldEquals,
        _vm.rules.containsAnyIfOtherFieldEquals,
        _vm.rules.notEqualToField
      ],"hide-details":""},on:{"change":function($event){return _vm.$emit('change', $event)}}}):(_vm.field.inputType == 'SELECT_ONE')?_c('select-one',{attrs:{"id":_vm.field.id,"items":_vm.field.options,"placeholder":_vm.field.placeholderText,"allowOther":_vm.field.allowOther,"displayInline":_vm.field.displayInline,"initialValue":_vm.value,"rules":[
        _vm.rules.required,
        _vm.rules.requiredIfOtherFieldEquals,
        _vm.rules.containsAnyIfOtherFieldEquals,
        _vm.rules.notEqualToField
      ],"disabled":_vm.isDisabled},on:{"change":function($event){return _vm.$emit('change', $event)}}}):(_vm.field.inputType == 'SELECT_MANY')?_c('select-many',{attrs:{"items":_vm.field.options,"placeholder":_vm.field.placeholderText,"allowOther":_vm.field.allowOther,"displayInline":_vm.field.displayInline,"initialValue":_vm.value,"rules":[
        _vm.rules.required,
        _vm.rules.requiredIfOtherFieldEquals,
        _vm.rules.containsAnyIfOtherFieldEquals,
        _vm.rules.notEqualToField
      ],"disabled":_vm.isDisabled},on:{"change":function($event){return _vm.$emit('change', $event)}}}):(_vm.field.inputType == 'SIGNATURE')?_c('canvas-input',{attrs:{"id":_vm.field.id,"value":_vm.value,"rules":[_vm.rules.required, _vm.rules.requiredIfOtherFieldEquals],"disabled":_vm.isDisabled},on:{"input":function($event){return _vm.$emit('change', $event)}}}):(_vm.field.inputType == 'DRAWING')?_c('canvas-input',{attrs:{"id":_vm.field.id,"asset":_vm.field.relatedAsset,"value":_vm.value,"rules":[_vm.rules.required, _vm.rules.requiredIfOtherFieldEquals],"disabled":_vm.isDisabled},on:{"input":function($event){return _vm.$emit('change', $event)}}}):(_vm.field.inputType == 'DATE')?_c('datepicker',{attrs:{"id":_vm.field.id,"placeholder":_vm.field.placeholderText,"value":_vm.value,"rules":[
        _vm.rules.required,
        _vm.rules.requiredIfOtherFieldEquals,
        _vm.rules.containsAnyIfOtherFieldEquals
      ],"disabled":_vm.isDisabled},on:{"change":function($event){return _vm.$emit('change', $event)}}}):(_vm.field.inputType == 'DATETIME')?_c('datetimepicker',{attrs:{"id":_vm.field.id,"placeholder":_vm.field.placeholderText,"initialValue":_vm.value,"rules":[_vm.rules.required, _vm.rules.requiredIfOtherFieldEquals],"disabled":_vm.isDisabled},on:{"change":function($event){return _vm.$emit('change', $event)}}}):(_vm.field.inputType == 'TIME')?_c('v-text-field',{attrs:{"type":"time","rules":[_vm.rules.required, _vm.rules.requiredIfOtherFieldEquals],"value":_vm.getTime,"id":_vm.field.id,"placeholder":_vm.field.placeholderText,"disabled":_vm.isDisabled,"clearable":"","outlined":""},on:{"input":function($event){return _vm.updateTime($event)},"click:clear":function($event){return _vm.$emit('change', null)}}}):(_vm.field.inputType == 'TRAFFIC_PLAN')?_c('traffic-plan',{attrs:{"id":_vm.field.id,"label":_vm.field.name,"value":_vm.value,"rules":[_vm.rules.required, _vm.rules.requiredIfOtherFieldEquals],"disabled":_vm.isInactive,"placeholder":_vm.field.placeholder},on:{"input":function($event){return _vm.$emit('change', $event)},"alertError":function($event){return _vm.$emit('alertError', $event)}}}):(
        _vm.field.inputType == 'EMPLOYEE' || _vm.field.inputType == 'EMPLOYEE_MANY'
      )?_c('employee-autocomplete',{attrs:{"id":_vm.field.id,"placeholder":_vm.field.placeholderText,"disabled":_vm.isDisabled,"initialValue":_vm.value,"multiple":_vm.field.inputType == 'EMPLOYEE_MANY',"rules":[
        _vm.rules.required,
        _vm.rules.requiredIfOtherFieldEquals,
        _vm.rules.containsAnyIfOtherFieldEquals,
        _vm.rules.notEqualToField
      ],"crewMembers":_vm.crewMembers},on:{"change":function($event){return _vm.$emit('change', $event)}}}):(_vm.field.inputType == 'AUDIO')?_c('audio-recorder',{attrs:{"title":"","id":_vm.field.id,"disabled":_vm.isDisabled,"initialValue":_vm.value,"rules":[
        _vm.rules.required,
        _vm.rules.requiredIfOtherFieldEquals,
        _vm.rules.containsAnyIfOtherFieldEquals
      ]},on:{"change":function($event){return _vm.$emit('change', $event, true)},"alertError":function($event){return _vm.$emit('alertError', $event)}}}):(_vm.field.inputType == 'FILE')?_c('file-upload',{attrs:{"id":_vm.field.id,"disabled":_vm.isInactive,"initialValue":_vm.value,"rules":[
        _vm.rules.required,
        _vm.rules.requiredIfOtherFieldEquals,
        _vm.rules.containsAnyIfOtherFieldEquals
      ]},on:{"change":function($event){return _vm.$emit('change', $event)}}}):(_vm.field.inputType == 'COMBOBOX')?_c('combobox',{attrs:{"id":_vm.field.id,"source":_vm.field.placeholderText,"disabled":_vm.isInactive,"initialValue":_vm.value,"rules":[
        _vm.rules.required,
        _vm.rules.requiredIfOtherFieldEquals,
        _vm.rules.containsAnyIfOtherFieldEquals
      ]},on:{"change":function($event){return _vm.$emit('change', $event)}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }