<template>
  <v-text-field
    outlined
    :type="isDecimal ? 'text' : 'number'"
    :id="id"
    :label="label"
    :value="value"
    :isDecimal="isDecimal"
    :placeholder="placeHolderText"
    :disabled="disabled"
    error-message
    :rules="rules"
    @input="$emit('change', $event)"
    @keypress="checkForDecimal"
  />
</template>

<script>
export default {
  name: "numeric",
  props: {
    label: String,
    value: String,
    placeholder: String,
    id: String,
    isDecimal: Boolean,
    disabled: Boolean,
    rules: Array
  },
  computed: {
    placeHolderText() {
      return this.value === "N/A" ? "N/A" : this.placeholder;
    }
  },
  methods: {
    checkForDecimal($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
      if (this.isDecimal) {
        let inputValue = $event.target.value;
        if (keyCode == 46 && inputValue.indexOf(".") != -1) {
          $event.preventDefault();
        }
      } else {
        if (keyCode == 46) $event.preventDefault();
      }
    }
  }
};
</script>
