<template>
  <preview-input-name
    :class="[{ 'is-checked': value }, 'preview-input-checkbox']"
  >
    {{ name }}
  </preview-input-name>
</template>

<script>
import previewInputName from "./preview-input-name.vue";

export default {
  components: { previewInputName },
  name: "PreviewInputCheckbox",
  props: {
    name: String,
    value: Boolean
  }
};
</script>

<style scoped>
.preview-input-checkbox {
  page-break-inside: avoid;
}

.preview-input-checkbox::before {
  align-items: center;
  background-color: var(--v-whiteBackground-base);
  border: solid 2px var(--v-primary-lighten4);
  border-radius: 3px;
  color: var(--v-primary-base);
  content: "";
  display: inline-flex;
  font-weight: 700;
  height: 27px;
  justify-content: center;
  margin-right: 16px;
  width: 27px;
}

.preview-input-checkbox.is-checked::before {
  content: "×";
}
</style>
