<template>
  <div class="model-main">
    <div class="model-header">
      <h2>Procedures</h2>
      <form-modal
        v-if="display == 'index' && user.role == 'ADMIN'"
        :fields="fields"
        :title="mutationLabel"
        :mutation="mutation"
        :value="{}"
      />
      <v-btn v-if="display == 'show'" @click="display = 'index'">Back</v-btn>
    </div>
    <v-card>
      <v-tabs
        v-model="currentTab"
        @change="updateMutation($event)"
        :hide-slider="display != 'index'"
      >
        <v-tab v-if="display == 'index'" ripple>Procedures</v-tab>
        <v-tab v-if="display == 'index' && user.role == 'ADMIN'" ripple
          >Procedure Groups</v-tab
        >
        <v-tab-item>
          <custom-table
            :columns="procedureHeaders"
            :data="procedures"
            :show="display == 'show'"
            @show="fetchPDF"
          />
        </v-tab-item>
        <v-tab-item v-if="user.role == 'ADMIN'">
          <custom-table
            :columns="groupHeaders"
            :data="procedureGroups"
            :show="display == 'show'"
            @show="display = 'show'"
          />
        </v-tab-item>
      </v-tabs>
    </v-card>
  </div>
</template>

<script>
import {
  PROCEDURES_QUERY,
  PROCEDURE_GROUPS_QUERY,
  CREATE_PROCEDURE_MUTATION,
  CREATE_PROCEDURE_GROUP_MUTATION,
  NEW_PROCEDURE_SUBSCRIPTION,
  NEW_PROCEDURE_GROUP_SUBSCRIPTION
} from "../constants/section-queries";
import formModal from "./form-modal.vue";
import customTable from "./custom-table.vue";

export default {
  name: "Procedures",
  components: { formModal, customTable },
  data() {
    return {
      display: "index",
      currentTab: 0,
      mutation: CREATE_PROCEDURE_MUTATION,
      mutationLabel: "New Procedure",
      fields: [],
      groupFields: [
        { label: "Name", name: "name", type: "text" },
        { label: "Description", name: "description", type: "text" },
        { label: "Position", name: "position", type: "number" },
        {
          label: "Sort",
          name: "sort",
          type: "select",
          options: [
            { value: "NAME", text: "Name" },
            { value: "POSITION", text: "Position" }
          ]
        }
      ],
      procedureFields: [
        { label: "Name", name: "name", type: "text" },
        { label: "External File", name: "externalFile", type: "text" },
        { label: "Position", name: "position", type: "number" }
      ],
      groupHeaders: [
        { value: "name", text: "Name", sortable: true },
        { value: "description", text: "Description", sortable: false },
        { value: "sort", text: "Sort", sortable: false },
        { value: "position", text: "Position", sortable: true },
        { value: "action", text: "Actions", sortable: false }
      ],
      procedureHeaders: [
        { value: "name", text: "Name", sortable: true },
        { value: "position", text: "Position", sortable: true },
        { value: "action", text: "Actions", sortable: false }
      ]
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    }
  },
  methods: {
    updateMutation(val) {
      if (val === 1) {
        this.mutation = CREATE_PROCEDURE_GROUP_MUTATION;
        this.mutationLabel = "New Procedure Group";
        this.fields = this.groupFields;
      } else {
        this.mutation = CREATE_PROCEDURE_MUTATION;
        this.mutationLabel = "New Procedure";
        this.fields = this.procedureFields;
      }
    },
    fetchPDF(document) {
      window.open("/doc/" + document.externalFile, "_blank");
    }
  },
  apollo: {
    procedureGroups: {
      query: PROCEDURE_GROUPS_QUERY,
      fetchPolicy: "cache-and-network",
      update(data) {
        this.procedureFields[3] = {
          label: "Procedure Group",
          name: "procedureGroupId",
          type: "select",
          options: data.procedureGroups.map(x => {
            return { value: x.id, text: x.name };
          })
        };
        return data.procedureGroups;
      },
      subscribeToMore: [
        {
          document: NEW_PROCEDURE_GROUP_SUBSCRIPTION,
          updateQuery: (previous, { subscriptionData }) => {
            if (!subscriptionData.data.newProcedureGroup) return;
            const newProcedureGroup = subscriptionData.data.newProcedureGroup;

            return {
              procedureGroups: [...previous.procedureGroups, newProcedureGroup]
            };
          }
        }
      ]
    },
    procedures: {
      query: PROCEDURES_QUERY,
      fetchPolicy: "cache-and-network",
      subscribeToMore: [
        {
          document: NEW_PROCEDURE_SUBSCRIPTION,
          updateQuery: (previous, { subscriptionData }) => {
            if (!subscriptionData.data.newProcedure) return;
            const newProcedure = subscriptionData.data.newProcedure;

            return {
              procedures: [...previous.procedures, newProcedure]
            };
          }
        }
      ]
    }
  }
};
</script>
