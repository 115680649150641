<template>
  <div>
    <v-row>
      <v-alert
        v-if="tailboardFormSubmission.state === 'IN_PROGRESS'"
        type="warning"
        width="100%"
        class="mt-2"
        text
        dense
        >Please ensure form is completed before signing off</v-alert
      >
      <v-simple-table class="table-theme" fixed-header>
        <template>
          <thead>
            <tr>
              <th>Name</th>
              <th>Title</th>
              <th>Vehicle #</th>
              <th>Signature</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(crew, index) in employeesList"
              :key="index + 1"
              style="height: 52px"
            >
              <td>
                {{ `${index + 1}. ${crew.name}` }}
              </td>
              <td>{{ crew.employee.title }}</td>
              <td></td>
              <td style="width: 180px">
                <v-btn
                  :disabled="tailboardFormSubmission.state !== 'COMPLETED'"
                  @click.stop="addSignature(index, crew.employee)"
                  width="186"
                  text
                  outlined
                >
                  <v-img
                    v-if="validUrl(crew.url)"
                    height="34"
                    width="102"
                    contain
                    :src="crew.url"
                  />
                  <span v-else class="text-capitalize" style="width: 122px"
                    >Add Signature</span
                  >
                  <v-icon right>border_color</v-icon>
                </v-btn>
              </td>
              <td>
                <v-menu :disabled="!crew.url" rounded="" offset-y>
                  <template v-slot:activator="{ attrs, on }">
                    <v-btn v-bind="attrs" v-on="on" icon>
                      <v-icon>more_horiz</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item btn @click="clearByLine(crew.employee)">
                      <v-list-item-title
                        ><v-icon left>delete_outline</v-icon
                        >Clear</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-row>
    <v-dialog v-model="dialog" max-width="500px" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">Add Signature</span>
          <v-spacer />
          <v-btn @click.stop="clear" text>
            <v-icon left>delete_outline</v-icon>Clear
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <p class="caption mt-2">
            Toronto Hydro Employees (Crew Members)<br />
            Through initialing, the undersigned acknowledges that he/she has
            been made aware of and agrees that the identified job steps, hazards
            and barriers will create a safe work area.
          </p>

          <p class="caption mt-2">
            Toronto Hydro Employees (Visitors)<br />
            Through initialing, the undersigned acknowledges that he/she has
            been made aware of the hazards and agrees to follow the direction of
            the On Site leader.
          </p>
          <h3 class="body-1 my-4">{{ page }}. {{ activeEmployeeFullName }}</h3>
          <canvas-input
            v-if="activeEmployee"
            ref="canvasInput"
            :id="activeEmployee.id"
            :value="activeSignature"
            :showClearBtn="false"
            @input="signatureData = $event"
          />
        </v-card-text>
        <v-pagination
          v-model="page"
          :length="this.employees.length"
          @input="setActiveEmployeeByPage($event)"
          class="mb-4"
        />
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="cancel">Cancel</v-btn>
          <v-btn
            class="primary"
            @click="signOff"
            :disabled="hasActiveSignature || !signatureData"
            :loading="loading"
            >Save Signature</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import CanvasInput from "@/components/submission/canvas-input.vue";
import utils from "@/shared/Utils";
import getEnv from "@/utilities/env.js";

export default {
  name: "EmployeeSignOff",

  props: {
    tailboardFormSubmission: Object,
    tailboardFormTitle: String,
    employeeVisitorComponentId: String,
    shouldHideEmployeeVisitors: Boolean
  },

  components: {
    CanvasInput
  },

  data() {
    return {
      dialog: false,
      loading: false,
      activeEmployee: null,
      page: 1,
      signatureData: null,
      activeSignature: null,
      employeesList: [],
      hideEmployeeVisitors: false
    };
  },

  watch: {
    employees: {
      immediate: true,
      deep: true,
      handler: function(value) {
        if (value) {
          this.mappingEmployeesSignature(value);
        }
      }
    },
    activeEmployee: {
      immediate: true,
      deep: true,
      handler: function(val) {
        if (val) {
          const signature = this.employeeSignatures[val.id];
          this.activeSignature = signature ? signature.file : null;
          if (this.$refs.canvasInput) this.$refs.canvasInput.updateCanvas();
        }
      }
    },
    componentInApplicabilities: {
      handler: function() {
        this.hideEmployeeVisitors = this.tailboardFormSubmission.inapplicableComponents.some(
          component => component === this.employeeVisitorComponentId
        );
      }
    },
    hideEmployeeVisitors: {
      handler: function() {},
      immediate: true,
      deep: true
    },
    shouldHideEmployeeVisitors: {
      handler() {
        this.hideEmployeeVisitors = this.shouldHideEmployeeVisitors;
      },
      immediate: true
    }
  },
  computed: {
    allEmployeesSignatures() {
      return this.tailboardFormSubmission.signatures
        ? this.tailboardFormSubmission.signatures.filter(x => x.employee)
        : [];
    },
    // Object mapping employee ids to signatures
    employeeSignatures() {
      return this.allEmployeesSignatures.reduce((obj, signature) => {
        obj[signature.employee.id] = signature;
        return obj;
      }, {});
    },

    activeEmployeeFullName() {
      return this.activeEmployee
        ? `${this.activeEmployee.firstName} ${this.activeEmployee.lastName}`
        : "";
    },

    employees() {
      const serverUrl = getEnv("VUE_APP_SERVER_URL");

      let crewMembers = this.tailboardFormSubmission.crewMembers.map(c => ({
        name: `${c.employee.firstName} ${c.employee.lastName}`,
        employee: c.employee,
        url: ""
      }));

      let employeeVisitors = this.tailboardFormSubmission.employeeVisitors.map(
        v => ({
          name: `${v.employee.firstName} ${v.employee.lastName}`,
          employee: v.employee,
          url: ""
        })
      );

      if (this.hideEmployeeVisitors) {
        employeeVisitors = [];
      }

      let employees = [...crewMembers, ...employeeVisitors];

      employees.forEach(e => {
        if (this.employeeSignatures[e.employee.id]) {
          e.url = `${serverUrl}/doc/${
            this.employeeSignatures[e.employee.id].file
          }`;
        }
      });

      return employees;
    },

    hasActiveSignature() {
      return Boolean(this.activeSignature);
    },
    componentInApplicabilities() {
      return this.tailboardFormSubmission.inapplicableComponents;
    }
  },

  methods: {
    validUrl(url) {
      try {
        new URL(url);
        return true;
      } catch (e) {
        return false;
      }
    },
    async mappingEmployeesSignature(value) {
      const employeePromises = value.map(async e => {
        let signatureObject = {
          employee: e.employee,
          name: e.name,
          url: ""
        };
        const cacheUrl = await utils.mapUrlToCache(e.url, "image/png");
        signatureObject.url = cacheUrl || e.url;
        return signatureObject;
      });

      this.employeesList = await Promise.all(employeePromises);
    },

    getNotifications() {
      return this.employees.length - this.allEmployeesSignatures.length;
    },
    addSignature(index, employee) {
      this.dialog = true;
      this.page = index + 1;
      this.activeEmployee = employee;
    },

    setActiveEmployeeByPage(page) {
      this.activeEmployee = null;
      if (this.$refs.canvasInput) this.$refs.canvasInput.clearCanvas();
      this.activeEmployee = this.employees[page - 1].employee;
      this.page = page;
    },
    clearByLine(employee) {
      const signature = this.employeeSignatures[employee.id];

      if (signature) this.$emit("delete", employee.id);
    },
    clear() {
      if (this.$refs.canvasInput) {
        if (this.activeEmployee) {
          const signature = this.employeeSignatures[this.activeEmployee.id];

          if (signature) {
            this.$emit("delete", this.activeEmployee.id);
            this.signatureData = null;
            this.activeSignature = null;
          }
        }

        this.$refs.canvasInput.clearCanvas();
      }
    },

    cancel() {
      if (this.$refs.canvasInput) this.$refs.canvasInput.clearCanvas();
      this.signatureData = null;
      this.dialog = false;
    },

    signOff() {
      this.loading = true;

      this.$emit(
        "signature",
        {
          employee: this.activeEmployee,
          file: this.signatureData
        },
        () => {
          if (this.employees.length === this.allEmployeesSignatures.length)
            this.cancel();
          else {
            const next =
              this.employees.findIndex(
                ({ employee, url }) => employee !== this.activeEmployee && !url
              ) + 1;

            this.setActiveEmployeeByPage(next);
          }

          this.loading = false;
        }
      );
    },
    paginationLength() {
      let length =
        this.tailboardFormSubmission.crewMembers.length() +
        this.tailboardFormSubmission.employeeVisitors.length();

      if (this.hideEmployeeVisitors) {
        length = this.tailboardFormSubmission.crewMembers.length();
      }

      return length;
    }
  }
};
</script>
<style scoped>
.table-theme {
  width: 100% !important;
}
.table-theme table th {
  color: #005d55 !important;
}
.signature-image {
  border: 1px solid gainsboro;
  border-radius: 5px;
}
</style>
