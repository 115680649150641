<template>
  <div :class="[{ 'is-grid-disabled': disableGrid }, 'preview-input']">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "PreviewInput",
  props: {
    disableGrid: Boolean
  }
};
</script>

<style scoped>
.preview-input {
  align-items: flex-start;
  break-inside: avoid;
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 4px;
  page-break-inside: avoid;
  margin: 0 0 4px 0;
}

.preview-input.is-grid-disabled {
  display: block;
}
</style>
