import gql from "graphql-tag";
import { CRITICAL_TASKS_ENTITY_FRAGMENT } from "@/graphql/criticaltasks/fragments.js";

export const CRITICAL_TASKS_QUERY = gql`
  {
    criticalTasks {
      ...CriticalTasksEntity
    }
  }
  ${CRITICAL_TASKS_ENTITY_FRAGMENT}
`;

export const NEW_CRITICAL_TASK_SUBSCRIPTION = gql`
  subscription criticalTaskSubscription {
    newCriticalTask {
      ...CriticalTasksEntity
    }
  }
  ${CRITICAL_TASKS_ENTITY_FRAGMENT}
`;
