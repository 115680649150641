<template>
  <div class="model-main justify-content-center">
    <div class="model-header">
      <h2>Forms</h2>
      <form-modal
        :fields="formFields"
        :title="mutationLabel"
        :mutation="mutation"
        :type="form.type"
        :value="form"
      />
    </div>
    <v-card>
      <v-tabs v-model="currentTab">
        <v-tab ripple>Tailboards</v-tab>
        <v-tab ripple>Reports</v-tab>
        <v-tab ripple>Checklists</v-tab>
        <v-tab ripple>Hazard Assessments</v-tab>
        <v-spacer />
        <v-tab-item>
          <custom-table
            key="tailboard-forms"
            :columns="tailboardColumns"
            :data="tailboards"
            @link="link($event)"
            @edit="edit($event)"
            @select="edit($event)"
            @delete="deleteForm($event)"
            @submit="submitNew($event)"
          />
          <select-child-form
            :multiple="true"
            :childFormsData="simpleForms"
            :showChildDialog="linkDialog"
            :selectAction="selectChildForm"
            :cancelAction="cancelAction"
          />
        </v-tab-item>
        <v-tab-item>
          <custom-table
            key="report-forms"
            :columns="columns"
            :data="reportForms"
            @edit="edit($event)"
            @select="edit($event)"
            @delete="deleteForm($event)"
            @submit="submitNew($event)"
          />
        </v-tab-item>
        <v-tab-item>
          <custom-table
            key="checklist-forms"
            :columns="columns"
            :data="checklistForms"
            @edit="edit($event)"
            @select="edit($event)"
            @delete="deleteForm($event)"
            @submit="submitNew($event)"
          />
        </v-tab-item>
        <v-tab-item>
          <custom-table
            key="ha-forms"
            :columns="columns"
            :data="hazardAssessmentForms"
            @edit="edit($event)"
            @select="edit($event)"
            @delete="deleteForm($event)"
            @submit="submitNew($event)"
          />
        </v-tab-item>
      </v-tabs>
    </v-card>
  </div>
</template>

<script>
import {
  TAILBOARD_FORMS_QUERY,
  CREATE_TAILBOARD_FORM_MUTATION,
  DELETE_TAILBOARD_FORM_MUTATION,
  NEW_TAILBOARD_FORM_SUBSCRIPTION,
  UPDATE_TAILBOARD_FORM_CHILDREN_MUTATION
} from "@/constants/form-queries";
import { CREATE_SECTION_MUTATION } from "@/constants/section-queries";
import formModal from "./form-modal.vue";
import customTable from "./custom-table.vue";
import selectChildForm from "@/components/submission/select-child-form.vue";

export default {
  name: "Forms",
  components: { formModal, customTable, selectChildForm },
  data() {
    return {
      mutation: CREATE_TAILBOARD_FORM_MUTATION,
      mutationLabel: "New Tailboard Form",
      currentTab: 0,
      linkDialog: false,
      form: { type: "TAILBOARD" },
      newSection: {},
      newComponent: {},
      newInputField: {},
      currentForm: null,
      currentSection: null,
      currentComponent: null,
      tailboardForms: [],
      selectedParent: {},
      formFields: [
        { label: "Title", name: "title", type: "text", required: true },
        { label: "Header", name: "header", type: "text", required: true },
        {
          label: "Instructions",
          name: "instructions",
          type: "text",
          required: true
        },
        {
          label: "Form Type",
          name: "type",
          type: "select",
          options: [
            {
              value: "SIMPLE",
              text: "Simple"
            },
            { value: "TAILBOARD", text: "Tailboard" }
          ],
          required: true
        },
        { label: "Logo", name: "logoFile", type: "file" }
      ],
      sectionFields: [
        { label: "Title", name: "title", type: "text" },
        {
          label: "Layout",
          name: "layout",
          type: "select",
          options: [
            { value: "TABLE", text: "Table" },
            { value: "GRID", text: "Grid" }
          ]
        },
        { label: "Table Headings", name: "tableHeadings", type: "array" },
        { label: "Position", name: "position", type: "number" }
      ],
      columns: [
        { value: "title", text: "Title", sortable: true },
        {
          value: ["show", "delete", "submit", "edit"],
          text: "Actions",
          sortable: false
        }
      ],
      tailboardColumns: [
        { value: "title", text: "Title", sortable: true },
        {
          value: ["show", "delete", "submit", "edit", "link"],
          text: "Actions",
          sortable: false
        }
      ],
      sectionColumns: [
        { value: "title", text: "Title", sortable: true },
        { value: "position", text: "Position", sortable: false },
        { value: "layout", text: "Layout", sortable: false },
        { value: ["show", "delete"], text: "Actions", sortable: false }
      ],
      componentColumns: [
        { value: "title", text: "Title", sortable: true },
        { value: "position", text: "Position", sortable: false },
        { value: "layout", text: "Layout", sortable: false },
        { value: ["show", "delete"], text: "Actions", sortable: false }
      ]
    };
  },
  computed: {
    createSectionMutation() {
      return CREATE_SECTION_MUTATION;
    },
    reportForms() {
      return this.tailboardForms.filter(form => form.type === "REPORT");
    },
    checklistForms() {
      return this.tailboardForms.filter(form => form.type === "CHECKLIST");
    },
    hazardAssessmentForms() {
      return this.tailboardForms.filter(
        form => form.type === "HAZARD_ASSESSMENT"
      );
    },
    simpleForms() {
      return this.tailboardForms
        .filter(form => form.type === "SIMPLE")
        .map(s => {
          return {
            id: s.id,
            text: s.title,
            instructions: s.instructions,
            selected: this.selectedParent.childForms
              ? this.selectedParent.childForms.findIndex(c => c.id === s.id) !==
                -1
              : false
          };
        });
    },
    tailboards() {
      return this.tailboardForms.filter(
        form => form.type === "TAILBOARD" || form.type === "SIMPLE"
      );
    }
  },
  watch: {
    currentTab(val) {
      if (val === 0) {
        this.mutationLabel = "New Tailboard Form";
        this.$set(this.form, "type", "TAILBOARD");
      } else if (val === 1) {
        this.mutationLabel = "New Report Form";
        this.$set(this.form, "type", "REPORT");
      } else if (val === 2) {
        this.mutationLabel = "New Checklist Form";
        this.$set(this.form, "type", "CHECKLIST");
      } else if (val === 3) {
        this.mutationLabel = "New Hazard Assessment Form";
        this.$set(this.form, "type", "HAZARD_ASSESSMENT");
      }
    }
  },
  methods: {
    cancelAction() {
      this.linkDialog = false;
    },
    async updateParentChildrenRelation() {
      const data = {
        childIds: this.selectedParent.childForms.map(c => c.id),
        id: this.selectedParent.id
      };
      let response = await this.$apollo.mutate({
        mutation: UPDATE_TAILBOARD_FORM_CHILDREN_MUTATION,
        variables: data
      });
      if (response.data.updateTailboardFormChildren.successful) {
        this.linkDialog = false;
      }
    },
    async selectChildForm(childs) {
      this.selectedParent.childForms = childs;
      await this.updateParentChildrenRelation();
    },
    edit(form) {
      this.$router.push("/forms/" + form.id);
    },
    link(form) {
      this.selectedParent = form;
      this.linkDialog = true;
    },
    async deleteForm(form) {
      try {
        await this.$apollo.mutate({
          mutation: DELETE_TAILBOARD_FORM_MUTATION,
          variables: { id: form.id }
        });
        this.$apollo.queries.tailboardForms.refetch();
      } catch (error) {
        console.error(error);
      }
    },
    submitNew(form) {
      this.$router.push("/forms/" + form.id + "/submit");
    }
  },
  apollo: {
    tailboardForms: {
      query: TAILBOARD_FORMS_QUERY,
      fetchPolicy: "cache-and-network",
      subscribeToMore: [
        {
          document: NEW_TAILBOARD_FORM_SUBSCRIPTION,
          updateQuery: (previous, { subscriptionData }) => {
            if (!subscriptionData.data.newTailboardForm) return;
            const newForm = subscriptionData.data.newTailboardForm;

            return {
              tailboardForms: [...previous.tailboardForms, newForm]
            };
          }
        }
      ]
    }
  }
};
</script>
