<template>
  <preview-input-any-file
    :name="name"
    :value="value"
    :fileNamingFunction="getFileName"
  />
</template>

<script>
import dayjs from "@/plugins/dayjs";

import PreviewInputAnyFile from "./preview-input-any-file.vue";

export default {
  name: "PreviewInputAudio",
  props: {
    name: String,
    value: Array
  },
  components: {
    PreviewInputAnyFile
  },
  methods: {
    getFileName({ file, position }) {
      // using the same name as the audio-recorder.vue to keep it consistent
      return `Recording ${position + 1} - ${dayjs(file.createdTime).format(
        "YYYY-MM-DD, HH:mm"
      )}`;
    }
  }
};
</script>
