<template>
  <div class="ma-3" style="width: 100%">
    <v-row>
      <v-expansion-panels focusable :disabled="disabled">
        <v-expansion-panel v-for="(category, i) in hazardCategories" :key="i">
          <v-expansion-panel-header>{{
            category.name
          }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row class="pt-3">
              <template v-for="(hazard, idx) in category.hazards">
                <div
                  :key="'col-' + idx"
                  class="hazard-item"
                  :class="[
                    hazard.selected ? 'div-border-active' : 'div-border-normal'
                  ]"
                >
                  <v-checkbox
                    dense
                    class="pa-0 ma-0 pt-3 pl-2"
                    style="width: 100%"
                    :disabled="disabled"
                    :id="'check-' + idx + category.id"
                    :label="hazard.name"
                    v-model="hazard.selected"
                    @change="onHazardSelect($event, hazard)"
                  />
                </div>

                <v-responsive
                  v-if="(idx + 1) % 3 == 0"
                  :key="idx"
                  width="100%"
                />
              </template>
              <v-spacer></v-spacer>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <h5 class="primary--text mt-5 mb-5">All Selected Hazards</h5>
    <v-row
      class="d-flex pa-2 selected-hazards"
      v-if="selectedHazardItems.length > 0"
    >
      <template v-for="(hazard, idx) in selectedHazardItems">
        <div :key="'selected-' + idx" class="selected-container">
          <div class="d-flex">
            <p>{{ hazard.name }}</p>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="disabled"
              icon
              @click="removeSelectedHazard(hazard)"
              ><v-icon>cancel</v-icon></v-btn
            >
          </div>
        </div>
      </template>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "HazardSelection",

  props: {
    disabled: Boolean,
    selectedHazards: Array,
    hazardIds: Array
  },
  data() {
    return {
      hazardCategories: [],
      selectedHazardItems: []
    };
  },
  watch: {
    categories: {
      deep: true,
      immediate: true,
      handler: function(value) {
        if (value)
          this.hazardCategories = value.map(c => {
            return {
              name: c.name,
              hazards: this.hazardsList
                .filter(h => h.hazardCategory.id === c.id)
                .map(r => {
                  return {
                    name: r.name,
                    id: r.id,
                    selected: this.hazardIds && this.hazardIds.includes(r.id)
                  };
                })
            };
          });
      }
    },
    hazardIds: {
      immediate: true,
      deep: true,
      handler: function(val) {
        this.hazardCategories = this.categories.map(c => {
          return {
            name: c.name,
            hazards: this.hazardsList
              .filter(h => h.hazardCategory.id === c.id)
              .map(r => {
                return {
                  name: r.name,
                  id: r.id,
                  selected: val && val.includes(r.id)
                };
              })
          };
        });
      }
    },
    selectedHazards: {
      immediate: true,
      deep: true,
      handler: function(val) {
        this.selectedHazardItems = val;
      }
    }
  },

  computed: {
    categories() {
      return this.$store.getters.getHazardCategories;
    },
    hazardsList() {
      return this.$store.getters.getHazards;
    }
  },

  methods: {
    removeSelectedHazard(hazard) {
      hazard.selected = false;
      this.$emit("delete", hazard.id);
    },

    onHazardSelect(event, hazard) {
      const index = this.selectedHazardItems.findIndex(i => i.id === hazard.id);
      if (event && index === -1) this.selectedHazardItems.push(hazard);
      else {
        if (index !== -1) {
          this.$emit("delete", this.selectedHazardItems[index].id);
        }
      }
      this.$emit("change", this.selectedHazardItems);
    }
  }
};
</script>
<style scoped>
.div-border-active,
.div-border-normal {
  border-radius: 5px;
}

.selected-container {
  padding: 0px;
}

.selected-container div {
  padding-top: 1px;
  margin: 3px;
  display: inline-block;
  background-color: var(--v-light-base);
  border-radius: 20px;
  color: var(--v-primary-base);
}

.selected-container p {
  padding: 0;
  margin: 5px 0 0 8px;
}

.hazard-item {
  height: auto;
  width: 32.4%;
  margin: 3px;
}

.selected-hazards {
  border-radius: 5px;
  border: 1px solid var(--v-border-base);
}

.div-border-active {
  background-color: var(--v-light-base) !important;
  border: 0.8px solid var(--v-primary-base) !important;
}

.div-border-normal {
  border: 0.8px solid var(--v-border-base);
}
</style>
