<template>
  <div style="width: 100%">
    <v-file-input
      outlined
      :disabled="loading || disabled"
      v-model="files"
      show-size
      :append-outer-icon="files.length > 0 ? 'file_upload' : ''"
      placeholder="Upload your documents"
      multiple
      @click:append-outer="uploadingFiles"
    >
      <template v-slot:selection="{ text }">
        <v-chip small label color="primary">
          {{ text }}
        </v-chip>
      </template>
      <template v-slot:append>
        <v-fade-transition leave-absolute>
          <v-progress-circular
            v-if="loading"
            size="24"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-fade-transition>
      </template>
    </v-file-input>
    <div>
      <div
        v-for="file in uploadedFiles"
        :key="file.lastModifiedDate"
        class="files-container d-flex"
      >
        <v-icon>{{ getFileIcon(file.type) }}</v-icon>
        <a
          @click="openFile(file.blobUrl)"
          target="_blank"
          :class="{ 'is-disabled': disabled }"
        >
          {{ file.name }}
        </a>
        <v-spacer />
        <v-btn
          @click.stop="deleteConfirmation(file)"
          :disabled="disabled"
          class="remove-btn"
          small
          icon
        >
          <v-icon>delete_outline</v-icon>
        </v-btn>
      </div>
    </div>
    <dialog-message
      :showDialogMessage="showDialogMessage"
      displayCaption="Delete"
      displayText="Are you sure to delete the file?"
      :okAction="deleteSelectedFile"
      :cancelAction="cancelAction"
    />
  </div>
</template>
<script>
import dayjs from "@/plugins/dayjs";
import dialogMessage from "@/components/submission/dialog-message.vue";
import getEnv from "@/utilities/env.js";
import utils from "@/shared/Utils";
import { workers } from "@/shared/workers.js";
import appsignal from "@/plugins/appsignal";

export default {
  name: "FileUpload",
  props: {
    disabled: Boolean,
    initialValue: String,
    id: String,
    rules: Array
  },
  components: {
    dialogMessage
  },
  data: () => ({
    loading: false,
    showDialogMessage: false,
    files: [],
    uploadedFiles: [],
    selectedFile: null
  }),

  watch: {
    initialValue: {
      deep: true,
      immediate: true,
      handler: async function(value) {
        let fileList = value ? JSON.parse(value) : [];
        this.uploadedFiles = fileList
          ? fileList.map(r => {
              return {
                blobUrl: `${getEnv("VUE_APP_SERVER_URL")}/doc/${r.blobUrl}`,
                uuid: r.blobUrl,
                type: r.type,
                name: r.name,
                size: r.size,
                createdTime: r.createdTime,
                time: dayjs
                  .utc(r.createdTime)
                  .local()
                  .format("YYYY-MM-DD, HH:mm")
              };
            })
          : [];
        for (let i = 0; i < this.uploadedFiles.length; i++) {
          const cacheUrl = await utils.mapUrlToCache(
            this.uploadedFiles[i].blobUrl,
            this.uploadedFiles[i].type
          );
          if (cacheUrl) this.uploadedFiles[i].blobUrl = cacheUrl;
        }
      }
    }
  },

  methods: {
    openFile(fileUrl) {
      window.open(fileUrl);
    },
    deleteConfirmation(file) {
      this.selectedFile = file;
      this.showDialogMessage = true;
    },
    cancelAction() {
      this.selectedFile = null;
      this.showDialogMessage = false;
    },
    deleteSelectedFile() {
      if (this.selectedFile) {
        this.uploadedFiles.splice(
          this.uploadedFiles.findIndex(
            r => r.createdTime === this.selectedFile.createdTime
          ),
          1
        );
        this.saveChanges();
        this.selectedFile = null;
        this.showDialogMessage = false;
      }
    },
    getFileIcon(type) {
      if (type.includes("image")) return "image";
      else if (type.includes("pdf")) return "picture_as_pdf";
      else return "description";
    },
    uploadingFiles() {
      this.loading = true;
      let worker = workers.uploadWorker();
      worker.postMessage({
        action: "start",
        files: this.files.map(f => {
          return { file: f, uuid: utils.getUUID() };
        }),
        uri: `${getEnv("VUE_APP_SERVER_URL")}`
      });
      worker.onmessage = e => {
        switch (e.data.status) {
          case 0:
          case 1:
            {
              let newFile = e.data.newFile;
              newFile.createdTime = dayjs.utc().format();
              this.uploadedFiles.push(newFile);
              const index = this.files.findIndex(
                f => f.lastModified === e.data.fileLastModified
              );
              if (index >= 0) this.files.splice(index, 1);
              this.loading = this.files.length !== 0;
              this.saveChanges();
            }
            break;
          case 3:
            appsignal.sendError(e.data.exception);
            break;
        }
      };
    },
    saveChanges() {
      let filesResult = this.uploadedFiles.map(f => {
        return {
          blobUrl: f.uuid,
          createdTime: f.createdTime,
          name: f.name,
          type: f.type,
          size: f.size
        };
      });
      this.$emit(
        "change",
        filesResult.length > 0 ? JSON.stringify(filesResult) : null
      );
    }
  }
};
</script>
<style scoped>
.is-disabled {
  color: currentColor;
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.7;
  text-decoration: none;
}

.files-container {
  border: 0.8px solid var(--v-border-base);
  padding: 5px;
}
.files-container a {
  margin: 5px 15px;
  font-size: 12px;
  text-decoration: none;
}
</style>
