import Vue from "vue";

import router from "@/plugins/router";
import vuetify from "@/plugins/vuetify";
import { apolloProvider } from "@/plugins/apollo";
import store from "@/plugins/store";

import "@/plugins/konva";
import App from "./app.vue";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "./registerServiceWorker";
import appsignal from "@/plugins/appsignal";
import { errorHandler } from "@appsignal/vue";

Vue.config.errorHandler = errorHandler(appsignal, Vue);

window.onload = async function() {
  new Vue({
    apolloProvider,
    router,
    store,
    vuetify,
    appsignal,
    render: h => h(App)
  }).$mount("#app");
};
