<template>
  <div class="model-main">
    <div class="model-header">
      <h2>Attendees</h2>
    </div>
    <ul>
      <li v-for="name in attendees" :key="name">{{ name }}</li>
    </ul>
  </div>
</template>

<script>
import { ATTENDEES_QUERY } from "../constants/form-queries";

export default {
  name: "Attendees",
  apollo: {
    // attendees for type ahead
    attendees: {
      query: ATTENDEES_QUERY,
      fetchPolicy: "cache-and-network",
      update: data => data
    }
  }
};
</script>
