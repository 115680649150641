<template>
  <v-main class="login">
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="4">
          <v-card class="elevation-12">
            <v-toolbar color="primary">
              <v-toolbar-title>Login</v-toolbar-title>
              <v-spacer />
            </v-toolbar>
            <v-alert :value="alert.msg !== ''" :type="alert.type">{{
              alert.msg
            }}</v-alert>
            <v-card-text></v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn href="/sso/auth/signin/th" color="secondary"
                >Toronto Hydro Login</v-btn
              >
              <v-btn href="/sso/auth/signin/adfs" color="secondary"
                >Tailboards Login</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: "Login",
  props: ["source"],
  data() {
    return {
      valid: false,
      alert: {
        msg: "",
        type: "success"
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.login {
  margin-top: 10rem;
  .v-card__text {
    padding-bottom: 0;
  }
}

.v-alert {
  margin: 0;
}
.white {
  color: white !important;
}
</style>
