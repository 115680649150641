import gql from "graphql-tag";
import {
  USER_ENTITY_FRAGMENT,
  EMPLOYEE_ENTITY_FRAGMENT
} from "@/graphql/accounts/fragments.js";

export const USERS_QUERY = gql`
  {
    users {
      ...UserEntity
    }
  }
  ${USER_ENTITY_FRAGMENT}
`;

export const CURRENT_USER_QUERY = gql`
  {
    user {
      ...UserEntity
      employee {
        ...EmployeeEntity
        supervisor {
          ...EmployeeEntity
        }
      }
    }
  }
  ${USER_ENTITY_FRAGMENT}
`;

export const EMPLOYEE_QUERY = gql`
  query employee($id: ID!) {
    employee(id: $id) {
      successful
      result {
        ...EmployeeEntity
      }
    }
  }
  ${EMPLOYEE_ENTITY_FRAGMENT}
`;

export const EMPLOYEE_BY_IDS_QUERY = gql`
  query employee($ids: [ID]) {
    employeesById(ids: $ids) {
      successful
      result {
        ...EmployeeEntity
      }
    }
  }
  ${EMPLOYEE_ENTITY_FRAGMENT}
`;

export const EMPLOYEES_QUERY = gql`
  query employees($name: String, $crewRef: String) {
    employees(name: $name, crewRef: $crewRef) {
      successful
      result {
        ...EmployeeEntity
      }
    }
  }
  ${EMPLOYEE_ENTITY_FRAGMENT}
`;
