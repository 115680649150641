import CryptoJS from "crypto-js";
import getEnv from "@/utilities/env.js";
import appsignal from "@/plugins/appsignal";

const DB_NAME = getEnv("VUE_APP_LOCAL_DATABASE");
const DB_VERSION = getEnv("VUE_APP_LOCAL_DATABASE_VERSION");
let DB;

export default {
  isEncrypted(entityName) {
    return ["user", "employees"].includes(entityName);
  },
  encryptEntity(entityData) {
    return CryptoJS.AES.encrypt(
      JSON.stringify(entityData),
      "toronto hydro secret key 991"
    ).toString();
  },

  decryptEnitity(entityData) {
    var bytesData = CryptoJS.AES.decrypt(
      entityData,
      "toronto hydro secret key 991"
    );
    return JSON.parse(bytesData.toString(CryptoJS.enc.Utf8));
  },
  async openDataBase() {
    return new Promise((resolve, reject) => {
      if (DB) {
        return resolve(DB);
      }
      let request = window.indexedDB.open(DB_NAME, DB_VERSION);

      request.onerror = e => {
        appsignal.sendError(e);
        reject("Error");
      };

      request.onsuccess = e => {
        DB = e.target.result;
        return resolve(DB);
      };

      request.onupgradeneeded = e => {
        let db = e.target.result;
        db.createObjectStore("tailboardForms");
        db.createObjectStore("tailboardFormSubmissions");
        db.createObjectStore("archivedTailboardFormSubmissions");
        let users = db.createObjectStore("users");
        db.createObjectStore("user");
        users.createIndex("employeeId", "employee.id", { unique: true });
        db.createObjectStore("hazards");
        db.createObjectStore("hazardCategories");
        db.createObjectStore("barriers");
        db.createObjectStore("workTypes");
        db.createObjectStore("employees");
        db.createObjectStore("criticalTasks");
        db.createObjectStore("vehicles");
        db.createObjectStore("updatedEntities");
        db.createObjectStore("deletedChildsQueue");
        db.createObjectStore("tailboardsQueue");
        let assets = db.createObjectStore("trafficAssets");
        assets.createIndex("type", "type", { unique: false });
        db.createObjectStore("feeders");
        db.createObjectStore("structures");
      };
    });
  },

  // Entity methods

  async deleteAllAsync(entityName) {
    let db = await this.openDataBase();

    return new Promise(resolve => {
      let trans = db.transaction([entityName], "readwrite");
      trans.oncomplete = () => {
        return resolve(true);
      };

      let store = trans.objectStore(entityName);
      store.clear();
    });
  },

  async deleteEntityById(id, entityName) {
    let db = await this.openDataBase();

    return new Promise(resolve => {
      let trans = db.transaction([entityName], "readwrite");
      trans.oncomplete = () => {
        return resolve(true);
      };

      let store = trans.objectStore(entityName);
      store.delete(id);
    });
  },

  async saveEntitiesAsync(entities, entityName) {
    let db = await this.openDataBase();

    return new Promise(resolve => {
      let trans = db.transaction([entityName], "readwrite");
      trans.oncomplete = () => {
        return resolve(true);
      };

      let store = trans.objectStore(entityName);
      entities.forEach(entity => {
        store.put(
          this.isEncrypted(entityName) ? this.encryptEntity(entity) : entity,
          entity.id
        );
      });
    });
  },

  async saveEntityByIdAsync(entity, entityName) {
    let db = await this.openDataBase();

    return new Promise(resolve => {
      let trans = db.transaction([entityName], "readwrite");
      trans.oncomplete = () => {
        return resolve(true);
      };

      let store = trans.objectStore(entityName);
      store.put(
        this.isEncrypted(entityName) ? this.encryptEntity(entity) : entity,
        entity.id
      );
    });
  },

  async saveEntityAsync(key, value, entityName) {
    let db = await this.openDataBase();

    return new Promise(resolve => {
      let trans = db.transaction([entityName], "readwrite");
      trans.oncomplete = () => {
        return resolve(true);
      };

      let store = trans.objectStore(entityName);
      store.put(value, key);
    });
  },

  async getEntitiesAsync(entity) {
    let db = await this.openDataBase();

    return new Promise(resolve => {
      let trans = db.transaction([entity], "readonly");
      trans.oncomplete = () => {
        return resolve(entityObject);
      };

      let store = trans.objectStore(entity);
      let entityObject = [];
      const isEncrypted = this.isEncrypted(entity);
      const request = store.openCursor();
      request.onsuccess = e => {
        let cursor = e.target.result;
        if (cursor) {
          entityObject.push(
            isEncrypted ? this.decryptEnitity(cursor.value) : cursor.value
          );
          cursor.continue();
        }
      };
    });
  },

  async getEntityAsync(entityName) {
    let db = await this.openDataBase();

    return new Promise(resolve => {
      let trans = db.transaction([entityName], "readonly");
      trans.oncomplete = () => {
        return resolve(entityObject);
      };

      let store = trans.objectStore(entityName);
      let entityObject = {};

      store.openCursor().onsuccess = e => {
        let cursor = e.target.result;
        if (cursor) {
          entityObject = this.isEncrypted(entityName)
            ? this.decryptEnitity(cursor.value)
            : cursor.value;
          cursor.continue();
        }
      };
    });
  },

  async getEntityByIdAsync(entityName, id) {
    let db = await this.openDataBase();
    return new Promise(resolve => {
      let transaction = db.transaction([entityName], "readonly");
      transaction.oncomplete = () => {
        return resolve(entityObject);
      };
      let objectStore = transaction.objectStore(entityName);
      let entityObject = objectStore.get(id);
    });
  },

  async getEntityByIndexAsync(entityName, indexName, id) {
    let db = await this.openDataBase();
    return new Promise(resolve => {
      let transaction = db.transaction([entityName], "readonly");

      transaction.oncomplete = () => {
        return resolve(entityObject);
      };
      let objectStore = transaction.objectStore(entityName);
      let objectIndex = objectStore.index(indexName);
      let entityObject = objectIndex.get(id);
    });
  },

  async getEntityIndexAsync(entityName, indexName, expression) {
    let db = await this.openDataBase();
    return new Promise(resolve => {
      let transaction = db.transaction([entityName], "readonly");

      transaction.oncomplete = () => {
        return resolve(entityObject);
      };
      let objectStore = transaction.objectStore(entityName);
      let objectIndex = objectStore.index(indexName);
      let entityObject = [];
      objectIndex.openCursor().onsuccess = e => {
        let cursor = e.target.result;
        if (cursor) {
          if (cursor.value[indexName] === expression)
            entityObject.push(cursor.value);
          cursor.continue();
        }
      };
    });
  },

  async isExistAsync(storeName) {
    let db = await this.openDataBase();
    return db.objectStoreNames.contains(storeName);
  }
};
