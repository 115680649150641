<template>
  <div class="preview-input-employee-many">
    <preview-input-name>Employees:</preview-input-name>

    <ul class="employees-list" v-if="employees && employees.length > 0">
      <preview-input-employee
        :as="'li'"
        v-for="employee in employees"
        :value="employee"
        :key="employee.id"
      />
    </ul>
  </div>
</template>

<script>
import PreviewInputEmployee from "./preview-input-employee.vue";
import PreviewInputName from "./preview-input-name.vue";

export default {
  name: "PreviewInputEmployeeMany",
  props: {
    name: String,
    value: String
  },
  components: {
    PreviewInputEmployee,
    PreviewInputName
  },
  computed: {
    employees() {
      if (!this.value) return [];

      return this.value.split(",");
    }
  }
};
</script>

<style scoped>
.employees-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
</style>
