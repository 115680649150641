<template>
  <div class="preview-form-section">
    <preview-form-title v-if="title" :level="2">{{ title }}</preview-form-title>

    <slot></slot>
  </div>
</template>

<script>
import PreviewFormTitle from "./preview-form-title.vue";

export default {
  name: "PreviewFormSection",
  props: {
    title: String
  },
  components: {
    PreviewFormTitle
  }
};
</script>

<style scoped>
.preview-form-section {
  page-break-inside: avoid;
  padding-bottom: 1px;
  border-radius: 8px;
  position: relative;
  background-color: var(--v-secondary-base);
  border: 1px solid var(--v-primary-lighten3);
  margin-bottom: 16px;
}
</style>
