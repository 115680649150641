<template>
  <v-tooltip
    v-if="tooltipText"
    v-model="tooltipIsVisible"
    :open-delay="tooltipDelay"
    :bottom="bottomAlignDefault"
  >
    <template v-slot:activator="{ on, attrs }">
      <outside-click @outsideclick="handleOutsideClick">
        <v-btn
          :class="buttonClass"
          :block="block"
          :color="color"
          :dark="dark"
          :disabled="disabled"
          :href="href"
          :loading="loading"
          :target="target"
          :to="to"
          :width="width"
          :height="height"
          fab
          icon
          outlined
          @touchstart="handleTouchStart"
          @touchend="handleTouchEnd"
          @touchcancel="handleTouchCancel"
          @click="handleClick"
          @blur="handleBlur"
          v-bind="attrs"
          v-on="on"
          ref="buttonRef"
        >
          <slot></slot>
        </v-btn>
      </outside-click>
    </template>
    <span>{{ tooltipText }}</span>
  </v-tooltip>
  <v-btn
    v-else
    :class="buttonClass"
    :block="block"
    :color="color"
    :dark="dark"
    :disabled="disabled"
    :href="href"
    :width="width"
    :height="height"
    :loading="loading"
    :target="target"
    :to="to"
    fab
    icon
    outlined
    @click="handleClick"
  >
    <slot></slot>
  </v-btn>
</template>

<script>
import OutsideClick from "./outside-click.vue";

export default {
  name: "TouchButton",

  model: {
    event: "click"
  },

  components: {
    OutsideClick
  },

  props: {
    block: Boolean,
    color: String,
    dark: Boolean,
    disabled: Boolean,
    buttonClass: [String, Object],
    href: [String, Object],
    loading: Boolean,
    target: String,
    to: [String, Object],
    tooltipText: String,
    tooltipDelay: {
      type: Number,
      default: 800
    },
    width: {
      type: [Number, String],
      default: 53
    },
    height: {
      type: [Number, String],
      default: 53
    }
  },

  data() {
    return {
      tooltipTimer: null,
      tooltipIsVisible: false,
      bottomAlignDefault:
        this.bottom || !(this.top || this.bottom || this.left || this.right)
    };
  },

  methods: {
    showTooltip() {
      this.tooltipIsVisible = true;
    },

    hideTooltip() {
      this.tooltipIsVisible = false;
    },

    startTooltipTimer() {
      if (this.tooltipText && !this.tooltipTimer) {
        const newTooltipTimer = setTimeout(this.showTooltip, this.tooltipDelay);
        this.tooltipTimer = newTooltipTimer;
      }
    },

    stopTooltipTimer() {
      if (this.tooltipTimer) {
        clearTimeout(this.tooltipTimer);
        this.tooltipTimer = null;
      }
    },

    // Show the tooltip after a delay while hovering over the button
    handleMouseEnter() {
      this.startTooltipTimer();
    },

    // The user stopped hovering the button, cancel/hide the tooltip
    handleMouseLeave() {
      this.stopTooltipTimer();
    },

    // The user is on a touch-based device. Show the tooltip after a delay while hovering over the button
    handleTouchStart() {
      this.stopTooltipTimer();
      this.hideTooltip();
      this.startTooltipTimer();
    },

    // The user is on a touch-based device. The user let go of the button, cancel/hide the tooltip
    // and invoke the 'click' prop if within the tooltip timer
    handleTouchEnd(event) {
      // If the tooltip timer is not active, prevent the click event
      if (!this.tooltipTimer) {
        event.preventDefault();
      }
    },

    handleTouchCancel() {
      this.stopTooltipTimer();
    },

    handleBlur() {
      this.stopTooltipTimer();
      this.hideTooltip();
    },

    // The button was clicked. Stop the tooltip timer and execute the onClick callback.
    handleClick(event) {
      this.stopTooltipTimer();
      this.hideTooltip();
      this.$emit("click", event);
    },

    handleOutsideClick() {
      this.stopTooltipTimer();
      this.hideTooltip();
      this.$refs.buttonRef.$el.blur();
    }
  }
};
</script>
