<template>
  <div class="pa-5">
    <v-row justify="end">
      <v-col cols="4" class="ma-0 pa-0">
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          max-width="18rem"
          single-line
          hide-details
          clearable
        ></v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      :items="data"
      :headers="columns"
      :loading="$apollo.loading"
      :options.sync="pagination"
      :search.sync="search"
      hide-default-footer
    >
      <v-progress-linear slot="progress" color="blue" indeterminate />
      <template slot="item" slot-scope="{ item, headers }">
        <tr class="action-row" @click="$emit('select', item)">
          <td v-for="header in headers" :key="header.text">
            <template v-if="header.text == 'Actions'">
              <v-btn
                @click="$emit('edit', item)"
                v-if="header.value.includes('edit')"
                class="mx-0"
                icon
              >
                <v-icon large>$vuetify.icons.edit</v-icon>
              </v-btn>
              <v-btn
                @click.stop="$emit('submit', item)"
                v-if="header.value.includes('submit')"
                class="mx-0"
                icon
              >
                <v-icon large>$vuetify.icons.pdf</v-icon>
              </v-btn>
              <v-btn
                @click.stop="$emit('link', item)"
                v-if="header.value.includes('link')"
                class="mx-0"
                icon
              >
                <v-icon class="primary--text">link</v-icon>
              </v-btn>
              <v-btn
                v-if="header.value.includes('show')"
                @click.stop="openPreview(item)"
                class="mx-0"
                icon
              >
                <v-icon large>$vuetify.icons.download</v-icon>
              </v-btn>
              <v-btn
                v-if="header.value.includes('delete') && user.role == 'ADMIN'"
                class="mx-0"
                icon
                @click.stop="showDeleteDialog(item)"
              >
                <v-icon large>$vuetify.icons.trash</v-icon>
              </v-btn>
            </template>
            <template v-else>
              <p v-html="item[header.value]" />
            </template>
          </td>
        </tr>
      </template>
    </v-data-table>
    <template>
      <v-row v-if="pagination.totalItems > 0" class="mt-5">
        <v-col cols="auto" class="d-flex pa-0">
          <h5 class="mt-3 mr-2">Rows per Page:</h5>
          <v-select
            solo
            v-model="pagination.itemsPerPage"
            class="mt-1 pages-count"
            :items="paginationOptions"
            dense
          />
        </v-col>
        <v-spacer />
        <v-col cols="auto" class="pa-0">
          <v-pagination
            v-if="pagination.totalItems > 10 && pagination.itemsPerPage != -1"
            v-model="pagination.page"
            :length="pages"
            :prev-icon="'$vuetify.icons.paginationLeft'"
            :next-icon="'$vuetify.icons.paginationRight'"
          />
        </v-col>
      </v-row>
    </template>
    <dialog-message
      :showDialogMessage="showDialogMessage"
      :displayCaption="
        `Delete ${
          selectedItem
            ? selectedItem.key ||
              selectedItem.text ||
              selectedItem.title ||
              selectedItem.name
            : ''
        }`
      "
      :displayText="
        displayText
          ? displayText
          : 'Are you sure you would like to delete this user?'
      "
      :okAction="emitDelete"
      :cancelAction="cancelAction"
    />
  </div>
</template>

<script>
import dialogMessage from "@/components/submission/dialog-message.vue";

export default {
  name: "CustomTable",
  components: {
    dialogMessage
  },
  props: ["data", "columns", "editable", "active", "displayText"],
  data() {
    return {
      showDialogMessage: false,
      selectedItem: null,
      pagination: {
        itemsPerPage: 10,
        totalItems: this.data ? this.data.length : 0
      },
      search: ""
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    paginationOptions() {
      let range = [];
      range.push(10);
      if (this.pagination.totalItems > 25) range.push(25);
      if (this.pagination.totalItems > 50) range.push(50);
      range.push({ text: "All", value: -1 });
      return range;
    },
    pages() {
      if (this.pagination.totalItems === null) {
        return 1;
      }
      return (
        Math.ceil(this.pagination.totalItems / this.pagination.itemsPerPage) ||
        1
      );
    }
  },
  watch: {
    data() {
      this.pagination.totalItems = this.data.length || 0;
      this.pagination.itemsPerPage = this.pagination.totalItems > 10 ? 10 : -1;
    }
  },
  methods: {
    showDeleteDialog(item) {
      this.selectedItem = item;
      this.showDialogMessage = true;
    },

    cancelAction() {
      this.showDialogMessage = false;
    },
    openPreview(item) {
      let type;
      switch (item.__typename) {
        case "ChecklistForm":
          type = "checklist";
          break;
        case "ReportForm":
          type = "report";
          break;
        case "HazardAssessmentForm":
          type = "hazard_assessment";
          break;
      }
      if (type && item.id) {
        const url = `/preview/${type}/${item.id}`;
        window.open(url, "_blank");
      }
    },
    emitDelete() {
      this.$emit("delete", this.selectedItem);
      this.selectedItem = null;
      this.showDialogMessage = false;
    }
  }
};
</script>
