import Vue from "vue";
import VueRouter from "vue-router";
import Attendees from "@/components/attendees.vue";
import Dashboard from "@/components/dashboard.vue";
import Document from "@/components/documents.vue";
import FormBuilder from "@/components/form-builder.vue";
import Forms from "@/components/forms.vue";
import Hazard from "@/components/hazards.vue";
import Login from "@/components/login.vue";
import Option from "@/components/option-groups.vue";
import Procedure from "@/components/procedures.vue";
import Submission from "@/components/submission.vue";
import PreviewComment from "@/components/preview-comment.vue";
import CustomPreview from "@/components/custom-preview.vue";
import Submissions from "@/components/submissions.vue";
import User from "@/components/users.vue";
import Criticals from "@/components/critical-task.vue";
import store from "@/plugins/store";
import getEnv from "@/utilities/env.js";
Vue.use(VueRouter);

const adminRoute = (to, from, next) => {
  const user = store.getters.getUser;
  if (user && user.role == "ADMIN") {
    next();
  } else {
    window.location = `${getEnv("VUE_APP_SERVER_URL")}/login?redirect=${getEnv(
      "VUE_APP_SERVER_CALLBACK"
    )}`;
  }
};

const adminOrSafetyRoute = (to, from, next) => {
  const user = store.getters.getUser;
  if (user && (user.role == "ADMIN" || user.role == "SAFETY")) {
    next();
  } else {
    window.location = `${getEnv("VUE_APP_SERVER_URL")}/login?redirect=${getEnv(
      "VUE_APP_SERVER_CALLBACK"
    )}`;
  }
};

const routes = [
  { path: "/login", component: Login },
  { path: "/hazards", component: Hazard, beforeEnter: adminOrSafetyRoute },
  { path: "/criticals", component: Criticals, beforeEnter: adminOrSafetyRoute },
  { path: "/procedures", component: Procedure },
  { path: "/documents", component: Document },
  { path: "/", component: Dashboard },
  {
    path: "/dashboard/:formId/submit/:submissionId",
    component: Dashboard,
    props: true
  },
  { path: "/options", component: Option, beforeEnter: adminOrSafetyRoute },
  { path: "/users", component: User, beforeEnter: adminRoute },
  { path: "/forms", component: Forms, beforeEnter: adminRoute },
  { path: "/forms/:formId/submit", component: Submission, props: true },
  {
    path: "/forms/:formId/submit/:submissionId",
    component: Submission,
    props: true
  },
  {
    path: "/preview/:submissionId",
    component: PreviewComment,
    props: true
  },
  {
    path: "/preview/:submissionId/:state",
    component: PreviewComment,
    props: true
  },
  {
    path: "/preview/:submissionId/:state/:index",
    component: PreviewComment,
    props: true
  },
  {
    path: "/custom/:submissionId/:state",
    component: CustomPreview,
    props: true
  },
  {
    path: "/forms/:formId",
    component: FormBuilder,
    beforeEnter: adminRoute,
    props: true
  },
  { path: "/submissions/:submissionId/attendees", component: Attendees },
  { path: "/submissions", component: Submissions }
];

export default new VueRouter({
  mode: "history",
  routes,
  linkActiveClass: "active",
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});
