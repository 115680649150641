export function getLockedRole(submission, user) {
  if (!submission || !user) return;

  return (
    submission.isLocked &&
    submission.isLockedBy &&
    submission.isLockedBy.id !== user.id &&
    !["MANAGER", "ADMIN"].includes(user.role)
  );
}

export function getReviewMode(submission, user) {
  if (!submission || !user) return;

  const isLockedRole = getLockedRole(submission, user);

  return (
    isLockedRole ||
    (submission.mode === "CLOSED" && submission.state === "SUBMITTED") ||
    submission.state === "ARCHIVED" ||
    user.id !== submission.user.id
  );
}
