<template>
  <v-radio-group
    v-model="inputValue"
    :rules="rules"
    row
    hide-details
    class="mt-n1"
  >
    <div class="div-border" tabindex="0">
      <label
        v-for="(option, index) in options"
        :key="'label-' + index"
        :for="id + index"
      >
        <v-radio
          class="v-radio"
          :id="id + index"
          :value="option.value"
          :disabled="disabled"
          @change="$emit('change', option.value)"
        />
        <span :class="{ 'primary white--text': option.value === inputValue }">{{
          option.text
        }}</span>
      </label>
    </div>
  </v-radio-group>
</template>

<script>
export default {
  name: "YesNo",

  props: {
    id: String,
    value: String,
    disabled: Boolean,
    rules: Array
  },

  data() {
    return {
      options: [
        { value: "true", text: "YES" },
        { value: "false", text: "NO" }
      ]
    };
  },
  computed: {
    inputValue: {
      get: function() {
        return this.value;
      },
      set: function() {}
    }
  }
};
</script>

<style scoped>
.div-border:focus {
  border: 0.5px solid var(--v-primary-base);
}

.div-border {
  box-sizing: border-box;
  border: 0.5px solid transparent;
  border-radius: 5px;
  outline: none;
}

.v-radio {
  position: absolute;
  opacity: 0;
}

/* Create a custom radio button */
span {
  width: 64px;
  height: 44px;
  font-size: 17px;
  border: solid 1px var(--v-secondary-base);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
</style>
