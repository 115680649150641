import gql from "graphql-tag";
import { USER_ENTITY_FRAGMENT } from "@/graphql/accounts/fragments.js";

export const CREATE_USER_MUTATION = gql`
  mutation createUserMutation(
    $name: String!
    $email: String!
    $role: Role!
    $password: String!
  ) {
    createUser(name: $name, email: $email, role: $role, password: $password) {
      messages {
        field
        code
        message
      }
      result {
        ...UserEntity
      }
      successful
    }
  }
  ${USER_ENTITY_FRAGMENT}
`;

export const UPDATE_USER_MUTATION = gql`
  mutation updateUserMutation($id: ID!, $name: String, $email: String!) {
    updateUser(id: $id, name: $name, email: $email) {
      messages {
        field
        code
        message
      }
      result {
        ...UserEntity
      }
      successful
    }
  }
  ${USER_ENTITY_FRAGMENT}
`;

export const UPDATE_USER_ROLE_MUTATION = gql`
  mutation updateUserRoleMutation($id: ID!, $role: Role!) {
    updateUserRole(id: $id, role: $role) {
      messages {
        field
        code
        message
      }
      result {
        ...UserEntity
      }
      successful
    }
  }
  ${USER_ENTITY_FRAGMENT}
`;

export const DELETE_USER_MUTATION = gql`
  mutation deleteUserMutation($id: ID!) {
    deleteUser(id: $id) {
      messages {
        field
        code
        message
      }
      result {
        ...UserEntity
      }
      successful
    }
  }
  ${USER_ENTITY_FRAGMENT}
`;
