<template>
  <div class="model-main">
    <div class="model-header">
      <h2>Documents</h2>
      <form-modal
        v-if="
          display == 'index' && (user.role == 'ADMIN' || user.role == 'SAFETY')
        "
        :fields="fields"
        :title="mutationLabel"
        :show="showData"
        :mutation="mutation"
        :value="values"
        @success="closeForm"
        @close="closeForm"
      />
      <v-btn class="primary" v-if="display == 'show'" @click="display = 'index'"
        >Back</v-btn
      >
    </div>
    <v-card class="pa-5">
      <v-tabs
        v-model="currentTab"
        @change="updateMutation($event)"
        :hide-slider="display != 'index'"
      >
        <v-tab v-if="display == 'index'" ripple>Documents</v-tab>
        <v-tab
          v-if="
            (display == 'index' && user.role == 'ADMIN') ||
              user.role == 'SAFETY'
          "
          ripple
          >Categories</v-tab
        >
        <v-tab-item>
          <custom-table
            :columns="documentHeaders"
            :data="documentsList"
            :show="display == 'show'"
            :displayText="displayText"
            @delete="deleteDocument"
            @edit="editData"
          />
        </v-tab-item>
        <v-tab-item v-if="user.role == 'ADMIN' || user.role == 'SAFETY'">
          <custom-table
            :columns="groupHeaders"
            :data="documentGroups"
            :displayText="displayText"
            :show="display == 'show'"
            @show="display = 'show'"
            @delete="deleteDocumentGroup"
            @edit="editData"
          />
        </v-tab-item>
      </v-tabs>
    </v-card>
  </div>
</template>

<script>
import {
  DOCUMENTS_QUERY,
  DOCUMENT_GROUPS_QUERY,
  CREATE_DOCUMENT_MUTATION,
  UPDATE_DOCUMENT_MUTATION,
  DELETE_DOCUMENT_MUTATION,
  CREATE_DOCUMENT_GROUP_MUTATION,
  UPDATE_DOCUMENT_GROUP_MUTATION,
  DELETE_DOCUMENT_GROUP_MUTATION,
  NEW_DOCUMENT_SUBSCRIPTION,
  NEW_DOCUMENT_GROUP_SUBSCRIPTION
} from "../constants/section-queries";
import formModal from "./form-modal.vue";
import customTable from "./custom-table.vue";
import dayjs from "@/plugins/dayjs";
import appsignal from "@/plugins/appsignal";

export default {
  name: "Documents",
  components: { formModal, customTable },
  data() {
    return {
      display: "index",
      currentTab: 0,
      values: {},
      showData: false,
      mutation: CREATE_DOCUMENT_MUTATION,
      mutationLabel: "New Document",
      groupFields: [
        { label: "Name", name: "name", type: "text" },
        { label: "Description", name: "description", type: "text" },
        { label: "Position", name: "position", type: "number" },
        {
          label: "Sort",
          name: "sort",
          type: "select",
          options: [
            { value: "NAME", text: "Name" },
            { value: "POSITION", text: "Position" }
          ]
        }
      ],
      documentFields: [
        { label: "Name", name: "name", type: "text" },
        { label: "External File URL", name: "externalFileUrl", type: "text" },
        { label: "Position", name: "position", type: "number" }
      ],
      groupHeaders: [
        { value: "name", text: "Name", sortable: true },
        { value: "description", text: "Description", sortable: false },
        { value: "sort", text: "Sort", sortable: false },
        { value: "position", text: "Position", sortable: true },
        {
          value: ["edit", "delete"],
          text: "Actions",
          sortable: false
        }
      ],
      documentHeaders: [
        { value: "name", text: "Title", sortable: true },
        { value: "group", text: "Category", sortable: true },
        { value: "link", text: "Link", sortable: false },
        { value: "insertedAt", text: "Date Added", sortable: true },
        { value: "updatedAt", text: "Date Modified", sortable: true },
        {
          value: ["edit", "delete"],
          text: "Actions",
          sortable: false
        }
      ]
    };
  },
  computed: {
    displayText() {
      return this.currentTab === 1
        ? "Are you sure you would like to delete this Group?<br/>"
        : "Are you sure you would like to delete this Document?<br/> This action will also delete the document and all related information.";
    },
    fields() {
      return this.currentTab === 1 ? this.groupFields : this.documentFields;
    },
    documentsList() {
      return this.documents
        ? this.documents.map(d => {
            return {
              name: d.name,
              id: d.id,
              position: d.position,
              group: d.documentGroup.name,
              documentGroup: d.documentGroup,
              externalFileUrl: d.externalFileUrl,
              link: d.externalFileUrl
                ? `<a href='${d.externalFileUrl}' target='_blank'>[link]</a>`
                : "",
              insertedAt: this.utcToLocalDateTime(d.insertedAt),
              updatedAt: this.utcToLocalDateTime(d.updatedAt)
            };
          })
        : [];
    },
    user() {
      return this.$store.getters.getUser;
    }
  },
  methods: {
    utcToLocalDateTime(date) {
      return dayjs
        .utc(date)
        .local()
        .format("YYYY-MM-DD, HH:mm");
    },
    closeForm() {
      this.mutationLabel =
        this.currentTab === 1 ? "New Category" : "New Document";
      this.mutation =
        this.currentTab === 1
          ? CREATE_DOCUMENT_GROUP_MUTATION
          : CREATE_DOCUMENT_MUTATION;
      this.values = {};
      this.showData = false;
    },
    editData(item) {
      if (this.currentTab === 1) {
        this.mutationLabel = "Edit Category";
        this.mutation = UPDATE_DOCUMENT_GROUP_MUTATION;
        this.values = item;
      } else {
        this.mutationLabel = "Edit Document";
        this.mutation = UPDATE_DOCUMENT_MUTATION;
        this.values = {
          name: item.name,
          id: item.id,
          position: item.position,
          documentGroup: item.documentGroup,
          documentGroupId: item.documentGroup.id,
          externalFileUrl: item.externalFileUrl
        };
      }

      this.showData = true;
    },
    async delete(mutation, id, callback) {
      try {
        const result = await this.$apollo.mutate({
          mutation,
          variables: { id: id }
        });
        callback(result);
      } catch (error) {
        appsignal.sendError(error);
      }
    },
    async deleteDocument(document) {
      const callback = result => {
        this.documents.splice(
          this.documents.findIndex(i => i.id === result.data.deleteDocument.id),
          1
        );
      };
      await this.delete(DELETE_DOCUMENT_MUTATION, document.id, callback);
    },
    async deleteDocumentGroup(group) {
      const callback = result => {
        this.documentGroups.splice(
          this.documentGroups.findIndex(
            i => i.id === result.data.deleteDocumentGroup.id
          ),
          1
        );
      };
      await this.delete(DELETE_DOCUMENT_GROUP_MUTATION, group.id, callback);
    },
    updateMutation(val) {
      if (val === 1) {
        this.mutation = CREATE_DOCUMENT_GROUP_MUTATION;
        this.mutationLabel = "New Category";
      } else {
        this.mutation = CREATE_DOCUMENT_MUTATION;
        this.mutationLabel = "New Document";
      }
    }
    // fetchPDF(document) {
    //   window.open("/doc/" + document.externalFile, "_blank");
    // }
  },
  apollo: {
    documentGroups: {
      query: DOCUMENT_GROUPS_QUERY,
      fetchPolicy: "cache-and-network",
      update(data) {
        this.documentFields[3] = {
          label: "Category",
          name: "documentGroupId",
          type: "select",
          options: data.documentGroups.map(x => {
            return { value: x.id, text: x.name };
          })
        };
        return data.documentGroups;
      },
      subscribeToMore: [
        {
          document: NEW_DOCUMENT_GROUP_SUBSCRIPTION,
          updateQuery: (previous, { subscriptionData }) => {
            if (!subscriptionData.data.newDocumentGroup) return;
            const newDocumentGroup = subscriptionData.data.newDocumentGroup;

            return {
              documentGroups: [...previous.documentGroups, newDocumentGroup]
            };
          }
        }
      ]
    },
    documents: {
      query: DOCUMENTS_QUERY,
      fetchPolicy: "cache-and-network",
      subscribeToMore: [
        {
          document: NEW_DOCUMENT_SUBSCRIPTION,
          updateQuery: (previous, { subscriptionData }) => {
            if (!subscriptionData.data.newDocument) return;
            const newDocument = subscriptionData.data.newDocument;

            return {
              documents: [...previous.documents, newDocument]
            };
          }
        }
      ]
    }
  }
};
</script>
