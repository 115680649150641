import gql from "graphql-tag";
import { USER_ENTITY_FRAGMENT } from "@/graphql/accounts/fragments.js";

export const USER_CREATED_SUBSCRIPTION = gql`
  subscription userCreatedSubscription {
    userCreated {
      messages {
        field
        code
        message
      }
      result {
        ...UserEntity
      }
      successful
    }
  }
  ${USER_ENTITY_FRAGMENT}
`;

export const USER_UPDATED_SUBSCRIPTION = gql`
  subscription userUpdatedSubscription {
    userUpdated {
      messages {
        field
        code
        message
      }
      result {
        id
        name
        email
      }
      successful
    }
  }
`;

export const USER_ROLE_UPDATED_SUBSCRIPTION = gql`
  subscription userRoleUpdatedSubscription {
    userRoleUpdated {
      messages {
        field
        code
        message
      }
      result {
        id
        role
      }
      successful
    }
  }
`;

export const USER_DELETED_SUBSCRIPTION = gql`
  subscription userDeletedSubscription {
    userDeleted {
      messages {
        field
        code
        message
      }
      result {
        id
      }
      successful
    }
  }
`;
