<template>
  <div>
    <p class="caption">
      Add another test if work conditions change, the Confined Space is
      unoccupied and unattended, or every 8 hours.
    </p>
    <v-form v-model="isValid" ref="confinedSpaceTests">
      <v-row>
        <v-expansion-panels
          multiple
          :disabled="disabled"
          flat
          v-model="activePanels"
        >
          <v-expansion-panel
            expand
            v-for="(test, i) in confinedSpaceTests"
            :key="i"
          >
            <v-expansion-panel-header class="font-weight-bold text-h6"
              >{{ `Test ${i + 1} ${i == 0 ? "(Initial Entry)" : ""}`
              }}<v-divider class="ml-3" />

              <v-btn
                @click.stop="removeTest(i)"
                :disabled="confinedSpaceTests.length === 1 || disabled"
                class="remove-btn"
                small
                text
              >
                <v-icon class="mr-2">delete_outline</v-icon>
                Remove
              </v-btn>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="pl-4">
                <v-row>
                  <label>
                    {{ `Test ${i + 1} Time` }}
                    <span class="ml-2 error--text">*</span>
                  </label>
                </v-row>
                <v-row>
                  <v-col class="ma-0 pa-0" cols="3">
                    <v-text-field
                      :disabled="disabled"
                      type="time"
                      :rules="[rules.required.time]"
                      @input="test.time = updateTime($event, test.time)"
                      :value="getTestTime(test.time)"
                      @click:clear="test.time = null"
                      clearable
                      outlined
                    ></v-text-field
                    ><v-spacer></v-spacer>
                  </v-col>
                </v-row>
                <v-row>
                  <table>
                    <tr>
                      <th>Sample</th>
                      <th>O2<span class="ml-2 error--text">*</span></th>
                      <th>LEL/COMBEX<span class="ml-2 error--text">*</span></th>
                      <th>CO<span class="ml-2 error--text">*</span></th>
                      <th>H2S<span class="ml-2 error--text">*</span></th>
                      <th>Other</th>
                    </tr>
                    <tr>
                      <td>Location</td>
                      <td>min 19.5% max 23%</td>
                      <td>
                        (max 5% - hot work)<br />
                        (max 10% - cold work)<br />
                        (max 25% - inspection)
                      </td>
                      <td>
                        max 25 ppm<br />
                        TWA* or 77 ppm
                      </td>
                      <td>max 10 ppm</td>
                      <td>(Ammonia, benzene ect.)</td>
                    </tr>
                    <tr v-for="location in locations" :key="location.value">
                      <td>{{ location.text }}</td>
                      <td>
                        <v-text-field
                          :disabled="disabled"
                          outlined
                          type="number"
                          suffix="%"
                          :step="0.1"
                          :min="19.5"
                          :max="23"
                          :value="test[`${location.value}O2`]"
                          @input="
                            test[`${location.value}O2`] = setFloatValue($event)
                          "
                          :rules="[
                            rules.required.number,
                            rules.minO2,
                            rules.maxO2
                          ]"
                        ></v-text-field>
                      </td>
                      <td>
                        <v-text-field
                          :disabled="disabled"
                          outlined
                          type="number"
                          suffix="% L.E.L"
                          min="0"
                          :max="maxLEL"
                          :value="test[`${location.value}Lel`]"
                          @input="
                            test[`${location.value}Lel`] = setFloatValue($event)
                          "
                          :rules="[
                            rules.required.number,
                            rules.min,
                            rules.maxLEL
                          ]"
                        ></v-text-field>
                      </td>
                      <td>
                        <v-text-field
                          :disabled="disabled"
                          outlined
                          type="number"
                          suffix="ppm"
                          min="0"
                          :max="maxCO"
                          :value="test[`${location.value}Co`]"
                          @input="
                            test[`${location.value}Co`] = setFloatValue($event)
                          "
                          :rules="[
                            rules.required.number,
                            rules.min,
                            rules.maxCO
                          ]"
                        ></v-text-field>
                      </td>
                      <td>
                        <v-text-field
                          :disabled="disabled"
                          outlined
                          type="number"
                          suffix="ppm"
                          min="0"
                          :max="maxH2S"
                          :value="test[`${location.value}H2s`]"
                          @input="
                            test[`${location.value}H2s`] = setFloatValue($event)
                          "
                          :rules="[
                            rules.required.number,
                            rules.min,
                            rules.maxH2S
                          ]"
                        ></v-text-field>
                      </td>
                      <td>
                        <v-textarea
                          v-model="test[`${location.value}Other`]"
                          :disabled="disabled"
                          rows="1"
                          auto-grow
                          outlined
                        ></v-textarea>
                      </td>
                    </tr>
                  </table>
                </v-row>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </v-form>
    <v-row class="mt-5 mr-5"
      ><v-spacer></v-spacer
      ><v-btn :disabled="disabled" @click.stop="addNewTest" class="primary pa-5"
        ><v-icon class="mr-2">add_circle_outline</v-icon> Add Another
        Test</v-btn
      ></v-row
    >
  </div>
</template>
<script>
import dayjs from "@/plugins/dayjs";
import utils from "@/shared/Utils";
export default {
  name: "ConfinedSpaceSubstance",

  props: {
    disabled: Boolean,
    id: String,
    confinedSpaceTestsData: Array,
    sectionId: String
  },
  data() {
    return {
      isValid: false,
      locations: [
        {
          text: "Top",
          value: "top"
        },
        {
          text: "Middle",
          value: "mid"
        },
        {
          text: "Bottom",
          value: "bottom"
        }
      ],
      minO2: 19.5,
      maxO2: 23,
      maxLEL: 25,
      maxCO: 77,
      maxH2S: 10,
      rules: {
        required: {
          time: value => {
            return (
              (!this.disabled && !!value && value !== "Invalid Date") ||
              "Time Required"
            );
          },

          number: value => {
            return (
              (!this.disabled && !isNaN(parseFloat(value))) || "Number Required"
            );
          }
        },

        min: value => {
          return (
            (!this.disabled && !isNaN(parseFloat(value)) && value >= 0) ||
            `Minimum is 0`
          );
        },
        minO2: value => {
          return (
            (!this.disabled &&
              !isNaN(parseFloat(value)) &&
              value >= this.minO2) ||
            `Minimum is ${this.minO2}`
          );
        },
        maxO2: value => {
          return (
            (!this.disabled &&
              !isNaN(parseFloat(value)) &&
              value <= this.maxO2) ||
            `maximum ${this.maxO2}`
          );
        },
        maxLEL: value => {
          return (
            (!this.disabled &&
              !isNaN(parseFloat(value)) &&
              value <= this.maxLEL) ||
            `maximum ${this.maxLEL}`
          );
        },
        maxCO: value => {
          return (
            (!this.disabled &&
              !isNaN(parseFloat(value)) &&
              value <= this.maxCO) ||
            `maximum ${this.maxCO}`
          );
        },
        maxH2S: value => {
          return (
            (!this.disabled &&
              !isNaN(parseFloat(value)) &&
              value <= this.maxH2S) ||
            `maximum ${this.maxH2S}`
          );
        }
      }
    };
  },
  watch: {
    confinedSpaceTests(newValue, oldValue) {
      const isOnFormCreate =
        newValue.length > 0 &&
        Object.prototype.hasOwnProperty.call(newValue[0], "bottomH2s") &&
        !Object.prototype.hasOwnProperty.call(oldValue[0], "bottomH2s");
      if (isOnFormCreate) {
        this.$refs.confinedSpaceTests.resetValidation();
      }

      if (
        oldValue[0].hasOwnProperty("id") && // eslint-disable-line
        newValue[0].hasOwnProperty("id") // eslint-disable-line
      ) {
        this.$emit("change", true);
      }
    }
  },
  computed: {
    confinedSpaceTests() {
      return this.confinedSpaceTestsData;
    },
    activePanels: {
      get: function() {
        return this.confinedSpaceTests.map((c, i) => i);
      },
      set: function() {}
    }
  },
  methods: {
    setFloatValue(value) {
      return Number(value) === parseFloat(value) ? parseFloat(value) : null;
    },
    getTestTime(time) {
      return dayjs(time).isValid() ? `${dayjs(time).format("HH:mm")}` : null;
    },
    updateTime(value, time) {
      const datetime = Date.parse(time)
        ? `${dayjs(time).format("YYYY-MM-DD")} ${value}`
        : value
        ? `${dayjs().format("YYYY-MM-DD")} ${value}`
        : null;
      return datetime ? dayjs(datetime).toISOString() : null;
    },
    getValidationResult() {
      return [this.isValid ? 1 : 0, 1];
    },
    removeTest(index) {
      this.confinedSpaceTests.splice(index, 1);
    },
    addNewTest() {
      this.confinedSpaceTests.push({
        id: utils.getUUID(),
        component_id: this.id,
        section_id: this.sectionId,
        time: dayjs().format()
      });
    }
  },
  mounted() {
    // When a form has just been created we don't want to display any input validation errors
    this.$refs.confinedSpaceTests.resetValidation();
  }
};
</script>
<style scoped>
table {
  width: 100%;
}
th,
label {
  color: var(--v-primary-base) !important;
  font-weight: bold !important;
  font-size: large !important;
  text-align: left;
}
th {
  width: 165px;
  padding: 0 15px;
}
td {
  font-size: small;
  vertical-align: top;
  padding: 5px 15px;
}
th:first-child {
  padding: 0px !important;
  width: 85px;
}

th:last-child,
td:last-child {
  width: auto !important;
}

tr:nth-child(2) td:first-child {
  color: var(--v-primary-base) !important;
}

tr td:first-child {
  font-weight: bold !important;
  padding: 0 !important;
  font-size: large;
}

tr:nth-child(n + 3) td:first-child {
  padding-top: 18px !important;
}
</style>
