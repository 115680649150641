<template>
  <div :class="{ 'text--disabled': isDisabled }">
    <v-row class="pa-0 ma-0" v-show="!isFullscreen">
      <div class="pa-0 ma-0">
        <div class="d-flex">
          <label
            v-html="textToHTML(field.name)"
            :class="{ 'primary--text text--lighten-4': isDisabled }"
            class="primary--text pa-0 ma-0"
            :for="field.id"
          ></label
          ><span class="red--text ml-2" v-if="displayAsterisk">*</span>
          <v-checkbox
            v-if="field.inputType == 'CHECKBOX'"
            :id="field.id"
            class="pa-0 ma-0 ml-2"
            :placeholder="field.placeholderText"
            :false-value="'false'"
            :true-value="'true'"
            :value="value"
            :input-value="value"
            :rules="[
              rules.required,
              rules.requiredIfOtherFieldEquals,
              rules.containsAnyIfOtherFieldEquals,
              rules.notEqualToField
            ]"
            :disabled="isDisabled"
            @change="$emit('change', $event)"
            hide-details
          />
        </div>

        <p v-html="textToHTML(field.description)" class="caption pa-0 ma-0" />
      </div>
      <v-spacer></v-spacer>
      <v-checkbox
        class="pa-0 ma-0 mr-2"
        :input-value="isDisabled"
        value
        v-if="field.allowNotApplicable"
        :disabled="disabled"
        label="N/A"
        @change="setNotApplicable($event)"
      />
    </v-row>
    <v-row class="pa-0 ma-0">
      <v-text-field
        outlined
        v-if="field.inputType == 'STRING'"
        v-show="!isFullscreen"
        :id="field.id"
        :placeholder="field.placeholderText || field.defaultValue"
        :value="value"
        :counter="maxLimit"
        :maxlength="maxLimit"
        :disabled="isDisabled"
        :rules="[
          rules.required,
          rules.requiredIfOtherFieldEquals,
          rules.containsAnyIfOtherFieldEquals,
          rules.notEqualToField,
          rules.min,
          rules.max
        ]"
        @input="$emit('change', $event)"
        @blur="checkForDefaultValue"
      />
      <numeric
        v-else-if="field.inputType == 'DECIMAL' || field.inputType == 'NUMBER'"
        :id="field.id"
        :placeholder="field.placeholderText"
        :value="value"
        :rules="[
          rules.required,
          rules.requiredIfOtherFieldEquals,
          rules.containsAnyIfOtherFieldEquals,
          rules.notEqualToField,
          rules.min,
          rules.max
        ]"
        :isDecimal="field.inputType == 'DECIMAL'"
        :disabled="isDisabled"
        @change="$emit('change', $event)"
      />
      <percentage
        v-else-if="field.inputType == 'PERCENT'"
        :id="field.id"
        :placeholder="field.placeholderText"
        :value="value"
        :rules="[
          rules.required,
          rules.requiredIfOtherFieldEquals,
          rules.containsAnyIfOtherFieldEquals,
          rules.notEqualToField,
          rules.min,
          rules.max
        ]"
        :disabled="isDisabled"
        @change="$emit('change', $event)"
      />
      <gps-address
        v-else-if="field.inputType == 'ADDRESS'"
        :id="field.id"
        :placeholder="field.placeholderText"
        :value="value"
        :rules="[
          rules.required,
          rules.requiredIfOtherFieldEquals,
          rules.containsAnyIfOtherFieldEquals,
          rules.notEqualToField
        ]"
        :disabled="isInactive"
        :isOnline="isOnline"
        @change="$emit('change', $event)"
      />
      <gps-weather
        v-else-if="field.inputType == 'WEATHER'"
        :id="field.id"
        :placeholder="field.placeholderText"
        :value="value"
        :rules="[rules.required, rules.requiredIfOtherFieldEquals]"
        :disabled="isInactive"
        :isOnline="isOnline"
        @change="$emit('change', $event)"
      />
      <v-textarea
        outlined
        v-else-if="field.inputType == 'TEXT'"
        :id="field.id"
        :placeholder="field.placeholderText"
        :value="value"
        :disabled="isDisabled"
        auto-grow
        :rules="[
          rules.required,
          rules.requiredIfOtherFieldEquals,
          rules.containsAnyIfOtherFieldEquals,
          rules.notEqualToField,
          rules.min,
          rules.max
        ]"
        @input="$emit('change', $event)"
      />
      <yes-no
        v-else-if="field.inputType == 'YES_NO_NA'"
        :id="field.id"
        :value="value"
        :disabled="isDisabled"
        :rules="[
          rules.required,
          rules.requiredIfOtherFieldEquals,
          rules.containsAnyIfOtherFieldEquals,
          rules.notEqualToField
        ]"
        @change="$emit('change', $event)"
        hide-details
      />
      <v-switch
        v-else-if="field.inputType == 'BOOLEAN'"
        :id="field.id"
        :false-value="'false'"
        :true-value="'true'"
        :value="value"
        :input-value="value"
        :disabled="isDisabled"
        :rules="[
          rules.required,
          rules.requiredIfOtherFieldEquals,
          rules.containsAnyIfOtherFieldEquals,
          rules.notEqualToField
        ]"
        @change="$emit('change', $event)"
        hide-details
      />
      <select-one
        v-else-if="field.inputType == 'SELECT_ONE'"
        :id="field.id"
        :items="field.options"
        :placeholder="field.placeholderText"
        :allowOther="field.allowOther"
        :displayInline="field.displayInline"
        :initialValue="value"
        :rules="[
          rules.required,
          rules.requiredIfOtherFieldEquals,
          rules.containsAnyIfOtherFieldEquals,
          rules.notEqualToField
        ]"
        :disabled="isDisabled"
        @change="$emit('change', $event)"
      />
      <select-many
        v-else-if="field.inputType == 'SELECT_MANY'"
        :items="field.options"
        :placeholder="field.placeholderText"
        :allowOther="field.allowOther"
        :displayInline="field.displayInline"
        :initialValue="value"
        :rules="[
          rules.required,
          rules.requiredIfOtherFieldEquals,
          rules.containsAnyIfOtherFieldEquals,
          rules.notEqualToField
        ]"
        :disabled="isDisabled"
        @change="$emit('change', $event)"
      />
      <canvas-input
        v-else-if="field.inputType == 'SIGNATURE'"
        :id="field.id"
        :value="value"
        :rules="[rules.required, rules.requiredIfOtherFieldEquals]"
        :disabled="isDisabled"
        @input="$emit('change', $event)"
      />
      <canvas-input
        v-else-if="field.inputType == 'DRAWING'"
        :id="field.id"
        :asset="field.relatedAsset"
        :value="value"
        :rules="[rules.required, rules.requiredIfOtherFieldEquals]"
        :disabled="isDisabled"
        @input="$emit('change', $event)"
      />
      <datepicker
        v-else-if="field.inputType == 'DATE'"
        :id="field.id"
        :placeholder="field.placeholderText"
        :value="value"
        :rules="[
          rules.required,
          rules.requiredIfOtherFieldEquals,
          rules.containsAnyIfOtherFieldEquals
        ]"
        :disabled="isDisabled"
        @change="$emit('change', $event)"
      />
      <datetimepicker
        v-else-if="field.inputType == 'DATETIME'"
        :id="field.id"
        :placeholder="field.placeholderText"
        :initialValue="value"
        :rules="[rules.required, rules.requiredIfOtherFieldEquals]"
        :disabled="isDisabled"
        @change="$emit('change', $event)"
      />
      <v-text-field
        v-else-if="field.inputType == 'TIME'"
        type="time"
        :rules="[rules.required, rules.requiredIfOtherFieldEquals]"
        @input="updateTime($event)"
        @click:clear="$emit('change', null)"
        :value="getTime"
        :id="field.id"
        :placeholder="field.placeholderText"
        :disabled="isDisabled"
        clearable
        outlined
      ></v-text-field>
      <traffic-plan
        v-else-if="field.inputType == 'TRAFFIC_PLAN'"
        :id="field.id"
        :label="field.name"
        :value="value"
        :rules="[rules.required, rules.requiredIfOtherFieldEquals]"
        :disabled="isInactive"
        :placeholder="field.placeholder"
        @input="$emit('change', $event)"
        @alertError="$emit('alertError', $event)"
      />
      <employee-autocomplete
        v-else-if="
          field.inputType == 'EMPLOYEE' || field.inputType == 'EMPLOYEE_MANY'
        "
        :id="field.id"
        :placeholder="field.placeholderText"
        :disabled="isDisabled"
        :initialValue="value"
        :multiple="field.inputType == 'EMPLOYEE_MANY'"
        :rules="[
          rules.required,
          rules.requiredIfOtherFieldEquals,
          rules.containsAnyIfOtherFieldEquals,
          rules.notEqualToField
        ]"
        :crewMembers="crewMembers"
        @change="$emit('change', $event)"
      />
      <audio-recorder
        v-else-if="field.inputType == 'AUDIO'"
        title=""
        :id="field.id"
        :disabled="isDisabled"
        :initialValue="value"
        :rules="[
          rules.required,
          rules.requiredIfOtherFieldEquals,
          rules.containsAnyIfOtherFieldEquals
        ]"
        @change="$emit('change', $event, true)"
        @alertError="$emit('alertError', $event)"
      />
      <file-upload
        v-else-if="field.inputType == 'FILE'"
        :id="field.id"
        :disabled="isInactive"
        :initialValue="value"
        :rules="[
          rules.required,
          rules.requiredIfOtherFieldEquals,
          rules.containsAnyIfOtherFieldEquals
        ]"
        @change="$emit('change', $event)"
      />
      <combobox
        v-else-if="field.inputType == 'COMBOBOX'"
        :id="field.id"
        :source="field.placeholderText"
        :disabled="isInactive"
        :initialValue="value"
        :rules="[
          rules.required,
          rules.requiredIfOtherFieldEquals,
          rules.containsAnyIfOtherFieldEquals
        ]"
        @change="$emit('change', $event)"
      />
      <!-- <v-text-field
        outlined
        v-else
        :type="field.inputType"
        :label="field.name"
        :id="field.id"
        :placeholder="field.placeholderText"
        :value="value"
        :disabled="isDisabled"
        :rules="[
          rules.required,
          rules.requiredIfOtherFieldEquals,
          rules.containsAnyIfOtherFieldEquals,
          rules.notEqualToField,
          rules.min,
          rules.max,
        ]"
        @input="$emit('change', $event)"
      /> -->
    </v-row>
  </div>
</template>
<script>
import dayjs from "@/plugins/dayjs";
import numeric from "@/components/submission/numeric.vue";
import percentage from "@/components/submission/percentage.vue";
import gpsAddress from "@/components/submission/gps-address.vue";
import gpsWeather from "@/components/submission/gps-weather.vue";
import yesNo from "@/components/submission/yes-no.vue";
import selectOne from "@/components/submission/select-one.vue";
import selectMany from "@/components/submission/select-many.vue";
import canvasInput from "@/components/submission/canvas-input.vue";
import datepicker from "@/components/submission/datepicker.vue";
import datetimepicker from "@/components/submission/datetimepicker.vue";
import combobox from "@/components/submission/combobox.vue";
import utils from "@/shared/Utils";
import trafficPlan from "@/components/submission/traffic-plan.vue";
import audioRecorder from "@/components/submission/audio-recorder.vue";
import fileUpload from "@/components/submission/file-upload.vue";
import employeeAutocomplete from "@/components/submission/employee-autocomplete.vue";

export default {
  name: "SubmissionInput",
  props: {
    field: Object,
    mode: String,
    value: String,
    disabled: Boolean,
    isOnline: Boolean,
    getOtherFieldValue: { type: Function },
    getOtherFieldName: { type: Function },
    getFieldNotApplicable: { type: Function },
    crewMembers: Array
  },
  data() {
    return {
      min: this.field.validations.filter(function(v) {
        return v.mode
          ? v.type === "MIN" && v.mode === this.mode
          : v.type === "MIN";
      }),
      max: this.field.validations.filter(function(v) {
        return v.mode
          ? v.type === "MAX" && v.mode === this.mode
          : v.type === "MAX";
      }),
      required: this.field.validations.filter(v => v.type === "REQUIRED"),
      otherEquals: this.field.validations.filter(function(v) {
        return v.mode
          ? v.type === "REQUIRED_IF_OTHER_FIELD_EQUALS" && v.mode === this.mode
          : v.type === "REQUIRED_IF_OTHER_FIELD_EQUALS";
      }),
      otherNotEmpty: this.field.validations.filter(function(v) {
        return v.mode
          ? v.type === "REQUIRED_IF_OTHER_FIELD_NOT_EMPTY" &&
              v.mode === this.mode
          : v.type === "REQUIRED_IF_OTHER_FIELD_NOT_EMPTY";
      }),
      containsAnyIfOtherEquals: this.field.validations.filter(function(v) {
        return v.mode
          ? v.type === "CONTAINS_ANY_IF_OTHER_FIELD_EQUALS" &&
              v.mode === this.mode
          : v.type === "CONTAINS_ANY_IF_OTHER_FIELD_EQUALS";
      }),
      notEqualToFieldValue: this.field.validations.filter(function(v) {
        return v.mode
          ? v.type === "NOT_EQUAL_TO_FIELD_IF_OTHER_FIELD_EQUALS" &&
              v.mode === this.mode
          : v.type === "NOT_EQUAL_TO_FIELD_IF_OTHER_FIELD_EQUALS";
      }),
      rules: {
        required: value => {
          return !this.isDisabled && this.isRequired
            ? !!value || "Required."
            : true;
        },
        requiredIfOtherFieldEquals: value => {
          return !this.isDisabled &&
            (this.isOtherEquals || this.isOtherNotEmpty)
            ? !!value || "This field is required"
            : true;
        },
        containsAnyIfOtherFieldEquals: value => {
          return !this.isDisabled && this.isContainsAnyIfOtherEquals
            ? this.containsAnyIfOtherEquals[0].containedValues.includes(
                value
              ) ||
                `This field must contain one of these values: (${this.containsAnyIfOtherEquals[0].containedValues.toString()})`
            : true;
        },
        min: value => {
          if (this.min && this.min.length > 0) {
            const minValidation =
              this.field.inputType == "STRING" || this.field.inputType == "TEXT"
                ? value && value.length >= this.min[0].limit
                : value >= this.min[0].limit;
            return !this.isDisabled
              ? minValidation ||
                  `This field must be greater than or equal to ${this.min[0].limit}`
              : true;
          } else return true;
        },
        max: value => {
          if (this.max && this.max.length > 0) {
            const maxValidation =
              this.field.inputType == "STRING" || this.field.inputType == "TEXT"
                ? value && value.length <= this.max[0].limit
                : value <= this.max[0].limit;
            return !this.isDisabled
              ? maxValidation ||
                  `This field must be less than or equal to ${this.max[0].limit}`
              : true;
          } else {
            if (this.field.inputType == "STRING") {
              const check = value ? value.length <= 255 : true;
              return !this.isDisabled
                ? check || `This field must be less than or equal to 255`
                : true;
            } else return true;
          }
        },
        notEqualToField: () => {
          const isRuleConfigured = (this.notEqualToFieldValue || []).length;
          return !this.isDisabled && isRuleConfigured && this.isNotEqualToField
            ? `This field can't be the same as ${this.getOtherFieldName(
                this.notEqualToFieldValue[0].notEqualFieldId
              )}`
            : true;
        }
      }
    };
  },
  computed: {
    isInactive() {
      return this.isDisabled;
    },

    getTime() {
      return dayjs(this.value).isValid()
        ? `${dayjs(this.value).format("HH:mm")}`
        : null;
    },
    maxLimit() {
      return this.max && this.max.length > 0
        ? this.max[0].limit
        : this.field.inputType == "STRING"
        ? 255
        : null;
    },
    isDisabled() {
      let notApplicable = this.field.allowNotApplicable
        ? this.value === "N/A"
        : false;
      return this.disabled || notApplicable;
    },
    displayAsterisk() {
      let result =
        this.isRequired ||
        this.isOtherEquals ||
        this.isOtherNotEmpty ||
        this.isContainsAnyIfOtherEquals ||
        this.isNotEqualToField;
      return result;
    },
    isRequired() {
      return (
        this.required &&
        this.required.length > 0 &&
        (this.required[0].mode ? this.required[0].mode === this.mode : true)
      );
    },
    isContainsAnyIfOtherEquals() {
      return (
        this.containsAnyIfOtherEquals &&
        this.containsAnyIfOtherEquals.length > 0 &&
        this.getOtherFieldValue(
          this.containsAnyIfOtherEquals[0].otherFieldId
        ) == this.containsAnyIfOtherEquals[0].otherFieldValue
      );
    },
    isOtherEquals() {
      return (
        this.otherEquals &&
        this.otherEquals.length > 0 &&
        this.getOtherFieldValue(this.otherEquals[0].otherFieldId) ==
          this.otherEquals[0].otherFieldValue
      );
    },
    isOtherNotEmpty() {
      let result = null;
      if (
        this.otherNotEmpty &&
        this.otherNotEmpty.length > 0 &&
        !this.getFieldNotApplicable(this.otherNotEmpty[0].otherFieldId)
      )
        result = this.getOtherFieldValue(this.otherNotEmpty[0].otherFieldId);
      return result ? result.length > 0 : false;
    },
    isNotEqualToField() {
      return (
        this.notEqualToFieldValue &&
        this.notEqualToFieldValue.length > 0 &&
        this.getOtherFieldValue(this.notEqualToFieldValue[0].otherFieldId) ==
          this.notEqualToFieldValue[0].otherFieldValue &&
        !!(this.value || "").length &&
        (
          this.getOtherFieldValue(
            this.notEqualToFieldValue[0].notEqualFieldId
          ) || ""
        ).includes(this.value)
      );
    },
    isFullscreen() {
      if (this.$store) {
        return this.$store.getters.getIsFullscreen;
      }
      return false;
    }
  },
  components: {
    numeric,
    percentage,
    gpsAddress,
    gpsWeather,
    yesNo,
    selectOne,
    selectMany,
    canvasInput,
    datepicker,
    datetimepicker,
    trafficPlan,
    employeeAutocomplete,
    audioRecorder,
    fileUpload,
    combobox
  },
  methods: {
    checkForDefaultValue() {
      if ((!this.value || !this.value.trim()) && this.field.defaultValue)
        this.$emit("change", this.field.defaultValue);
    },
    updateTime(time) {
      const datetime = dayjs(this.value).isValid()
        ? `${dayjs(this.value).format("YYYY-MM-DD")} ${time}`
        : time
        ? `${dayjs().format("YYYY-MM-DD")} ${time}`
        : null;
      this.$emit("change", datetime ? dayjs(datetime).toISOString() : null);
    },
    setNotApplicable(val) {
      //this.isDisabled = !this.isDisabled;
      if (this.field.inputType !== "AUDIO") {
        if (val) this.$emit("change", "N/A");
        else {
          if (this.value === "N/A") this.$emit("change", "");
        }
      }
    },
    textToHTML(txt) {
      return utils.parseTextToHTML(txt);
    }
    // checkValue(evt) {
    //   // Check for when has default value and input is empty
    //   //   - Set input's value to default when input is empty
    //   //   - Typically for text input ('STRING')
    //   const noEmptySpaceRegExp = new RegExp('^\\s*$'); // Need to double escape to get past linter
    //   const isEmpty = noEmptySpaceRegExp.test(evt.target.value);

    //   if (!this.disabled && this.field.defaultValue !== null && isEmpty) {
    //     this.appliedValue = this.field.defaultValue;
    //   }
    // }
  }
};
</script>
<style scoped>
p {
  min-height: 18px;
}
</style>
