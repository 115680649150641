<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "OutsideClick",

  model: {
    event: "outsideclick"
  },

  mounted() {
    document.addEventListener("touchstart", this.handleClick);
    document.addEventListener("click", this.handleClick);
  },

  beforeDestroy() {
    document.removeEventListener("touchstart", this.handleClick);
    document.removeEventListener("click", this.handleClick);
  },

  methods: {
    handleClick(event) {
      let insideClick = false;

      // Check if the user clicked within any of this component's children
      this.$children.forEach(child => {
        if (child.$el.contains(event.target)) {
          insideClick = true;
        }
      });

      if (!insideClick) {
        this.$emit("outsideclick", event);
      }
    }
  }
};
</script>
