<template>
  <div class="select-one-input">
    <select-one-radio-group
      v-if="displayInline"
      :initialValue="initialValue"
      :items="items"
      :allowOther="allowOther"
      :rules="rules"
      :disabled="disabled"
      @change="$emit('change', $event)"
    />
    <template v-else>
      <allow-other-combobox
        v-if="allowOther"
        :id="id"
        :placeholder="placeholder"
        :multiple="false"
        :initialValue="initialValue"
        :initialItems="items"
        :rules="rules"
        :disabled="disabled"
        @change="$emit('change', $event)"
      />
      <v-select
        outlined
        v-else
        :id="id"
        :items="items"
        :placeholder="placeholder"
        :value="initialValue"
        :rules="rules"
        :disabled="disabled"
        @input="$emit('change', $event)"
      />
    </template>
  </div>
</template>
<script>
import SelectOneRadioGroup from "@/components/submission/select-one-radio-group.vue";
import AllowOtherCombobox from "@/components/submission/allow-other-combobox.vue";

export default {
  name: "SelectOne",

  props: {
    id: String,
    items: Array,
    placeholder: String,
    allowOther: Boolean,
    displayInline: Boolean,
    initialValue: [String, Number],
    disabled: Boolean,
    rules: Array
  },

  components: { SelectOneRadioGroup, AllowOtherCombobox }
};
</script>
