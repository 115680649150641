export default function paginate({ fetchPage, toEntries, toCursor }) {
  function mkNextPageFetcher(acc = []) {
    return function(res) {
      const after = toCursor(res);
      const entries = toEntries(res);
      const allSoFar = acc.concat(entries);
      if (after == null) {
        return Promise.resolve(allSoFar);
      }
      return fetchPage(after).then(mkNextPageFetcher(allSoFar));
    };
  }

  return fetchPage(null).then(mkNextPageFetcher());
}
